import React, {Component, createRef} from 'react';
import {
  Button, Form, Icon, Input, Upload, message, DatePicker,
  Tooltip, Select
} from 'antd'
import {getBase64, beforeUpload} from '../helpers/Funcs';
import {uploadPath} from '../helpers/ServerHost';
import Request from '../utils/Request';
import styled from 'styled-components';
import LocationSearch from '../ui/LocationSearch';
import moment from 'moment';
import Spinner from "../ui/Spinner";
import {withRouter} from "react-router-dom";

const {Option} = Select;

const initialState = {
  loading: false,
  imageUrl: '',
  showLink: false,
  showLocation: false,
  showImage: false,
  showDate: false,
  address: '',
  coords: '',
  showProduct: false,
  products: [],
  productsLoading: true,
  selectedProduct: 0,
  imageLoading: false
}

const OptsWrap = styled.div`
  margin-bottom: 2px;
  text-align: right;
  
  button {
    margin: 0 3px;
  }
`;

class ShoutForm extends Component{


  constructor (props, context){
    super (props, context);
    if (props.edit) {
      initialState.imageUrl = props.data.image ? props.data.image : '';
      initialState.showLink = !!props.data.link;
      initialState.showLocation = !!props.data.address;
      initialState.showDate = !!props.data.originalDate;
      initialState.showImage = !!props.data.image;
      initialState.showProduct = !!props.data.data.product;
      initialState.selectedProduct = props.data.data.product ? props.data.data.product.id : null;
    }
    this.state = initialState;
    this.uploadElement = createRef();
  }

  async componentDidMount() {
    const {edit, data} = this.props;
    if (edit && data.data.product) {
      await this.getProducts();
      this.setState({
        selectedProduct: data.data.product.id
      })
    }
  }

   getProducts = async () => {
    const {group} = this.props;
    let groupParam = '';
    if (group) groupParam = `?group_id=${group}`;
    const r = await new Request(`product/my${groupParam}`).get();
    if (r.status === 200) {
      this.setState({
        products: r.data,
        productsLoading: false
      });
    } else {
      message.error('Something went wrong');
    }
  }

  bu = async (file) => {
    if(beforeUpload(file)){
      let base64 = await getBase64(file);
      this.setState({
        imageUrl: base64,
        imageLoading: true
      })
    }
  };

  setLocation = (address, coords) => {
    console.log(coords)
    this.setState({
      address: address,
      coords: coords
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {form, edit, data, push, update, group} = this.props;
    const {imageUrl, address, coords, selectedProduct} = this.state;
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let postData = {
          text: values.text,
          link: values.link,
          image: imageUrl,
          address: address,
          coords: coords,
          event_time: values.date,
          product: selectedProduct
        };
        if (edit) postData.post_id = data.id;
        if (group) postData.group_id = group;
        let req = new Request(`post/shout/${edit ? 'update' : 'create'}`, postData);
        let res = await req.post();
        if (res.status === 200){
          this.setState(initialState);
          edit ? update(data.index, res.data) : push(res.data);
          form.resetFields();
        } else if (res.status === 403) {
          message.info(res.data.error);
          this.props.close();
          this.props.history.push('/settings/account');
        } else {
          message.error(res.data.error);
          this.setState({
            loading: false
          });
        }
      }
    });
  };

  manageField = (field) => {
    this.setState({
      [`show${field}`]: !this.state[`show${field}`],
      [field]: ''
    })
  };

  loadProducts = async () => {
    const {showProduct, products} = this.state;
    if (showProduct){
      this.setState({
        showProduct: false,
        selectedProduct: 0
      })
    } else {
      this.setState({
        showProduct: true
      });
      if(products.length === 0) await this.getProducts();
    }
  }

  render(){
    const { getFieldDecorator } = this.props.form;
    const {
      imageUrl,
      showLink,
      showLocation,
      showDate,
      showProduct,
      products,
      productsLoading,
      selectedProduct,
      imageLoading
    } = this.state;
    const {edit, data} = this.props;
    const uploadButton = (
      <div>
        <Icon type={'picture'} />
        <div className="ant-upload-text">Upload a picture</div>
      </div>
    );
    const productsSelectProps = {
      onChange: (val) => this.setState({selectedProduct: val}),
      placeholder: "Select a product",
      style: {width: '100%'},
      loading: productsLoading
    }
    if (!productsLoading && selectedProduct) productsSelectProps.value = selectedProduct;
    return (
      <Form onSubmit={this.handleSubmit} className="post-form">
        <OptsWrap>
          <Tooltip title="Product"><Button title="Product" onClick={this.loadProducts} size="small" type="link" icon="dollar"/></Tooltip>
          <Tooltip title="Link"><Button title="Link" onClick={() => this.manageField('Link')} size="small" type="link" icon="link"/></Tooltip>
          <Tooltip title="Location"><Button onClick={() => this.manageField('Location')} size="small" type="link" icon="environment"/></Tooltip>
          <Tooltip title="Date"><Button onClick={() => this.manageField('Date')} size="small" type="link" icon="calendar"/></Tooltip>
        </OptsWrap>
        <Form.Item hasFeedback>
          {getFieldDecorator('text', {
            rules: [
              {
                required: true,
                message: 'Please put some content',
              }
            ],
            initialValue: edit ? data.data.text : ''
          })(<Input.TextArea
            placeholder="What is on your mind?"
            rows={4}
            dir={'auto'}
          />)}
        </Form.Item>
        <Form.Item>
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={uploadPath}
            beforeUpload={this.bu}
            customRequest={async () => {
              let req = new Request('utils/upload', {
                file: imageUrl
              });
              let res = await req.post()
              if (res.status === 200) {
                this.setState({
                  imageUrl: res.data.url,
                  imageLoading: false
                });
                message.success('Picture was uploaded successfully');
              } else {
                this.setState({
                  imageUrl: '',
                  imageLoading: false
                });
              }
            }}
          >
            {imageLoading ? <Spinner/> : imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        </Form.Item>
        {showProduct && <Form.Item>
          <Select {...productsSelectProps}>
            {products.length > 0 ? products.map((v, i) => {
              return <Option value={v.id} key={i}>{v.name}</Option>
            }) : null}
          </Select>
        </Form.Item>}
        {showLink && <Form.Item hasFeedback>
          {getFieldDecorator('link', {
            initialValue: edit ? data.link ? data.link : '' : ''
          })(<Input
            placeholder="Share a link"
            prefix={<Icon type="link" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />)}
        </Form.Item>}
        {showLocation && <Form.Item>
          <LocationSearch default={edit ? data.address : ''} setLocation={this.setLocation}/>
        </Form.Item>}
        {showDate && <Form.Item hasFeedback>
          {getFieldDecorator('date', {
            initialValue: edit ? moment(moment.utc(data.originalDate).local(), "YYYY-MM-DD HH:mm") : null
          })(<DatePicker format="YYYY-MM-DD HH:mm" style={{width: '100%'}} showTime={{ format: 'HH:mm' }} placeholder="Select Time"/>)}
        </Form.Item>}
        <Form.Item>
          <Button
            loading={this.state.loading}
            shape="round"
            block
            type="primary"
            htmlType="submit"
            disabled={imageLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
  }

}

export default withRouter(Form.create({ name: 'shout' })(ShoutForm));