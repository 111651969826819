import {message} from 'antd';
import Request from '../utils/Request';
import React from 'react';
import styled from 'styled-components';
import Constants from "../Constants";


const Hashtag = styled.span`
  color: ${Constants.primaryColor};
  
  &:hover {
    cursor: pointer;
  }
`;


export function containsObject (object, list) {
  let i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === object) {
      return true;
    }
  }
  return false;
}

export function scrolledToBottom(el){
  return el.getBoundingClientRect().bottom <= window.innerHeight + 1;
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 4;
  if (!isLt2M) {
    message.error('Image must be smaller than 4MB!');
  }
  return isJpgOrPng && isLt2M;
}

export function beforeDocumentUpload(file) {
  const isLt2M = file.size / 1024 / 1024 < 4;
  if (!isLt2M) {
    message.error('Image must be smaller than 4MB!');
  }
  return isLt2M;
}

export async function getCurrentPrice (from, to) {
  let req = new Request(`utils/quotes?from=${from}&to=${to}`);
  let res = await req.get();
  if (res.status === 200){
    return res.data.value
  } else {
    return null;
  }
}

export function hashtagfy (string, push) {
  let parts = string.split(' ');
  let partsNew = parts.map((v, i) => {
    if (v.charAt(0) === '#') {
      let withoutHash = v.substr(1);
      return <Hashtag onClick={() => push(`/search/${withoutHash}`)} key={i}>{` ${v}`}</Hashtag>;
    } else {
      return ' '+v;
    }
  });
  return <span>{partsNew}</span>;
}
