import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Col, Row, Typography, message, Icon, Tabs, Badge } from 'antd'
import Request from '../utils/Request';
import ImgsViewer from 'react-images-viewer';
import Constants from '../Constants';
import EntityDrawer from './EntityDrawer';
import Posts from './Posts';
import Spinner from './Spinner'
import ProfileDetails from './ProfileDetails'
import Provider from '../utils/Provider'
import GroupInfo from './GroupInfo'
import Feedbacks from './Feedbacks'
import TransSpinner from './TransSpinner'
import CheckMark from "./CheckMark";
import Products from "./Products";

const { TabPane } = Tabs;

const {Title, Text, Paragraph} = Typography;

const HeaderWrap = styled.div`
  width: 100%;
  height: 150px;
  position: relative;
`;

const Background = styled.div`
  width: 100%;
  height: 125px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  background-color: #e8e8e8;
`;

const Avatar = styled.div`
  width: 112px;
  height: 112px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 25px;
  border: 4px solid #fff;
`;

const BodyWrap = styled.div`
  padding: 0 25px;
`;

class EntityFeed extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      data: props.data,
      entity: '',
      id: '',
      images: [],
      viewerIsOpen: false,
      currImg: 0,
      drawerIsOpen: false,
      loading: true,
      entityLoading: true,
      hash: null
    }
  }

  updateState = async () => {
    const {data} = this.state;
    if (data.hasOwnProperty('profile')){
      await this.setState({
        entity: 'user',
        id: data.profile.id,
        images: [{src:data.profile.basics.photo}],
        loading: false,
        entityLoading: false

      });
    } else {
      await this.setState({
        entity: 'group',
        id: data.id,
        images: [{src:data.basics.photo}],
        loading: false,
        entityLoading: false
      });
    }
  };

  componentDidMount(){
    console.log(window.location.hash);
    const {data} = this.state;
    if (data && 'profile' in data) {
      new Request('user/visit', {
        id: data.profile.id
      }).post();
    }
    this.updateState();
  }

  follow = async () => {
    let {entity, id, data} = this.state;
    let req = new Request(`${entity}/follow`, {
      [`${entity}_id`]: id
    });
    let res = await req.post();
    if (res.status === 200) {
      message.success(res.data.message);
      if (entity === 'user'){
        data.profile = res.data.data;
      } else {
        data = res.data.data
      }
      this.setState({
        data: data
      })
    }
  };

  goTo = (path) => {
    this.props.history.push(path);
  };

  gotoPrev = () => {
    const {currImg} = this.state;
    this.setState({
      currImg: currImg-1
    })
  };

  gotoNext = () => {
    const {currImg} = this.state;
    this.setState({
      currImg: currImg+1
    })
  };

  tabChange = (e) => {
    console.log(e);
  };

  async componentDidUpdate(prevProps, prevState){
    const prevUrl = prevProps.match.url;
    const newUrl = this.props.match.url;
    if (prevUrl !== newUrl) {
      await this.setState({
        loading: true,
        data: this.props.data
      });
      await this.updateState();
    }
  }

  render(){
    const {data, images, viewerIsOpen, currImg, drawerIsOpen, entity, id, loading} = this.state;
    const ownId = new Provider('user').get('profile').id;
    let selfCheck;
    if (entity === 'user' && id === ownId) selfCheck = true;
    else if (entity === 'group' && data.basics.isAdmin) selfCheck = true;
    let groupId = 0;
    let config = [];
    if (data.hasOwnProperty('profile')){
      config['backgroundImage'] = data.profile.backgroundImage;
      config['photo'] = data.profile.basics.photo;
      config['name'] = <span style={{color: data.profile.basics.nameColor}}>{data.profile.basics.name} {data.profile.basics.checkMark && <CheckMark />}</span>;
      if (data.profile.following === 'self'){
        config['followButton'] = <Button shape="round" type="default" onClick={() => this.goTo('/settings/profile')}>Update Profile</Button>;
        config['creatingPosts'] = true;
      } else {
        if (data.profile.following !== true) {
          config['followButton'] = <Button shape="round" type="primary" onClick={this.follow}>Follow</Button>
        }
      }
      config['website'] = data.socialNetworks.website;
      config['registered'] = data.profile.registered;
      config['about'] = data.profile.basics.about;
      config['followers'] = data.profile.count.followers;
      config['following'] = data.profile.count.following;
      config['groups'] = data.profile.count.groups;
      config['country'] = data.profile.basics.country;
      config['rating'] = data.profile.basics.rating;
      config['me'] = data.profile.me;
      config['rated'] = data.profile.rated;
      config['feedbackData'] = data.profile.basics;
    } else {
      groupId = data.basics.id;
      config['backgroundImage'] = data.backgroundImage;
      config['photo'] = data.basics.photo;
      config['name'] = data.basics.name;
      config['about'] = data.basics.motto;
      config['followers'] = data.followers;
      config['registered'] = data.basics.registered;
      config['website'] = data.socialNetworks.website;
      config['rating'] = data.basics.rating;
      config['rated'] = data.basics.rated;
      config['feedbackData'] = data.basics;
      if (data.basics.isAdmin) {
        config['followButton'] = <Button shape="round" type="default" onClick={() => this.goTo(`/group/${data.id}/settings`)}>Options</Button>;
        if (data.newRequests > 0 || data.newMessages > 0) config['followButton'] = <Badge dot><Button shape="round" type="default" onClick={() => this.goTo(`/group/${data.id}/settings`)}>Options</Button></Badge>
        config['creatingPosts'] = true;
        config['me'] = true;
      } else {
        if (data.following !== true) {
          config['followButton'] = <Button shape="round" type="primary" onClick={this.follow}>Follow</Button>
        }
        config['me'] = false;
      }
    }
    if (loading) return <Spinner/>;
    if (this.state.entityLoading) return <TransSpinner/>;
    return (
      <div className="entity-feed">
        <HeaderWrap>
          <Background src={config['backgroundImage']}/>
          <Avatar src={config['photo']} onClick={() => this.setState({viewerIsOpen: true})}/>
        </HeaderWrap>
        <BodyWrap>
          <Row>
            <Col xs={24} style={{textAlign: 'right'}}>
              {!selfCheck && <Button onClick={() => this.setState({drawerIsOpen: true})} type="primary" shape="circle" icon="ellipsis" />} {config['followButton']}
            </Col>
            <Col dir={'auto'} xs={24}><Title level={4}>{config['name']}</Title></Col>
          </Row>
          <Paragraph dir={'auto'}><Text style={{whiteSpace: 'pre-wrap'}}>{config['about']}</Text></Paragraph>
          {config['website'] && <Paragraph style={{margin: 0}}><Text>
            <Icon type="link"/>{' '}
            <a href={config['website']} target="_blank" rel="noopener noreferrer" style={{color: Constants.primaryColor,
              textDecoration: 'underline'}}>
            {config['website'].replace('https://', '').replace('http://', '')}
          </a></Text></Paragraph>}
          <Paragraph style={{margin: entity === 'user' ? 0 : 'inherit'}}><Icon type="calendar"/> {entity === 'group' ? 'Created' : 'Registration'}: {config['registered']}</Paragraph>
          {entity === 'user' && <Paragraph><Icon type="environment" /> {config['country']}</Paragraph>}
          <Row>
            <Col xs={8} onClick={() => this.goTo(`/${entity}/${id}/followers`)}><Text><strong>{config['followers']}</strong> {parseInt(config['followers']) === 1 ? 'follower' : 'followers'}</Text></Col>
            {entity === 'user' && <Col xs={8} onClick={() => this.goTo(`/${entity}/${id}/following`)}><Text><strong>{config['following']}</strong> following</Text></Col>}
            {entity === 'user' && <Col xs={8} onClick={() => this.goTo(`/${entity}/${id}/groups`)}><Text><strong>{config['groups']}</strong> groups</Text></Col>}
          </Row>
        </BodyWrap>
        {images.length > 0 && <ImgsViewer
          backdropCloseable={true}
          imgs={images}
          currImg={currImg}
          isOpen={viewerIsOpen}
          onClickPrev={this.gotoPrev}
          onClickNext={this.gotoNext}
          onClose={() => this.setState({viewerIsOpen: false})}
        />}
        <EntityDrawer
          data={data}
          visible={drawerIsOpen}
          close={() => this.setState({drawerIsOpen: false})}
        />
        <Tabs defaultActiveKey="posts" onChange={this.tabChange}>
          <TabPane tab="Posts" key="posts">
            <Posts group={groupId} path={`${entity}/feed?${entity}_id=${id}`} create={config['creatingPosts']} scroll={true} />
          </TabPane>
          <TabPane tab={entity === 'user' ? 'Profile' : 'Info'} key="profile">
            {entity === 'user' ? <ProfileDetails data={data}/> : <GroupInfo data={data}/>}
          </TabPane>
          <TabPane tab="Products" key="products">
            <Products entity={entity} id={id} adding={config['creatingPosts']} />
          </TabPane>
          <TabPane tab="Feedbacks" key="feedbacks">
            <Feedbacks
              entity={entity}
              id={id}
              rating={config['rating']}
              me={config['me']}
              data={config['feedbackData']}
              rated={config['rated']}
            />
          </TabPane>
        </Tabs>
      </div>
    )
  }

}

export default withRouter(EntityFeed);