import React, {Component} from 'react';
import WhiteBackground from '../ui/backgrounds/WhiteBackground';
import Container from './Container';


class Blank extends Component {
  render(){
    return (
      <WhiteBackground>
        <Container className={this.props.className}>
          {this.props.content}
        </Container>
      </WhiteBackground>
    )
  }
}

export default Blank;