import React, {Component} from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Icon, Input, List, Popover } from 'antd'
import Spinner from './Spinner';



class LocationSearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.default,
      coords: '',
      visible: false
    };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .then(latLng => {
      this.setState({
        address: address,
        visible: false
      });
      this.props.setLocation(address, `${latLng.lat},${latLng.lng}`);
    })
    .catch(error => console.error('Error', error));
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  render() {
    const {visible, address} = this.state;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div>
              <div className="autocomplete-dropdown-container">
                <Popover placement="bottom" content={loading ? <Spinner/> : suggestions.length > 0 ? <List
                  size="small"
                  dataSource={suggestions}
                  visible={visible}
                  onVisibleChange={this.handleVisibleChange}
                  renderItem={item => <List.Item className="cursor-pointer" {...getSuggestionItemProps(item)}>{item.description}</List.Item>}
                /> : address ? address : <span style={{fontSize: '12px', color: '#d3d3d3'}}>Start to type location name</span>} trigger="click">
                  <Input
                    {...getInputProps({
                      placeholder: 'Location',
                      className: 'location-search-input',
                      prefix: <Icon type="environment" />
                    })}
                  />
                </Popover>
              </div>
            </div>
          )
        }}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput