import React, {Component} from 'react';
import styled from 'styled-components';
import { Button, Icon, List, Popover } from 'antd'
import ShoutModal from '../modals/ShoutModal'
import TradeModal from '../modals/TradeModal'
import SignalModal from '../modals/SignalModal'
import DealModal from '../modals/DealModal'

const CreateButton = styled.div`
  position: fixed;
  right: 10px;
  bottom: 55px;
  z-index: 99;
`;

class CreatePostStack extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      showItems: false,
      shoutModal: false,
      tradeModal: false,
      signalModal: false,
      dealModal: false
    }
  }

  showItems = () => {
    this.setState({
      showItems: !this.state.showItems
    })
  };

  modal = (type) => {
    this.setState({
      [type]: !this.state[type],
      showItems: false
    })
  };

  render(){
    const createButtons = [
      <Button onClick={() => this.modal('shoutModal')} key="shout" type="link">Share an Idea</Button>,
      <Button onClick={() => this.modal('tradeModal')} key="trade" type="link">Trade Coins</Button>,
      <Button onClick={() => this.modal('dealModal')} key="deal" type="link">Have a deal</Button>
    ];
    const {showItems, shoutModal, tradeModal, signalModal, dealModal} = this.state;
    const {push, group} = this.props;
    return (
      <div>
        <CreateButton className="create-button">
          {/*<Popover*/}
          {/*  content={*/}
          {/*    <List*/}
          {/*      size="small"*/}
          {/*      dataSource={createButtons}*/}
          {/*      renderItem={item => <List.Item style={{zIndex: 99}}>{item}</List.Item>}*/}
          {/*    />*/}
          {/*  }*/}
          {/*  visible={showItems}*/}
          {/*  onVisibleChange={this.showItems}*/}
          {/*  className="create-popover"*/}
          {/*  placement="topRight"*/}
          {/*  trigger="click"*/}
          {/*>*/}
          {/*  <Button size="large" onClick={this.showItems} type="danger" shape="circle"><Icon type="plus"/></Button>*/}
          {/*</Popover>*/}
            <Button size="large" onClick={() => {
                this.setState({
                    shoutModal: true
                })
            }} type="danger" shape="circle"><Icon type="plus"/></Button>
        </CreateButton>
        <ShoutModal
          visible={shoutModal}
          close={() => this.modal('shoutModal')}
          push={push}
          group={group}
          key={'posts'}
        />
        <TradeModal
          visible={tradeModal}
          close={() => this.modal('tradeModal')}
          push={push}
          group={group}
        />
        <SignalModal
          visible={signalModal}
          close={() => this.modal('signalModal')}
          push={push}
          group={group}
        />
        <DealModal
          visible={dealModal}
          close={() => this.modal('dealModal')}
          push={push}
          group={group}
        />
      </div>
    )
  }

}

export default CreatePostStack;