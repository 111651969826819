import React, {Component} from 'react';
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import Spinner from './Spinner';
import Request from '../utils/Request';
import {scrolledToBottom} from '../helpers/Funcs';
import PostWrapper from './posts/PostWrapper';
import {message} from 'antd'
import Provider from '../utils/Provider';
import SessionData from '../utils/SessionData';
import PostLoader from './PostLoader';
import PostDrawer from './PostDrawer';
import styled from 'styled-components';

import CreatePostStack from '../stacks/CreatePostStack'


const initialState = {
  limit: 12,
  posts: [],
  page: 1,
  profile: false,
  loading: true,
  postsLoading: false,
  postsLoadingStarted: false,
  fullLoad: false,
  showDrawer: false,
  drawerData: {},
  createMenuOpen: false
}


const Nothing = styled.div`
  padding: 40px;
  text-align: center;
  width: 100%;
  color: #a3a3a3;
  font-size: 16px;
`;


class Posts extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      ...initialState,
      scroll: props.scroll
    };
    this.mounted = false;
  }

  resetState = async () => {
    await this.setState(initialState);
  };

  getPosts = async () => {
    if (this.mounted){
      const {limit, page, posts} = this.state;
      const {path} = this.props;
      let sign = path.includes('?') ? '&' : '?';
      const route = `${path}${sign}ppp=${limit}&page=${page}`;
      const session = new SessionData(route);
      if (!session.get()){
        let req = new Request(route);
        let res = await req.get();
        if (typeof(res) === 'undefined') {
          window.location.reload();
          return null;
        }
        if (res.status === 200){
          if (res.data.length > 0){
            this.setState({
              posts: [...posts, ...res.data],
              postsLoading: false,
              loading: false,
              postsLoadingStarted: false
            });
            session.set(res.data);
          } else {
            this.setState({
              postsLoading: false,
              loading: false,
              page: page - 1
            })
          }
        } else {
          message.warning('Oups! Something wrong happen! Please try again later...');
          return null;
        }
      } else {
        let prevPosts = [];
        for (let i = 1; i < page + 1; i++) {
          let prevRoute = `${path}${sign}ppp=${limit}&page=${i}`;
          let prevRec = new SessionData(prevRoute);
          prevPosts = [...prevPosts, ...prevRec.get()];
        }
        await this.setState({
          posts: prevPosts,
          postsLoading: false,
          postsLoadingStarted: false,
          loading: false
        });
      }
    }
  };

  async componentDidMount(){
    this.mounted = true;
    document.addEventListener('scroll', this.trackScrolling);
    const {profile} = this.state;
    const {path} = this.props;
    const self = this;
    const page = new SessionData(`page:${path}`)
    await this.setState({
      profile: new Provider('user').get('profile'),
      page: page.get() ? page.get() : 1
    });
    await this.getPosts();
    window.scrollTo(0, new SessionData(`scroll:${path}`).get());
    if (profile) {
      let flash = firebase.database().ref('global').child(profile.basics.id).child('flash');
      flash.on("value", (snapshot) => {
        let val = snapshot.val();
        const {posts} = this.state;
        posts.map((v, i) => {
          if (v.postType === val.postType && v.id === val.id){
            posts[i].flash = val.data;
            self.setState({posts: posts})
          }
          return null;
        });
      });
    }
  }

  async componentDidUpdate(prevProps){
    const {path} = this.props;
    const offset = window.pageYOffset;
    if (path !== prevProps.path){
      await this.resetState();
      await this.getPosts();
      const session = new SessionData(`scroll:${prevProps.path}`);
      session.set(offset);
      window.scrollTo(0, new SessionData(`scroll:${path}`).get());
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    const {path} = this.props;
    const offset = window.pageYOffset;
    const session = new SessionData(`scroll:${path}`);
    session.set(offset);
    const page = new SessionData(`page:${path}`)
    page.set(this.state.page);
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = async () => {
    if (this.state.scroll){
      const wrappedElement = document.getElementById('post-list');
      if (wrappedElement){
        const {fullLoad, postsLoadingStarted} = this.state;
        if (scrolledToBottom(wrappedElement) && !fullLoad && !postsLoadingStarted) {
          await this.setState({
            postsLoading: true,
            postsLoadingStarted: true,
            page: this.state.page+1
          });
          await this.getPosts();
        }
      }
    }
  };

  openDrawer = (data, index) => {
    data.index = index;
    this.setState({
      showDrawer: true,
      drawerData: data
    })
  };

  closeDrawer = () => {
    this.setState({
      showDrawer: false,
      drawerData: {}
    })
  };

  updatePost = (i, val) => {
    const {limit, page, posts} = this.state;
    const {path} = this.props;
    posts[i] = val;
    this.setState({
      posts: posts
    });
    const route = `${path}?ppp=${limit}&page=${page}`;
    const session = new SessionData(route);
    session.set(posts);
  };

  pushPost = (val) => {
    const {limit, page, posts} = this.state;
    const {path} = this.props;
    this.setState({
      posts: [val, ...posts]
    });
    const route = `${path}?ppp=${limit}&page=${page}`;
    const session = new SessionData(route);
    session.set(this.state.posts);
    window.scrollTo(0, 0);
  };

  deletePost = (i) => {
    const {limit, page, posts} = this.state;
    const {path} = this.props;
    posts.splice(i, 1);
    this.setState({
      posts: posts
    });
    const route = `${path}?ppp=${limit}&page=${page}`;
    const session = new SessionData(route);
    session.set(posts);
  };

  render(){
    const {posts, loading, postsLoading, fullLoad, showDrawer, drawerData} = this.state;
    if (loading) return <Spinner/>;
    if (posts.length === 0) return (
      <div>
        <Nothing>Nothing there yet :(</Nothing>
        {this.props.create && (
          <CreatePostStack group={this.props.group} push={this.pushPost}/>
        )}
      </div>
    );

    return (
      <div>
        <div id="post-list">
          {posts && (
            posts.map((v, i) => {
              if (v.flash) {
                return <PostLoader key={i} data={v.flash}/>
              } else {
                return <PostWrapper
                  options={this.openDrawer}
                  key={i}
                  index={i}
                  data={v}
                  update={this.updatePost}
                />
              }
            })
          )}
          {postsLoading && !fullLoad && <Spinner/>}
        </div>
        <PostDrawer
          data={drawerData}
          visible={showDrawer}
          close={this.closeDrawer}
          update={this.updatePost}
          del={this.deletePost}
        />
        {this.props.create && (
          <CreatePostStack group={this.props.group} push={this.pushPost}/>
        )}
      </div>
    )
  }

}

export default Posts;