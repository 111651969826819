import React , { Component} from 'react';
import { Modal } from 'antd';
import TradeForm from '../forms/TradeForm';


class TradeModal extends Component {

  push = (val) => {
    this.props.close();
    this.props.push(val);
  }

  render (){
    const {visible, close, group} = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={close}
        title="Trade coins"
        footer={null}
        className="shout-modal"
        style={{ top: 0 }}
      >
        <TradeForm group={group} push={this.push} edit={false}/>
      </Modal>
    )
  }

}

export default TradeModal;