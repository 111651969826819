import React, {Component} from 'react';
import { Col, Icon, Row, Typography } from 'antd'
import styled from 'styled-components';
import moment from 'moment';

import GoogleMap from '../GoogleMap';
import Constants from '../../Constants'
import FollowButton from '../FollowButton'

const { Paragraph, Title } = Typography;
const Date = styled.div`
  color: ${Constants.primaryColor}
  font-size: 16px;
  font-weight: bold;
`;

class Event extends Component {

  render(){
    const {data} = this.props.data;
    const {icon, map, follow} = this.props;
    let utc = moment.utc(data.originalDate);
    const local = moment(utc).local().format("dddd, MMMM Do YYYY, HH:mm");
    return (
      <div>
        <Date>{local}</Date>
        <Title level={4}>{data.title}</Title>
        <Row>
          <Col xs={icon ? 16 : 24}>
            <Paragraph ellipsis>
              {data.description}
            </Paragraph>
          </Col>
          {icon && <Col xs={8}>
            <FollowButton onClick={follow} disabled={this.props.data.owner} following={this.props.data.following}/>
          </Col>}
        </Row>
        <Paragraph ellipsis={this.props.cut}>
          <Icon type="environment" /> {data.address}
        </Paragraph>
        {map && data.coords && <GoogleMap
          lat={data.coords.lat}
          lng={data.coords.lng}
          width="100%"
          height="150px"
        />}
      </div>
    )
  }

}

export default Event;