import React, {Component} from 'react';
import {Form, Button, InputNumber, message, Modal} from 'antd'
import Request from '../utils/Request';
import Provider from '../utils/Provider'
import { Redirect } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";


class ConfirmPhoneForm extends Component {

  constructor(props, context){
    super(props, context);
    let codeSent = false;
    if (localStorage.getItem('codeSent')) codeSent = localStorage.getItem('codeSent');
    this.state = {
      loading: false,
      codeSent: codeSent,
      complete: false,
      phone: props.phone,
      captcha: null
    }
  }

  handlePhone = (e) => {
    e.preventDefault();
    const {captcha} = this.state;
    if (!captcha) {
      message.error('Please, confirm you are not a robot!')
      return null;
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let req = new Request('user/set_phone', {
          phone: values.phone
        });
        let res = await req.post();
        if (res.status !== 200){
          message.error(res.data.error);
          this.setState({
            loading: false
          });
        } else {
          message.info(res.data.message);
          this.setState({
            phone: '',
            loading: false,
            codeSent: true
          });
          localStorage.setItem('codeSent', '1');
          this.props.form.resetFields();
        }
      }
    });
  };

  componentDidMount(){
    this.props.form.setFields({
      phone: {
        value: this.state.phone
      },
    });
  }

  handleCode = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let res = await Request.create('GET', 'user/confirm', false, {
          'Phone': values.code
        });
        if (res.status !== 200){
          message.error(res.data.error);
          this.setState({
            loading: false
          });
        } else {
          message.info(res.data.message);
          const provider = new Provider('user');
          await provider.set(res.data.data);
          await this.setState({
            complete: true,
            loading: false
          });

        }
      }
    });
  };

  call = async () => {
    const r = await new Request('user/verification_call').post();
    if (r.status === 200) {
      message.info('We are calling you. Pick up the phone!');
    } else {
      message.error(r.data.error);
    }
  }

  render(){
    const { getFieldDecorator } = this.props.form;
    const {codeSent, complete} = this.state;
    const {params} = this.props;
    if (complete) {
      if (!params.postType && !params.id) {
        return <Redirect to="/feed"/>;
      } else {
        return <Redirect to={'/post/' + params.postType + '/' + params.id }/>;
      }
    }
    return (
      <div>
        {codeSent ? (
          <Form onSubmit={this.handleCode} id="codeForm" key={'code'}>
            <Form.Item>
              {getFieldDecorator('code', {
                rules: [
                  { required: true, message: 'Please put verification code!' },
                  { type: 'number', message: 'Code is not valid!' },
                ]
              })(
                <InputNumber
                  placeholder="Verification code"
                  style={{width: '100%'}}
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Button
                loading={this.state.loading}
                shape="round"
                block
                type="primary"
                htmlType="submit"
                form="codeForm"
              >
                Verify
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={() => Modal.confirm({
                title: 'Verification required',
                content: 'In case you did not receive SMS with verification code, we might call you to spell it. Would you like to continue?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: this.call
              })} size={'small'} type={'link'} block>Didn't receive SMS?</Button>
            </Form.Item>
          </Form>
        ) : (
          <Form onSubmit={this.handlePhone} id="phoneForm" key={'form'}>
            <Form.Item>
              {getFieldDecorator('phone', {
                rules: [
                  { required: true, message: 'Please put your phone!' }
                ]
              })(
                <InputNumber
                  placeholder="Phone number"
                  style={{width: '100%'}}
                  formatter={value => `+${value}`}
                />,
              )}
            </Form.Item>
            <Form.Item>
              <ReCAPTCHA
                  sitekey={'6LfQXW4dAAAAAKvr_0Ng1ey9HZywATZAUDGGUxmn'}
                  onChange={v => this.setState({captcha: v})}
                  size="normal"
                  className="captcha"
                  badge={'inline'}
                  render="explicit"
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={this.state.loading}
                shape="round"
                block
                type="primary"
                htmlType="submit"
                form="phoneForm"
              >
                Send code
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    )
  }
}

export default Form.create({ name: 'confirmPhone' })(ConfirmPhoneForm);