import React, {Component} from "react";
import styled from "styled-components";
import Constants from "../Constants";
import Logo from "../template/Logo";
import {Button, Empty, message} from "antd";
import Spinner from "./Spinner";
import Request from "../utils/Request";
import {withRouter} from "react-router-dom";
import {Typography} from "antd";
import ItemsCarousel from 'react-items-carousel';
import ProductPreview from "./ProductPreview";
import Cart from "../desktop/components/Cart";
import Payment from "../desktop/components/Payment";


const {Title, Paragraph} = Typography;


const Wrapper = styled.div`
  border: 2px solid ${Constants.primaryColor};
  margin: 10px;
  border-radius: 20px;
  width: calc(100% - 20px);
  max-width: 550px;
  min-height: 600px;
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Header = styled.div`
  flex-direction: row;
  display: flex;
  margin: 20px 0;
  & div:first-child {
    width: 130px;
  }
  & div:last-child {
    width: 100%;
  }
  
`;

const Avatar = styled.div`
  width: 100px;
  height: 100px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-repeat: no-repeat;
  margin-right: 15px;
`;

const Description = styled.div`
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  margin: 40px 0;
  text-align: center;
`;


class ProductsWidget extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            products: [],
            data: null,
            activeScreen: 'products',
            activeItemIndex: 0,
            cart: [],
            pid: null
        }
    }

    async componentDidMount() {
        const {entity, id} = this.props.match.params;
        const r = await new Request(`product/all?entity=${entity}&id=${id}`).get();
        if (r.status === 200) {
            let cart = localStorage.getItem(`${entity}${id}cart`);
            if (cart === null) {
                cart = []
            } else {
                cart = JSON.parse(cart);
            }
            this.setState({
               products: r.data.products,
               data: r.data.info,
                cart: cart,
               loading: false
            });
        }
    }

    addToCart = (product_id, quantity) => {
        const {entity, id} = this.props.match.params;
        const key = `${entity}${id}cart`;
        let cart = localStorage.getItem(key);
        if (cart === null) {
            cart = []
        } else {
            cart = JSON.parse(cart);
        }
        if (cart.length === 0) {
            cart.push({
                id: product_id,
                quantity: quantity
            });
            localStorage.setItem(key, JSON.stringify(cart));
            this.setState({cart: cart});
            message.info('Product was added to the cart');
        } else {
            cart.map((v) => {
                if (v.id === product_id) {
                    message.error('Product already in the cart!');
                    return null;
                } else {
                    cart.push({
                        id: product_id,
                        quantity: quantity
                    });
                    localStorage.setItem(key, JSON.stringify(cart));
                    this.setState({cart: cart});
                    message.info('Product was added to the cart');
                }
                return null;
            });
        }
    }

    goToCart = () => {
        const {cart} = this.state;
        if (!cart.length) {
            message.error('Your cart is empty!');
            return null;
        }
        this.setState({activeScreen: 'cart'});
    }

    goToPayment = (pid) => {
        this.setState({
            activeScreen: 'payment',
            pid: pid
        })
    }

    renderProducts = () => {
        const {products, data, activeItemIndex, cart} = this.state;
        const {entity, id} = this.props.match.params;
        return <div style={{width: '100%'}}>
            <Header>
                <Avatar src={data.photo}/>
                <div>
                    <Title level={4} style={{color: Constants.primaryColor}}>{data.name}</Title>
                    <Description>
                        <Paragraph type={'secondary'}>{data.description}</Paragraph>
                    </Description>
                </div>
            </Header>
            <ItemsCarousel
                infiniteLoop={false}
                gutter={12}
                activePosition={'center'}
                chevronWidth={15}
                disableSwipe={false}
                alwaysShowChevrons={false}
                numberOfCards={3}
                slidesToScroll={1}
                outsideChevron={true}
                showSlither={false}
                firstAndLastGutter={false}
                activeItemIndex={activeItemIndex}
                requestToChangeActive={value => this.setState({ activeItemIndex: value })}
                rightChevron={'>'}
                leftChevron={'<'}
            >
                {products.map((v, i) => {
                    return <ProductPreview
                        productIndex={i}
                        key={i}
                        // remove={() => this.removeProduct(i)}
                        data={v}
                        editing={false}
                        // defaultOpen={i === openedProductIndex}
                        addToCart={this.addToCart}
                        entity={entity}
                        entity_id={id}
                        // updateProduct={this.updateProduct}
                    />
                })}
            </ItemsCarousel>
            <ButtonContainer>
                <Button onClick={this.goToCart} type={'danger'} size={'large'} shape={'round'} icon={'shopping-cart'}>Cart {cart.length > 0 && `(${cart.length})`}</Button>
            </ButtonContainer>
        </div>
    }

    renderCart = () => {
        return <Cart isWidget={true} goToPayment={this.goToPayment}/>;
    }

    renderPayment = () => {
        const {pid} = this.state;
        return <Payment isWidget={true} pid={pid}/>;
    }

    renderScreen = () => {
        const {activeScreen} = this.state;
        switch (activeScreen) {
            case 'products':
                return this.renderProducts();
            case 'cart':
                return this.renderCart();
            case 'payment':
                return this.renderPayment();
            default:
                return null;
        }
    }

    render() {
        const {loading, products} = this.state;
        const {entity, id} = this.props.match.params;
        return <Wrapper>
            <a href={`${Constants.client}?src=${entity}${id}`} target={'_blank'}>
                <Logo width={75} height={100} path={'logos/original'}/>
            </a>
            <Container>
                {loading && <Spinner/>}
                {!loading && !products.length && <Empty description={'No items found'}/>}
                {!loading && products.length && this.renderScreen()}
            </Container>
        </Wrapper>
    }

}

export default withRouter(ProductsWidget);