import React, {Component} from 'react';
import styled from 'styled-components';
import Provider from '../utils/Provider';


const AvatarIcon = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-image: url("${props => props.photo}");
  
  @media screen and (max-width: 768px) {
    width: 32px
    height: 32px;
  }
`;

class Avatar extends Component {

  constructor (props, context){
    super(props, context);
    const profile = new Provider('user').get('profile');
    this.state = {
      avatar: props.src ? props.src : profile.basics.photo,
      width: props.width ? props.width : 32,
      height: props.height ? props.height : 32
    }
  }

  render(){
    const {avatar, width, height} = this.state
    return (
      <AvatarIcon
        photo={avatar}
        width={width}
        height={height}
      />
    )
  }
}


export default Avatar;