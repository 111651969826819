import React, {Component} from 'react';
import { Icon, Typography } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 15px;
  width: 100%;
  background-color: rgba(255,255,255,.8);
  border-radius: 8px;
  position: absolute;
  max-width: 250px;
  top: 15px;
  right: 15px;
  
  &:hover {
    cursor: pointer;
  }
`

const {Title, Paragraph, Text} = Typography;

class OG extends Component {

  openLink = () => {
    if (this.props.data.url) {
      let win = window.open(this.props.data.url, '_blank');
      win.focus();
    }
    return null;
  }

  render(){
    const {data} = this.props
    return(
      <Wrapper onClick={this.openLink}>
        <Paragraph style={{textAlign: 'right', margin: 0}}><Icon type={'monitor'}/> See more:</Paragraph>
        <Paragraph style={{margin: 0}} strong>{data.title}</Paragraph>
        <Paragraph  style={{marginBottom: 10}} ellipsis>{data.description}</Paragraph>
        <Paragraph style={{textAlign: 'right', margin: 0}}>{data.site_name}</Paragraph>
      </Wrapper>
    )
  }

}

export default OG;