import styled from 'styled-components';
import ServerHost from '../helpers/ServerHost'

const LogoMobile = styled.div`
  width: 40px;
  height: 40px;
  background-image: url("${props => ServerHost(`img/logos/logo144.png`)}");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
`

export default LogoMobile;