import styled from 'styled-components';
import ServerHost from '../helpers/ServerHost'


const LikeIcon = styled.button`
  width: 60px;
  height: 60px;
  background-image: url("${props =>  ServerHost(`img/icons/${props.active ? 'like' : 'dislike'}.png`)}");
  background-size: 100%;
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: -25px;
  z-index: 1;
  transform: translateX(-50%);
  left: 50%;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
  border-radius: 50%;
  
  &:focus {
    outline: none;
  }
  
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, .5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }
  
  &:focus:not(:active)::after {
    animation: ripple 1s ease-out;
  }
`;

export default LikeIcon;