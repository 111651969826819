import React, {Component} from 'react';
import { Avatar, Card, Icon, Badge, Button, message } from 'antd';
import CheckMark from '../CheckMark';
import Constants from '../../Constants';
import styled from 'styled-components';
import Shout from './Shout';
import PostImage from '../PostImage';
import OG from '../OG';
import ReactPlayer from 'react-player';
import Request from '../../utils/Request';
import Event from './Event';
import Trade from './Trade';
import Signal from './Signal';
import { NavLink } from 'react-router-dom'
import LikeIcon from '../LikeIcon'
import ImgsViewer from 'react-images-viewer'
import Deal from './Deal'


const Time = styled.span`
  color: #b3b3b3;
  font-size: 12px;
  margin: 0 5px;
  font-weight: 100;
`

const Options = styled.span`
  float: right;
`

const Likes = styled.span`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -25px;
  color: ${props => props.active ? Constants.primaryColor : '#a8a8a8'};
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const PicWrap = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
`

const { Meta } = Card;


class PostWrapper extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      image: '',
      viewerIsOpen: false,
      currImg: 0,
    }
  }

  componentDidMount(){
    this.setPic();
  }

  setPic = () => {
    const {data} = this.props;

    if (data.og) {
      if (data.og.image && !data.image){
        this.setState({
          image: <PicWrap>
            <PostImage onClick={() => this.setState({viewerIsOpen: true})} src={data.og.image}/>
            <OG data={data.og}/>
            <LikeIcon active={data.liked} onClick={this.like}>
              <Likes active={data.likes > 0}>{data.likes}</Likes>
            </LikeIcon>
          </PicWrap>
        });
      } else {
        this.setState({
          image: <PicWrap>
            <PostImage onClick={() => this.setState({viewerIsOpen: true})} src={data.image}/>
            <OG data={data.og}/>
            <LikeIcon active={data.liked} onClick={this.like}>
              <Likes active={data.likes > 0}>{data.likes}</Likes>
            </LikeIcon>
          </PicWrap>
        });
      }
      if (data.og['video:url']){
        this.setState({
          image: <ReactPlayer
            key="media"
            muted
            controls
            width="100%"
            height="250px"
            url={data.og['video:url']}
            playing
          />
        });
      }
    } else {
      if (data.image){
        this.setState({
          image: <PicWrap>
            <PostImage onClick={() => this.setState({viewerIsOpen: true})} src={data.image}/>
            <LikeIcon active={data.liked} onClick={this.like}>
              <Likes active={data.likes > 0}>{data.likes}</Likes>
            </LikeIcon>
          </PicWrap>
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState){
    const {data} = this.props;
    const newStr = `${data.postType}/${data.id}`;
    const oldStr = `${prevProps.data.postType}/${prevProps.data.id}`;
    if (newStr !== oldStr) this.setPic();
  }

  openLink = () => {
    if (this.props.data.link) {
      let win = window.open(this.props.data.link, '_blank');
      win.focus();
    }
    return null;
  }

  follow = async () => {
    const {data, update, index} = this.props;
    let req = new Request(`post/${data.postType}/follow`, {
      post_id: data.id
    });
    let res = await req.post();
    if (res.status === 200){
      update(index, res.data);
    } else {
      message.error(res.data.error)
    }
  }

  like = async () => {
    const {data, update, index} = this.props;
    let req = new Request(`post/${data.postType}/like`, {
      post_id: data.id
    });
    let res = await req.post();
    if (res.status === 200){
      await update(index, res.data);
      this.setPic();
    } else {
      message.error(res.data.error)
    }
  }

  render(){
    const {data, options, index} = this.props;
    const {image, viewerIsOpen} = this.state;
    let component = <Shout data={data} collapse={false}/>
    switch (data.postType){
      case 'shout':
        component = <Shout collapse={false} follow={this.follow} key="content" data={data} cut={true} icon id={data.id}/>;
        break;
      case 'event':
        component = <Event follow={this.follow} key="content" data={data} cut={true} icon id={data.id}/>;
        break;
      case 'trade':
        component = <Trade follow={this.follow} key="content" data={data} icon id={data.id} short/>;
        break;
      case 'signal':
        component = <Signal follow={this.follow} key="content" data={data} icon id={data.id} short/>;
        break;
      case 'deal':
        component = <Deal follow={this.follow} key="content" data={data} icon id={data.id} short/>;
        break;
      default:
        return null;
    }
    let actions = [
      <Button disabled={!data.link} onClick={this.openLink} size="small" type="link" key="link" >
        <Icon style={{
          width: 'auto',
          color: data.link ? Constants.primaryColor : '#a8a8a8',
          fontSize: data.link ? 20 : 14
        }} type="link"/>
      </Button>,
      <NavLink to={`/post/${data.postType}/${data.id}`} key="calendar">
        <Icon style={{
          width: 'auto',
          color: data.date ? Constants.secondaryColor : '#a8a8a8',
          fontSize: data.date ? 20 : 14
        }} type="calendar"/>
      </NavLink>,
      <NavLink to={`/post/${data.postType}/${data.id}`} key="location">
        <Icon style={{
          width: 'auto',
          color: data.address ? Constants.green : '#a8a8a8',
          fontSize: data.address ? 20 : 14
        }} type="environment"/>
      </NavLink>,
      <NavLink to={`/post/${data.postType}/${data.id}`} key="comments">
        <Button size="small" type="link">
          <Icon style={{
            width: 'auto',
            color: data.comments > 0 ? Constants.orange : '#a8a8a8',
            fontSize: data.comments > 0 ? 20 : 14
          }} type="message" />
          <span style={{
            margin: '0 2px',
            color: data.comments > 0 ? Constants.orange : '#a8a8a8'
          }}>{data.comments}</span>
        </Button>
      </NavLink>
    ];
    return (
      <div className="feed-post-wrap">
        <Card
          style={{ width: '100%' }}
          dir={'auto'}
        >
          <Meta
            avatar={<NavLink to={`/${data.entity}/${data[data.entity].id}`}>
              <Badge
                status={data.user ? data.user.online ? 'success' : false : false}
                offset={[-5, 30]}
                style={{zIndex: 0}}
              >
                <Avatar src={data.user ? data.user.photo : data.group.photo} />
              </Badge>
            </NavLink>}
            title={
              <p>
                <NavLink style={{color: data.user ? data.user.nameColor : `${Constants.primaryColor}`}} to={`/${data.entity}/${data[data.entity].id}`}>
                  {data.user ? data.user.name : data.group.name}
                  {data.user ? data.user.checkMark ? <CheckMark/> : '' : data.group.checkMark ? <CheckMark/> : ''}
                </NavLink>
                <Time>{data.createdAt}</Time>
                <Options><Button onClick={() => options(data, index)} size="small" type="link"><Icon style={{width: 'auto'}} type="more"/></Button></Options>
              </p>
            }
            description={component}
          />
        </Card>
        {image}
        <Card
          style={{ width: '100%' }}
          actions={actions}
        />
        <ImgsViewer
          backdropCloseable={true}
          imgs={[{src: data.image}]}
          currImg={0}
          isOpen={viewerIsOpen}
          onClickPrev={this.gotoPrev}
          onClickNext={this.gotoNext}
          onClose={() => this.setState({viewerIsOpen: false})}
        />
      </div>
    )
  }

}

export default PostWrapper;