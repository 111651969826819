import React, {Component} from 'react';
import {
  Avatar, Badge, Button, Card, Col, Icon, Row, Tag,
  Typography,
} from 'antd';
import { NavLink } from 'react-router-dom';
import CheckMark from './CheckMark';
import Constants from '../Constants';
import EntityListDrawer from './EntityListDrawer';
import Provider from '../utils/Provider';
const { Text, Paragraph } = Typography;


const {Meta} = Card;


class EntityCard extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      showDrawer: false
    }
  }

  render(){
    const {showDrawer} = this.state;
    let {data, admin, remove, index, update} = this.props;
    let fullData = data;
    if (data.entity === 'user') {
      data = data.basics
    }
    const id = new Provider('user').get('profile').id;
    const selfCheck = data.entity === 'user' && data.id === id;
    return (
      <div>
        <Card dir={'auto'} style={{width: '100%'}} className="entity-list">
          {!selfCheck && <Button icon="more" type="link" style={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1
          }} onClick={() => this.setState({
            showDrawer: true
          })} />}
          <Row>
            <Col xs={16}>
              <Meta
                avatar={
                  <NavLink to={`/${data.entity}/${data.id}`}>
                    <Badge
                      status={typeof (data.online) !== 'undefined' && data.online ? 'success' : false}
                      offset={[-5, 30]}
                      style={{zIndex: 0}}
                    >
                      <Avatar src={data.photo} />
                    </Badge>
                  </NavLink>}
                title={(
                    <NavLink to={`/${data.entity}/${data.id}`} style={{
                      color: typeof (data.nameColor) !== 'undefined' ? data.nameColor : Constants.primaryColor
                    }}>{data.name} {typeof (data.checkMark) !== 'undefined' && data.checkMark && <CheckMark/>} {fullData.blocked === true && <Icon type="stop" style={{
                      color: Constants.secondaryColor
                    }}/>}
                      {this.props.data.isAdmin === true && [' ', <Badge count={'A'}/>]}
                      {this.props.data.isModerator === true && [' ', <Badge count={'M'}/>]}
                    </NavLink>)}
                description={(
                  <div>
                    <Paragraph><Text type="secondary">{data.entity === 'user' ? data.about : data.motto}</Text></Paragraph>
                    <Paragraph style={{margin: 0}}><Tag color="blue">{data.followers} {parseInt(data.followers) === 1 ? 'follower' : 'followers'}</Tag></Paragraph>
                  </div>
                )}
              />
            </Col>
            <Col xs={8} style={{textAlign: 'right', height: 78, position: 'relative'}}>
              <NavLink to={`/${data.entity}/${data.id}`} style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
                <Button type={fullData.following ? 'default' : 'primary'} shape="round">{fullData.following ? 'Following' : "Follow"}</Button>
              </NavLink>
            </Col>
          </Row>
        </Card>
        <EntityListDrawer
          index={index}
          data={fullData}
          visible={showDrawer}
          admin={admin}
          close={() => this.setState({
            showDrawer: false
          })}
          update={update}
          remove={remove}
        />
      </div>
    )
  }

}

export default EntityCard;