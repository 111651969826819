class SessionData {

  constructor (object){
    if (sessionStorage.getItem(object)){
      this.data = JSON.parse(sessionStorage.getItem(object));
    } else {
      this.data = false;
    }
    this.object = object;
  }

  get = () => {
    return this.data;
  }

  set = (value) => {
    sessionStorage.setItem(this.object, JSON.stringify(value));
    return null;
  }

}

export default SessionData;