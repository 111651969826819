import React, {Component} from 'react';
import { Button } from 'antd';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';


class FacebookButton extends Component {

  constructor (props, context){
    super(props, context);
    this.state = {
      loading: false
    }
  }

  click = (renderProps) => {
    renderProps.onClick();
    this.setState({
      loading: true
    })
  }

  render(){
    return (
      <FacebookLogin
        appId="196107921163251"
        callback={this.props.callback}
        isDisabled={this.props.disabled}
        render={renderProps => (
          <Button
            disabled={this.props.disabled}
            loading={this.state.loading}
            shape="round"
            className="btn-facebook"
            block
            icon="facebook"
            onClick={() => this.click(renderProps)}>
            Continue with Facebook
          </Button>
        )}
      />
    )
  }

}

export default FacebookButton;