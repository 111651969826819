import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Centerized from '../template/Centerized';
import BlueFrame from '../template/BlueFrame';
import Logo from '../template/Logo';
import RecoveryForm from '../forms/RecoveryForm';
import { Row, Col } from 'antd';
import { Typography } from 'antd';

const { Title } = Typography;

class RecoveryScreen extends Component {

  render(){
    return (
      <Row>
        <Col lg={{span: 8, offset: 8}} sm={24}>
          <Centerized>
            <BlueFrame>
              <Logo/>
              <Title level={3}>Password Recovery</Title>
              <RecoveryForm
                token={this.props.match.params.token}
                notify={this.props.notify}
                saveData={this.props.saveData}
              />
            </BlueFrame>
          </Centerized>
        </Col>
      </Row>
    )
  }

}

export default withRouter(RecoveryScreen);