import styled from 'styled-components';
import ServerHost from '../helpers/ServerHost'


const Market = styled.div`
   width: 150px;
   height: 50px;
   background-image: url("${ServerHost('img/markets.png')}");
   background-position: ${props => props.ios ? '0 52px' : '0 -1px'};
   background-size: 100%;
   margin: 0 auto;
`

export default Market;