import React, {Component} from 'react';
import {Button, Icon, Col, Row, Typography, message} from 'antd'
import {hashtagfy} from '../../helpers/Funcs';
import FollowButton from '../FollowButton';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom'
import ProductViewModal from "../../modals/ProductViewModal";

const { Paragraph, Text } = Typography;

const ProductPreview = styled.div`
  width: 100%;
  min-height: 75px;
  display: grid;
  grid-template-columns: 25% 75%;
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  margin-bottom: 20px;
  div.ant-typography {
    white-space: nowrap !important;
  }
  &:hover {
    cursor:pointer;
  }
`;

const ProductPhoto = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.src});
`;

class Shout extends Component {

  constructor (props, context){
    super(props, context);
    this.state = {
      cut: props.cut,
      showProduct: false
    }
  }

  render(){
    const {data, collapse, icon, follow, addToCart} = this.props;
    const {product} = data.data;
    const {cut, showProduct} = this.state;
    return (
      <Row dir={'auto'}>
        <Col xs={20}>
          <Paragraph ellipsis={cut} onClick={collapse ? () => this.setState({
            cut: !cut
          }) : () => this.props.history.push(`/post/${data.postType}/${data.id}`)}>
            {hashtagfy(data.data.text, this.props.history.push)}
          </Paragraph>
          {product && <ProductPreview onClick={() => this.setState({showProduct: true})}>
            <div>
              <ProductPhoto src={product.picsLinks[0]}/>
            </div>
            <div style={{padding: 10}}>
              <Paragraph style={{margin: 0}} strong ellipsis>{product.name}</Paragraph>
              <Paragraph style={{margin: 0}} ellipsis>{product.description}</Paragraph>
              <Paragraph style={{margin: 0}}>{product.price} {product.currency}</Paragraph>
            </div>
          </ProductPreview>}

        </Col>
        <Col xs={4}>

          <div style={{textAlign: 'right'}}>
            {cut && <Button style={{color: '#329ab7', borderColor: '#329ab7', fontSize: 12}} onClick={() => this.props.history.push(`/post/${data.postType}/${data.id}`)} type={'default'} size={'small'} shape={'round'}>More</Button>}
            <p><small><Icon type={'eye'}/> {data.views}</small></p>
          </div>
        </Col>
        {/*{icon && <Col xs={4}>*/}
        {/*  <FollowButton onClick={follow} disabled={this.props.data.owner} following={this.props.data.following}/>*/}
        {/*</Col>}*/}
        {product && <ProductViewModal
            visible={showProduct}
            close={() => this.setState({
              showProduct: false
            })}
            data={product}
            editing={false}
            addToCart={addToCart}
        />}
      </Row>
    )
  }

}

export default withRouter(Shout);