import React, {Component} from 'react';
import Spinner from './Spinner';
import Request from '../utils/Request';
import { Badge, message, Tabs, Typography } from 'antd'
import Posts from './Posts'
import SessionData from '../utils/SessionData'
import Constants from '../Constants'
import NoResults from './NoResults'
import PopularItems from './PopularItems'
import SearchResults from './SearchResults'

const {Title, Text} = Typography;
const {TabPane} = Tabs;


class Popular extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      users: [],
      groups: [],
      loading: true,
      searchQuery: props.searchQuery,
      searchResult: {},
    }
  }

  defineContent = async () => {
    const {searchQuery} = this.state;
    if (searchQuery.length > 0) {
      await this.search();
    } else {
      await this.getPopularStuff();
    }
  };

  async componentDidMount(){
    await this.defineContent();
  }

  async componentDidUpdate(prevProps, prevState){
    const {searchQuery} = this.props;
    if (prevProps.searchQuery !== searchQuery){
      await this.setState({
        loading: true,
        searchQuery: searchQuery,
      });
      await this.defineContent();
    }
  }

  getPopularStuff = async () => {
    const session = new SessionData('popular');
    if (!session.get()) {
      let req = new Request('app/popular');
      let res = await req.get();
      if (res.status === 200) {
        this.setState({
          users: res.data.users,
          groups: res.data.groups,
          loading: false
        });
        session.set(res.data);
      } else {
        message.warning(
          'Oups! Something wrong happen! Please try again later...');
        return null;
      }
    } else {
      this.setState({
        users: session.get().users,
        groups: session.get().groups,
        loading: false
      });
    }
  };

  search = async () => {
    const {searchQuery} = this.state;
    await this.setState({loading: true});
    const session = new SessionData(`search:${searchQuery}`);
    if (!session.get()){
      let req = new Request(`app/search?query=${searchQuery}`);
      let res = await req.get();
      if (res.status === 200){
        this.setState({
          searchResult: res.data,
          loading: false
        });
        session.set(res.data);
      } else {
        message.error(res.data.error);
        this.setState({
          loading: false
        });
      }
    } else {
      this.setState({
        searchResult: session.get(),
        loading: false
      });
    }
  };

  renderPopular = () => {
    const {loading, users, groups} = this.state;
    if (loading) return <Spinner/>;
    return (
      <div>
        {users.length > 0 && <PopularItems items={users} title="Interesting People"/>}
        {groups && <PopularItems items={groups} title="Popular groups"/>}
        <Title style={{padding: '0 15px', margin: '10px 0'}} level={4}>Trending Talks</Title>
        <Posts forceLoad={true} path="app/popular_posts" create={false} scroll={false}/>
      </div>
    )
  };

  renderSearchResults = () => {
    const {loading, searchResult, searchQuery} = this.state;
    const {resetSearch} = this.props;
    if (loading) return <Spinner/>;

    const obj = {
      users: searchResult.users ? searchResult.users.length : 0,
      groups: searchResult.groups ? searchResult.groups.length : 0,
      posts: searchResult.postCount
    };

    const activeTab = Object.keys(obj).reduce((a, b) => obj[a] > obj[b] ? a : b);
    return (
      <div>
        <Tabs defaultActiveKey={activeTab} size="small" className="search-tabs">
          {<TabPane disabled={searchResult.users.length === 0} tab={
            <div>
              <Text>People</Text>{' '}
              <Badge count={searchResult.users.length} style={{ backgroundColor: Constants.secondaryColor }} />
            </div>
          } key="users">
            <SearchResults items={searchResult.users}/>
          </TabPane>}
          {<TabPane disabled={searchResult.groups.length === 0} tab={
            <div>
              <Text>Groups</Text>{' '}
              <Badge count={searchResult.groups.length} style={{ backgroundColor: Constants.secondaryColor }} />
            </div>
          } key="groups">
            <SearchResults items={searchResult.groups}/>
          </TabPane>}
          {<TabPane disabled={searchResult.postCount === 0} tab={
            <div>
              <Text>Posts</Text>{' '}
              <Badge count={searchResult.postCount} style={{ backgroundColor: Constants.secondaryColor }} />
            </div>
          } key="posts">
            <Posts forceLoad={true} path={`app/search_posts?query=${searchQuery}`} create={false} scroll={true}/>
          </TabPane>}
        </Tabs>
        {searchResult.postCount === 0 && searchResult.groups.length === 0 && searchResult.users.length === 0 && (
          <NoResults query={searchQuery} back={resetSearch}/>
        )}
      </div>
    )
  };

  render(){
    const {searchQuery} = this.state;
    return (
      <div>
        {searchQuery.length === 0 ? this.renderPopular() : this.renderSearchResults()}
      </div>
    )

  }

}

export default Popular;