import React, {Component} from 'react';
import { Spin, Icon } from 'antd';
import styled from 'styled-components';
import spinner from '../../assets/img/spinner.svg';
import animation from '../../assets/img/animation.gif';
import postSpinner from '../../assets/img/postSpinner.svg';

const Wrapper = styled.div`
  padding: 25px;
  text-align: center;
`

class Spinner extends Component {

  render(){
    let {type} = this.props;
    let svg = null;
    switch (type) {
      case 1:
        // svg = spinner;
        svg = animation;
        break;
      case 2:
        svg = postSpinner;
        break;
      default:
        // svg = spinner;
        svg = animation;
    }
    return <Wrapper>
      {/*<Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />*/}
      <img style={{width: 75}} src={svg} alt={<Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />}/>
    </Wrapper>;
  }

}

export default Spinner;

