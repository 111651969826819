import React, {Component} from 'react';
import {Popover, Icon, Typography, Row, Col, message, Divider, Badge, Button} from 'antd';
import styled from 'styled-components';
import IAm from '../../ui/IAm';
import { withRouter } from 'react-router-dom';
import SessionData from '../../utils/SessionData';
import Request from '../../utils/Request';
import Spinner from '../../ui/Spinner';
import ReportModal from '../../modals/ReportModal';
// import {
//   ExportOutlined,
//   DownloadOutlined,
//   ArrowDownOutlined,
//   ArrowUpOutlined,
//   LinkOutlined,
//   ReloadOutlined
// } from '@ant-design/icons';


const Avatar = styled.div`
  width: 32px;
  height: 32px;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  background-position: center, center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const Info = styled.p`
  font-size: 13px;
  color: #a3a3a3;
  margin: 4px 0;
  
  &:hover {
    cursor: pointer;
  }
  
  span {
    font-weight: bold;
    color: #000000;
  }
`;

const SettingsItems = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: 13px;
  
  li {
    margin: 10px 0;
    font-size: 14px;
    
    &:hover {
      cursor: pointer;
    }
  }
`;

const Wrap = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const {Title} = Typography;


class ExtraTools extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      info: {},
      loading: true,
      showReportModal: false
    }
  }

  getInfo = async () => {
    const session = new SessionData('sidebarInfo');
    const info = session.get();
    if (!info) {
      let req = new Request('user/count');
      let res = await req.get();
      if (res.status === 200) {
        this.setState({
          info: res.data,
          loading: false
        });
        session.set(res.data);
      } else {
        message.error(res.data.error);
      }
    } else {
      this.setState({
        info: info,
        loading: false
      });
    }
  };

  logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    this.props.history.push(`/`);
  };

  async componentDidMount() {
    await this.getInfo();
  }

  goTo = (path) => {
    const {visibleChange} = this.props;
    this.props.history.push(path);
    visibleChange();
  };

  render() {
    const {visible, visibleChange, width, provider, payment} = this.props;
    const {loading, info, showReportModal} = this.state;
    return (
      <div>
        <Popover
          placement='right'
          title={
            <div>
              <Wrap onClick={() => {
                this.goTo(`/user/${provider.profile.id}`);
                visibleChange();
              }}>
                <Row>
                  <Col md={12}>
                    <Avatar src={provider.profile.basics.photo}/>
                    <Title level={4} style={{color: provider.profile.basics.nameColor}}>{provider.profile.basics.name}</Title>
                  </Col>
                  <Col md={12}>
                    {provider.profile.iamStatus && <IAm style={{
                      width: '50px',
                      height: '50px'
                    }}/>}
                  </Col>
                </Row>
              </Wrap>
              {loading ? <Spinner/> : (
                <div>
                  <Info onClick={() => this.goTo(`/user/${provider.profile.id}/followers`)}>Followers: <span>{info.followers}</span></Info>
                  <Info onClick={() => this.goTo(`/user/${provider.profile.id}/following`)}>Following: <span>{info.following}</span></Info>
                  <Info onClick={() => this.goTo(`/user/${provider.profile.id}/groups`)}>Groups: <span>{info.groups}</span></Info>
                </div>
              )}
              {info.accountType === 0 && info.iam_status && <Button onClick={() => {
                visibleChange();
                payment();
              }} type={'primary'} icon={'arrow-up'} shape={'round'} block>Upgrade to PRO</Button>}
              {info.accountType === 1 && <SettingsItems>
                <li onClick={() => this.goTo('/wallet')}><Icon type="wallet" /> My wallet</li>
              </SettingsItems>}
            </div>
          }
          content={
            <div>
              <SettingsItems>
                {info.followRequests > 0 && <li onClick={() => this.goTo('/follow_requests')}><Icon type="user-add" /> Follow Requests <Badge count={info.followRequests}/></li>}
                <li onClick={() => this.goTo('/group/create')}><Icon type="usergroup-add" /> Create Group</li>
              </SettingsItems>
              <Divider/>
              <SettingsItems>
                <li onClick={() => this.goTo('/settings/account')}><Icon type="setting" /> Account Settings</li>
                <li onClick={() => this.goTo('/settings/profile')}><Icon type="user"/> Profile Settings</li>
                <li onClick={() => this.goTo('/settings/notifications')}><Icon type="alert"/> Notifications Settings</li>
                <li onClick={() => this.goTo('/settings/kyc')}><Icon type="dollar"/> Finance</li>
                <li onClick={() => {
                  this.setState({
                    showReportModal: true
                  });
                  visibleChange();
                }}><Icon type="question-circle"/> Support</li>

              </SettingsItems>
              <Divider/>
              <SettingsItems>
                <li onClick={this.logout}><Icon type="logout"/> Log out</li>
              </SettingsItems>
            </div>
          }
          trigger="click"
          visible={visible}
          onVisibleChange={visibleChange}
        >
          <button style={{padding: '5px 15px'}} type="link"><Icon type="ellipsis"/> {width > 767 && 'More'}</button>
        </Popover>
        <ReportModal
          visible={showReportModal}
          close={() => {
            this.setState({
              showReportModal: false
            })
          }}
          source="Support Request"
          text="Please, write your request"
        />
      </div>
    )
  }

}

export default withRouter(ExtraTools);