import React, {Component} from 'react';
import Nothing from "../../template/Nothing";
import Constants from "../../Constants";
import {ChatList} from "react-chat-elements";
import styled from "styled-components";
import {withRouter} from "react-router-dom";
import CheckMark from "../../ui/CheckMark";
import renderHTML from "react-render-html";


const Label = styled.span`
  background-color: ${Constants.secondaryColor};
  padding: 2px 4px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
`;


class ChatRooms extends Component {

    render(){
        const {chatrooms, loader} = this.props;
        if (!loader && chatrooms.length === 0) return <Nothing>Nothing there yet :(</Nothing>;
        return (
            <ChatList
                className='chat-list'
                onClick={(item) => this.props.history.push(`/chat/${item.roomId}`)}
                dataSource={chatrooms.map((v) => {
                    return {
                        roomId: v.uniqueId,
                        avatar: v.interloc.photo,
                        alt: v.interloc.name,
                        title: [<span style={{color: v.interloc.nameColor, fontWeight: 'bold'}}>{v.interloc.name}</span>, v.interloc.checkMark && <CheckMark/>],
                        subtitle: v.lastMessage ? renderHTML(v.lastMessage.text) : <span style={{
                            color: '#e3e3e3',
                            fontStyle: 'italic',
                            margin: 0
                        }}>No messages</span>,
                        date: v.lastMessage ? new Date(v.lastMessage.created) : <Label>New!</Label>,
                        unread: v.newMessages,
                        statusColor: v.interloc.online ? Constants.green : false,
                        statusColorType: 'badge'
                    }
                })} />
        );
    }

}

export default withRouter(ChatRooms);