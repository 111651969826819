import React, {Component} from 'react';
import Spinner from "../../ui/Spinner";
import Nothing from "../../template/Nothing";
import {Avatar, Comment, Icon} from "antd";
import {NavLink} from "react-router-dom";
import CheckMark from "../../ui/CheckMark";


class ContactList extends Component {

    render () {
        const {list, loading} = this.props;
        if (list.length === 0 && !loading) return <Nothing>No contacts found</Nothing>
        return (
            <div id="contacts-list">
                {list.map((v, i) => {
                    const actions = [
                        <span key="email">
              <a href={`mailto:${v.email}`} className={'hover'}>
                <Icon type="mail" />
                <span style={{ paddingLeft: 8, cursor: 'auto' }}>{v.email}</span>
              </a>
            </span>,
                        <span key="phone">
              <a href={`tel:${v.phone}`} className={'hover'}>
                <Icon type="phone" />
                <span style={{ paddingLeft: 8, cursor: 'auto' }}>{v.phone}</span>
              </a>
            </span>
                    ];
                    return (
                        <Comment
                            key={i}
                            actions={actions}
                            author={<NavLink style={{color: v.basics.nameColor}} to={`/user/${v.basics.id}`}>
                                {v.basics.name}
                                {v.basics.checkMark && <CheckMark/>}
                            </NavLink>}
                            avatar={
                                <NavLink style={{color: v.basics.nameColor}} to={`/user/${v.basics.id}`}>
                                    <Avatar
                                        src={v.basics.photo}
                                        alt={v.basics.name}
                                    />
                                </NavLink>
                            }
                            content={
                                <p>
                                    {v.basics.about}
                                </p>
                            }
                        />
                    )
                })}
            </div>
        )
    }

}

export default ContactList;