import React, {Component} from 'react';
import { GoogleLogin } from 'react-google-login';
import { Button } from 'antd';


class GoogleButton extends Component {

  constructor (props, context){
    super(props, context);
    this.state = {
      loading: false
    }
  }

  click = (renderProps) => {
    renderProps.onClick();
    this.setState({
      loading: true
    })
  }

  render(){
    return (
      <GoogleLogin
        clientId="391150763502-rfj9ho3g4jsdpiocgv8rdmpm46jk320t.apps.googleusercontent.com"
        onSuccess={this.props.callback}
        onFailure={this.props.callback}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <Button
            disabled={this.props.disabled}
            shape="round"
            loading={this.state.loading}
            className="btn-google"
            block icon="google"
            onClick={() => this.click(renderProps)}>
            Continue with Google
          </Button>
        ) }
      />
    )
  }

}

export default GoogleButton;