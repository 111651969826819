import React, {Component} from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import { Avatar, Button, Card, Icon, message, Badge, Typography, notification } from 'antd';
import styled from 'styled-components';
import Spinner from '../Spinner';
import Request from '../../utils/Request';
import Constants from '../../Constants';
import CheckMark from '../CheckMark';
import PostDrawer from '../PostDrawer';
import Shout from './Shout';
import ImgsViewer from 'react-images-viewer';
import PostImage from '../PostImage';
import ReactPlayer from 'react-player';
import OG from '../OG';
import Event from './Event';
import Trade from './Trade';
import Signal from './Signal';
import LikeIcon from '../LikeIcon';
import CommentDrawer from '../CommentDrawer';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import moment from 'moment';
import GoogleMap from '../GoogleMap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Deal from './Deal'
import SessionData from '../../utils/SessionData';


const { Meta } = Card;
const Options = styled.span`
  float: right;
`;
const Time = styled.span`
  color: #b3b3b3;
  font-size: 12px;
  margin: 0 5px;
  font-weight: 100;
`;

const PicWrap = styled.div`
  width: 100%;
  height: 55vh;
  position: relative;
  border-bottom: 1px solid #e8e8e8;
`;

const Wrapper = styled.div`
  width: 100%;
  background-size: cover;
`;

const MediaWrap = styled.div`

`;

const PostFuncs = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  position: relative;
  width: 100%;
  transform: translateY(-50%);
  div {position: relative;}
  margin-bottom: 80px;
`;

const Likes = styled.span`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -25px;
  color: ${props => props.active ? Constants.primaryColor : '#a8a8a8'};
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const Date = styled.div`
  color: ${Constants.primaryColor}
  font-size: 16px;
  font-weight: bold;
`;

const ExtraWrapper = styled.div`
  padding: 10px;
`;

const {Paragraph} = Typography;

class OpenPostWrapper extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      loading: true,
      type: props.match.params.type,
      id: props.match.params.id,
      data: {},
      showDrawer: false,
      showCommentsDrawer: false,
      viewerIsOpen: false,
      currImg: 0,
      comments: [],
      page: 1,
      cpp: 12,
      reply: false
    }
  }

  getData = () => {
    const {type, id} = this.state;
    const session = new SessionData(`${type}_${id}`);
    return session.get();
  };

  saveData = (data) => {
    const {type, id} = this.state;
    const session = new SessionData(`${type}_${id}`);
    session.set(data);
    return null;
  };

  async componentDidMount(){
    const {type, id, page, cpp} = this.state;
    const session = new SessionData(`${type}_${id}`);
    if (!session.get()) {
      let req = new Request(`post/${type}/info?post_id=${id}&page=${page}&cpp=${cpp}`);
      let res = await req.get();
      if (res.status === 200) {
        this.setState({
          data: res.data,
          loading: false,
          comments: res.data.comments
        });
        this.saveData(res.data);
      } else {
        message.error(res.data.error);
        this.props.history.goBack();
        return null;
      }
    } else {
      this.setState({
        data: session.get(),
        loading: false,
        comments: session.get().comments
      });
    }

  }

  comment = async (text) => {
    const {data, reply} = this.state;
    let postData = {
      text: text,
      post_type: data.postType,
      post_id: data.id
    };

    if (reply) postData.reply_to = reply.id;
    let req = new Request('comment/create', postData);
    let res = await req.post();
    if (res.status === 200){
      const {comments} = this.state;
      await this.setState({
        comments: [...comments, res.data],
        reply: false
      });

      let data = this.getData();
      data.comments = this.state.comments;
      this.saveData(data);

      window.scrollTo(0,document.body.scrollHeight);
    } else {
      message.error(res.data.error);
      this.setState({
        reply: false
      });

    }
  };

  loadMore = async () => {
    const {type, id, page, cpp, comments} = this.state;
    let req = new Request(`post/${type}/info?post_id=${id}&page=${page+1}&cpp=${cpp}`);
    let res = await req.get();
    if (res.status === 200) {
      if (res.data.comments.length === 0) message.info('All comments have been loaded')
      await this.setState({
        comments: [...comments, ...res.data.comments],
        page: page+1
      });

      let data = this.getData();
      data.comments = this.state.comments;
      this.saveData(data);

    } else {
      message.error(res.data.error);
    }
  };

  like = async (index, id, reply_index=false) => {
    const {comments} = this.state;
    let req = new Request('comment/like', {
      comment_id: id
    });
    let res = await req.post();
    if (res.status === 200) {
      if (reply_index === false){
        comments[index] = res.data;
      } else {
        comments[index].replies[reply_index] = res.data;
      }
      await this.setState({
        comments: comments
      });

      let data = this.getData();
      data.comments = this.state.comments;
      this.saveData(data);

    } else {
      message.error(res.data.error);
    }
  };

  likePost = async () => {
    const {data} = this.state;
    let req = new Request(`post/${data.postType}/like`, {
      post_id: data.id
    });
    let res = await req.post();
    if (res.status === 200){
      this.setState({
        data: res.data
      });

      this.saveData(res.data);

    } else {
      message.error(res.data.error)
    }
  };

  trade = async () => {
    const {data} = this.state;
    let req = new Request(`post/${data.postType}/ask`, {
      post_id: data.id
    });
    let res = await req.post();
    if (res.status === 200){
      this.setState({
        data: res.data
      });
      this.saveData(res.data);
    } else {
      if (res.status === 403){
        notification.warning({
          description: res.data.error,
          message: 'Oups...',
          onClick: () => {
            this.props.history.push('/settings/account');
            notification.destroy()
          }
        })
      } else {
        message.error(res.data.error)
      }
    }
  };

  closeDrawer = () => {
    this.setState({
      showDrawer: false,
      showCommentsDrawer: false
    })
  };

  updatePost = (index, val) => {
    sessionStorage.clear();
    this.setState({
      data: val
    });
    this.saveData(val);
  };

  deletePost = () => {
    sessionStorage.clear();
    this.props.history.goBack();
  };

  gotoPrev = () => {
    const {currImg} = this.state;
    this.setState({
      currImg: currImg-1
    })
  };

  gotoNext = () => {
    const {currImg} = this.state;
    this.setState({
      currImg: currImg+1
    })
  }

  updateData = (data) => {
    this.setState({
      data: data
    });
    this.saveData(data);
  };

  setReply = (id, name) => {
    this.setState({
      reply: {
        id: id,
        name: name
      }
    })
  };

  follow = async () => {
    const {data} = this.state;
    let req = new Request(`post/${data.postType}/follow`, {
      post_id: data.id
    });
    let res = await req.post();
    if (res.status === 200){
      this.setState({
        data: res.data
      });
      this.saveData(res.data);
    } else {
      message.error(res.data.error)
    }
  };

  openLink = () => {
    if (this.state.data.link) {
      let win = window.open(this.state.data.link, '_blank');
      win.focus();
    }
    return null;
  };

  share = async () => {
    // message.info('Link copied to clipboard!');
    const {data} = this.state;
    console.log(data);
    let text;
    let title;
    const url = `${Constants.client}post/${data.postType}/${data.id}`;


    switch (data.postType) {
      case 'shout':
        if (data.user){
          title = data.user.name;
        } else {
          title = data.group.name;
        }
        text = data.data.text;
        break;
      case 'deal':
        title = data.data.title;
        text = data.data.description + "\n" + `${data.data.price} ${data.data.currency}`;
        break;
      case 'trade':
        if (data.user){
          title = data.user.name;
        } else {
          title = data.group.name;
        }
        text = data.data.string;
        break;
    }

    const shareData = {
      title: title,
      text: text,
      url: url
    }
    await navigator.share(shareData);
  };

  render(){
    const {loading, data, showDrawer, viewerIsOpen, currImg, comments, reply, cpp, showCommentsDrawer} = this.state;
    if (loading) return <Spinner/>;
    let component = <Shout text={data.data.text}/>;
    switch (data.postType){
      case 'shout':
        component = <Shout data={data} collapse={true} cut={false}/>;
        break;
      case 'event':
        component = <Event data={data} cut={false} map/>;
        break;
      case 'trade':
        component = <Trade data={data} flag={data.user ? data.user.countryFlag : false} trade={this.trade}/>;
        break;
      case 'signal':
        component = <Signal data={data} />;
        break;
      case 'deal':
        component = <Deal data={data} trade={this.trade} cut={true}/>;
        break;
      default:
        return null;

    }

    let images = [];
    if (data.image) images.push({src: data.image});
    if (data.og && data.og.image && data.image !== data.og.image) images.push({src: data.og.image});
    let local = false;
    let address = false;
    let coords = false;
    let link = false;
    let atLeastSomething = false;
    if (data.originalDate){
      let utc = moment.utc(data.originalDate);
      local = (
        <Date>{moment(utc).local().format("dddd, MMMM Do YYYY, HH:mm")}</Date>
      );
    }
    if (data.address){
      address = (
        <Paragraph ellipsis={this.props.cut}>
          <Icon type="environment" /> {data.address}
        </Paragraph>
      )
    }
    if (data.coords) {
      coords = (
        <GoogleMap
          lat={data.coords.lat}
          lng={data.coords.lng}
          width="100%"
          height="150px"
        />
      )
    }
    if (data.link) {
      link = (
        <div style={{textAlign: 'center'}}>
          <Button onClick={this.openLink} type="link" icon="link">Open</Button>
        </div>
      )
    }
    if (link || coords || address || local) atLeastSomething = true;
    const url = `${Constants.client}post/${data.postType}/${data.id}`;
    return (
      <Wrapper className="open-post">
        <Card
          style={{ width: '100%' }}
          dir={'auto'}
        >
          <Meta
            avatar={
              <NavLink to={`/${data.entity}/${data[data.entity].id}`}>
                <Badge
                  status={data.user ? data.user.online ? 'success' : false : false}
                  offset={[-5, 30]}
                  style={{zIndex: 0}}
                >
                  <Avatar src={data.user ? data.user.photo : data.group.photo} />
                </Badge>
              </NavLink>}
            title={<p>
              <NavLink style={{color: data.user ? data.user.nameColor : `${Constants.primaryColor}`}} to={`/${data.entity}/${data[data.entity].id}`}>{data.user ? data.user.name : data.group.name}
                {data.user ? data.user.checkMark ? <CheckMark/> : '' : data.group.checkMark ? <CheckMark/> : ''}</NavLink>
              <Time>{data.createdAt}</Time>
              <Options><Button onClick={() => this.setState({showDrawer: true})} size="small" type="link"><Icon style={{width: 'auto'}} type="more"/></Button></Options>
            </p>}
            description={component}
          />
        </Card>
        <MediaWrap>
          {data.og ? (
            <div>
              {data.og['video:url'] && <ReactPlayer
                key="media"
                // muted
                controls
                width="100%"
                height="100%"
                url={data.og['video:url']}
                // playing
              />}
              {!data.og['video:url'] && images.length > 0 &&
              <PicWrap>
                <PostImage onClick={() => this.setState({viewerIsOpen: true})} src={images[0].src}>{<OG key="og" data={data.og}/>}</PostImage>
              </PicWrap>}
            </div>
          ) : (
            images.length > 0 && (
              <PicWrap>
                <PostImage onClick={() => this.setState({viewerIsOpen: true})} src={images[0].src}/>
              </PicWrap>
            )
          )}
          <PostFuncs>
            <div>
              <Button className="share-btn" onClick={this.share} type="link" style={{
                fontSize: '32px',
                width: '35px',
                height: '35px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fff',
                borderRadius: '50%',
                border: '1px solid #e8e8e8',
                boxShadow: '2px 2px 4px rgba(0, 0, 0, .4)'
              }}/>
            </div>
            <div>
              <LikeIcon style={{
                width: '50px',
                height: '50px',
              }} active={data.liked} onClick={this.likePost}>
                <Likes active={data.likes > 0}>{data.likes}</Likes>
              </LikeIcon>
            </div>
            <div>
              <Button onClick={() => this.setState({
                showCommentsDrawer: true
              })} className="comment-btn" type="link" style={{
                fontSize: '32px',
                width: '35px',
                height: '35px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fff',
                borderRadius: '50%',
                border: '1px solid #e8e8e8',
                boxShadow: '2px 2px 4px rgba(0, 0, 0, .4)'
              }}/>
              <Likes style={{textAlign: 'center', top: '30px'}} active={true}>{
                typeof(data.comments.length) !== 'undefined' ? data.comments.length : data.comments
              }</Likes>
            </div>
          </PostFuncs>
        </MediaWrap>
        {atLeastSomething && <SwipeableBottomSheet overflowHeight={0}>
          <ExtraWrapper>
            {local && local}
            {address && address}
            {coords && coords}
            {link && link}
          </ExtraWrapper>
        </SwipeableBottomSheet>}
        <PostDrawer
          data={data}
          visible={showDrawer}
          close={this.closeDrawer}
          update={this.updatePost}
          del={this.deletePost}
          single={true}
        />
        <CommentDrawer
          data={data}
          visible={showCommentsDrawer}
          close={this.closeDrawer}
          comment={this.comment}
          reply={reply}
          loadMore={this.loadMore}
          comments={comments}
          like={this.like}
          setReply={this.setReply}
          cpp={cpp}
        />

        {images.length > 0 && <ImgsViewer
          backdropCloseable={true}
          imgs={images}
          currImg={currImg}
          isOpen={viewerIsOpen}
          onClickPrev={this.gotoPrev}
          onClickNext={this.gotoNext}
          onClose={() => this.setState({viewerIsOpen: false})}
        />}
      </Wrapper>
    )
  }

}

export default withRouter(OpenPostWrapper);