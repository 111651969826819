import React, {Component} from 'react';
import { Button, Drawer, List, Modal, message } from 'antd';
import Constants from '../Constants';
import ReportModal from '../modals/ReportModal';
import Request from '../utils/Request'
import { withRouter } from 'react-router-dom'


const {confirm} = Modal;


class ChatDrawer extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      data: props.data,
      reportWindow: false,
      reportSource: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data
      })
    }
  }

  render () {
    const {visible, close} = this.props;
    const {data, reportWindow, reportSource} = this.state;
    const opts = [];
    if (data) {
      opts.push(
        <Button onClick={() => {this.setState({reportWindow: true, reportSource: `${Constants.client}${data.entity}/${data.id}`}); close();}} block style={{textAlign: 'left'}} type="link" icon="warning" key="report">Report {`${data.name}`}</Button>
      );
      if (data.entity === 'user') {
        opts.push(
          <Button onClick={() => confirm({
            title: 'Confirm action',
            content: `Do you really want to ${data.blocked ? 'unblock' : 'block'} ${data.name}? Your conversation will be deleted in this case.`,
            onOk: async () => {
              const req = new Request('user/block', {
                user_id: data.id
              });
              const res = await req.post();
              if (res.status === 200) {
                message.info(res.data.message);
                const {data} = this.state;
                data.blocked = res.data.blocked;
                this.setState({
                  data: data
                });
                close();
                this.props.history.push('/chat');
              } else {
                message.error(res.data.error);
              }
            }
          })} block style={{textAlign: 'left'}} type="link" icon={data.blocked ? "check-circle" : "close-circle"} key="block">{data.blocked ? 'Unblock' : 'Block'} {`${data.name}`}</Button>
        );
        if (!data.infoShared) opts.push(<Button onClick={() => confirm({
          title: 'Confirm action',
          content: `Do you really want to share your personal information with ${data.name}? NOTE: ${data.name} will have to accept your share information request. Till that time, neither ${data.name} nor you won't be able to see personal contact information of each other.`,
          onOk: async () => {
            const req = new Request('user/send_request', {
              type: 'info',
              user_id: data.id
            });
            const res = await req.post();
            if (res.status === 200) {
              message.info(res.data.message);
            } else {
              message.error(res.data.error);
            }
            close();
          }
        })} block style={{textAlign: 'left'}} type="link" icon="user-add" key="share">Add {`${data.name}`} to contacts</Button>);
      }
    }
    return (
      <div>
        <Drawer
          title={false}
          placement="bottom"
          visible={visible}
          onClose={close}
          height="auto"
          closable={false}
        >
          <List
            dataSource={opts}
            size='small'
            renderItem={item => (
              <List.Item>
                {item}
              </List.Item>
            )}
          />
        </Drawer>
        <ReportModal
          visible={reportWindow}
          close={() => this.setState({
            reportWindow: false,
            reportSource: ''
          })}
          source={reportSource}
        />
      </div>
    )
  }

}

export default withRouter(ChatDrawer);