import React, {Component} from 'react';
import {Modal, Carousel, Typography, Button, message, Icon, Table, Avatar} from "antd";
import styled from 'styled-components';
import ImgsViewer from "react-images-viewer";
import Request from "../utils/Request";
import QRCode from "react-qr-code";
import ProductModal from "./ProductModal";


const {Paragraph, Text} = Typography;
const { confirm } = Modal;

const Slide = styled.div`
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${props => props.src});

    &:hover {
    cursor: pointer;
    }
`;


class ProductViewModal extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            viewerIsOpen: false,
            currImg: 0,
            buttonLoad: false,
            iframeUrl: null,
            amount: 1,
            productModal: false
        }
        this.carousel = React.createRef();
    }

    images = () => {
        const {data} = this.props;
        return data.picsLinks.map((v, i) => {
            return {src: v}
        })
    }

    pay = async () => {
        const {data, promoId} = this.props;
        this.setState({
            buttonLoad: true
        })
        const r = await new Request('product/pay', {
            id: data.id,
            promo_id: promoId
        }).post();
        if (r.status === 200) {
            this.setState({iframeUrl: r.data.redirect});

        } else {
            message.error(r.data.error);
        }
        this.setState({
            buttonLoad: false
        });
    }

    close = () => {
        const {close} = this.props;
        this.setState({iframeUrl: null});
        close();
    }

    notify = () => {
        const {data} = this.props;
        new Request('product/notify', {
            id: data.id,
        }).post();
        this.close();
    }

    discountPrice = (price, discount) => {
        return (price - price / 100 * discount).toFixed(2);
    }

    calculatePrice = (price) => {
        const {amount} = this.state;
        return (price * amount).toFixed(5);
    }

    addToCart = () => {
        const {addToCart, close, data} = this.props;
        const {amount} = this.state;
        addToCart(data.id, amount);
        close();
    }

    render (){
        const {visible, close, data, editing, edit, remove, entity, entity_id, productIndex, updateProduct} = this.props;
        const {viewerIsOpen, currImg, buttonLoad, iframeUrl, amount, productModal} = this.state;
        const props = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        // let buyBtn = null;
        // if (data.promo || data.coinify) {
        //     buyBtn = <Button loading={buttonLoad} type={'primary'} block shape={'round'} onClick={this.pay}>Buy</Button>
        // } else {
        //     buyBtn = <Button shape={'round'} type={'primary'} block onClick={this.notify}>Done</Button>;
        // }
        const columns = [
            {
                title: 'Crypto',
                dataIndex: 'crypto',
                render: item => {
                    return <Paragraph>
                        <Avatar size={'small'} src={item.icon}/> {item.name}
                    </Paragraph>
                }
            },
            {
                title: 'Price',
                dataIndex: 'price',
                render: price => {
                    return this.calculatePrice(price)
                }
            }

        ];
        return (
            <div>
                <Modal
                    visible={visible}
                    onCancel={this.close}
                    title={[data.name, editing && <Button key={'edit'} onClick={() => {
                        close();
                        this.setState({productModal: true})
                    }} icon={'edit'} type={'link'}/>, editing && <Button key={'delete'} icon={'delete'} type={'link'} onClick={() => confirm({
                        title: 'Do you want to delete this product?',
                        onOk() {
                            close();
                            remove();
                        },
                        onCancel() {}
                    })}/>]}
                    footer={null}
                    style={{ top: 20 }}
                >
                    {iframeUrl ? <iframe style={{minHeight: 650, width: '100%'}} src={iframeUrl} frameborder="0"/> : (
                        <div>
                            <Carousel ref={node => (this.carousel = node)} {...props}>
                                {data.picsLinks.map((v, i) => {
                                    return <Slide key={i} src={v} onClick={() => this.setState({
                                        viewerIsOpen: true,
                                        currImg: i
                                    })}/>
                                })}
                            </Carousel>
                            <Paragraph>{data.description}</Paragraph>
                            {data.discount ? (
                                <Paragraph>
                                    Price: <Text strong><Text delete>{data.price}</Text> {this.discountPrice(data.price, data.discount)} {data.currency} (-{data.discount}%)</Text>
                                </Paragraph>
                            ) : (
                                <Paragraph>
                                    Price: <Text strong>{data.price} {data.currency}</Text>
                                </Paragraph>
                            )}
                            {data.fixed_amount && <Paragraph>Amount: <Button onClick={() => {
                                if (amount > 1) {
                                    this.setState({
                                        amount: amount-1
                                    })
                                } else {
                                    return null;
                                }
                            }} size={'small'} shape={'circle'} type={'primary'} icon={'minus'}/> {amount} <Button onClick={() => this.setState({
                                amount: amount+1
                            })} size={'small'} shape={'circle'} type={'primary'} icon={'plus'}/></Paragraph>}
                            <Table pagination={false} dataSource={data.prices} columns={columns} size={'small'}/>
                            {data.attributes.map((v, i) => {
                                return <Paragraph key={i}>{v.key}: <Text strong>{v.value}</Text></Paragraph>
                            })}
                            {!data.promo && data.wallet && !data.coinify && <Paragraph>Wallet address: <Text copyable>{data.wallet}</Text> <Button onClick={() => Modal.info({
                                title: 'Wallet address',
                                content: <div style={{margin: '40px 15px'}}><QRCode value={data.wallet}/></div>,
                                onOk() {},
                            })} size={'small'} type={'primary'} shape={'round'}>QR Code</Button></Paragraph>}
                            {/*{buyBtn}*/}
                            {!editing && (
                                data.fixed_amount ? (
                                    data.in_stock > 0 ? (
                                        <Button type={'primary'} block shape={'round'} onClick={this.addToCart}>Add to Cart</Button>
                                    ) : (
                                        <Button type={'default'} disabled block shape={'round'}>Out of stock</Button>
                                    )
                                ) : (
                                    <Button type={'primary'} block shape={'round'} onClick={this.addToCart}>Add to Cart</Button>
                                )
                            )}
                            <ImgsViewer
                                backdropCloseable={true}
                                imgs={this.images()}
                                currImg={currImg}
                                isOpen={viewerIsOpen}
                                onClickNext={() => this.setState({currImg: currImg+1})}
                                onClickPrev={() => this.setState({currImg: currImg-1})}
                                onClose={() => this.setState({viewerIsOpen: false})}
                            />
                        </div>
                    )}
                </Modal>
                <ProductModal
                    visible={productModal}
                    close={() => this.setState({
                        productModal: false,
                    })}
                    entity={entity}
                    id={entity_id}
                    edit={true}
                    data={data}
                    update={updateProduct}
                    index={productIndex}
                    key={data.id}
                />
            </div>
        )
    }
}

export default ProductViewModal;