import React, {Component} from 'react';
import styled from "styled-components";
import {Badge, Button, Col, Drawer, Icon, Row, Tabs, Typography} from "antd";
import CheckMark from "../../ui/CheckMark";
import Constants from "../../Constants";
import Provider from "../../utils/Provider";
import {withRouter} from "react-router-dom";
import Posts from '../posts/Posts';
import ImgsViewer from "react-images-viewer";
import EntityOptions from "./EntityOptions";
import ClosedEntity from "../../ui/ClosedEntity";
import ProfileDetails from "../../ui/ProfileDetails";
import GroupInfo from "../../ui/GroupInfo";
import Feedbacks from "../../ui/Feedbacks";
import Products from "../../ui/Products";
import Request from '../../utils/Request';
import Spinner from "../../ui/Spinner";
import Nothing from "../../template/Nothing";
import CreatePostStack from "../../stacks/CreatePostStack";
import MobileMenu from "../template/MobileMenu";
import {Avatar as AntdAvatar} from "antd";


const {Title, Paragraph, Text} = Typography;

const { TabPane } = Tabs;

const HeaderWrap = styled.div`
  width: 100%;
  height: 150px;
  position: relative;
`;

const Background = styled.div`
  width: 100%;
  height: 200px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  background-color: #e8e8e8;
`;

const Avatar = styled.div`
  width: 136px;
  height: 136px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -75px;
  left: 25px;
  border: 4px solid #fff;
  
  &:hover {
    cursor: pointer;
  }
`;

const BodyWrap = styled.div`
  padding: 90px 25px 10px;
`;

const Hover = styled.span`
    &:hover {
        cursor: pointer;
    }
`;

const MobileOptions = styled.div`
  display: inline-block;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const TabsWrapper = styled.div`
  .ant-tabs-tab {
    width: ${props => props.extraTab ? '25%' : '33.33333%'};
  }
`;


class EntityFeed extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            viewerIsOpen: false,
            hash: null,
            drawer: false,
            loading: true
        }
    }

    goTo = (path) => {
      this.props.history.push(`/${path}`);
      return null;
    };

    entityAction = (entity, id, action, request = false) => {
        const {entityAction} = this.props;
        entityAction(entity, id, action, request);
    };

    componentDidMount() {
        const {data} = this.props;
        if (data && 'profile' in data) {
            new Request('user/visit', {
                id: data.profile.id
            }).post();
        }
        this.setState({
            hash: window.location.hash ? window.location.hash.substr( 1) : null,
            loading: false
        });
    }

    componentWillUnmount() {
        if (typeof (this.props.onUnmount) !== 'undefined') this.props.onUnmount(window.scrollY);
    }

    tabChange = (e) => {
        window.location.hash = e;
        this.setState({hash: e})
    }

    render() {
        const {viewerIsOpen, hash} = this.state;
        const {
            setMeetAbout,
            countInfo,
            openMobileDrawer,
            drawerOpen,
            data,
            loader,
            posts,
            openUpdateModal,
            update,
            remove,
            onScrollToBottom,
            recordsLoading,
            postAction,
            report,
            message,
            closedEntity,
            closedMessage,
            loading,
            push,
            entityAction,
            payment
        } = this.props;
        if (loader) return <Spinner/>;
        if (typeof (data) === 'undefined') return null;
        if (closedEntity) return <ClosedEntity data={data} message={closedMessage} entityAction={entityAction}/>;
        let rootParams;
        if (data.hasOwnProperty('profile')) {
            rootParams = data.profile;
        } else {
            rootParams = data;
        }

        let selfCheck = false;
        const ownId = new Provider('user').get('profile').id;
        if (rootParams.entity === 'user' && rootParams.id === ownId) selfCheck = true;
        else if (rootParams.entity === 'group' && rootParams.basics.isAdmin) selfCheck = true;

        let followBtn;
        if (selfCheck && rootParams.entity === 'user') followBtn = <Button shape="round" type="default" onClick={() => this.goTo('settings/profile')}>Update Profile</Button>;
        else if (selfCheck && rootParams.entity === 'group') {
            if (rootParams.newRequests > 0 || rootParams.newMessages > 0 ) {
                followBtn = <Badge dot>
                    <Button shape="round" type="default" onClick={() => this.goTo(`group/${rootParams.id}/settings`)}>Options</Button>
                </Badge>
            } else {
                followBtn = <Button shape="round" type="default" onClick={() => this.goTo(`group/${rootParams.id}/settings`)}>Options</Button>
            }
        }
        else if (!selfCheck && !rootParams.following) followBtn = <Button shape="round" type="primary" onClick={() => this.entityAction(rootParams.entity, rootParams.id, 'follow')}>Follow</Button>;
        let followers = rootParams.entity === 'user' ? rootParams.count.followers : data.followers;

        let groupId = false;
        if (rootParams.entity === 'group' && selfCheck) groupId = rootParams.id;

        if (!data) return null;
        if (loading) return null;
        return (
            <div className={'entity-screen'}>
                <HeaderWrap>
                    <Background src={rootParams.basics.background}/>
                    <Avatar src={rootParams.basics.photo} onClick={() => this.setState({viewerIsOpen: true})}/>
                </HeaderWrap>
                <BodyWrap>
                    <Row>
                        <Col md={24} style={{textAlign: 'right'}}>
                            {!selfCheck ?
                            <EntityOptions
                                data={rootParams}
                                entityAction={this.entityAction}
                                report={report}
                                message={message}
                            /> : <MobileOptions><Button onClick={openMobileDrawer} type={'primary'} icon={'ellipsis'} shape={'circle'}/></MobileOptions>}{' '}
                            {followBtn}
                        </Col>
                       <Col md={24} dir={'auto'}>
                           <Title dir={'auto'} level={4} style={{color: rootParams.entity === 'user' ? rootParams.basics.nameColor : Constants.primaryColor}}>
                               {rootParams.basics.name} {rootParams.basics.checkMark && <CheckMark/>}
                           </Title>
                           <Paragraph>
                               <Text style={{whiteSpace: 'pre-wrap'}}>
                                   {rootParams.entity === 'group' ? rootParams.basics.motto : rootParams.basics.about}
                               </Text>
                           </Paragraph>
                       </Col>
                    </Row>
                    {data.socialNetworks.website && <Paragraph style={{margin: 0}}><Text>
                        <Icon type="link"/>{' '}
                        <a href={data.socialNetworks.website} target="_blank" rel="noopener noreferrer" style={{color: Constants.primaryColor,
                            textDecoration: 'underline'}}>
                            {data.socialNetworks.website.replace('https://', '').replace('http://', '')}
                        </a></Text></Paragraph>}
                    <Paragraph style={{margin: rootParams.entity === 'user' ? 0 : 'inherit'}}><Icon type="calendar"/> {rootParams.entity === 'group' ? 'Created' : 'Registration'}: {rootParams.basics.registered}</Paragraph>
                    {rootParams.entity === 'user' && <Paragraph><Icon type="environment" /> {rootParams.basics.country}</Paragraph>}
                    <Row>
                        <Col md={8} onClick={() => this.goTo(`${rootParams.entity}/${rootParams.id}/followers`)}>
                            <Hover><Text><strong>{followers}</strong> {parseInt(followers) === 1 ? 'follower' : 'followers'}</Text></Hover>
                        </Col>
                        {rootParams.entity === 'user' &&
                        <Col md={8} onClick={() => this.goTo(`${rootParams.entity}/${rootParams.id}/following`)}>
                            <Hover><Text><strong>{rootParams.count.following}</strong> following</Text></Hover>
                        </Col>}
                        {rootParams.entity === 'user' &&
                        <Col md={8} onClick={() => this.goTo(`${rootParams.entity}/${rootParams.id}/groups`)}>
                            <Hover><Text><strong>{rootParams.count.groups}</strong> {parseInt(rootParams.count.groups) === 1 ? 'group' : 'groups'}</Text></Hover>
                        </Col>}
                    </Row>
                </BodyWrap>
                <TabsWrapper extraTab={rootParams.basics.accountType === 1 || rootParams.business}>
                    <Tabs activeKey={hash ? hash : 'posts'} onChange={this.tabChange}>
                        <TabPane tab="Posts" key="posts">
                            {posts.length > 0 && <Posts
                                openUpdateModal={openUpdateModal}
                                update={update}
                                remove={remove}
                                key={`${rootParams.entity}_${rootParams.id}`}
                                posts={posts}
                                report={report}
                                onScrollToBottom={onScrollToBottom}
                                recordsLoading={recordsLoading}
                                htmlId={`${rootParams.entity}_${rootParams.id}`}
                                postAction={postAction}
                                entityAction={this.entityAction}
                                loading={loading}
                                create={selfCheck}
                                groupId={groupId}
                                push={push}
                            />}
                            {!recordsLoading && posts.length === 0 && <Nothing>
                                No posts yet
                                {selfCheck && (
                                    <CreatePostStack push={push} group={groupId}/>
                                )}
                            </Nothing>}
                        </TabPane>
                        <TabPane tab={rootParams.entity === 'user' ? 'Profile' : 'Info'} key="profile">
                            {rootParams.entity === 'user' ? <ProfileDetails data={data}/> : <GroupInfo data={data}/>}
                        </TabPane>
                        {(rootParams.basics.accountType === 1 || rootParams.business) && <TabPane tab="Products" key="products">
                            <Products entity={rootParams.entity} id={rootParams.id} adding={selfCheck} />
                        </TabPane>}
                        <TabPane tab={rootParams.entity === 'user' ? 'Meet' : 'Feedbacks'} key="feedbacks">
                            <Feedbacks
                                entity={rootParams.entity}
                                id={rootParams.id}
                                rating={rootParams.basics.rating}
                                me={selfCheck}
                                data={rootParams}
                                rated={rootParams.rated}
                                setMeetAbout={setMeetAbout}
                                entityAction={entityAction}
                            />
                        </TabPane>
                    </Tabs>
                </TabsWrapper>
                <ImgsViewer
                    backdropCloseable={true}
                    imgs={[{src: rootParams.basics.photo}]}
                    currImg={0}
                    isOpen={viewerIsOpen}
                    onClose={() => this.setState({viewerIsOpen: false})}
                />
                <Drawer
                    title={[
                        <AntdAvatar size="small" src={rootParams.basics.photo}/>,
                        ' ',
                        <Text strong level={4}>{rootParams.basics.name}</Text>
                    ]}
                    placement="left"
                    closable={true}
                    onClose={openMobileDrawer}
                    visible={drawerOpen}
                >
                    <MobileMenu data={rootParams} countInfo={countInfo} payment={payment}/>
                </Drawer>
            </div>
        )
    }

}

export default withRouter(EntityFeed);