import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Icon, Badge } from 'antd';
import Feed from '../ui/Feed';
import Popular from '../ui/Popular';
import Provider from '../utils/Provider';
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import Notifications from '../ui/Notifications'
import Rooms from '../chat/Rooms';
import 'react-chat-elements/dist/main.css';


class NavigationStack extends Component {

  constructor(props, context){
    super(props, context);
    this.state = {
      component: false,
      module: props.module,
      searchQuery: props.searchQuery,
      newNotifications: 0,
      newMessages: 0
    }
  }

  handleClick = (e) => {
    this.props.history.push(`/${e.key}`);
  };

  updateState = async () => {
    await this.setState({
      module: this.props.module
    });

    const {module, searchQuery} = this.state;
    const {resetSearch} = this.props;
    switch (module){
      case 'feed':
        this.setState({
          component: <Feed/>
        });
        break;
      case 'popular':
        this.setState({
          component: <Popular searchQuery={searchQuery} resetSearch={resetSearch}/>
        });
        break;
      case 'notifications':
        this.setState({
          component: <Notifications/>
        });
        break;
      case 'chat':
        this.setState({
          component: <Rooms/>
        });
        break;
      default:
        return null;
    }
  };

  async componentDidMount(){
    await this.updateState();
    const profile = new Provider('user').get('profile');
    const newNotifications = firebase.database().ref('global').child(profile.basics.id).child('newNotifications');
    newNotifications.on("value", (snapshot) => {
      let val = snapshot.val();
      this.setState({
        newNotifications: val
      })
    });
    const newMessages = firebase.database().ref('global').child(profile.basics.id).child('newMessages');
    newMessages.on("value", (snapshot) => {
      let val = snapshot.val();
      this.setState({
        newMessages: val
      })
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const {module, searchQuery} = this.props;
    if (prevProps.module !== module) {
      await this.updateState();
    }
    if (prevProps.searchQuery !== searchQuery) {
      this.setState({
        searchQuery: searchQuery
      });
      await this.updateState();
    }
  }


  render(){
    const style = {
      position: 'fixed',
      top: 60,
      zIndex: 2,
      maxWidth: 800,
      width: '100%',
      backgroundColor: '#ffffff'
    }
    const {newNotifications, newMessages} = this.state;
    return (
      <div style={{paddingTop: 43}}>
        <Menu
          onClick={this.handleClick}
          selectedKeys={[this.state.module]}
          mode="horizontal"
          style={style}
          >
          <Menu.Item key="feed">
            <Icon style={{fontSize: '18px'}} type="home" />
          </Menu.Item>
          <Menu.Item key="popular">
            <Icon style={{fontSize: '18px'}} type="search" />
          </Menu.Item>
          <Menu.Item key="notifications">
            <Badge count={newNotifications}><Icon style={{fontSize: '18px'}} type="bell" /></Badge>
          </Menu.Item>
          <Menu.Item key="chat">
            <Badge count={newMessages}><Icon style={{fontSize: '18px'}} type="mail" /></Badge>
          </Menu.Item>
        </Menu>
        {this.state.component}
      </div>
    )
  }

}

export default withRouter(NavigationStack)