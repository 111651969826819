import React, {Component} from 'react';
import Spinner from '../ui/Spinner';
import Request from '../utils/Request';
import { MessageList } from 'react-chat-elements';
import MessageInput from './MessageInput';
import { SystemMessage } from 'react-chat-elements'
import renderHTML from 'react-render-html';


class Dialog extends Component {

  constructor (props, context){
    super(props, context);
    this.state = {
      loading: true,
      list: []
    }
  }

  buildList = (object) => {
    if (object) {
      const keys = Object.keys(object);
      const list = [];
      keys.map((v) => {
        list.push(object[v]);
        return null;
      });
      return list;
    }
    else {
      return [];
    }
  };

  buildObject = (object) => {
    if (object) {
      const keys = Object.keys(object);
      return object[keys[keys.length-1]];
    }
    else {
      return {};
    }
  };

  getAll = async (list) => {
    const req = new Request('chat/message_data', list);
    const res = await req.post();
    if (res.status === 200) {
      await this.setState({
        list: res.data,
        loading: false
      });
    }
  };

  getOne = async (object) => {
    let {list} = this.state;
    const req = new Request('chat/message_data', object);
    const res = await req.post();
    if (res.status === 200) {
      await this.setState({
        list: [...list, res.data[0]]
      });
    }
  };

  readMessages = () => {
    const {list} = this.state;
    list.map((v) => {
      v.read = true;
      return null;
    });
    this.setState({
      list: list
    })
  };

  async componentDidMount() {
    const {messages} = this.props;
    try {
      messages.on('value', async (snapshot) => {
        const list = snapshot.val() ? snapshot.val() : [];
        if (list) {
          if (this.state.list.length === 0) {
            const dataList = this.buildList(list);
            await this.getAll(dataList);
            let objDiv = document.getElementsByClassName("rce-mlist")[0];
            if (typeof (objDiv) !== 'undefined') objDiv.scrollTop = objDiv.scrollHeight;
          } else {
            const keys = Object.keys(list);
            if (keys.length !== this.state.list.length) {
              const object = this.buildObject(list);
              await this.getOne(object);
            } else {
              this.readMessages();
            }
          }
        }
      });
    } catch (e) {}
  }

  componentWillUnmount(){
    const {messages, interlocutor} = this.props;
    try {
      messages.off("value");
      interlocutor.off('value');
    } catch (e) {}
  }

  render () {
    const {loading, list} = this.state;
    const {me, roomId} = this.props;
    if (loading) return <Spinner/>;
    return (
      <div dir={'auto'}>
        {list.length === 0 ? <SystemMessage
          text={'Chat history is empty'}/> : (
          <MessageList
              dir={'auto'}
            className='message-list'
            toBottomHeight='100%'
            lockable
            dataSource={list.map((v) => {
              return {
                position: v.fromMe ? 'right' : 'left',
                type: 'text',
                text: renderHTML(v.text),
                date: new Date(v.created),
                status: v.read ? 'read' : 'received',
                dir: "auto"
              }
            })} />
        )}
        <MessageInput me={me} roomId={roomId}/>
      </div>
    )
  }

}

export default Dialog;