import React, {Component, cloneElement} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {Col, Row, PageHeader, message, Switch, Modal, Button, notification, Typography, Empty} from 'antd';
import Navigation from './Navigation';
import Provider from '../../utils/Provider';
import Spinner from '../../ui/Spinner';
import Request from '../../utils/Request';
import Posts from '../posts/Posts';
import SessionData from '../../utils/SessionData';
import Open from '../posts/Open'
import ReportModal from "../../modals/ReportModal";
import UpdateModal from "../../modals/UpdateModal";
import RightSide from "./RightSide";
import SearchResults from "../components/SearchResults";
import Explore from "../components/Explore";
import EntityFeed from "../components/EntityFeed";
import MessageModal from "../../modals/MessageModal";
import ProfileForm from "../../forms/ProfileForm";
import EntityList from "../components/EntityList";
import Notifications from "../components/Notifications";
import * as firebase from "firebase";
import EntityRequests from "../components/EntityRequests";
import GroupForm from "../../forms/GroupForm";
import GroupSettings from "../../ui/GroupSettings";
import GroupMemberList from "../components/GroupMemberList";
import AccountForm from "../../forms/AccountForm";
import NotificationsForm from "../../forms/NotificationsForm";
import KycForm from "../../forms/KycForm";
import Avatar from "../../ui/Avatar";
import ChatRooms from "../components/ChatRooms";
import Dialog from "../../chat/Dialog";
import ChatOptions from "../components/ChatOptions";
import CheckMark from "../../ui/CheckMark";
import Constants from "../../Constants";
import Rooms from "../../chat/Rooms";
import ContactList from "../components/ContactList";
import PaymentModal from "../../modals/PaymentModal";
import MobileNavigation from "./MobileNavigation";
import Logo from "../../template/Logo";
import Cart from "../components/Cart";
import Payment from "../components/Payment";
import Wallet from "../../ui/Wallet";
import ServerHost from "../../helpers/ServerHost";


const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #f3f2ef;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;

const headerStyle = {
  border: '1px solid #e3e3e3',
  backgroundColor: '#fff',
  borderTop: 'none',
  minHeight: 63,
  width: '100%',
  top: 0,
  zIndex: 99,
  position: 'sticky'
};

const {confirm} = Modal;
const {Paragraph, Title} = Typography;

class DesktopStack extends Component {

  constructor (props, context) {
    super (props, context);

    const provider = new Provider('user').get();
    this.state = {
      header: false,
      mainContent: false,
      extraContent: false,
      provider: provider,
      headerTitle: '',
      headerBack: false,
      headerSubtitle: '',
      headerExtra: null,
      posts: {
        solo: [],
        groups: []
      },
      loader: true,
      component: false,
      activeFeed: 'solo',
      rpp: 6,
      soloPage: 1,
      fullLoadSolo: false,
      groupsPage: 1,
      fullLoadGroups: false,
      personalPage: 1,
      fullLoadPersonal: false,
      entityPage: 1,
      fullLoadEntity: false,
      scroll: false,
      recordsLoading: false,
      post: false,
      postIndex: null,
      reportWindow: false,
      reportSource: '',
      showUpdateModal: false,
      updateModalTitle: '',
      updateForm: false,
      topEntities: false,
      map: false,
      date: false,
      link: false,
      popularEntities: false,
      popularPosts: false,
      personalPosts: [],
      entityData: false,
      entityPosts: [],
      onPersonal: false,
      messageWindow: false,
      messageToName: '',
      messageToId: 0,
      messageToEntity: '',
      personalSettings: {},
      notificationsPage: 1,
      fullLoadNotifications: false,
      notifications: [],
      newNotifications: 0,
      newMessages: 0,
      closedEntity: false,
      closedMessage: '',
      followRequests: [],
      followRequestsPage:  1,
      fullLoadFollowRequests: false,
      groupData: {},
      headerButtonLoad: false,
      kyc: {},
      chatrooms: [],
      chatData: false,
      contacts: [],
      fullLoadContacts: false,
      contactsPage: 1,
      paymentModal: false,
      paymentLink: null,
      onPaymentModalClose: () => {return null},
      mounted: false,
      soloScrollPosition: 0,
      groupsScrollPosition: 0,
      exploreScrollPosition: 0,
      searchQuery: null,
      postsLoading: false,
      commentsLoading: false,
      module: props.module,
      avatar: provider.profile.basics.photo,
      switchedToGroups: false,
      mobileDrawer: false,
      countInfo: null,
      emailModal: false,
      emailModalLoading: false
    }
  }

  openMobileDrawer = () => {
    const {mobileDrawer} = this.state;
    this.setState({
      mobileDrawer: !mobileDrawer
    })
  }

  getCountInfo = async () => {
    const {countInfo} = this.state;
    if (!countInfo) {
      let req = new Request('user/count');
      let res = await req.get();
      if (res.status === 200) {
        this.setState({
          countInfo: res.data,
        });
      }
    }
  }

  getPostsEntity = async (nextPage=false) => {
    const {id, entity} = this.props.match.params;
    if (this.state[`${entity}${id}info`].closed) return null;
    const rpp = 6;
    let page = 1;
    let fullLoad = false;
    let posts = [];
    if (typeof (this.state[`${entity}${id}postsPage`]) !== 'undefined') page = this.state[`${entity}${id}postsPage`];
    if (typeof (this.state[`${entity}${id}fullLoad`]) !== 'undefined') fullLoad = this.state[`${entity}${id}fullLoad`];
    if (typeof (this.state[`${entity}${id}posts`]) !== 'undefined') posts = this.state[`${entity}${id}posts`];
    this.setState({
      postsLoading: true
    });

    if (!nextPage && posts.length === 0 && !fullLoad || nextPage && !fullLoad) {
      const req = new Request(`${entity}/feed?${entity}_id=${id}&ppp=${rpp}&page=${page}`);
      const res = await req.get();
      if (res.status === 200) {
        if (res.data.length > 0) {
          const state = {
            [`${entity}${id}posts`]: [...posts, ...res.data],
            [`${entity}${id}postsPage`]: page + 1,
            postsLoading: false,
          }
          if (res.data.length < rpp) state[`${entity}${id}fullLoad`] = true;
          this.setState(state);
        } else {
          this.setState({
            [`${entity}${id}posts`]: [],
            [`${entity}${id}postsPage`]: page,
            [`${entity}${id}fullLoad`]: true,
            postsLoading: false
          });
        }
      } else {
        message.error(res.data.error);
      }
    }
    else {
      this.setState({
        postsLoading: false
      });
    }
  };

  getPosts = async (type) => {
    const {rpp, posts, recordsLoading} = this.state;
    const cap = type.charAt(0).toUpperCase() + type.slice(1);
    const fullLoadKey = 'fullLoad' + cap;
    const fullLoad = this.state[fullLoadKey];
    const pageKey = type + 'Page';
    const page = this.state[pageKey];
    if (!recordsLoading) {
      this.setState({
        recordsLoading: true
      });
      if (!fullLoad) {
        const req = new Request(`app/${type}?ppp=${rpp}&page=${page}`);
        const res = await req.get();
        if (res.status === 200) {
          posts[type] = [...posts[type], ...res.data];
          if (res.data.length > 0){
            this.setState({
              posts: posts,
              [pageKey]: page + 1,
              recordsLoading: false
            });

            if (res.data.length < rpp) {
              this.setState({
                [fullLoadKey]: true
              })
            }
          } else {
            this.setState({
              [fullLoadKey]: true,
              recordsLoading: false
            });
          }
        } else {
          message.error(res.data.error);
        }
      } else {
        this.setState({
          recordsLoading: false
        });
      }
    }
  };

  getPostsGroups = async () => {
    const {rpp, groupsPage, posts, fullLoadGroups, recordsLoading} = this.state;
    if (!recordsLoading) {
      this.setState({
        recordsLoading: true
      });
      if (!fullLoadGroups) {
        const req = new Request(`app/groups?ppp=${rpp}&page=${groupsPage}`);
        const res = await req.get();
        if (res.status === 200) {
          if (res.data.length > 0){
            posts.groups = [...posts.groups, ...res.data];
            const state = {
              posts: posts,
              groupsPage: groupsPage + 1,
              recordsLoading: false
            };
            if (res.data.length < rpp) state.fullLoadGroups = true;
            this.setState(state);
          } else {
            this.setState({
              fullLoadGroups: true,
              recordsLoading: false
            });
          }
        } else {
          message.error(res.data.error);
        }
      } else {
        this.setState({
          recordsLoading: false
        });
      }
    }
  };

  getNotifications = async () => {
    let {notificationsPage, notifications, fullLoadNotifications, recordsLoading} = this.state;
    const rpp = 18;
    if (!recordsLoading) {
      this.setState({
        recordsLoading: true
      });
      if (!fullLoadNotifications) {
        const req = new Request(`user/notifications?page=${notificationsPage}&rpp=${rpp}`);
        const res = await req.get();
        if (res.status === 200) {
          if (res.data.length > 0){
            const state = {
              notifications: [...notifications, ...res.data],
              notificationsPage: notificationsPage + 1,
              recordsLoading: false
            }
            if (res.data.length < rpp) state.fullLoadNotifications = true;
            this.setState(state);
          } else {
            this.setState({
              fullLoadNotifications: true,
              recordsLoading: false
            });
          }
        } else {
          message.error(res.data.error);
        }
      } else {
        this.setState({
          recordsLoading: false
        });
      }
    }
  };

  getFollowRequests = async (entity , id = false) => {
    const {recordsLoading} = this.state;
    const rpp = 18;
    const str = entity + id + 'followRequests';
    if (!recordsLoading) {
      this.setState({
        recordsLoading: true
      });
      let fullLoad = false;
      if (typeof (this.state[`${str}FullLoad`]) !== 'undefined') {
        fullLoad = this.state[`${str}FullLoad`];
      }
      if (!fullLoad) {
        let page = 1;
        if (typeof (this.state[`${str}Page`]) !== 'undefined') {
          page = this.state[`${str}Page`];
        }
        let followRequestsList = [];
        if (typeof (this.state[str]) !== 'undefined') {
          followRequestsList = this.state[str];
        }
        const req = new Request(`${entity}/follow_requests?${entity}_id=${id}&page=${page}&rpp=${rpp}`);
        const res = await req.get();
        if (res.status === 200) {
          if (res.data.length > 0){
            const state = {
              [str]: [...followRequestsList, ...res.data],
              [`${str}Page`]: page + 1,
              recordsLoading: false
            }
            if (res.data.length < rpp) state[`${str}FullLoad`] = true;
            this.setState(state);
          } else {
            this.setState({
              [`${str}FullLoad`]: true,
              recordsLoading: false
            });
          }
        } else {
          message.error(res.data.error);
        }
      } else {
        this.setState({
          recordsLoading: false
        });
      }
    }
  }

  getGroupMembersListAdmin = async (list) => {
    const {recordsLoading} = this.state;
    const {id} = this.props.match.params;
    const rpp = 24;
    const str = 'group' + id + list + 'Admin';
    if (!recordsLoading) {
      this.setState({
        recordsLoading: true
      });
      let fullLoad = false;
      if (typeof (this.state[`${str}FullLoad`]) !== 'undefined') {
        fullLoad = this.state[`${str}FullLoad`];
      }
      if (!fullLoad) {
        let page = 1;
        if (typeof (this.state[`${str}Page`]) !== 'undefined') {
          page = this.state[`${str}Page`];
        }
        let membersList = [];
        if (typeof (this.state[str]) !== 'undefined') {
          membersList = this.state[str];
        }
        const req = new Request(`group/${list}?group_id=${id}&page=${page}&rpp=${rpp}`);
        const res = await req.get();
        if (res.status === 200) {
          if (res.data.list.length > 0){
            const state = {
              [str]: [...membersList, ...res.data.list],
              [`${str}Page`]: page + 1,
              recordsLoading: false
            }
            if (res.data.list.length < rpp) state[`${str}FullLoad`] = true;
            this.setState(state);
          } else {
            this.setState({
              [str]: [],
              [`${str}FullLoad`]: true,
              recordsLoading: false
            });
          }
        } else {
          message.error(res.data.error);
        }
      } else {
        this.setState({
          recordsLoading: false
        });
      }
    }
  }

  getChatRooms = async () => {
    let {loader} = this.state;
    if (!loader) {
      this.setState({
        loader: true
      });
      let path = 'chat/rooms';
      const req = new Request(path);
      const res = await req.get();
      if (res.status === 200) {
        this.setState({
          chatrooms: res.data,
          loader: false
        });
      } else {
        message.error(res.data.error);
        this.setState({
          recordsLoading: false
        });
      }
    }
  };

  getGroupChatRooms = async () => {
    console.log('ok');
  }

  getContacts = async () => {
    let {rpp, contactsPage, contacts, fullLoadContacts, recordsLoading} = this.state;
    if (!recordsLoading) {
      this.setState({
        recordsLoading: true
      });
      if (!fullLoadContacts) {
        const req = new Request(`user/contacts?rpp=${rpp}&page=${contactsPage}`);
        const res = await req.get();
        if (res.status === 200) {
          if (res.data.length > 0){
            this.setState({
              contacts: [...contacts, ...res.data],
              contactsPage: contactsPage + 1,
              recordsLoading: false
            });
            if (res.data.length < rpp) await this.setState({fullLoadContacts: true});
          } else {
            this.setState({
              fullLoadContacts: true,
              recordsLoading: false
            });
          }
        } else {
          message.error(res.data.error);
        }
      } else {
        this.setState({
          recordsLoading: false
        });
      }
    }
  };

  getGroups = async () => {
    const {recordsLoading} = this.state;
    const rpp = 10;
    const {id} = this.props.match.params;
    if (!recordsLoading) {
      this.setState({
        recordsLoading: true
      });
      let fullLoad = false;
      if (typeof (this.state[`user${id}groupsFullLoad`]) !== 'undefined') {
        fullLoad = this.state[`user${id}groupsFullLoad`];
      }
      if (!fullLoad) {
        let page = 1;
        if (typeof (this.state[`user${id}groupsPage`]) !== 'undefined') {
          page = this.state[`user${id}groupsPage`];
        }
        let groups = [];
        if (typeof (this.state[`user${id}groups`]) !== 'undefined') {
          groups = this.state[`user${id}groups`];
        }
        const req = new Request(`user/groups?user_id=${id}&page=${page}&rpp=${rpp}`);
        const res = await req.get();
        if (res.status === 200) {
          if (res.data.list.length > 0){
            const state = {
              [`user${id}groups`]: [...groups, ...res.data.list],
              [`user${id}groupsPage`]: page + 1,
              recordsLoading: false
            }
            if (res.data.list.length < rpp) state[`user${id}groupsFullLoad`] = true;
            this.setState(state);
          } else {
            this.setState({
              [`user${id}groupsFullLoad`]: true,
              recordsLoading: false
            });
          }
        } else {
          message.error(res.data.error);
        }
      } else {
        this.setState({
          recordsLoading: false
        });
      }
    }
  }

  getFollows = async (rpp = 18) => {
    const {recordsLoading} = this.state;
    const {module} = this.props;
    const {entity, id} = this.props.match.params;
    const str = entity + id + module;
    if (!recordsLoading) {
      this.setState({
        recordsLoading: true
      });
      let fullLoad = false;
      if (typeof (this.state[`${str}FullLoad`]) !== 'undefined') {
        fullLoad = this.state[`${str}FullLoad`];
      }
      if (!fullLoad) {
        let page = 1;
        if (typeof (this.state[`${str}Page`]) !== 'undefined') {
          page = this.state[`${str}Page`];
        }
        let followList = [];
        if (typeof (this.state[str]) !== 'undefined') {
          followList = this.state[str];
        }
        const req = new Request(`${entity}/${module}?page=${page}&rpp=${rpp}&${entity}_id=${id}`);
        const res = await req.get();
        if (res.status === 200) {
          if (res.data.list.length > 0){
            const state = {
              [str]: [...followList, ...res.data.list],
              [`${str}Page`]: page + 1,
              recordsLoading: false
            }
            if (res.data.list.length < rpp) state[`${str}FullLoad`] = true;
            this.setState(state);
          } else {
            this.setState({
              [`${str}FullLoad`]: true,
              recordsLoading: false
            });
          }
        } else {
          message.error(res.data.error);
        }
      } else {
        this.setState({
          recordsLoading: false
        });
      }
    }
  }

  getPostComments = async () => {
    const {commentsLoading, rpp} = this.state;
    const {type, id} = this.props.match.params;
    if (typeof (type) !== 'undefined') {
      const str = type + id + 'comments';
      if (!commentsLoading) {
        let fullLoad = false;
        if (typeof (this.state[`${str}FullLoad`]) !== 'undefined') {
          fullLoad = this.state[`${str}FullLoad`];
        }
        if (!fullLoad) {
          this.setState({
            commentsLoading: true
          });
          let page = 1;
          if (typeof (this.state[`${str}Page`]) !== 'undefined') {
            page = this.state[`${str}Page`];
          }
          let commentList = [];
          if (typeof (this.state[str]) !== 'undefined') {
            commentList = this.state[str];
          }
          const r = await new Request(`post/${type}/comments?post_id=${id}&page=${page}&cpp=${rpp}`).get();
          if (r.status === 200) {
            if (r.data.length > 0){
              const state = {
                [str]: [...commentList, ...r.data],
                [`${str}Page`]: page + 1,
                commentsLoading: false
              }
              if (r.data.length < rpp) state[`${str}FullLoad`] = true;
              await this.setState(state);

            } else {
              this.setState({
                [`${str}FullLoad`]: true,
                commentsLoading: false,
                [str]: []
              });
            }
          } else {
            message.error(r.data.error);
          }
        } else {
          this.setState({
            commentsLoading: false
          });
        }
      }
    }

  }

  switchFeed = async () => {
    const {activeFeed} = this.state;
    const newFeed = activeFeed === 'solo' ? 'groups' : 'solo';
    await this.setState({
      [`${activeFeed}ScrollPosition`]: window.scrollY,
      activeFeed: newFeed,
      headerExtra: (
          <Switch
              checkedChildren={<span>Groups</span>}
              unCheckedChildren={<span>Solo</span>}
              checked={newFeed === 'groups'}
              onChange={this.switchFeed}
              className='feed-switch'
          />
      ),
    });
    if (newFeed === 'groups') {
      if (this.state.posts.groups.length === 0) await this.getPosts('groups');
      if (!this.state.switchedToGroups) this.setState({switchedToGroups: true});
    } else {
      if (this.state.posts.solo.length === 0) await this.getPosts('solo');
    }
    window.scrollTo(0, this.state[`${newFeed}ScrollPosition`]);
  };

  addPost = (value) => {
    this.globalPostAction(value.postType, value.id, 'add', value);
  };

  saveData = (data) => {
    const {type, id} = this.state;
    const session = new SessionData(`${type}_${id}`);
    session.set(data);
    return null;
  };

  setGroupForm = (type) => {
    this.setState({
      ...this.getRegularHeader(),
      headerExtra: <Button
          type={'primary'}
          shape={'round'}
          size={'small'}
          style={{textTransform: 'capitalize'}}
          onClick={() => this.groupAction(type)}>
        {type}
      </Button>,
      loader: false
    });
  }

  setGroupData = async () => {
    const {id} = this.props.match.params;
    const {state} = this;
    const key = 'group' + id + 'info';
    if (typeof (state[key]) === 'undefined') {
      this.setState({
        loader: true
      });
      const req = new Request(`group/info?group_id=${id}`);
      const res = await req.get();
      if (res.status === 200) {
        this.setState({
          [key]: res.data,
          loader: false
        });
      } else {
        message.error(res.data.message);
      }
    }
  }

  setSettings = async (stype) => {
    const {headerButtonLoad} = this.state;
    let action = null;
    let header = false;
    switch (stype) {
      case 'profile':
        action = 'update_profile';
        break;
      case 'account':
        action = 'update_profile';
        break;
      case 'kyc':
        action = 'update_kyc';
        header = 'Finance';
        break;
      case 'notifications':
        action = 'update_push_settings';
        break;
      default:
        return null;
    }
    this.setState({
      ...this.getRegularHeader(header ? header : stype + ' settings'),
      headerExtra: <Button type={'primary'} shape={'round'} size={'small'} onClick={() => this.saveSettings(action)}>Save</Button>,
      loader: false,
    });
  }

  setGroupCheck = async () => {
    const {id} = this.props.match.params;
    const {state} = this;
    const key = 'group' + id + 'check';
    if (typeof (state[key]) === 'undefined') {
      this.setState({
        loader: true
      });
      const req = new Request(`group/check?group_id=${id}`);
      const res = await req.get();
      if (res.status === 200) {
        this.setState({
          [key]: res.data,
          loader: false
        })
      } else {
        message.error(res.data.message)
      }
    }
  }

  getPost = async () => {
    await this.setState({
      loader: true
    });
    const {state} = this;
    const {type, id} = this.props.match.params;
    const str = type + id;
    // const {state} = this;
    if (typeof state[str] === 'undefined') {
      const r = await new Request(`post/${type}/info?post_id=${id}`).get();
      if (r.status === 200) {
        state[str] = r.data;
      } else {
        message.error(r.data.error);
      }
    }
    state.headerTitle = state[str].user ? state[str].user.name : state[str].group.name;
    state.headerSubtitle = type;
    state.headerBack = () => this.props.history.goBack();
    state.loader = false;
    if (state[str].link) state.link = state[str].link;
    if (state[str].address) state.address = state[str].address;
    if (state[str].coords) state.map = state[str].coords;
    if (state[str].address) state.map.address = state[str].address;
    this.setState(state);
  }

  setPost = async () => {
    this.setState({...this.getEmptyHeader()})
    await this.getPost();
  }

  getEmptyHeader = () => {
    return {
      headerTitle: null,
      headerSubtitle: '',
      headerBack: null,
      headerExtra: null,
      scroll: true,
      map: false,
      date: false,
      link: false,
      chatData: false,
      recordsLoading: false,
    }
  }

  setFollowList = async () => {
    await this.getEntityHeader();
    await this.getFollows();
  }

  setUserFollowRequests = async () => {
    this.setState(this.getRegularHeader('My follow requests'));
    const id = new Provider('user').get('profile').id;
    await this.getFollowRequests('user', id);
  }

  getEntityHeader = async (subtitle=false) => {
    // this.setState({
    //   closedEntity: false,
    //   closedMessage: null
    // })
    const {state} = this;
    let {entity, id} = this.props.match.params;
    if (typeof (entity) === 'undefined') entity = 'group';
    let blocked = false;
    if (typeof (state[`${entity}${id}info`]) !== 'undefined') blocked = state[`${entity}${id}info`].closed;
    if (typeof (state[`${entity}${id}info`]) === 'undefined') {
      // this.setState({
      //   loader: true
      // });
      const req = new Request(`${entity}/info?${entity}_id=${id}`);
      const res = await req.get();
      if (res.status === 200) {
        state[`${entity}${id}info`] = res.data;
        state.closedEntity = false;
        state.closedMessage = null;
      } else {
        if (res.status === 403) {
          state[`${entity}${id}info`] = res.data.data;
          state[`${entity}${id}info`].closed = true;
          state[`${entity}${id}info`].closedMessage = res.data.error;
          blocked = true;
        } else {
          message.error(res.data.error);
          return null;
        }
      }
    }
    const params = {};
    if (entity === 'user') {
      if (state[`${entity}${id}info`].hasOwnProperty('profile')) {
        params.name = state[`${entity}${id}info`].profile.basics.name;
        params.posts = state[`${entity}${id}info`].profile.basics.posts;
      } else {
        params.name = state[`${entity}${id}info`].name;
        params.posts = state[`${entity}${id}info`].posts;
      }
    } else {
      if (!blocked) {
        params.name = state[`${entity}${id}info`].basics.name;
        params.posts = state[`${entity}${id}info`].basics.posts;
      } else {
        params.name = state[`${entity}${id}info`].name;
        params.posts = state[`${entity}${id}info`].posts;
      }
    }
    let headerExtra = null;
    switch (subtitle) {
      case 'posts':
        subtitle = `${params.posts} ${params.posts === 1 ? 'post' : 'posts'}`;
        break;
      case 'groups':
          headerExtra = <Button size={'small'} shape={'round'} type={'primary'} onClick={() => this.props.history.push('/group/create')}>Create group</Button>;
        break;
      default:
        subtitle = this.props.module.replace('_', ' ');
    }

    state.headerTitle = params.name;
    state.headerSubtitle = subtitle;
    state.headerExtra = headerExtra;
    state.loader = false;
    state.headerBack = () => this.props.history.goBack();
    this.setState(state);
  }

  setEntity = async () => {
    this.setState({
      ...this.getEmptyHeader(),
      loader: true
    });
    await this.getCountInfo();
    await this.getEntityHeader('posts');
    await this.getPostsEntity();
    this.setState({loader: false});
  }

  getRegularHeader = (title=false) => {
    const {module} = this.props;
    return {
      headerTitle: (<span style={{textTransform: 'capitalize'}}>{title ? title : module.replace('_', ' ')}</span>),
      headerSubtitle: '',
      headerBack: () => this.props.history.goBack(),
      headerExtra: module === 'groups' ? <Button size={'small'} shape={'round'} type={'primary'} onClick={() => this.props.history.push('/group/create')}>Create group</Button> : null,
      scroll: true,
      map: false,
      date: false,
      link: false,
      chatData: false,
      recordsLoading: false,
    }
  }

  setGroupsList = async () => {
    await this.getEntityHeader('groups');
    const {entity, id} = this.props.match.params;
    let groups = [];
    if (typeof (this.state[`${entity}${id}groups`]) !== 'undefined') {
      groups = this.state[`${entity}${id}groups`];
    }
    this.setState({
      entity: entity,
      id: id,
      loader: false,
    });
    if (groups.length === 0) await this.getGroups();
  }

  getChatDialogHeader = () => {
    return {
      headerTitle: '',
      headerSubtitle: '',
      headerBack: () => this.props.history.goBack(),
      headerExtra: null,
      scroll: true,
      map: false,
      date: false,
      link: false,
      chatData: false,
      recordsLoading: false,
    }
  }

  setChatDialog = async () => {
    const roomId = this.props.match.params.room_id;
    const req = new Request(`chat/rooms?room_unique_id=${roomId}`);
    const res = await req.get();
    const room = firebase.database().ref('chat').child(roomId);
    const interlocutor = room.child('participants').child(`${res.data.interloc.entity}_${res.data.interloc.id}`);
    const messages = room.child('messages');
    interlocutor.on("value", (snapshot) => {
      let val = snapshot.val();
      this.setState({
        headerSubtitle: val.typing ? 'typing...' : val.online ? 'online' : res.data.interloc.hasOwnProperty('lastSeen') ? `last seen ${res.data.interloc.lastSeen.toLowerCase()}` : ''
      });
    });
    await this.setState({
      headerTitle: [
        <NavLink style={{color: res.data.interloc.hasOwnProperty('nameColor') ? res.data.interloc.nameColor : Constants.primaryColor}} to={`/${res.data.interloc.entity}/${res.data.interloc.id}`}>{res.data.interloc.name}</NavLink>,
        res.data.interloc.checkMark && <CheckMark/>
      ],
      headerSubtitle:res.data.interloc.hasOwnProperty('lastSeen') && `last seen ${res.data.interloc.lastSeen.toLowerCase()}`,
      headerExtra: <ChatOptions
          data={res.data.interloc}
          report={() => this.report(`Chatroom ${roomId}`)}
          action={this.globalEntityAction}
          goBack={() => this.props.history.goBack()}
      />,
      headerBack: () => this.props.history.goBack(),
      chatData: {
        interlocutor: interlocutor,
        messages: messages,
        roomId: roomId,
        me: res.data.me
      },
      loader: false
    });
  }

  getChatHeader = () => {
    return {
      headerTitle: <span style={{textTransform: "capitalize"}}>Messages</span>,
      headerSubtitle: '',
      headerBack: () => this.props.history.goBack(),
      headerExtra: null,
      scroll: true,
      map: false,
      date: false,
      link: false,
      loader: false
    }
  }

  setChat = async () => {
    this.setState({
      ...this.getChatHeader()
    });
    const {chatrooms} = this.state;
    if (chatrooms.length === 0) await this.getChatRooms();
  }

  getNotificationsHeader = () => {
    return {
      headerTitle: <span style={{textTransform: "capitalize"}}>Notifications</span>,
      headerSubtitle: '',
      headerBack: () => this.props.history.goBack(),
      headerExtra: <NavLink to={'/settings/notifications'}><Button type={'link'} size={'small'} icon={'setting'}/></NavLink>,
      scroll: true,
      map: false,
      date: false,
      link: false,
      loader: false
    }
  }

  setNotifications = async () => {
    const {notifications} = this.state;
    this.setState({
      ...this.getNotificationsHeader()
    });
    if (notifications.length === 0) {
      await this.getNotifications();
    }
  }

  getExploreHeader = () => {
    const {query} = this.props.match.params;
    const {pathname} = this.props.location;
    if (pathname.includes('search')) {
      return {
        headerTitle: 'Search',
        headerSubtitle: query,
        headerBack: async () => {
          this.setState({
            headerTitle: 'Explore',
            headerSubtitle: '',
          });
          this.props.history.goBack();
          await this.setFeed(this.state);
        },
        headerExtra: null,
        scroll: false,
        loader: false,
        map: false,
        date: false,
        link: false
      }
    } else {
      return {
        headerTitle: 'Explore',
        headerSubtitle: '',
        headerBack: () => this.props.history.goBack(),
        headerExtra: null,
        scroll: false,
        map: false,
        date: false,
        link: false,
      }
    }
  }

  setExplore = async () => {
    const {popularEntities, popularPosts} = this.state;
    this.setState({
      ...this.getExploreHeader()
    })
    if (!popularEntities) {
      const req = new Request('app/popular');
      const res = await req.get();
      if (res.status === 200) {
        this.setState({
          popularEntities: res.data
        });
      }
    }

    if (!popularPosts) {
      const req = new Request('app/popular_posts');
      const res = await req.get();
      if (res.status === 200) {
        this.setState({
          popularPosts: res.data
        })
      }
    }
    this.setState({
      loader: false
    });
  }

  getFeedHeader = () => {
    const {activeFeed} = this.state;
    return {
      headerTitle: 'Feed',
      headerSubtitle: '',
      headerExtra: (
          <Switch
              checkedChildren={<span>Groups</span>}
              unCheckedChildren={<span>Solo</span>}
              checked={activeFeed === 'groups'}
              onChange={this.switchFeed}
              className='feed-switch'
          />
      ),
      map: false,
      date: false,
      link: false,
      scroll: true
    }
  }

  setFeed = async () => {
    const {posts} = this.state;
    this.setState({
      ...this.getFeedHeader(),
      loader: false
    });
    if (posts.solo.length === 0) {
      await this.getPosts('solo');
    }
  }

  setModule = async () => {
    const {module} = this.props;
    const {id} = this.props.match.params;
    switch (module) {
      case 'feed':
        await this.setFeed();
        break;
      case 'popular':
        await this.setExplore();
        break;
      case 'notifications':
        await this.setNotifications();
        break;
      case 'chat':
        await this.setChat();
        break;
      case 'chat_dialog':
        await this.setChatDialog();
        break;
      case 'groups':
        await this.setGroupsList();
        break;
      case 'entity':
        await this.setEntity();
        break;
      case 'followers': case 'following':
        await this.setFollowList();
        break;
      case 'create_group': case 'update_group':
        this.setGroupForm(module.split('_')[0]);
        if (module === 'update_group') await this.setGroupData();
        break;
      case 'settings':
        const {stype} = this.props.match.params;
        await this.setSettings(stype);
        break;
      case 'post':
        await this.setPost();
        break;
      case 'user_requests':
        await this.setUserFollowRequests();
        this.setState({
          loader: false
        });
        break;
      case 'group_settings':
        await this.getEntityHeader();
        await this.setGroupCheck();
        break;
      case 'group_followers': case 'group_moderators': case 'group_blocked':
        const list = module.split('_')[1];
        await this.getEntityHeader();
        await this.getGroupMembersListAdmin(list);
        break;
      case 'group_requests':
        await this.getEntityHeader();
        await this.getFollowRequests('group', id)
        break;
      case 'group_chat':
        await this.getEntityHeader();
        await this.getGroupChatRooms();
        break;
      case 'cart':
        await this.getEntityHeader();
        break;
      case 'payment':
        this.setState({
          ...this.getRegularHeader('Complete payment'),
          loader: false
        });
        break;
      case 'wallet':
        this.setState({
          ...this.getRegularHeader('Wallet'),
          loader: false
        });
        break;
      default:
        return null;
    }
  }

  groupAdminAction = async (action, group_id, user_data) => {
    const groupKey = 'group' + group_id + 'info';
    const followersKey = 'group' + group_id + 'followersAdmin';
    const moderatorsKey = 'group' + group_id + 'moderatorsAdmin';
    const blockedKey = 'group' + group_id + 'blockedAdmin';
    const {state} = this;
    switch (action) {
      case 'unfollow':
        if (typeof (state[groupKey]) !== 'undefined') state[groupKey].followers -= 1;
        if (typeof (state[followersKey]) !== 'undefined') {
          state[followersKey].map((v, i) => {
            if (v.id === user_data.id) state[followersKey].splice(i, 1);
          })
        }
        if (typeof (state[moderatorsKey]) !== 'undefined') {
          state[moderatorsKey].map((v, i) => {
            if (v.id === user_data.id) state[moderatorsKey].splice(i, 1);
          })
        }
        break;
      case 'promote':
        if (typeof (state[followersKey]) !== 'undefined') {
          state[followersKey].map((v, i) => {
            if (v.id === user_data.id) state[followersKey][i].isModerator = !user_data.isModerator;
          })
        }
        if (typeof (state[moderatorsKey]) !== 'undefined') {
          const moderators = [];
          state[moderatorsKey].map((v) => {
            moderators.push(v.id);
          });
          if (moderators.includes(user_data.id)) {
            state[moderatorsKey].map((v, i) => {
              if (v.id === user_data.id) state[moderatorsKey].splice(i, 1);
            });
          } else {
            state[moderatorsKey].push(user_data);
          }
        }
        break;
      case 'block':
        if (typeof (state[groupKey]) !== 'undefined') state[groupKey].followers -= 1;
        if (typeof (state[followersKey]) !== 'undefined') {
          state[followersKey].map((v, i) => {
            if (v.id === user_data.id) {
              state[followersKey][i].isModerator = false;
              state[followersKey].splice(i, 1);
            }
          })
        }
        if (typeof (state[moderatorsKey]) !== 'undefined') {
          state[moderatorsKey].map((v, i) => {
            if (v.id === user_data.id) {
              state[moderatorsKey][i].isModerator = false;
              state[moderatorsKey].splice(i, 1);
            }
          })
        }
        if (typeof (state[blockedKey]) !== 'undefined') {
          const blocked = [];
          state[blockedKey].map((v) => {
            blocked.push(v.id);
          });
          if (blocked.includes(user_data.id)) {
            state[blockedKey].map((v, i) => {
              if (v.id === user_data.id) state[blockedKey].splice(i, 1);
            });
          } else {
            user_data.groupBlocked = true;
            state[blockedKey].push(user_data);
          }
        }
        break;
    }
    this.setState(state);
    const req = new Request(`group/${action}`, {
      group_id: group_id,
      user_id: user_data.id
    });
    const res = await req.post();
    if (res.status === 200) {
      message.info(res.data.message);
    } else {
      message.error(res.data.error);
    }
  }

  globalPostAction = (type, id, action, data=null) => {
    const {state} = this;
    id = parseInt(id);
    const key = type + id;
    const {entity} = this.props.match.params;
    switch (action) {
      case 'like':
        if (typeof(state[key]) !== 'undefined') {
          state[key].likes += 1;
          state[key].liked = true;

          const entity = {
            id: state[key].user ? state[key].user.id : state[key].group.id,
            name: state[key].user ? 'user' : 'group'
          }
          const entityPostsKey = entity.name + entity.id + 'posts';
          if (typeof(state[entityPostsKey]) !== 'undefined') {
            state[entityPostsKey].map((v, i) => {
              if (v.postType === type && v.id === id) {
                state[entityPostsKey][i]['likes'] += 1;
                state[entityPostsKey][i]['liked'] = true;
              }
              return null;
            });
          }
        }

        if (typeof (entity) !== 'undefined') {
          const entityId = this.props.match.params.id;
          const entityPostsKey = entity + entityId + 'posts';
          if (typeof(state[entityPostsKey]) !== 'undefined') {
            state[entityPostsKey].map((v, i) => {
              if (v.postType === type && v.id === id) {
                state[entityPostsKey][i]['likes'] += 1;
                state[entityPostsKey][i]['liked'] = true;
              }
              return null;
            });
          }
        }

        state.posts.solo.map((v, i) => {
          if (v.postType === type && v.id === id) {
            state.posts.solo[i]['likes'] += 1;
            state.posts.solo[i]['liked'] = true;
          }
          return null;
        });

        state.posts.groups.map((v, i) => {
          if (v.postType === type && v.id === id) {
            state.posts.groups[i]['likes'] += 1;
            state.posts.groups[i]['liked'] = true;
          }
          return null;
        });
        new Request(`post/${type}/${action}`, {
          post_id: id
        }).post();
        break;
      case 'update':
        if (typeof(state[key]) !== 'undefined') {
          state[key] = data;

          const entity = {
            id: state[key].user ? state[key].user.id : state[key].group.id,
            name: state[key].user ? 'user' : 'group'
          }
          const entityPostsKey = entity.name + entity.id + 'posts';
          if (typeof(state[entityPostsKey]) !== 'undefined') {
            state[entityPostsKey].map((v, i) => {
              if (v.postType === type && v.id === id) {
                state[entityPostsKey][i] = data;
              }
              return null;
            });
          }
        }

        if (typeof (entity) !== 'undefined') {
          const entityId = this.props.match.params.id;
          const entityPostsKey = entity + entityId + 'posts';
          if (typeof(state[entityPostsKey]) !== 'undefined') {
            state[entityPostsKey].map((v, i) => {
              if (v.postType === type && v.id === id) {
                state[entityPostsKey][i] = data;
              }
              return null;
            });
          }
        }

        state.posts.solo.map((v, i) => {
          if (v.postType === type && v.id === id) {
            state.posts.solo[i] = data;
          }
          return null;
        });

        state.posts.groups.map((v, i) => {
          if (v.postType === type && v.id === id) {
            state.posts.groups[i] = data;
          }
          return null;
        });
        break;
      case 'delete':
        if (typeof(state[key]) !== 'undefined') {
          const entity = {
            id: state[key].user ? state[key].user.id : state[key].group.id,
            name: state[key].user ? 'user' : 'group'
          }
          const entityPostsKey = entity.name + entity.id + 'posts';
          if (typeof(state[entityPostsKey]) !== 'undefined') {
            state[entityPostsKey].map((v, i) => {
              if (v.postType === type && v.id === id) {
                state[entityPostsKey].splice(i, 1);
              }
              return null;
            });
          }
          delete state[key];
        }

        if (typeof (entity) !== 'undefined') {
          const entityId = this.props.match.params.id;
          const entityPostsKey = entity + entityId + 'posts';
          if (typeof(state[entityPostsKey]) !== 'undefined') {
            state[entityPostsKey].map((v, i) => {
              if (v.postType === type && v.id === id) {
                state[entityPostsKey].splice(i, 1);
              }
              return null;
            });
          }
        }

        state.posts.solo.map((v, i) => {
          if (v.postType === type && v.id === id) {
            state.posts.solo.splice(i, 1);
          }
          return null;
        });

        state.posts.groups.map((v, i) => {
          if (v.postType === type && v.id === id) {
            state.posts.groups.splice(i, 1);
          }
          return null;
        });
        break;
      case 'add':
        state[key] = data;
        state[key + 'comments'] = [];
        if (typeof(state[key]) !== 'undefined') {
          const entity = {
            id: state[key].user ? state[key].user.id : state[key].group.id,
            name: state[key].user ? 'user' : 'group'
          }
          const entityPostsKey = entity.name + entity.id + 'posts';
          if (typeof (state[entityPostsKey]) !== 'undefined') state[entityPostsKey].unshift(data);

          if (entity.name === 'user') {
            state.posts.solo.unshift(data);
          } else {
            if (state.switchedToGroups) state.posts.groups.unshift(data);
          }

          // if (typeof (entity) !== 'undefined') {
          //   const entityPostsKey = state[key].entity + state[key][state[key].entity].id + 'posts';
          //   if (typeof(state[entityPostsKey]) !== 'undefined') state[entityPostsKey].unshift(data);
          // }
        }
        break;
      default:
        return null;
    }
    this.setState(state);
  };

  globalEntityAction = async (entity, id, action, request = false) => {
    const {topEntities} = this.state;
    const feed = entity === 'user' ? 'solo' : 'groups';
    id = parseInt(id);
    const {state} = this;
    const postsKey = entity + id + 'posts';
    const entityKey = entity + id + 'info';
    switch (action) {
      case 'follow':
        const ownId = new Provider('user').get('profile').id;
        const ownKey = 'user' + ownId + 'info';
        // if (typeof (state[ownKey]) !== 'undefined') {
        //   state[ownKey].profile.following -=1 ;
        //   state[ownKey].profile.count.following -=1 ;
        // }

        if (typeof (state[postsKey]) !== 'undefined') {
          state[postsKey].map((v, i) => {
            if (v[entity] && v[entity].id === id) {
              state[postsKey][i][entity].following = !state[postsKey][i][entity].following;
            }
          })
        }

        if (typeof (state[entityKey]) !== 'undefined') {
          if (state[entityKey].private) {
            state[entityKey].followRequestSent = !state[entityKey].followRequestSent
          } else {
            if (entityKey.includes('user')) {
              state[entityKey].profile.following = !state[entityKey].profile.following;
              // state[entityKey].profile.followers -=1 ;
              // state[entityKey].profile.count.followers -=1 ;

            } else {
              state[entityKey].following = !state[entityKey].following;
              // state[entityKey].followers -= 1;
            }
          }
        }
        state.posts[feed].map((v, i) => {
          if (v[entity].id === id) {
            state.posts[feed][i][entity].following = !state.posts[feed][i][entity].following;
          }
        });

        if (topEntities) {
            topEntities[`${entity}s`].map((v) => {
              if (v.id === id) {
                v.following = !v.following;
              }
              return null;
            });
        }
        break;
      case 'mute':
        if (typeof (state[postsKey]) !== 'undefined') {
          state[postsKey].map((v, i) => {
            if (v[entity] && v[entity].id === id) {
              state[postsKey][i][entity].muted = !state[postsKey][i][entity].muted;
            }
          })
        }

        if (typeof (state[entityKey]) !== 'undefined') {
          if (entityKey.includes('user')) {
            state[entityKey].profile.muted = !state[entityKey].profile.muted;
          } else {
            state[entityKey].muted = !state[entityKey].muted;
          }
        }

        state.posts[feed].map((v, i) => {
          if (v[entity].id === id) {
            state.posts[feed][i][entity].muted = !state.posts[feed][i][entity].muted;
          }
        });

        if (topEntities) {
          topEntities[`${entity}s`].map((v) => {
            if (v.id === id) {
              v.muted = !v.muted;
            }
            return null;
          });
        }
        break;
      case 'block':
        if (typeof (state[postsKey]) !== 'undefined') {
          state[postsKey].map((v, i) => {
            if (v[entity] && v[entity].id === id) {
              state[postsKey][i][entity].blocked = !state[postsKey][i][entity].blocked;
            }
          })
        }

        if (typeof (state[entityKey]) !== 'undefined') {
          if (entityKey.includes('user')) {
            state[entityKey].profile.blocked = !state[entityKey].profile.blocked;
          } else {
            state[entityKey].blocked = !state[entityKey].blocked;
          }
        }

        state.posts[feed].map((v, i) => {
          if (v[entity].id === id) {
            state.posts[feed][i][entity].blocked = !state.posts[feed][i][entity].blocked;
          }
        });

        if (topEntities) {
          topEntities[`${entity}s`].map((v) => {
            if (v.id === id) {
              v.blocked = !v.blocked;
            }
            return null;
          });
        }
        break;
      case 'send_request':
        switch (request) {
          case 'chat':
            if (typeof (state[entityKey]) !== 'undefined') state[entityKey].profile.chatRequestSent = !state[entityKey].profile.chatRequestSent;
            break;
          default:
            return null;
        }
        break;
      case 'kick':
        const userId = new Provider('user').get('profile').id;
        if (entity === 'user') {
          if (typeof state[`user${userId}followers`] !== 'undefined') {
            state[`user${userId}followers`].map((v, i) => {
              if (v.basics.id === id) {
                state[`user${userId}followers`].splice(i, 1);
              }
            })
          }
          if (typeof state[`user${userId}info`] !== 'undefined') {
            state[`user${userId}info`].profile.followers -= 1;
            state[`user${userId}info`].profile.count.followers -= 1;
          }
        }
        break;
      default:
        return null;
    }
    await this.setState(state);
    const req = new Request(`${entity}/${action}`, {
      [`${entity}_id`]: id,
      type: request
    });
    const res = await req.post();
    if (res.status === 200) {
      message.success(res.data.message);
    } else {
      message.error('Some error happened');
    }
  };

  globalUserRequestAction = async (type, entity, id, request_action, request_id, index) => {
    const {state} = this;
    let key = null;
    let  entityKey = entity + id + 'info';
    const requestsKey = entity + id + 'followRequests';
    if (typeof (state[requestsKey]) !== 'undefined') state[requestsKey].splice(index, 1);
    if (typeof state[entityKey] !== 'undefined') {
      if (request_action === 'accept') {
        if (entity === 'user') {
          state[entityKey].profile.followers += 1;
          state[entityKey].profile.count.followers += 1;
        } else {
          state[entityKey].followers += 1;
        }
      }
      if (entity === 'group') {
        const checkKey = 'group' + id + 'check';
        if (typeof (state[checkKey]) !== 'undefined') state[checkKey].newRequests -=1;
        state[entityKey].newRequests -=1;
      }
    }
    this.setState(state);
    const data = {
      [`${entity}_id`]: id,
      request_id: request_id,
      type: type
    };
    const r = await new Request(`${entity}/${request_action}_request`, data).post();
    if (r.status === 200) {
      const data = r.data.data;
      key = entity + id + 'followers';
      if (typeof state[key] !== 'undefined') state[key].unshift(data.from);
      state.module = this.state.module;
      message.success(r.data.message);
      this.setState(state);
    }
  }

  globalPostUpdate = async (type, id, data) => {
    this.globalPostAction(type, id, 'update', data);
    this.closeModal();
  };

  globalPostDelete = async (type, id) => {
    const self = this;
    confirm({
      title: 'Do you want to delete this post?',
      content: 'This action is not reversible',
      onOk() {
        const {posts, personalPosts, entityPosts} = self.state;

        new Request(`post/${type}/delete`, {
          post_id: id
        }).post();
        self.globalPostAction(type, id, 'delete');
        if (self.props.location.pathname.includes('post')) self.props.history.goBack();
      },
      onCancel() {},
    });
  };

  async componentDidMount() {
    const {module} = this.props;
    const {state} = this;
    const req = new Request('app/popular?limit=3');
    const res = await req.get();
    if (res.status === 200) {
      this.setState({
        topEntities: res.data
      })
    } else {
      message.error(res.data.error);
    }
    const profile = new Provider('user').get('profile');
    if (profile) {
      const newNotifications = firebase.database().ref('global').child(profile.basics.id).child('newNotifications');
      newNotifications.on("value", (snapshot) => {
        if (module === 'notifications') this.getNotifications();
        this.setState({
          newNotifications: snapshot.val()
        });
      });
      const newMessages = firebase.database().ref('global').child(profile.basics.id).child('newMessages');
      newMessages.on("value", (snapshot) => {
        this.setState({
          newMessages: snapshot.val()
        });
      });
      const ntfctn = firebase.database().ref('global').child(profile.basics.id).child('notification');
      ntfctn.on("value", (snapshot) => {
        let val = snapshot.val();
        if (val) {
          const {notifications} = this.state;
          notifications.unshift(val);
          this.setState({
            notifications: notifications
          })
          try {
            return notification[val.type]({
              message: val.title,
              description: val.text,
              onClick: () => {
                this.props.history.push('/notifications');
                notification.destroy();
              }
            });
          } catch (e) {
            return null;
          }
        }
      });

      const message = firebase.database().ref('global').child(profile.basics.id).child('message');
      message.on("value", (snapshot) => {
        const val = snapshot.val();
        if (val && module !== 'chat_dialog') {
          try {
            return notification['info']({
              message: val.title,
              description: val.text,
              icon: <Avatar src={val.photo}/>,
              onClick: () => {
                this.props.history.push(`/chat/${val.room_id}`);
                notification.destroy();
              }
            });
          } catch (e) {
            return null;
          }
        }
      })
    }
    await this.setModule();
    const r = await new Request('user/me').get();
    const self = this;
    if (r.status === 200) {
      const {profile, contactInfoConfirmations} = r.data
      if (!contactInfoConfirmations.email.status) this.setState({emailModal: true});
      if (profile.basics.photo.includes('default/user.png') && !window.location.pathname.includes('settings') && contactInfoConfirmations.email.status) {
        setTimeout(() => {
          const profile = new Provider('user').get('profile');
          if (profile) {
            Modal.info({
              title: 'Add cool picture!',
              content: (
                  <Paragraph>Add profile picture so more CrypTalkers will follow you!</Paragraph>
              ),
              onOk() {
                self.props.history.push('/settings/profile');
              },
            });
          }
        }, 10000);
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {module} = await this.props;
    const {id, entity} = this.props.match.params;
    const {stype} = this.props.match.params;
    const prevStype = prevProps.match.params.stype;
    const {activeFeed} = this.state;
    if (module !== prevProps.module) {
      this.setState({loader: true, module: module});
      switch (module) {
        case 'feed':
          this.setState({
            ...this.getFeedHeader()
          });
          if (this.state.posts[activeFeed].length === 0) await this.getPosts(activeFeed);
          this.setState({loader: false})
          window.scrollTo(0, this.state.soloScrollPosition)
          break;
        case 'popular':
          const {popularEntities, popularPosts} = this.state;
          this.setState({
            ...this.getExploreHeader()
          });
          if (!window.location.pathname.includes('search')) {
            if (!popularEntities || !popularPosts) {
              this.setState({loader: true});
              await this.setExplore();
            }
          }
          this.setState({loader: false})
          window.scrollTo(0, this.state.exploreScrollPosition);
          break;
        case 'notifications':
          this.setState({
            ...this.getNotificationsHeader()
          });
          if (this.state.notifications.length === 0) await this.getNotifications();
          window.scrollTo(0, this.state.notificationsScrollPosition);
          break;
        case 'chat':
          this.setState({
            ...this.getChatHeader()
          });
          if (this.state.chatrooms.length === 0) await this.getChatRooms();
          break;
        case 'chat_dialog':
          await this.setChatDialog();
          break;
        case 'groups':
          await this.getEntityHeader('groups');
          let groups = [];
          if (typeof (this.state[`user${id}groups`]) !== 'undefined') {
            groups = this.state[`user${id}groups`];
          }
          if (groups.length === 0) await this.getGroups();
          if (typeof (this.state[`user${id}groupsScroll`]) !== 'undefined') {
            window.scrollTo(0, this.state[`user${id}groupsScroll`]);
          }
          break;
        case 'entity':
          await this.setEntity();
          break;
        case 'followers': case 'following':
          await this.getEntityHeader();
          let followList = [];
          if (typeof (this.state[`${entity}${id}${module}`]) !== 'undefined') {
            followList = this.state[`${entity}${id}${module}`]
          }
          if (followList.length === 0) await this.getFollows();
          this.setState({loader: false});
          if (typeof (this.state[`${entity}${id}${module}Scroll`]) !== 'undefined') {
            window.scrollTo(0, this.state[`${entity}${id}${module}Scroll`]);
          }
          break;
        case 'create_group': case 'update_group':
          this.setGroupForm(module.split('_')[0]);
          if (module === 'update_group') await this.setGroupData();
          break;
        case 'settings':
          await this.setSettings(stype);
          break;
        case 'post':
          await this.setPost();
          break;
        case 'user_requests':
          await this.setUserFollowRequests();
          this.setState({
            loader: false
          })
          break;
        case 'group_settings':
          await this.getEntityHeader();
          await this.setGroupCheck();
          break;
        case 'group_followers': case 'group_moderators': case 'group_blocked':
          const list = module.split('_')[1];
          await this.getEntityHeader();
          await this.getGroupMembersListAdmin(list);
          break;
        case 'group_requests':
          await this.getEntityHeader();
          await this.getFollowRequests('group', id)
          break;
        case 'group_chat':
          await this.getEntityHeader();
          await this.getGroupChatRooms();
          break;
        case 'cart':
          await this.getEntityHeader();
          break;
        case 'payment':
          this.setState({
            ...this.getRegularHeader('Complete payment'),
            loader: false
          });
          break;
        case 'wallet':
          this.setState({
            ...this.getRegularHeader(),
            loader: false
          });
          break;
        default:
          this.setState({loader: true});
      }
    }
    if (stype && prevStype && stype !== prevStype) {
      await this.setSettings(stype);
    }
    if (prevProps.module === 'entity' && entity && id !== prevProps.match.params.id) {
      await this.setEntity();
      if (typeof (this.state[`${entity}${id}ScrollPosition`]) !== 'undefined') {
        window.scrollTo(0, this.state[`${entity}${id}ScrollPosition`])
      }
    }
  }

  report = (link) => {
    this.setState({
      reportWindow: true,
      reportSource: link
    })
  };

  openUpdateModal = (title, form) => {
    this.setState({
      showUpdateModal: true,
      updateModalTitle: `Update ${title}`,
      updateForm: form
    })
  };

  openMessageModal = (name, entity, id) => {
    this.setState({
      messageWindow: true,
      messageToName: name,
      messageToId: id,
      messageToEntity: entity
    })
  };

  closeModal = () => {
    this.setState({
      reportWindow: false,
      reportSource: '',
      showUpdateModal: false,
      updateForm: null,
      updateModalTitle: '',
      messageWindow: false,
      messageToName: '',
      messageToId: 0,
      messageToEntity: ''
    })
  };

  search = (val) => {
    this.setState({
      headerTitle: 'Search',
      headerSubtitle: val,
      searchQuery: val,
      headerBack: () => {
        this.setState({
          headerTitle: 'Explore',
          headerSubtitle: '',
        });
        this.props.history.goBack();
      },
    });
    this.props.history.push(`/search/${val}`);
  };

  updateProvider = async () => {
    const r = await new Request('user/me').get();
    const provider = new Provider('user');
    provider.set(r.data);
  }

  saveSettings = async (action = 'update_profile') => {
    await this.setState({
      headerButtonLoad: true
    });
    const {personalSettings} = this.state;
    const {state} = this;
    const provider = new Provider('user');
    let req = new Request(`user/${action}`, personalSettings);
    let res = await req.post();
    if (res.status === 200){
      message.success(res.data.message);
      provider.set(res.data.data);
      switch (action) {
        case 'update_profile':
          const key = 'user' + res.data.data.profile.id + 'info';
          if (typeof (state[key]) !== 'undefined') this.setState({[key]: res.data.data});
          const postsKey = 'user' + res.data.data.profile.id + 'posts';
          if (typeof (state[postsKey]) !== 'undefined') {
            let posts = state[postsKey];
            posts.map((v, i) => {
              if (v.user && v.user.id === res.data.data.profile.id) {
                posts[i].user.name = res.data.data.profile.basics.name;
                posts[i].user.photo = res.data.data.profile.basics.photo;
              }
            });
            this.setState({[postsKey]: posts});
          }
          if (state.posts.solo.length > 0) {
            let posts = state.posts;
            posts.solo.map((v, i) => {
              if (v.user && v.user.id === res.data.data.profile.id) {
                posts.solo[i].user.name = res.data.data.profile.basics.name;
                posts.solo[i].user.photo = res.data.data.profile.basics.photo;
              }
            });
            this.setState({posts: posts});
          }
          this.setState({avatar: res.data.data.profile.basics.photo})
          break;
      }
    } else {
      message.error(res.data.error);
    }
    this.setState({
      headerButtonLoad: false
    });
  };

  handleSettings = async (e) => {
    const {personalSettings} = this.state;
    let keys = Object.keys(e);
    keys.map((key) => {
      personalSettings[key] = e[key];
      return null;
    });
    await this.setState({
      personalSettings: personalSettings
    });
  };

  removeNotification = async (index) => {
    const {notifications} = this.state;
    notifications.splice(index, 1);
    await this.setState({
      notifications: notifications
    });
  };

  removeFollowRequest = async (index) => {
    const {followRequests, notifications} = this.state;
    let {newNotifications} = this.state;
    notifications.map((v, i) => {
      if (v.typeId === 'USER_FOLLOW_REQUEST' && v.user.id === followRequests[index].profile.id) {
        notifications.splice(i, 1);
        newNotifications--;
      }
    });
    followRequests.splice(index, 1);
    await this.setState({
      followRequests: followRequests,
      notifications: notifications,
      newNotifications: newNotifications
    });
  };

  groupAction = async (type) => {
    this.setState({
      headerButtonLoad: true
    });
    const {groupData} = this.state;
    const {state} = this;
    let req = new Request(`group/${type}`, groupData);
    let res = await req.post();
    const r = type === 'create' ? res.data : res.data.data;
    if (res.status === 200){
      const key = 'group' + r.basics.id + 'info';
      const postsKey = 'group' + r.basics.id + 'posts';
      const fullLoadKey = 'group' + r.basics.id + 'fullLoad';
      this.setState({
        [key]: r,
        [postsKey]: [],
        [fullLoadKey]: true
      });
      if (type === 'create') {
        const userId = new Provider('user').get('profile').id;
        const groupsKey = 'user' + userId + 'groups';
        if (typeof (this.state[groupsKey]) !== 'undefined') {
          const groups = this.state[groupsKey];
          groups.push(r.basics);
          this.setState({[groupsKey]: groups});
        }
        if (!res.data.business) {
          message.success('Your group was successfully created!');
          this.props.history.push(`/group/${r.id}`);
        } else {
          this.setState({
            paymentModal: true,
            paymentLink: `https://api.cryptalks.app/processing/paypal?plan=P-8SY51871YT7382139L6X7LWY&description=Business%20Group&group=${r.id}&text=Owning%20a%20Business%20Group%20requires%20a%20payment%20of%20%2418%20per%20month%20-%20%20starting%20the%202nd%20month%20(1-month%20free%20trial).`,
            onPaymentModalClose: () => {
              this.props.history.push(`/group/${r.id}`);
              this.setState({
                paymentModal: false
              })
            }
          })
        }
      } else {
        const postsKey = 'group' + r.id + 'posts';
        if (typeof (state[postsKey]) !== 'undefined') {
          let posts = state[postsKey];
          posts.map((v, i) => {
            if (v.group && v.group.id ===r.id) {
              posts[i].group.name = r.basics.name;
              posts[i].group.photo = r.basics.photo;
            }
          });
          this.setState({[postsKey]: posts});
        }
        if (state.posts.groups.length > 0) {
          let posts = state.posts;
          posts.groups.map((v, i) => {
            if (v.group && v.group.id === r.id) {
              posts.groups[i].group.name = r.basics.name;
              posts.groups[i].group.photo = r.basics.photo;
            }
          });
          this.setState({posts: posts});
        }
        const userId = new Provider('user').get('profile').id;
        const groupsKey = 'user' + userId + 'groups';
        if (typeof (state[groupsKey]) !== 'undefined') {
          const groups = state[groupsKey];
          groups.map((v, i) => {
            if (v.id === r.id) {
              groups[i] = r.basics;
            }
          });
          this.setState({
            [groupsKey]: groups
          });
        }
        message.success(res.data.message)
      }
    } else if(res.status === 403) {
      window.location.href = r.redirect;
    } else {
      message.error(res.data.error);
    }
    this.setState({
      headerButtonLoad: false,
      groupData: {}
    });
  };

  handleGroupData = async (e) => {
    const {groupData} = this.state;
    let keys = Object.keys(e);
    keys.map((key) => {
      groupData[key] = e[key];
      return null;
    });
    await this.setState({
      groupData: groupData
    });
  };

  setComments = (list) => {
    const {type, id} = this.props.match.params;
    const key = type + id + 'comments';
    this.setState({
      [key]: list
    });
  }

  addComment = (comment) => {
    const {type, id} = this.props.match.params;
    const {state} = this;
    const key = type + id + 'comments';
    const postKey = type + id;
    if (typeof (state[key]) !== 'undefined') {
      console.log(state[key])
      state[key].push(comment)
      if (typeof (state[postKey]) !== 'undefined') {
        state[postKey].comments += 1
        const entity = {
          id: state[postKey].user ? state[postKey].user.id : state[postKey].group.id,
          name: state[postKey].user ? 'user' : 'group'
        }
        const entityPostsKey = entity.name + entity.id + 'posts';
        if (typeof(state[entityPostsKey]) !== 'undefined') {
          state[entityPostsKey].map((v, i) => {
            if (v.postType === type && v.id === parseInt(id)) {
              state[entityPostsKey][i].comments += 1;
            }
          });
        }
      }
      this.setState(state);
    }
  }

  likeComment = (index, reply_index=false) => {
    const {type, id} = this.props.match.params;
    const {state} = this;
    const key = type + id + 'comments';
    if (reply_index === false){
      state[key][index].likes = state[key][index].liked ? state[key][index].likes -1 : state[key][index].likes + 1;
      state[key][index].liked = !state[key][index].liked;
    } else {
      state[key][index].replies[reply_index].likes = state[key][index].replies[reply_index].liked ? state[key][index].replies[reply_index].likes - 1 : state[key][index].replies[reply_index].likes + 1;
      state[key][index].replies[reply_index].liked = !state[key][index].replies[reply_index].liked;
    }
    this.setState(state);
  }

  setMeetAbout = (text) => {
    const {id} = this.props.match.params;
    const {state} = this;
    const key = 'user' + id + 'info';
    if (typeof (state[key]) !== 'undefined') {
      state[key].profile.meet_about = text;
      this.setState(state);
      new Request('user/update_profile', {
        meet_about: text
      }).post();
    }
  }

  upgrade = () => {
    const id = new Provider('user').get('profile').id
    this.setState({
      paymentModal: true,
      paymentLink: `https://api.cryptalks.app/processing/paypal?plan=P-8JY55919LC177433GMDSEMBY&description=PRO%20Account&user=${id}`,
      onPaymentModalClose: () => {
        this.setState({
          paymentModal: false
        });
        // window.location.reload();
      }
    })
  }

  checkEmail = async () => {
    this.setState({emailModalLoading: true});
    const r = await new Request('user/me').get();
    if (r.status === 200) {
      const {contactInfoConfirmations} = r.data
      if (!contactInfoConfirmations.email.status) {
        message.error('Please, check your inbox and confirm email to proceed');
      } else {
        message.success('Thank you for confirming email');
        this.setState({emailModal: false});
      }
    } else {
      message.error('Unexpected error happened');
    }
    this.setState({emailModalLoading: false});
  }

  render() {
    const {
      provider,
      headerTitle,
      headerBack,
      headerSubtitle,
      headerExtra,
      loader,
      activeFeed,
      posts,
      recordsLoading,
      post,
      postIndex,
      reportWindow,
      reportSource,
      updateModalTitle,
      showUpdateModal,
      updateForm,
      topEntities,
      map,
      date,
      link,
      popularEntities,
      popularPosts,
      mobileDrawer,
      messageWindow,
      messageToName,
      messageToId,
      messageToEntity,
      notifications,
      newNotifications,
      newMessages,
      kyc,
      chatrooms,
      chatData,
      contacts,
        paymentLink,
        paymentModal,
      onPaymentModalClose,
      postsLoading,
      commentsLoading,
      headerButtonLoad,
      avatar,
      countInfo,
        emailModal,
      emailModalLoading,
    } = this.state;
    let {module} = this.state;
    const listModules = [
      'followers',
      'following',
      'groups'
    ];
    let listName;
    if (listModules.includes(module)) {
      listName = module;
      module = 'list';
    }
    let updateGroup = false;
    const groupModules = [
      'create_group',
      'update_group'
    ];
    if (groupModules.includes(module)) {
      if (module === 'update_group') updateGroup =  true;
    }

    const {entity, id} = this.props.match.params;
    let component;
    if (loader) {
      component = null;
    } else {
      switch (module) {
        case 'feed':
          component = <Posts
              remove={this.globalPostDelete}
              update={this.globalPostUpdate}
              add={this.addPost}
              key={activeFeed}
              posts={posts ? posts[activeFeed]: []}
              postForm={activeFeed}
              report={this.report}
              openUpdateModal={this.openUpdateModal}
              onScrollToBottom={() => this.getPosts(activeFeed)}
              recordsLoading={recordsLoading}
              htmlId={'feed'}
              entityAction={this.globalEntityAction}
              postAction={this.globalPostAction}
              onUnmount={(pos) => this.setState({
                [`${activeFeed}ScrollPosition`]: pos
              })}
          />;
          break;
        case 'post':
          component = <Open
              // key={this.props.match.params.type+this.props.match.params.id}
              // post={this.state[this.props.match.params.type+this.props.match.params.id]}
              loading={commentsLoading}
              addComment={this.addComment}
              likeComment={this.likeComment}
              fullCommentsLoad={this.state[this.props.match.params.type+this.props.match.params.id+'commentsFullLoad']}
              getComments={this.getPostComments}
              index={postIndex}
              remove={this.globalPostDelete}
              update={this.globalPostUpdate}
              report={this.report}
              openUpdateModal={this.openUpdateModal}
              entityAction={this.globalEntityAction}
              postAction={this.globalPostAction}
              module={module}
          />;
          break;
        case 'popular':
          const {pathname} = this.props.location;
          const {query} = this.props.match.params;
          if (pathname.includes('search')) {
            component = <SearchResults
                key={query}
                query={query}
                onUnmount={(pos) => this.setState({
                  exploreScrollPosition: pos
                })}
            />
          } else {
            component = <Explore
                posts={popularPosts}
                entities={popularEntities}
                onUnmount={(pos) => this.setState({
                  exploreScrollPosition: pos
                })}
                search={this.search}
            />
          }
          break;
        case 'entity':
          let p = [];
          let closed = false;
          let closedMessage = null;
          if (typeof (this.state[`${entity}${id}info`]) !== 'undefined') {
            closed = this.state[`${entity}${id}info`].closed;
            closedMessage = this.state[`${entity}${id}info`].closedMessage;
          }

          if (typeof (this.state[`${entity}${id}posts`]) !== 'undefined') {
            p = this.state[`${entity}${id}posts`];
          }

          component = <EntityFeed
              loader={loader}
              // key={entityKey}
              data={this.state[`${entity}${id}info`]}
              posts={p}
              onScrollToBottom={this.getPostsEntity}
              entityAction={this.globalEntityAction}
              postAction={this.globalPostAction}
              recordsLoading={postsLoading}
              openUpdateModal={this.openUpdateModal}
              update={this.globalPostUpdate}
              remove={this.globalPostDelete}
              report={this.report}
              message={this.openMessageModal}
              closedEntity={closed}
              closedMessage={closedMessage}
              loading={recordsLoading}
              push={this.addPost}
              onUnmount={(pos) => {
                if (typeof (entity) !== 'undefined') {
                  this.setState({
                    [`${entity}${id}ScrollPosition`]: pos,
                  });
                }
              }}
              drawerOpen={mobileDrawer}
              openMobileDrawer={this.openMobileDrawer}
              countInfo={countInfo}
              setMeetAbout={this.setMeetAbout}
              payment={this.upgrade}
          />;
          break;
        case 'settings':
          const stype = this.props.match.params.stype;
          switch (stype) {
            case 'profile':
              component = <ProfileForm save={this.handleSettings} />;
              break;
            case 'account':
              component = <AccountForm save={this.handleSettings}/>;
              break;
            case 'notifications':
              component = <NotificationsForm save={this.handleSettings}/>;
              break;
            case 'kyc':
              component = <KycForm kyc={kyc} save={this.handleSettings}/>;
              break;
            default:
              return null;
          }
          break;
        case 'list':
          let records = [];
          let onUnmount = null;
          let onScrollToBottom = null;
          switch (listName) {
            case 'groups':
              records = this.state[`user${id}groups`];
              onUnmount = (pos) => {
                this.setState({
                  [`user${id}groupsScroll`]: pos
                });
              }
              onScrollToBottom = this.getGroups;
              break;
            case 'following': case 'followers':
              const {module} = this.props;
              const str = `${entity}${id}${module}`;
              records = this.state[str];
              onUnmount = (pos) => {
                this.setState({
                  [`${str}Scroll`]: pos
                });
              }
              onScrollToBottom = this.getFollows;
              break;
          }
          component = <EntityList
              records={records}
              recordsLoading={recordsLoading}
              reset={() => {return null}}
              action={this.globalEntityAction}
              htmlId={'entity-list'}
              onUnmount={onUnmount}
              onScrollToBottom={onScrollToBottom}
              key={listName}
          />;
          break;
        case 'notifications':
          component = <Notifications
              notifications={notifications}
              recordsLoading={recordsLoading}
              htmlId={'notifications'}
              onScrollToBottom={this.getNotifications}
              remove={this.removeNotification}
              onUnmount={(pos) => {
                this.setState({
                  notificationsScrollPosition: pos
                })}
              }
          />;
          break;
        case 'user_requests': case 'group_requests':
          const e = module.split('_')[0];
          let i;
          i = e === 'user' ? new Provider('user').get('profile').id : id;
          let requests = [];
          const key = `${e}${i}followRequests`;
          if (typeof (this.state[key]) !== 'undefined') requests = this.state[key];
          component = <EntityRequests
              requests={requests}
              recordsLoading={recordsLoading}
              htmlId={'follow-requests'}
              onScrollToBottom={() => this.getFollowRequests(e, i)}
              remove={this.removeFollowRequest}
              entity={e}
              id={i}
              type={'follow'}
              action={this.globalUserRequestAction}
          />;
          break;
        case 'create_group': case 'update_group':
          component = <GroupForm id={id} data={this.state['group' + id + 'info']} save={this.handleGroupData} edit={updateGroup}/>;
          break;
        case 'group_settings':
          component = <GroupSettings info={this.state['group' + id + 'info']} data={this.state['group' + id + 'check']} id={id} />;
          break;
        case 'group_followers': case 'group_moderators': case 'group_blocked':
          const list = module.split('_')[1];
          component = <GroupMemberList
              records={this.state['group' + id + list + 'Admin']}
              onScrollToBottom={() => this.getGroupMembersListAdmin(list)}
              recordsLoading={recordsLoading}
              adminAction={this.groupAdminAction}
          />
          break;
        case 'chat':
          component = <ChatRooms chatrooms={chatrooms} loader={loader}/>;
          break;
        case 'chat_dialog':
          component = <Dialog
              interlocutor={chatData.interlocutor}
              roomId={chatData.roomId}
              me={chatData.me}
              messages={chatData.messages}
              key={chatData.roomId}
          />;
          break;
        case 'group_chat':
          component = <Rooms group={this.props.match.params.id} />;
          break;
        case 'contacts':
          component = <ContactList list={contacts} loading={recordsLoading}/>;
          break;
        case 'cart':
          component = <Cart isWidget={false}/>;
          break;
        case 'payment':
          component = <Payment isWidget={false}/>;
          break;
        case 'wallet':
          component = <Wallet/>;
          break;
        default:
          component = null;
      }
    }

    let comments = [];
    if (typeof (this.state[this.props.match.params.type+this.props.match.params.id+'comments']) !== 'undefined') {
      comments = this.state[this.props.match.params.type+this.props.match.params.id+'comments'];
    }
    let shout = null;
    if (typeof (this.state[this.props.match.params.type+this.props.match.params.id]) !== 'undefined') {
      shout = this.state[this.props.match.params.type+this.props.match.params.id];
    }
    return (
        <Wrapper>
          <Container>
            <Row>
              <Col md={0} xs={24} style={{backgroundColor: '#fff', borderBottom: '1px solid #d9d9d9'}}>
                <Logo
                    width={40}
                    height={50}
                    path={'logos/original'}
                    // onClick={() => this.props.history.push('/feed')}
                />
              </Col>
              <Col md={5} xs={0}>
                <Navigation payment={this.upgrade} avatar={avatar} provider={provider} newNotifications={newNotifications} newMessages={newMessages} push={this.addPost}/>
              </Col>
              <Col md={12} xs={24}>
                <PageHeader
                    style={headerStyle}
                    onBack={headerBack}
                    title={<div dir={'auto'}>{headerTitle}</div>}
                    subTitle={<div dir={'auto'}>{headerSubtitle}</div>}
                    extra={headerExtra && cloneElement(headerExtra, {
                      loading: headerButtonLoad
                    })}
                />
                <div id="component">
                  {loader && <Spinner/>}
                  {component && cloneElement(component, {
                    comments: comments,
                    post: shout
                  })}
                  {recordsLoading && !loader && <Spinner/>}
                  {postsLoading && <Spinner type={2}/>}
                </div>
              </Col>
              <Col md={7} className="mobile-hide" style={{position: 'sticky', top: 0}} xs={0}>
                <RightSide
                    top={topEntities}
                    map={map}
                    link={link}
                    date={date}
                    search={this.search}
                    post={post}
                />
              </Col>
              <Col md={0} xs={24}>
                <MobileNavigation
                    avatar={avatar}
                    provider={provider}
                    newNotifications={newNotifications}
                    newMessages={newMessages}
                    currentKey={window.location.pathname.substring(1)}
                />
              </Col>
            </Row>
          </Container>
          <ReportModal
              visible={reportWindow}
              close={this.closeModal}
              source={reportSource}
          />
          <UpdateModal
              model={updateModalTitle}
              visible={showUpdateModal}
              form={updateForm}
              close={this.closeModal}
          />
          <MessageModal
              visible={messageWindow}
              close={this.closeModal}
              name={messageToName}
              id={messageToId}
              entity={messageToEntity}
          />
          <PaymentModal
            visible={paymentModal}
            close={onPaymentModalClose}
            link={paymentLink}
          />
          <Modal
              title={false}
              closable={false}
              footer={null}
              visible={emailModal}
              width={400}
          >
            <Empty
                description={<Title style={{color: Constants.primaryColor}} level={4}>We've sent you an email. <br/> Please, confirm it.</Title>}
                image={ServerHost(`img/confirm_email.png`)}
            />
            <Button type={'primary'} loading={emailModalLoading} onClick={this.checkEmail} block shape={'round'}>Done</Button>
          </Modal>
        </Wrapper>
    )
  }

}

export default withRouter(DesktopStack);