import React, {Component} from 'react';
import { Button, Form, Icon, Input, Upload, message, DatePicker } from 'antd'
import {getBase64, beforeUpload} from '../helpers/Funcs';
import {uploadPath} from '../helpers/ServerHost';
import Request from '../utils/Request';
import LocationSearch from '../ui/LocationSearch';
import moment from 'moment';


const initialState = {
  loading: false,
  imageUrl: '',
  address: '',
  coords: ''
}

class EventForm extends Component{


  constructor (props, context){
    super (props, context);
    if (props.edit) {
      initialState.imageUrl = props.data.image ? props.data.image : '';
    }
    this.state = initialState;
  }

  bu = async (file) => {
    if(beforeUpload(file)){
      let base64 = await getBase64(file);
      this.setState({
        imageUrl: base64,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {form, edit, data, push, update} = this.props;
    const {coords, address, imageUrl} = this.state;
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let postData = {
          title: values.title,
          description: values.description,
          event_time: values.date,
          address: address,
          coords: coords,
          link: values.link,
          image: imageUrl
        }
        if (edit) postData.post_id = data.id
        let req = new Request(`post/event/${edit ? 'update' : 'create'}`, postData);
        let res = await req.post();
        if (res.status === 200){
          this.setState(initialState);
          edit ? update(data.index, res.data) : push(res.data);
          form.resetFields();
        } else {
          message.error(res.data.error);
          this.setState({
            loading: false
          });
        }
      }
    });
  };

  setLocation = (address, coords) => {
    this.setState({
      address: address,
      coords: coords
    })
  }

  render(){
    const { getFieldDecorator } = this.props.form;
    const { imageUrl } = this.state;
    const {edit, data} = this.props;
    const uploadButton = (
      <div>
        <Icon type={'picture'} />
        <div className="ant-upload-text">Upload a picture</div>
      </div>
    );
    return (
      <Form onSubmit={this.handleSubmit} style={{marginTop: 15}} className="post-form">
        <Form.Item hasFeedback>
          {getFieldDecorator('title', {
            rules: [
              {
                required: true,
                message: 'Please put a title',
              }
            ],
            initialValue: edit ? data.data.title : ''
          })(<Input
            placeholder="Title"
          />)}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: 'Please put a description',
              }
            ],
            initialValue: edit ? data.data.description : ''
          })(<Input.TextArea
            placeholder="Description"
            rows={2}
          />)}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator('date', {
            rules: [
              {
                required: true,
                message: 'Please put a date',
              }
            ],
            initialValue: edit ? moment(data.data.originalDate, "YYYY-MM-DD HH:mm") : null
          })(<DatePicker format="YYYY-MM-DD HH:mm" style={{width: '100%'}} showTime={{ format: 'HH:mm' }} placeholder="Select Time"/>)}
        </Form.Item>
        <Form.Item>
          <LocationSearch default={edit ? data.data.address : ''} setLocation={this.setLocation}/>
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator('link', {
            rules: [
              {
                required: false
              }
            ],
            initialValue: edit ? data.data.link ? data.data.link : '' : ''
          })(<Input
            placeholder="Share a link"
            prefix={<Icon onClick={this.paste} type="link" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />)}
        </Form.Item>
        <Form.Item>
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={uploadPath}
            beforeUpload={this.bu}
            customRequest={async () => {
              let req = new Request('utils/upload', {
                file: imageUrl
              });
              let res = await req.post()
              this.setState({
                imageUrl: res.data.url
              })
            }}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            loading={this.state.loading}
            shape="round"
            block
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
  }

}

export default Form.create({ name: 'event' })(EventForm);