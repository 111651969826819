import React, {Component} from 'react';
import { Avatar, Card, Icon, Badge, Button } from 'antd'
import { NavLink, withRouter } from 'react-router-dom'
import CheckMark from '../../ui/CheckMark';
import ImgsViewer from 'react-images-viewer';
import Constants from '../../Constants';
import styled from 'styled-components';
import Shout from '../../ui/posts/Shout';
import Event from '../../ui/posts/Event';
import Trade from '../../ui/posts/Trade';
import Signal from '../../ui/posts/Signal';
import Deal from '../../ui/posts/Deal';
import PostImage from '../../ui/PostImage';
import OG from '../../ui/OG';
import LikeIcon from '../../ui/LikeIcon';
import ReactPlayer from 'react-player';
import PostOptions from "./PostOptions";
import Provider from "../../utils/Provider";


const { Meta } = Card;

const Options = styled.span`
  float: right;
`;

const Time = styled.span`
  color: #b3b3b3;
  font-size: 12px;
  margin: 0 5px;
  font-weight: 100;
`;

const PicWrap = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  &:hover {
    cursor: pointer;
  }
`;

const Likes = styled.span`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -25px;
  color: ${props => props.active ? Constants.primaryColor : '#a8a8a8'};
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
;`

class Closed extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      image: '',
      viewerIsOpen: false,
      data: props.data
    }
  }

  componentDidMount(){
    this.setPic();
  }

  componentDidUpdate(prevProps){
    if (prevProps.data !== this.props.data) {
      this.setPic();
    }
  }

  imageClick = () => {
    // this.setState({viewerIsOpen: true});
    const {data} = this.props;
    this.props.history.push(`/post/${data.postType}/${data.id}`)
  };

  like = async () => {
    const {postAction} = this.props;
    const {data} = this.state;
    postAction(data.postType, data.id, 'like');
    this.setPic();
  };

  setPic = () => {
    const {data, history} = this.props;

    if (data.og) {

      if (data.og['video:url']){
        this.setState({
          image: <ReactPlayer
            key="media"
            muted
            controls
            width="100%"
            height="250px"
            url={data.og['video:url']}
            playing
          />
        });
      } else {
        this.setState({
          image: <PicWrap>
            <PostImage onClick={this.imageClick} src={data.og.image}/>
            <OG onClick={this.imageClick} data={data.og}/>
            <LikeIcon active={data.liked} onClick={this.like}>
              <Likes active={data.likes > 0}>{data.likes}</Likes>
            </LikeIcon>
          </PicWrap>
        });
      }
    } else {
      if (data.image){
        this.setState({
          image: <PicWrap>
            <PostImage onClick={this.imageClick} src={data.image}/>
            <LikeIcon active={data.liked} onClick={this.like}>
              <Likes active={data.likes > 0}>{data.likes}</Likes>
            </LikeIcon>
          </PicWrap>
        });
      }
    }
  };

  openLink = () => {
    if (this.props.data.link) {
      let win = window.open(this.props.data.link, '_blank');
      win.focus();
    }
    return null;
  };

  render(){

    const {data, index, update, openUpdateModal, remove, report, entityAction, postAction, addToCart} = this.props;
    data.index = index;
    const {image, viewerIsOpen} = this.state;
    const user = new Provider('user').get('profile');

    //=====================[DEFINE COMPONENT]===================================

    let component = <Shout data={data} collapse={false}/>;
    switch (data.postType){
      case 'shout':
        component = <Shout addToCart={addToCart} collapse={false} follow={async () => await postAction(data.postType, data.id, 'follow')} key="content" data={data} cut={true} icon id={data.id}/>;
        break;
      case 'event':
        component = <Event follow={async () => await postAction(data.postType, data.id, 'follow')} key="content" data={data} cut={true} icon id={data.id}/>;
        break;
      case 'trade':
        component = <Trade follow={async () => await postAction(data.postType, data.id, 'follow')} key="content" data={data} icon id={data.id} short/>;
        break;
      case 'signal':
        component = <Signal follow={async () => await postAction(data.postType, data.id, 'follow')} key="content" data={data} icon id={data.id} short/>;
        break;
      case 'deal':
        component = <Deal follow={async () => await postAction(data.postType, data.id, 'follow')} key="content" data={data} icon id={data.id} short/>;
        break;
      default:
        return null;
    }

    //============================[POST ACTIONS]================================

    let actions = [
      <Button disabled={!data.link} onClick={this.openLink} size="small" type="link" key="link" >
        <Icon style={{
          width: 'auto',
          color: data.link ? Constants.primaryColor : '#a8a8a8',
          fontSize: data.link ? 20 : 14
        }} type="link"/>
      </Button>,
      <NavLink to={`/post/${data.postType}/${data.id}`} key="calendar">
        <Icon style={{
          width: 'auto',
          color: data.date ? Constants.secondaryColor : '#a8a8a8',
          fontSize: data.date ? 20 : 14
        }} type="calendar"/>
      </NavLink>,
      <NavLink to={`/post/${data.postType}/${data.id}`} key="location">
        <Icon style={{
          width: 'auto',
          color: data.address ? Constants.green : '#a8a8a8',
          fontSize: data.address ? 20 : 14
        }} type="environment"/>
      </NavLink>,
      <NavLink to={`/post/${data.postType}/${data.id}`} key="comments">
        <Button size="small" type="link">
          <Icon style={{
            width: 'auto',
            color: data.comments > 0 ? Constants.orange : '#a8a8a8',
            fontSize: data.comments > 0 ? 20 : 14
          }} type="message" />
          <span style={{
            margin: '0 2px',
            color: data.comments > 0 ? Constants.orange : '#a8a8a8',
          }}>{data.comments}</span>
        </Button>
      </NavLink>
    ];

    //===========================[RENDER]=======================================
    return (
      <div className="feed-post-wrap">
        <Card
          style={{ width: '100%' }}
        >
          <Meta
            avatar={<NavLink to={`/${data.entity}/${data[data.entity].id}`}>
              <Badge
                status={data.user ? data.user.online ? 'success' : false : false}
                offset={[-5, 30]}
                style={{zIndex: 0}}
              >
                <Avatar src={data.user ? data.user.photo : data.group.photo} />
              </Badge>
            </NavLink>}
            title={
              <p>
                <NavLink style={{color: data.user ? data.user.nameColor : `${Constants.primaryColor}`}} to={`/${data.entity}/${data[data.entity].id}`}>
                  {data.user ? data.user.name : data.group.name}
                  {data.user ? data.user.checkMark ? <CheckMark/> : '' : data.group.checkMark ? <CheckMark/> : ''}
                </NavLink>
                {data[data.entity].id !== user.id && !data[data.entity].following && <Button size={'small'} type={'primary'} shape={'round'} onClick={() => {
                  entityAction(data.entity, data[data.entity].id, 'follow')
                }}>Follow</Button>}
                <Time>{data.createdAt}</Time>
                <Options>
                  <PostOptions
                      data={data}
                      update={update}
                      openUpdateModal={openUpdateModal}
                      remove={remove}
                      report={report}
                      index={index}
                      entityAction={entityAction}
                  />
                </Options>
              </p>
            }
            description={component}
          />
        </Card>
        {image}
        <Card
          className={'post-actions'}
          actions={actions}
        />
        <ImgsViewer
            backdropCloseable={true}
            imgs={[{src: data.image}]}
            currImg={0}
            isOpen={viewerIsOpen}
            onClickPrev={this.gotoPrev}
            onClickNext={this.gotoNext}
            onClose={() => this.setState({viewerIsOpen: false})}
        />
      </div>
    )
  }

}

export default withRouter(Closed);