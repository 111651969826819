import React, {Component} from 'react';
import styled from 'styled-components';
import { Comment, Icon, Tooltip, Avatar, Button } from 'antd'
import Constants from '../Constants'
import CheckMark from './CheckMark'
import Provider from '../utils/Provider'
import { NavLink } from 'react-router-dom'


const Wrapper = styled.div`
  padding: 10px;
`;

class CommentsList extends Component {

  render(){
    const profile = new Provider('user').get('profile');
    const {comments, like, setReply, loadButton, loadMore, loading} = this.props;
    return comments.length > 0 ? (
      <Wrapper>
        {comments.map((v, i) => {
          const name = (
            <b style={{color: v.fromUser ? v.fromUser.nameColor : `${Constants.primaryColor}`}}>
              {v.fromUser ? v.fromUser.name : v.fromGroup.name}
              {v.fromUser ? v.fromUser.checkMark ? <CheckMark/> : '' : ''}
            </b>
          );
          return (
            <Comment
              key={i}
              style={{borderBottom: '#e8e8e8'}}
              actions={[
                <span key="comment-basic-like">
                  <Tooltip title="It's LIT!">
                    <Icon
                      type="fire"
                      theme={v.liked === true ? 'filled' : 'outlined'}
                      onClick={() => like(i, v.id)}
                      style={{color: Constants.secondaryColor}}
                    />
                  </Tooltip>
                  <span style={{ paddingLeft: 8, cursor: 'auto' }}>{v.likes}</span>
                </span>,
                v.fromUser && v.fromUser.id !== profile.id ? <span onClick={() => setReply(v.id, v.fromUser ? v.fromUser.name : v.fromGroup.name)} key="comment-basic-reply-to">Reply</span> : '',
              ]}
              author={<NavLink to={v.fromUser ? `/user/${v.fromUser.id}` : `/group/${v.fromGroup.id}`}>{name}</NavLink>}
              avatar={
                <NavLink to={v.fromUser ? `/user/${v.fromUser.id}` : `/group/${v.fromGroup.id}`}>
                  <Avatar
                    src={v.fromUser ? v.fromUser.photo : v.fromGroup.photo}
                    alt={v.fromUser ? v.fromUser.name : v.fromGroup.name}
                  />
                </NavLink>
              }
              content={v.text}
              datetime={
                <Tooltip>
                  <span>{v.created}</span>
                </Tooltip>
              }
            >{v.replies.map((vv, ii) => {
              const rname = (
                <b style={{color: vv.fromUser ? vv.fromUser.nameColor : `${Constants.primaryColor}`}}>
                  {vv.fromUser ? vv.fromUser.name : vv.fromGroup.name}
                  {vv.fromUser ? vv.fromUser.checkMark ? <CheckMark/> : '' : ''}
                </b>
              );
              return (
                <Comment
                  key={ii}
                  style={{borderBottom: '#e8e8e8'}}
                  actions={[
                    <span key="comment-basic-like">
                      <Tooltip title="It's LIT!">
                        <Icon
                          type="fire"
                          theme={vv.liked === true ? 'filled' : 'outlined'}
                          onClick={() => like(i, vv.id, ii)}
                          style={{color: Constants.secondaryColor}}
                        />
                      </Tooltip>
                      <span style={{ paddingLeft: 8, cursor: 'auto' }}>{vv.likes}</span>
                    </span>,
                    vv.fromUser && vv.fromUser.id !== profile.id ? <span onClick={() => setReply(vv.id, vv.fromUser ? vv.fromUser.name : vv.fromGroup.name)} key="comment-basic-reply-to">Reply</span> : '',
                  ]}
                  author={<NavLink to={vv.fromUser ? `/user/${vv.fromUser.id}` : `/group/${vv.fromGroup.id}`}>{rname}</NavLink>}
                  avatar={
                    <NavLink to={vv.fromUser ? `/user/${vv.fromUser.id}` : `/group/${vv.fromGroup.id}`}>
                      <Avatar
                        src={vv.fromUser ? vv.fromUser.photo : vv.fromGroup.photo}
                        alt={vv.fromUser ? vv.fromUser.name : vv.fromGroup.name}
                      />
                    </NavLink>
                  }
                  content={vv.text}
                  datetime={
                    <Tooltip>
                      <span>{vv.created}</span>
                    </Tooltip>
                  }
                />
              )
            })}</Comment>
          );
        })
        }
        {loadButton && !loading && <Button onClick={loadMore} type="primary" ghost size="small" block shape="round" style={{margin: '10px 0'}}>Load more comments</Button>}
      </Wrapper>
    ) : <p style={{
      textAlign: 'center',
      color: '#e8e8e8',
      marginBottom: '10px'
    }}>Be the first one who will begin a talk</p>
  }

}

export default CommentsList;