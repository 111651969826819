import React, {Component} from 'react';
import {
  Button, Form, Icon, Upload, message,
  InputNumber, Select, Row, Col, Typography, Tooltip, Input, DatePicker,
  Checkbox,
} from 'antd'
import {getBase64, beforeUpload, getCurrentPrice} from '../helpers/Funcs';
import {uploadPath} from '../helpers/ServerHost';
import Request from '../utils/Request';
import styled from 'styled-components';
import LocationSearch from '../ui/LocationSearch'
import moment from 'moment';
import { Redirect } from 'react-router-dom';

const { Option } = Select;
const { Title, Text } = Typography;
const initialState = {
  loading: false,
  imageUrl: '',
  cryptoList: [],
  currencyList: [],
  crypto: 'BTC',
  currency: 'USD',
  price: 0,
  showLink: false,
  showLocation: false,
  showDate: false,
  address: '',
  coords: '',
  cryptoPrice: 0,
  title: '',
  description: '',
  toSettings: false,
  fixed_amount: false
}

const OptsWrap = styled.div`
  margin-bottom: 2px;
  text-align: right;
  
  button {
    margin: 0 3px;
  }
`;

class DealForm extends Component{


  constructor (props, context){
    super (props, context);
    if (props.edit) {
      const {title, description, crypto, currency, price} = props.data.data;
      const {image} = props.data;
      initialState.crypto = crypto;
      initialState.imageUrl = image ? image : '';
      initialState.currency = currency;
      initialState.price = price;
      initialState.title = title;
      initialState.description = description;
      initialState.showLink = !!props.data.link;
      initialState.showLocation = !!props.data.address;
      initialState.showDate = !!props.data.originalDate;
      initialState.showImage = !!props.data.image;
    }
    this.state = initialState;
  }

  bu = async (file) => {
    if(beforeUpload(file)){
      let base64 = await getBase64(file);
      this.setState({
        imageUrl: base64,
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  setCurrentPrice = async () => {
    const {crypto, currency, price} = this.state;
    let cryptoPrice = await getCurrentPrice(crypto, currency);
    await this.setState({
      cryptoPrice: parseFloat(price) / cryptoPrice
    });
    return null;
  }

  handlePriceChange = async (val) => {
    await this.setState({
      price: val
    });
    await this.setCurrentPrice();
  }

  handleCryptoChange = async (val) => {
    await this.setState({
      crypto: val
    });
    await this.setCurrentPrice();
  }

  handleCurrencyChange = async (val) => {
    await this.setState({
      currency: val
    });
    await this.setCurrentPrice();
  }

  handleCryptoSearch = async (value) => {
    if (value) {
      let req = new Request(`utils/search_crypto?query=${value}`);
      let res = await req.get();
      if (res.status === 200){
        this.setState({ cryptoList: res.data });
      } else {
        message.error(res.data.error)
      }
    } else {
      this.setState({ cryptoList: [] });
    }
  };

  handleCurrencySearch = async (value) => {
    if (value) {
      let req = new Request(`utils/search_crypto?query=${value}`);
      let res = await req.get();
      if (res.status === 200){
        this.setState({ currencyList: res.data });
      } else {
        message.error(res.data.error)
      }
    } else {
      this.setState({ currencyList: [] });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {form, edit, data, push, update, group} = this.props;
    const {imageUrl, crypto, currency, price, address, coords} = this.state;
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let postData = {
          title: values.title,
          description: values.description,
          convert_to: crypto,
          price: price,
          currency: currency,
          image: imageUrl,
          address: address,
          coords: coords,
          event_time: values.date,
          link: values.link,
          fixed_amount: values.fixed_amount
        }
        if (edit) postData.post_id = data.id;
        if (group) postData.group_id = group;
        let req = new Request(`post/deal/${edit ? 'update' : 'create'}`, postData);
        let res = await req.post();
        if (res.status === 200){
          this.setState(initialState);
          edit ? update(data.index, res.data) : push(res.data);
          form.resetFields();
        } else {
          message.error(res.data.error);
          this.setState({
            loading: false
          });
          if (res.status === 403) {
            this.setState({
              toSettings: true
            })
          }
        }
      }
    });
  };

  manageField = (field) => {
    this.setState({
      [`show${field}`]: !this.state[`show${field}`]
    })
  }

  setLocation = (address, coords) => {
    this.setState({
      address: address,
      coords: coords
    })
  }

  componentDidMount = async () => {
    const {edit} = this.props;
    if (edit) await this.setCurrentPrice();
  }

  render(){
    const { imageUrl, crypto, cryptoList, currency, price,
      currencyList, showLink, showLocation, showDate, cryptoPrice, toSettings} = this.state;
    if (toSettings) return <Redirect to="/settings/account"/>;
    const cryptoOptions = cryptoList.map((v, i) => <Option value={v.value} key={i}>{v.text}</Option>);
    const currencyOptions = currencyList.map((v, i) => <Option value={v.value} key={i}>{v.text}</Option>);
    const uploadButton = (
      <div>
        <Icon type={'picture'} />
        <div className="ant-upload-text">Upload a picture</div>
      </div>
    );
    const {edit, data} = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} style={{marginTop: 15}} className="post-form">
        <OptsWrap>
          <Tooltip title="Link"><Button title="Link" onClick={() => this.manageField('Link')} size="small" type="link" icon="link"/></Tooltip>
          <Tooltip title="Location"><Button onClick={() => this.manageField('Location')} size="small" type="link" icon="environment"/></Tooltip>
          <Tooltip title="Date"><Button onClick={() => this.manageField('Date')} size="small" type="link" icon="calendar"/></Tooltip>
        </OptsWrap>
        <Form.Item hasFeedback>
          {getFieldDecorator('title', {
            initialValue: edit ? data.data.title ? data.data.title : '' : ''
          })(<Input
            placeholder="Title"
          />)}
        </Form.Item>
        <Form.Item hasFeedback>
          <Row>
            <Col xs={12}>
              <Title level={4}>Price</Title>
            </Col>
            <Col xs={12}>
              <div className="currency-input">
                <InputNumber
                  value={price}
                  type="number"
                  placeholder="Price"
                  onChange={this.handlePriceChange}
                />
                <Select
                  showSearch
                  value={currency}
                  name="currency"
                  placeholder='Currency'
                  showArrow={true}
                  onSearch={this.handleCurrencySearch}
                  onChange={this.handleCurrencyChange}
                  notFoundContent={null}
                  filterOption={false}
                >
                  {currencyOptions}
                </Select>
              </div>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item hasFeedback>
          <Row>
            <Col xs={12}>
              <Title level={4}>Convert to</Title>
            </Col>
            <Col xs={12}>
              <Select
                showSearch
                value={crypto}
                name="crypto"
                placeholder='Cryptocurrency'
                showArrow={true}
                onSearch={this.handleCryptoSearch}
                onChange={this.handleCryptoChange}
                notFoundContent={null}
                filterOption={false}
              >
                {cryptoOptions}
              </Select>
            </Col>
            <Col xs={24} style={{textAlign: 'center', marginTop: '5px'}}>
              <Text style={{'color': '#a8a8a8'}}>~{cryptoPrice.toFixed(5)} {crypto}</Text>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('description', {
            initialValue: edit ? data.data.description : ''
          })(<Input.TextArea
            placeholder="Description (optional)"
            rows={2}
          />)}
        </Form.Item>
        <Form.Item>
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={uploadPath}
            beforeUpload={this.bu}
            customRequest={async () => {
              let req = new Request('utils/upload', {
                file: imageUrl
              });
              let res = await req.post()
              this.setState({
                imageUrl: res.data.url
              })
            }}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        </Form.Item>
        {showLink && <Form.Item hasFeedback>
          {getFieldDecorator('link', {
            initialValue: edit ? data.link ? data.link : '' : ''
          })(<Input
            placeholder="Share a link"
            prefix={<Icon onClick={this.paste} type="link" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />)}
        </Form.Item>}
        {showLocation && <Form.Item>
          <LocationSearch default={edit ? data.address : ''} setLocation={this.setLocation}/>
        </Form.Item>}
        {showDate && <Form.Item hasFeedback>
          {getFieldDecorator('date', {
            initialValue: edit ? moment(moment.utc(data.originalDate).local(), "YYYY-MM-DD HH:mm") : null
          })(<DatePicker format="YYYY-MM-DD HH:mm" style={{width: '100%'}} showTime={{ format: 'HH:mm' }} placeholder="Select Time"/>)}
        </Form.Item>}
        <Form.Item>
          {getFieldDecorator('fixed_amount', {
            valuePropName: 'checked',
            initialValue: edit ? data.data.fixed_amount : false,
          })(<Checkbox>Fixed Amount</Checkbox>)}
        </Form.Item>
        <Form.Item>
          <Button
            loading={this.state.loading}
            shape="round"
            block
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
  }

}

export default Form.create({ name: 'trade' })(DealForm);