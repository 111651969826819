import React, {Component} from 'react';
import {Button, Dropdown, Menu, message as notification} from "antd";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Constants from "../../Constants";


class EntityOptions extends Component {

    share = async () => {
        const {data} = this.props;
        let text;
        let title;
        const url = `${Constants.client}${data.entity}/${data.id}`;
        if (data.entity === 'user') {
            title = data.name;
            text = data.basics.about;
        } else {
            title = data.basics.name;
            text = data.about
        }
        const shareData = {
            title: title,
            text: text,
            url: url
        }
        await navigator.share(shareData);
        // console.log(shareData);
    };

    render() {
        const {data, entityAction, report, message} = this.props;
        const link = `${Constants.client}${data.entity}/${data.id}`;
        let menu = (
            <Menu>
                <Menu.Item key="1">
                    <Button onClick={() => entityAction(data.entity, data.id, 'follow')} block style={{textAlign: 'left'}} type="link" icon={data.following ? 'user-delete' : 'user-add'} key="delete">{data.following ? 'Stop' : 'Start'} follow {`${data.basics.name}`}</Button>
                </Menu.Item>
                <Menu.Item key="2">
                    {window.innerWidth > 1024 ? (
                        <CopyToClipboard
                            key="share"
                            onCopy={() => notification.info('Link copied to clipboard!')}
                            text={link}
                        >
                            <Button
                                block
                                style={{textAlign: 'left'}}
                                type="link"
                                icon="share-alt">
                                Share profile
                            </Button>
                        </CopyToClipboard>
                    ) : (
                        <Button
                            block
                            onClick={this.share}
                            style={{textAlign: 'left'}}
                            type="link"
                            icon="share-alt">
                            Share profile
                        </Button>
                    )}
                </Menu.Item>
                <Menu.Item key="3">
                    <Button onClick={() => entityAction(data.entity, data.id, 'mute')} block style={{textAlign: 'left'}} type="link" icon="sound" key="mute">{data.muted ? 'Take off mute from' : 'Mute'} {`${data.basics.name}`}</Button>
                </Menu.Item>
                <Menu.Item key="4">
                    <Button onClick={() => report(link)} block style={{textAlign: 'left'}} type="link" icon="warning" key="report">Report {`${data.basics.name}`}</Button>
                </Menu.Item>
                {
                    data.entity === 'user' && (
                        <Menu.Item key="5">
                            <Button onClick={() => entityAction(data.entity, data.id, 'block')} block style={{textAlign: 'left'}} type="link" icon={data.blocked ? "check-circle" : 'close-circle'} key="report">{data.blocked ? 'Unblock' : 'Block'} {`${data.basics.name}`}</Button>
                        </Menu.Item>
                    )
                }
                {
                    data.entity === 'user' && !data.chatRequestSent && !data.chatAllowed && (
                        <Menu.Item key="6">
                            <Button onClick={() => entityAction(data.entity, data.id, 'send_request', 'chat')} block style={{textAlign: 'left'}} type="link" icon="message" key="chat">Ask To Chat</Button>
                        </Menu.Item>
                    )
                }
                {
                    data.entity === 'user' && data.chatAllowed &&  (
                        <Menu.Item key="7">
                            <Button onClick={() => message(data.name, data.entity, data.id)} block style={{textAlign: 'left'}} type="link" icon="message" key="chat">Send message</Button>
                        </Menu.Item>
                    )
                }
                {
                    data.entity === 'group' && data.basics.chatAllowed &&  (
                        <Menu.Item key="8">
                            <Button onClick={() => message(data.name, data.entity, data.id)} block style={{textAlign: 'left'}} type="link" icon="message" key="chat">Send message</Button>
                        </Menu.Item>
                    )
                }
            </Menu>
        );
        return (
            <Dropdown overlay={menu} trigger={['click']} className={'entity-options'} placement="bottomRight">
                <Button type="primary" shape="circle" icon="ellipsis" />
            </Dropdown>
        )
    }

}

export default EntityOptions;