import React, {Component} from 'react';
import { Button, Col, Row, Typography } from 'antd'
import { withRouter } from 'react-router-dom'
import Constants from '../../Constants'
import MethodIcon from '../MethodIcon'
import FollowButton from '../FollowButton'

const { Paragraph, Title } = Typography;


class Trade extends Component {

  render(){
    const {icon, short, data, trade} = this.props;
    let buttonStyle = {
      float: 'right',
      position: 'relative',
      bottom: '11px',
    };
    if (!data.requestSent) {
      buttonStyle.backgroundColor = Constants.green;
      buttonStyle.borderColor = Constants.green;
    }
    return (

        <Row>
          <Col xs={icon ? 16 : 24}>
            {short ? <Paragraph onClick={() => this.props.history.push(`/post/${data.postType}/${data.id}`)}>
              {data.data.string}
            </Paragraph> : (
              <div>
                <Title style={{color: Constants.primaryColor, margin: 0}} level={4}>Going to {data.data.type} {data.data.crypto} with {data.data.method.text}</Title>
                <Paragraph style={{color: Constants.primaryColor, textTransform: 'uppercase', margin: 0}}>
                  <span style={{
                    color: data.data.type === 'buy' ? Constants.green : Constants.secondaryColor
                  }}>{data.data.type}</span> {data.data.amount} {data.data.crypto} {data.data.percentage > 0 && (
                    <span style={{
                      color: data.data.percentage >= 1 ? Constants.green : Constants.secondaryColor
                    }}>{data.data.percentage >= 1 ? `+${data.data.percentage}` : data.data.percentage}%</span>
                )}
                </Paragraph>
                <Paragraph style={{color: Constants.primaryColor, margin: 0}}>
                  For <span style={{color: '#000'}}>{data.data.price}</span> {data.data.currency}{' '}
                  <span style={{color: '#000'}}>{data.data.method.text}</span> <MethodIcon icon={data.data.method.icon}/>
                  {!data.owner && !data.data.accepted && <Button disabled={data.requestSent} onClick={trade} type={data.requestSent ? 'default' : 'danger'} shape="round" style={buttonStyle}>{data.requestSent ? 'Request sent' : 'TRADE'}</Button>}
                </Paragraph>
              </div>
            )}
          </Col>
          {icon && <Col xs={8}>
            <FollowButton disabled={data.owner} following={data.following}/>
          </Col>}
        </Row>
    )
  }

}

export default withRouter(Trade);