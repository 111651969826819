import styled from 'styled-components';
import ServerHost from '../helpers/ServerHost'


const FollowButton = styled.button`
  width: 32px;
  height: 32px;
  margin: 0 auto 15px;
  background-image: url("${props => ServerHost(`img/icons/${props.following ? 'unfollow' : 'follow'}.png`)}");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  outline: none;
  float: right;
  &:disabled {
    opacity: 0.7;
  }
  
  &:hover {
    cursor: pointer;
  }
`;

export default FollowButton;