import React, {Component} from 'react';
import {
  Button, Form, Icon, Upload, message,
  Radio, InputNumber, Select, Row, Col, Typography
} from 'antd';
import {getBase64, beforeUpload} from '../helpers/Funcs';
import {uploadPath} from '../helpers/ServerHost';
import Request from '../utils/Request';

const { Option } = Select;
const { Title } = Typography;
const initialState = {
  loading: false,
  imageUrl: '',
  baseList: [],
  quoteList: [],
  call: 'long',
  base: 'BTC',
  quote: 'USD',
  entryPoint: 0,
  takeProfit: 0,
  stopLoss: 0
}

class SignalForm extends Component{


  constructor (props, context){
    super (props, context);
    if (props.edit) {
      const {call, base, quote, entryPoint, takeProfit, stopLoss} = props.data.data;
      const {image} = props.data;
      initialState.call = call;
      initialState.base = base;
      initialState.quote = quote;
      initialState.imageUrl = image ? image : '';
      initialState.entryPoint = entryPoint;
      initialState.takeProfit = takeProfit;
      initialState.stopLoss = stopLoss;
    }
    this.state = initialState;
  }

  bu = async (file) => {
    if(beforeUpload(file)){
      let base64 = await getBase64(file);
      this.setState({
        imageUrl: base64,
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleEpChange = async (val) => {
    await this.setState({
      entryPoint: val
    });
  }

  handleTpChange = async (val) => {
    await this.setState({
      takeProfit: val
    });
  }

  handleSlChange = async (val) => {
    await this.setState({
      stopLoss: val
    });
  }

  handleBaseSearch = async (val) => {
    if (val) {
      let req = new Request(`utils/search_crypto?query=${val}`);
      let res = await req.get();
      if (res.status === 200){
        this.setState({ baseList: res.data });
      } else {
        message.error(res.data.error)
      }
    } else {
      this.setState({ baseList: [] });
    }
  }

  handleBaseChange = async (val) => {
    await this.setState({
      base: val
    });
    let req = new Request(`utils/top_pairs?base=${val}`);
    let res = await req.get();
    if (res.status === 200) {
      this.setState({
        quoteList: res.data
      })
    } else {
      message.error(res.date.error);
    }
    await this.setRate();
  }

  handleQuoteChange = async (val) => {
    await this.setState({
      quote: val
    });
    await this.setRate();
  }

  setRate = async () => {
    const {base, quote} = this.state;
    let req = new Request(`utils/quotes?from=${base}&to=${quote}`);
    let res = await req.get();
    if (res.status === 200) {
      this.setState({
        entryPoint: res.data.value,
        stopLoss: res.data.value,
        takeProfit: res.data.value
      })
    } else {
      message.error(res.date.error);
    }
  }

  async componentDidMount(){
    if (!this.props.edit) await this.setRate();
    const {base} = this.state;
    let req = new Request(`utils/top_pairs?base=${base}`);
    let res = await req.get();
    if (res.status === 200) {
      this.setState({
        quoteList: res.data
      });
    } else {
      message.error(res.date.error);
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {form, edit, data, push, update, group} = this.props;
    const {imageUrl, call, base, quote, entryPoint, takeProfit, stopLoss} = this.state;
    this.setState({
      loading: true
    });
    let postData = {
      call: call,
      base: base,
      quote: quote,
      entry_point: entryPoint,
      take_profit: takeProfit,
      stop_loss: stopLoss,
      image: imageUrl
    }
    if (edit) postData.post_id = data.id;
    if (group) postData.group_id = group;
    let req = new Request(`post/signal/${edit ? 'update' : 'create'}`, postData);
    let res = await req.post();
    if (res.status === 200){
      this.setState(initialState);
      edit ? update(data.index, res.data) : push(res.data);
      form.resetFields();
    } else {
      message.error(res.data.error);
      this.setState({
        loading: false
      });
    }
  };

  render(){
    const { imageUrl, call, base, quote, entryPoint, takeProfit, stopLoss, baseList, quoteList } = this.state;
    const baseOptions = baseList.map((v, i) => <Option value={v.value} key={i}>{v.text}</Option>);
    const quoteOptions = quoteList.map((v, i) => <Option value={v.value} key={i}>{v.text}</Option>);
    const uploadButton = (
      <div>
        <Icon type={'picture'} />
        <div className="ant-upload-text">Upload a picture</div>
      </div>
    );
    return (
      <Form onSubmit={this.handleSubmit} style={{marginTop: 15}} className="post-form">
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>Call</Title>
            </Col>
            <Col xs={12}>
              <Radio.Group name="call" onChange={this.handleChange} defaultValue={call} buttonStyle="solid" style={{width: '100%'}}>
                <Radio.Button value="long">Long</Radio.Button>
                <Radio.Button value="short">Short</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>Base</Title>
            </Col>
            <Col xs={12}>
              <Select
                showSearch
                value={base}
                name="base"
                placeholder='Base'
                showArrow={true}
                onSearch={this.handleBaseSearch}
                onChange={this.handleBaseChange}
                notFoundContent={null}
                filterOption={false}
              >
                {baseOptions}
              </Select>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>Quote</Title>
            </Col>
            <Col xs={12}>
              <Select
                showSearch
                value={quote}
                name="quote"
                placeholder='Quote'
                showArrow={true}
                onChange={this.handleQuoteChange}
                notFoundContent={null}
                filterOption={false}
                style={{bottom: '1px'}}
              >
                {quoteOptions}
              </Select>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>Entry Point</Title>
            </Col>
            <Col xs={12}>
              <InputNumber
                type="number"
                value={entryPoint}
                placeholder="EP"
                onChange={this.handleEpChange}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>Take Profit</Title>
            </Col>
            <Col xs={12}>
              <InputNumber
                type="number"
                value={takeProfit}
                placeholder="TP"
                onChange={this.handleTpChange}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>Stop Loss</Title>
            </Col>
            <Col xs={12}>
              <InputNumber
                type="number"
                value={stopLoss}
                placeholder="SL"
                onChange={this.handleSlChange}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={uploadPath}
            beforeUpload={this.bu}
            customRequest={async () => {
              let req = new Request('utils/upload', {
                file: imageUrl
              });
              let res = await req.post()
              this.setState({
                imageUrl: res.data.url
              })
            }}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            loading={this.state.loading}
            shape="round"
            block
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
  }

}

export default Form.create({ name: 'signal' })(SignalForm);