import React, {Component} from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';
import Request from '../utils/Request';
import { message } from 'antd'
import EntityCard from './EntityCard';
import {scrolledToBottom} from '../helpers/Funcs';


const Nothing = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 16px;
  color: #e3e3e3;
`;


class EntityList extends Component {

  constructor(props, context){
    super (props, context);
    this.state = {
      path: props.path,
      page: 1,
      records: [],
      loading: true,
      recordsLoading: false,
      fullLoad: false,
      groupAdmin: false
    }
  }

  async componentDidMount(){
    await this.getList();
    document.addEventListener('scroll', this.trackScrolling);
  }

  async componentDidUpdate(prevProps){
    const {path} = this.props;
    if (path !== prevProps.path){
      await this.setState({
        records: [],
        path: path,
        page: 1,
        loading: true
      });
      await this.getList();
    }
  }

  componentWillUnmount(){
    document.removeEventListener('scroll', this.trackScrolling);
    this.props.reset();
  }

  getList = async () => {
    await this.setState({
      recordsLoading: true
    });
    const {path, page, records} = this.state;
    const req = new Request(`${path}&page=${page}`);
    const res = await req.get();
    if (res.status === 200) {
      if (res.data.list.length > 0) {
        this.setState({
          records: [...records, ...res.data.list],
          loading: false,
          recordsLoading: false
        });
      } else {
        this.setState({
          loading: false,
          fullLoad: true,
          recordsLoading: false
        })
      }
    } else {
      message.error(res.data.error);
    }
  };

  trackScrolling = async () => {
    const wrappedElement = document.getElementById('entity-list');
    if (wrappedElement){
      const {fullLoad, page} = this.state;
      if (scrolledToBottom(wrappedElement) && !fullLoad) {
        await this.setState({
          page: page+1
        });
        await this.getList();
      }
    }
  };

  update = (index, value) => {
    const {records} = this.state;
    records[index] = value;
    this.setState({
      records: records
    });
  };

  remove = (index) => {
    const {records} = this.state;
    records.splice(index, 1);
    this.setState({
      records: records
    });
  };

  render(){
    const {records, loading, recordsLoading, fullLoad, groupAdmin} = this.state;
    const {title} = this.props;
    if (loading) return <Spinner/>
    if (records.length === 0) return <Nothing>No {title} found</Nothing>;
    return (
      <div id="entity-list">
        {records.map((v, i) => {
          return <EntityCard
            index={i}
            key={i}
            data={v}
            admin={groupAdmin}
            update={this.update}
            remove={this.remove}
          />
        })}
        {recordsLoading && !fullLoad && <Spinner/>}
      </div>
    )
  }

}

export default EntityList;