import React, {Component} from 'react';
import {
  Button, Form, Icon, Upload, message,
  Radio, InputNumber, Select, Row, Col, Typography, Tooltip, Input, DatePicker
} from 'antd'
import {getBase64, beforeUpload, getCurrentPrice} from '../helpers/Funcs';
import {uploadPath} from '../helpers/ServerHost';
import Request from '../utils/Request';
import styled from 'styled-components';
import LocationSearch from '../ui/LocationSearch'
import moment from 'moment';
import { Redirect } from 'react-router-dom';


const { Option } = Select;
const { Title, Paragraph } = Typography;
const initialState = {
  loading: false,
  imageUrl: '',
  cryptoList: [],
  currencyList: [],
  type: 'buy',
  crypto: 'BTC',
  amount: 1,
  exchangeList: [],
  currency: 'USD',
  price: 0,
  methodList: [],
  method: 1,
  percent: 0,
  showLink: false,
  showLocation: false,
  showDate: false,
  address: '',
  coords: '',
  toSettings: false
}

const OptsWrap = styled.div`
  margin-bottom: 2px;
  text-align: right;
  
  button {
    margin: 0 3px;
  }
`;

class TradeForm extends Component{


  constructor (props, context){
    super (props, context);
    if (props.edit) {
      const {type, crypto, amount, currency, price, percentage} = props.data.data;
      const {image} = props.data;
      initialState.type = type;
      initialState.crypto = crypto;
      initialState.amount = amount;
      initialState.imageUrl = image ? image : '';
      initialState.currency = currency;
      initialState.price = price;
      initialState.percent = percentage;
      initialState.showLink = !!props.data.link;
      initialState.showLocation = !!props.data.address;
      initialState.showDate = !!props.data.originalDate;
      initialState.showImage = !!props.data.image;
    }
    this.state = initialState;
  }

  bu = async (file) => {
    if(beforeUpload(file)){
      let base64 = await getBase64(file);
      this.setState({
        imageUrl: base64,
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleAmountChange = async (val) => {
    await this.setState({
      amount: val
    });
    await this.setCurrentPrice();
    this.setState({percent: 0});
  }

  handlePercentChange = async (val) => {
    await this.setState({
      percent: val
    });
    const {crypto, currency, amount} = this.state;
    const price = await getCurrentPrice(crypto, currency);
    let percentPrice = price * (1 + val / 100) * amount;
    this.setState({
      price: percentPrice
    });
  }

  handlePriceChange = (val) => {
    this.setState({
      price: val
    });
    this.setState({percent: 0});
  }

  handleCryptoChange = async (val) => {
    await this.setState({
      crypto: val
    });
    await this.setCurrentPrice();
    this.setState({percent: 0});
  }

  handleCurrencyChange = async (val) => {
    await this.setState({
      currency: val
    });
    await this.setCurrentPrice();
    this.setState({percent: 0});
  }

  handleMethodChange = (val) => {
    this.setState({
      method: val
    });
  }

  async componentDidMount(){
    if (!this.props.edit) await this.setCurrentPrice();
    let req = new Request('utils/payment_methods');
    let res = await req.get();
    if (res.status === 200) {
      this.setState({
        methodList: res.data
      })
    } else {
      message.error(res.date.error);
    }
  }

  handleCryptoSearch = async (value) => {
    if (value) {
      let req = new Request(`utils/search_crypto?query=${value}`);
      let res = await req.get();
      if (res.status === 200){
        this.setState({ cryptoList: res.data });
      } else {
        message.error(res.data.error)
      }
    } else {
      this.setState({ cryptoList: [] });
    }
  };

  handleCurrencySearch = async (value) => {
    if (value) {
      let req = new Request(`utils/search_crypto?query=${value}`);
      let res = await req.get();
      if (res.status === 200){
        this.setState({ currencyList: res.data });
      } else {
        message.error(res.data.error)
      }
    } else {
      this.setState({ currencyList: [] });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {form, edit, data, push, update, group} = this.props;
    const {imageUrl, type, amount, crypto, currency, price, method, percent, address, coords} = this.state;
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let postData = {
          trade_type: type,
          amount: amount,
          crypto: crypto,
          price: price,
          currency: currency,
          method: method,
          image: imageUrl,
          percentage: percent,
          address: address,
          coords: coords,
          event_time: values.date,
          link: values.link
        }
        if (edit) postData.post_id = data.id;
        if (group) postData.group_id = group;
        let req = new Request(`post/trade/${edit ? 'update' : 'create'}`, postData);
        let res = await req.post();
        if (res.status === 200){
          this.setState(initialState);
          edit ? update(data.index, res.data) : push(res.data);
          form.resetFields();
        } else {
          message.error(res.data.error);
          this.setState({
            loading: false
          });
          if (res.status === 403) {
            this.setState({
              toSettings: true
            })
          }
        }
      }
    });
  };

  setCurrentPrice = async () => {
    const {crypto, currency, amount} = this.state;
    let price = await getCurrentPrice(crypto, currency);
    await this.setState({
      price: price * parseFloat(amount)
    });
    return null;
  }

  manageField = (field) => {
    this.setState({
      [`show${field}`]: !this.state[`show${field}`]
    })
  }

  setLocation = (address, coords) => {
    this.setState({
      address: address,
      coords: coords
    })
  }

  render(){
    const { imageUrl, crypto, type, cryptoList, amount, currency, price,
      currencyList, method, methodList, percent, showLink, showLocation, showDate, toSettings} = this.state;
    if (toSettings) return <Redirect to="/settings/account"/>;
    const cryptoOptions = cryptoList.map((v, i) => <Option value={v.value} key={i}>{v.text}</Option>);
    const currencyOptions = currencyList.map((v, i) => <Option value={v.value} key={i}>{v.text}</Option>);
    const methodOptions = methodList.map((v, i) => <Option value={v.value} key={i}>{v.text}</Option>);
    const uploadButton = (
      <div>
        <Icon type={'picture'} />
        <div className="ant-upload-text">Upload a picture</div>
      </div>
    );
    const {edit, data} = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} style={{marginTop: 15}} className="post-form">
        <OptsWrap>
          <Tooltip title="Link"><Button title="Link" onClick={() => this.manageField('Link')} size="small" type="link" icon="link"/></Tooltip>
          <Tooltip title="Location"><Button onClick={() => this.manageField('Location')} size="small" type="link" icon="environment"/></Tooltip>
          <Tooltip title="Date"><Button onClick={() => this.manageField('Date')} size="small" type="link" icon="calendar"/></Tooltip>
        </OptsWrap>
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>Looking for</Title>
            </Col>
            <Col xs={12}>
              <Radio.Group name="type" onChange={this.handleChange} defaultValue={type} buttonStyle="solid" style={{width: '100%'}}>
                <Radio.Button value="buy">Buy</Radio.Button>
                <Radio.Button value="sell">Sell</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>Crypto</Title>
            </Col>
            <Col xs={12}>
              <div className="currency-input">
                <InputNumber
                  defaultValue={amount}
                  type="number"
                  placeholder="Amount"
                  onChange={this.handleAmountChange}
                  step={0.0001}
                />
                <Select
                  showSearch
                  value={crypto}
                  name="crypto"
                  placeholder='Cryptocurrency'
                  showArrow={true}
                  onSearch={this.handleCryptoSearch}
                  onChange={this.handleCryptoChange}
                  notFoundContent={null}
                  filterOption={false}
                >
                  {cryptoOptions}
                </Select>
              </div>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>For</Title>
            </Col>
            <Col xs={12}>
              <div className="currency-input">
                <InputNumber
                  value={price}
                  type="number"
                  placeholder="Price"
                  onChange={this.handlePriceChange}
                />
                <Select
                  showSearch
                  value={currency}
                  name="currency"
                  placeholder='Currency'
                  showArrow={true}
                  onSearch={this.handleCurrencySearch}
                  onChange={this.handleCurrencyChange}
                  notFoundContent={null}
                  filterOption={false}
                  style={{bottom: '1px'}}
                >
                  {currencyOptions}
                </Select>
              </div>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>Payment Method</Title>
            </Col>
            <Col xs={12}>
              <Select
                showSearch
                value={method}
                name="method"
                placeholder='Payment Method'
                showArrow={true}
                onChange={this.handleMethodChange}
                notFoundContent={null}
                filterOption={false}
                style={{bottom: '1px'}}
              >
                {methodOptions}
              </Select>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col xs={12}>
              <Title level={4}>Percentage</Title>
              <Paragraph>(Optional)</Paragraph>
            </Col>
            <Col xs={12}>
              <InputNumber
                type="number"
                value={percent}
                placeholder="Percentage"
                onChange={this.handlePercentChange}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={uploadPath}
            beforeUpload={this.bu}
            customRequest={async () => {
              let req = new Request('utils/upload', {
                file: imageUrl
              });
              let res = await req.post();
              this.setState({
                imageUrl: res.data.url
              })
            }}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        </Form.Item>
        {showLink && <Form.Item hasFeedback>
          {getFieldDecorator('link', {
            initialValue: edit ? data.link ? data.link : '' : ''
          })(<Input
            placeholder="Share a link"
            prefix={<Icon onClick={this.paste} type="link" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />)}
        </Form.Item>}
        {showLocation && <Form.Item>
          <LocationSearch default={edit ? data.address : ''} setLocation={this.setLocation}/>
        </Form.Item>}
        {showDate && <Form.Item hasFeedback>
          {getFieldDecorator('date', {
            initialValue: edit ? moment(moment.utc(data.originalDate).local(), "YYYY-MM-DD HH:mm") : null
          })(<DatePicker format="YYYY-MM-DD HH:mm" style={{width: '100%'}} showTime={{ format: 'HH:mm' }} placeholder="Select Time"/>)}
        </Form.Item>}
        <Form.Item>
          <Button
            loading={this.state.loading}
            shape="round"
            block
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
  }

}

export default Form.create({ name: 'trade' })(TradeForm);