import React, {Component} from 'react';
import { Modal, Typography } from 'antd';
import RatingAvatar from '../ui/RatingAvatar';

const {Paragraph} = Typography;

const style = {
  margin: 0,
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 'bold'
};

class ChatModal extends Component {

  render(){
    const {user, decline, accept, visible} = this.props;
    return (
      <Modal
        title={`Chat request from ${user.name}`}
        visible={visible}
        onOk={accept}
        onCancel={decline}
        okText="Accept"
        cancelText="Decline"
      >
          <RatingAvatar
            entity={user}
          />
          <Paragraph style={style}>wants to chat with you!</Paragraph>

      </Modal>
    )
  }

}

export default ChatModal;