import React, {Component} from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import Request from '../../utils/Request';
import SessionData from '../../utils/SessionData';
import {Card, message, Avatar, Badge, Modal, Icon, Button, Drawer} from 'antd';
import Constants from '../../Constants';
import CheckMark from '../../ui/CheckMark';
import styled from 'styled-components';
import PostImage from '../../ui/PostImage';
import OG from '../../ui/OG';
import Spinner from '../../ui/Spinner';
import Shout from '../../ui/posts/Shout';
import Event from '../../ui/posts/Event';
import Trade from '../../ui/posts/Trade';
import Signal from '../../ui/posts/Signal';
import Deal from '../../ui/posts/Deal';
import ReactPlayer from 'react-player';
import LikeIcon from '../../ui/LikeIcon'
import CommentsList from '../../ui/CommentsList'
import CommentForm from '../../forms/CommentForm'
import PostOptions from "./PostOptions";
import ImgsViewer from "react-images-viewer";
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";
import moment from "moment";
import GoogleMap from "../../ui/GoogleMap";
import {Typography} from 'antd';


const { Meta } = Card;
const {confirm} = Modal;
const {Paragraph} = Typography;

const Options = styled.span`
  float: right;
`;
const Time = styled.span`
  color: #b3b3b3;
  font-size: 12px;
  margin: 0 5px;
  font-weight: 100;
`;

const PicWrap = styled.div`
  width: 100%;
  height: 35vh;
  position: relative;
  border: 1px solid #e8e8e8;
`;

const Wrapper = styled.div`
  width: 100%;
  background-size: cover;
`;

const MediaWrap = styled.div`
  position: relative;
  margin-bottom: 80px;
`;

const Comments = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 83px;
  min-height:250px;
`;

const Likes = styled.span`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -25px;
  color: ${props => props.active ? Constants.primaryColor : '#a8a8a8'};
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;
const Date = styled.div`
  color: ${Constants.primaryColor}
  font-size: 16px;
  font-weight: bold;
`;
const ExtraWrapper = styled.div`
  min-height: 200px;
  z-index: 99;
`;

const MobileOptions = styled.div`
  padding: 20px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

class Open extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      page: 1,
      rpp: 12,
      type: this.props.match.params.type,
      id: this.props.match.params.id,
      post: props.post,
      index: props.index,
      reply: false,
      viewerIsOpen: false,
      currImg: 0,
      drawer: false
    }
  }

  getPostComments = async () => {
    const {getComments, comments, fullCommentsLoad} = this.props;
    if (comments && comments.length === 0 && !fullCommentsLoad) {
      await getComments();
    }
  };

  comment = async (text) => {
    const {type, id} = this.props.match.params;
    const {reply} = this.state;
    let postData = {
      text: text,
      post_type: type,
      post_id: id
    };
    if (reply) postData.reply_to = reply.id;
    let req = new Request('comment/create', postData);
    let res = await req.post();
    if (res.status === 200){
      const {addComment} = this.props;
      addComment(res.data);
      window.scrollTo(0,document.body.scrollHeight);
    } else {
      message.error(res.data.error);
      this.setState({
        reply: false
      });
    }
  };

  likeComment = async (index, id, reply_index=false) => {
    const {likeComment} = this.props;
    likeComment(index, reply_index);
    new Request('comment/like', {
      comment_id: id
    }).post();
  };

  setReply = async (id, name) => {
    await this.setState({
      reply: {
        id: id,
        name: name
      }
    })
  };

  loadMore = async () => {
    const {page} = this.state;
    await this.setState({
      page: page + 1
    });
    await this.getPostComments();
  };

  delete = () => {
    const {type, id} = this.state;
    const {remove} = this.props;
    remove(type, id);
  }

  async componentDidMount() {
    await this.getPostComments();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {post} = this.props;
    if (post && post !== prevProps.post) {
      await this.getPostComments();
      this.setState({
        post: post
      });
    }
  }

  entityAction = async (entity, id, task) => {
    const {entityAction, index} = this.props;
    const {post} = this.state;
    if (index !== null) {
      entityAction(post.entity, post[post.entity].id, task);
    }
    else {
      const {post} = this.state;
      switch (task) {
        case 'follow':
          post[entity].following = !post[entity].following;
          break;
        case 'mute':
          post[entity].muted = !post[entity].muted;
          break;
        case 'block':
          post[entity].blocked = !post[entity].blocked;
          break;
        default:
          return null;
      }
      this.setState({
        post: post
      });
      const req = new Request(`${entity}/${task}`, {
        [`${entity}_id`]: id
      });
      const res = await req.post();
      if (res.status === 200){
        message.info(res.data.message);
      } else {
        message.error(res.data.error);
      }
    }
  };

  like = () => {
    const {postAction} = this.props;
    const {post} = this.state;
    postAction(post.postType, post.id, 'like');
  };

  gotoPrev = () => {
    const {currImg} = this.state;
    this.setState({
      currImg: currImg-1
    })
  };

  gotoNext = () => {
    const {currImg} = this.state;
    this.setState({
      currImg: currImg+1
    })
  }

  openLink = () => {
    const {post} = this.props;
    if (post.link) {
      let win = window.open(post.link, '_blank');
      win.focus();
    }
    return null;
  };

  render(){
    const {post, index, reply, viewerIsOpen, currImg, drawer} = this.state;
    const {report, openUpdateModal, update, loading, comments, fullCommentsLoad, getComments} = this.props;
    if (!post) return null;
    let component = <Shout text={post.data.text} collapse={false}/>;
    switch (post.postType){
      case 'shout':
        component = <Shout data={post} collapse={false}/>;
        break;
      case 'event':
        component = <Event data={post} cut={false} map/>;
        break;
      case 'trade':
        component = <Trade data={post} flag={post.user ? post.user.countryFlag : false} trade={this.trade}/>;
        break;
      case 'signal':
        component = <Signal data={post} />;
        break;
      case 'deal':
        component = <Deal data={post} trade={this.trade} cut={true}/>;
        break;
      default:
        return null;

    }
    let images = [];
    if (post.image) images.push({src: post.image});
    if (post.og && post.og.image && post.og.image !== post.image) images.push({src: post.og.image});
    let local = false;
    let address = false;
    let coords = false;
    let link = false;
    let atLeastSomething = false;
    if (post.originalDate){
      let utc = moment.utc(post.originalDate);
      local = (
          <Date>{moment(utc).local().format("dddd, MMMM Do YYYY, HH:mm")}</Date>
      );
    }
    if (post.address){
      address = (
          <Paragraph ellipsis={this.props.cut}>
            <Icon type="environment" /> {post.address}
          </Paragraph>
      )
    }
    if (post.coords) {
      coords = (
          <GoogleMap
              lat={post.coords.lat}
              lng={post.coords.lng}
              width="100%"
              height="150px"
          />
      )
    }
    if (post.link) {
      link = (
          <div style={{textAlign: 'center'}}>
            <Button onClick={this.openLink} type="link" icon="link">Open</Button>
          </div>
      )
    }
    if (link || coords || address || local) atLeastSomething = true;
    return (
      <div style={{backgroundColor: '#fff'}}>
        <Wrapper className="open-post">
          <Card
            style={{ width: '100%' }}
            dir={'auto'}
          >
            <Meta
              avatar={
                <NavLink to={`/${post.entity}/${post[post.entity].id}`}>
                  <Badge
                    status={post.user ? post.user.online ? 'success' : false : false}
                    offset={[-5, 30]}
                    style={{zIndex: 0}}
                  >
                    <Avatar src={post.user ? post.user.photo : post.group.photo} />
                  </Badge>
                </NavLink>}
              title={<p>
                <NavLink style={{color: post.user ? post.user.nameColor : `${Constants.primaryColor}`}} to={`/${post.entity}/${post[post.entity].id}`}>{post.user ? post.user.name : post.group.name}
                  {post.user ? post.user.checkMark ? <CheckMark/> : '' : post.group.checkMark ? <CheckMark/> : ''}</NavLink>
                <Time>{post.createdAt}</Time>{' '}
                {!post[post.entity].following && <Button
                    size={'small'}
                    onClick={() => this.entityAction(post.entity, post[post.entity].id, 'follow')}
                    style={{textAlign: 'left'}}
                    type={post[post.entity].following ? 'link' : "primary"}
                    shape={'round'}
                >
                  Follow
                </Button>}
                <Options>
                  <PostOptions
                      data={post}
                      update={update}
                      openUpdateModal={openUpdateModal}
                      remove={this.delete}
                      report={report}
                      index={index}
                      entityAction={this.entityAction}
                  />
                </Options>
              </p>}
              description={component}
            />
          </Card>
          <MediaWrap>
            {post.og ? (
              <div>
                {post.og['video:url'] && <ReactPlayer
                  key="media"
                  // muted
                  controls
                  width="100%"
                  height="350px"
                  url={post.og['video:url']}
                  // playing
                />}
                {!post.og['video:url'] && images.length > 0 &&
                <PicWrap>
                  <PostImage onClick={() => this.setState({viewerIsOpen: true})} src={images[0].src}/>
                </PicWrap>}
              </div>
            ) : (
              images.length > 0 && (
                <PicWrap>
                  <PostImage onClick={() => this.setState({viewerIsOpen: true})} src={images[0].src}/>
                </PicWrap>
              )
            )}
            <LikeIcon style={{
              width: '50px',
              height: '50px',
            }} active={post.liked} onClick={this.like}>
              <Likes active={post.likes > 0}>{post.likes}</Likes>
            </LikeIcon>
          </MediaWrap>
          <MobileOptions>
            {atLeastSomething && <Button type={'primary'} danger block shape={'round'} onClick={() => this.setState({drawer: true})}>Show More</Button>}
          </MobileOptions>
          <Comments>
            {typeof (comments) !== 'undefined' && !loading && <CommentsList
              comments={comments}
              like={this.likeComment}
              setReply={this.setReply}
              loadMore={getComments}
              loadButton={!fullCommentsLoad}
              loading={loading}
            />}
            {loading && <Spinner type={2}/>}
          </Comments>
        </Wrapper>
        {images.length > 0 && <ImgsViewer
            backdropCloseable={true}
            imgs={images}
            currImg={currImg}
            isOpen={viewerIsOpen}
            onClickPrev={this.gotoPrev}
            onClickNext={this.gotoNext}
            onClose={() => this.setState({viewerIsOpen: false})}
        />}
        <CommentForm send={this.comment} reply={reply}/>
        <Drawer
            title={null}
            placement="bottom"
            closable={true}
            onClose={() => this.setState({drawer: false})}
            visible={drawer}
        >
          <ExtraWrapper>
            {local && local}
            {address && address}
            {coords && coords}
            {link && link}
          </ExtraWrapper>
        </Drawer>

      </div>
    )
  }

}

export default withRouter(Open);