import React , { Component} from 'react';
import { Modal } from 'antd';
import SignalForm from '../forms/SignalForm';


class SignalModal extends Component {

  push = (val) => {
    this.props.close();
    this.props.push(val);
  }

  render (){
    const {visible, close, group} = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={close}
        title="Post a signal"
        footer={null}
        className="shout-modal"
      >
        <SignalForm group={group} push={this.push} edit={false}/>
      </Modal>
    )
  }

}

export default SignalModal;