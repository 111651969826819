import React, {Component} from 'react';
import {Form, Input, Icon, Upload, Button, message, Checkbox, Avatar} from 'antd'
import Request from '../utils/Request';
import styled from 'styled-components';
import {uploadFilePath, uploadPath} from '../helpers/ServerHost';
import {getBase64, beforeDocumentUpload} from '../helpers/Funcs';
import Constants from '../Constants';
import AmlModal from '../modals/AmlModal'
import Spinner from "../ui/Spinner";
import Provider from "../utils/Provider";

const FormWrap = styled.div`
  padding: 15px;
`;

const FileName = styled.div`
  color: #93928e;
  font-size: 12px;
`;


class KycForm extends Component {

  constructor(props, context){
    super (props, context);
    const provider = new Provider('user');
    const tokens = provider.get('tokens');
    this.state = {
      kyc: false,
      b64residence: '',
      residenceFileName: '',
      b64id: null,
      idFileName: null,
      aml: '',
      showAmlModal: false,
      token: tokens.auth,
    }
  }

  beforeUpload = async (file, name, fileName) => {
     let base64 = await getBase64(file);
     if (beforeDocumentUpload(file)) {
       this.setState({
         [name]: base64,
         [fileName]: [file.name, ' ', <Icon key="icon" type="check-circle" style={{color: Constants.green}}/>]
       })
     }
  };

  async componentDidMount() {
    const req = new Request('user/kyc_status');
    const res = await req.get();
    if (res.status === 200) {
      await this.setState({
        kyc: res.data
      })
    } else {
      message.error(res.data.error);
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.kyc !== prevProps.kyc) {
      this.setState({kyc: this.props.kyc})
    }
  }

  upload = async (file, b64, name) => {
    const {save} = this.props;
    const req = new Request('utils/upload', {
      file: this.state[b64]
    });
    let res = await req.post();
    if (res.status === 200) {
      save({[name]: res.data.url});
    }
  };

  handleChange = (e) => {
    const {save} = this.props;
    const {kyc} = this.state;
    let key = e.target.name;
    if (key === 'postal_code') key = 'postalCode';
    kyc[key].value = e.target.value;
    this.setState({
      kyc:kyc
    });
    save({[e.target.name]: e.target.value})
  };

  addCoin = e => {
    const {kyc} = this.state;
    const {save} = this.props;
    if (!kyc.pro_account.acceptable_crypto.includes(e.target.value)) {
      kyc.pro_account.acceptable_crypto.push(e.target.value);
    } else {
      const index = kyc.pro_account.acceptable_crypto.indexOf(e.target.value);
      if (index !== -1) kyc.pro_account.acceptable_crypto.splice(index, 1);
    }

    this.setState({kyc: kyc});
    save({acceptable_crypto: kyc.pro_account.acceptable_crypto});
  }

  selectAll = () => {
    const {kyc} = this.state;
    const {save} = this.props;
    const allCoins = [];
    kyc.pro_account.available_crypto.map((v) => {
      allCoins.push(v.name);
      return null;
    });
    kyc.pro_account.acceptable_crypto = allCoins;
    this.setState({kyc: kyc});
    save({acceptable_crypto: kyc.pro_account.acceptable_crypto});
  }

  render(){
    const {kyc, residenceFileName, showAmlModal, idFileName} = this.state;
    if (!kyc) return <Spinner/>;
    return (
      <div className="profile-form">
        <Form onSubmit={this.save} layout="vertical">
          <FormWrap>
            <Form.Item label="Proof of the Identity" extra={kyc.identity.text}>
              <Upload
                  name="bill"
                  showUploadList={false}
                  action={uploadPath}
                  beforeUpload={(file) => this.beforeUpload(file, 'b64id', 'idFileName')}
                  customRequest={(file) => this.upload(file, 'b64id', 'bill')}
              >
                <Button disabled={kyc.identity.status}>
                  <Icon type="upload" /> Click to Upload
                </Button>
                <FileName>{idFileName}</FileName>
              </Upload>
            </Form.Item>
            <Form.Item label="Proof of the Residence" extra={kyc.residence.text}>
              <Upload
                name="residence"
                showUploadList={false}
                action={uploadPath}
                beforeUpload={(file) => this.beforeUpload(file, 'b64residence', 'residenceFileName')}
                customRequest={(file) => this.upload(file, 'b64residence', 'residence')}
              >
                <Button disabled={kyc.residence.status}>
                  <Icon type="upload" /> Click to Upload
                </Button>
                <FileName>{residenceFileName}</FileName>
              </Upload>
            </Form.Item>
            <Form.Item label="Full address" extra={kyc.address.text}>
              <Input.TextArea
                placeholder="Write your full address"
                rows={2}
                onChange={this.handleChange}
                name="address"
                disabled={kyc.address.text !== 'Not confirmed'}
                value={kyc.address.value}
              />
            </Form.Item>
            <Form.Item label="Postal code" extra={kyc.postalCode.text}>
              <Input
                placeholder="Write your postal code"
                onChange={this.handleChange}
                name="postal_code"
                disabled={kyc.postalCode.text !== 'Not confirmed'}
                value={kyc.postalCode.value}
              />
            </Form.Item>
            <Form.Item label="AML" extra={kyc.aml.text}>
              <Button type="default" onClick={() => this.setState({
                showAmlModal: true
              })}>Open</Button>
            </Form.Item>

            {/*<Form.Item label="Wallet Address">*/}
            {/*  <Input*/}
            {/*      name="wallet_address"*/}
            {/*      onChange={this.handleChange}*/}
            {/*      placeholder="Your crypto-wallet address"*/}
            {/*      value={kyc.wallet_address.value}*/}
            {/*  />*/}
            {/*</Form.Item>*/}
          </FormWrap>
        </Form>
        <AmlModal
          visible={showAmlModal}
          close={() => {
            this.setState({
              showAmlModal: false
            });
          }}
          accepted={kyc.aml.status}
          save={(kyc) => this.setState({
            kyc: kyc
          })}
          path={'user/update_kyc'}
        />
      </div>
    )
  }

}

export default KycForm;