import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Avatar, Badge, Icon, Menu} from "antd";
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  border-top: 1px solid #d9d9d9;
  z-index: 99;
`;


class MobileNavigation extends Component {

    render() {
        const {newNotifications, newMessages, avatar, provider, currentKey} = this.props;
        const {id} = provider.profile.basics;
        return <Wrapper>
            <Menu
                onClick={(e) => {
                    this.props.history.push('/' + e.key);
                    this.setState({currentKey: e.key});
                }}
                selectedKeys={[currentKey]}
                mode="horizontal"
            >
                <Menu.Item key="feed">
                    <Icon style={{fontSize: '18px'}} type="home" />
                </Menu.Item>
                <Menu.Item key="popular">
                    <Icon style={{fontSize: '18px'}} type="search" />
                </Menu.Item>
                <Menu.Item key="notifications">
                    <Badge count={newNotifications}><Icon style={{fontSize: '18px'}} type="bell" /></Badge>
                </Menu.Item>
                <Menu.Item key="chat">
                    <Badge count={newMessages}><Icon style={{fontSize: '18px'}} type="mail" /></Badge>
                </Menu.Item>
                <Menu.Item key={'user/' + id}>
                    <Avatar size="large" src={avatar}/>
                </Menu.Item>
            </Menu>
        </Wrapper>
    }

}

export default withRouter(MobileNavigation);