import React, {Component} from 'react';
import styled from 'styled-components';
import {Typography, Button, Tag} from "antd";
import ProductViewModal from "../modals/ProductViewModal";


const { Paragraph, Text} = Typography;


const Wrapper = styled.div`
    width: calc(100% - 5px);
    padding: 10px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
`;

const Image = styled.div`
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.src});
    margin-bottom: 10px;
  
    &:hover {
      cursor: pointer;
    } 
`


class ProductPreview extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: props.defaultOpen
        }
    }

    discountPrice = (price, discount) => {
        return (price - price / 100 * discount).toFixed(2);
    }

    render() {
        const {data, editing, edit, remove, addToCart, entity, entity_id, updateProduct, productIndex} = this.props;
        const {showModal} = this.state;
        return <div>
            <Wrapper>
                <Paragraph ellipsis strong style={{textAlign: 'center'}}>{data.name}</Paragraph>
                <Image onClick={() => this.setState({showModal: true})} src={data.picsLinks[0]}/>
                <Paragraph style={{textAlign: 'center'}} ellipsis>
                    {data.discount ? (
                        <div>
                            <Text delete>{data.price}</Text> {this.discountPrice(data.price, data.discount)} {data.currency}
                        </div>
                    ) : (
                        <Text>{data.price} {data.currency}</Text>
                    )}
                </Paragraph>
                <Button onClick={() => this.setState({showModal: true})} type={'primary'} block shape={'round'}>Buy</Button>
            </Wrapper>
            <ProductViewModal
                visible={showModal}
                close={() => this.setState({
                    showModal: false
                })}
                data={data}
                editing={editing}
                edit={edit}
                remove={remove}
                addToCart={addToCart}
                entity={entity}
                entity_id={entity_id}
                updateProduct={updateProduct}
                productIndex={productIndex}
            />
        </div>
    }
}

export default ProductPreview;