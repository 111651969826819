import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Spinner from "./Spinner";
import Request from "../utils/Request";
import {Button, Empty, Icon, message, Typography} from "antd";
import styled from 'styled-components';
import ProductModal from "../modals/ProductModal";
import ProductPreview from "./ProductPreview";

const {Text} = Typography;

const ProductsWrapper = styled.div`
    padding: 20px;
  background-color: #fff;
`;

const CreateButton = styled.div`
  position: fixed;
  right: 10px;
  bottom: 55px;
  z-index: 99;
`;

const ProductsGrid = styled.div`
    display: grid;
    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(2, 50%);
    }
    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(4, 25%);
    }
    grid-gap: 5px;
`;


class Products extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            products: props.products,
            productModal: false,
            edit: false,
            rate: null,
            editData: null,
            openedProductIndex: null,
            buttonDisabled: false,
            paymentLink: null,
            cartItems: 0
        }
    }

    async componentDidMount() {
        const {entity, id} = this.props;
        const r = await new Request(`product/all?entity=${entity}&id=${id}`).get();
        if (r.status === 200) {
            let cart = localStorage.getItem(`${entity}${id}cart`);
            if (cart === null) {
                cart = []
            } else {
                cart = JSON.parse(cart);
            }
            this.setState({
                loading: false,
                products: r.data.products,
                buttonDisabled: r.data.disabled,
                paymentLink: r.data.paymentLink,
                cartItems: cart.length
            });
        } else {
            this.setState({
                loading: false
            })
        }
    }

    pushProduct = (product) => {
        const {products} = this.state;
        products.unshift(product);
        this.setState({products: products});
    }

    updateProduct = (index, data) => {
        const {products} = this.state;
        products[index] = data;
        this.setState({products: products});
    }

    removeProduct = (index) => {
        const {products} = this.state;
        new Request('product/delete', {
            id: products[index].id
        }).post();
        products.splice(index, 1);
        this.setState({products: products});
    }

    backOpenModal = () => {
        const {products, openedProductIndex} = this.state;
    }

    addToCart = (product_id, quantity) => {
        const {entity, id} = this.props;
        const key = `${entity}${id}cart`;
        let cart = localStorage.getItem(key);
        if (cart === null) {
            cart = []
        } else {
            cart = JSON.parse(cart);
        }
        if (cart.length === 0) {
            cart.push({
                id: product_id,
                quantity: quantity
            });
            localStorage.setItem(key, JSON.stringify(cart));
            this.setState({cartItems: cart.length});
            message.info('Product was added to the cart');
        } else {
            cart.map((v) => {
                if (v.id === product_id) {
                    message.error('Product already in the cart!');
                    return null;
                } else {
                    cart.push({
                        id: product_id,
                        quantity: quantity
                    });
                    localStorage.setItem(key, JSON.stringify(cart));
                    this.setState({cartItems: cart.length});
                    message.info('Product was added to the cart');
                }
                return null;
            });
        }
    }

    goToCart = () => {
        const {entity, id} = this.props;
        this.props.history.push(`/${entity}/${id}/cart`)
    }

    render() {
        const {loading, products, productModal, edit, editData, openedProductIndex, buttonDisabled, paymentLink, cartItems} = this.state;
        const {adding, entity, id} = this.props;
        if (loading) return <Spinner/>;
        return <ProductsWrapper>
            {products.length === 0 ? (
                <Empty description={'No products found'}/>
            ) : (
                <div>
                    <ProductsGrid>
                        {products.map((v, i) => {
                            return <ProductPreview
                                productIndex={i}
                                key={i}
                                remove={() => this.removeProduct(i)}
                                data={v}
                                editing={adding}
                                defaultOpen={i === openedProductIndex}
                                addToCart={this.addToCart}
                                entity={entity}
                                entity_id={id}
                                updateProduct={this.updateProduct}
                            />
                        })}
                    </ProductsGrid>
                </div>
            )}
            {adding ? (
                <div>
                    <CreateButton className="create-button">
                        <Button disabled={buttonDisabled} size="large" onClick={paymentLink ? () => window.location.href = paymentLink : async () => {
                            await this.setState({
                                edit: false,
                                editData: null,
                                openedProductIndex: false
                            });
                            this.setState({
                                productModal: true
                            })
                        }} type="danger" shape="round" >Add Product</Button>
                    </CreateButton>
                    <ProductModal
                        visible={productModal}
                        close={() => this.setState({
                            productModal: false,
                            // editData: null,
                            // edit: false,
                            // openedProductIndex: false
                        })}
                        entity={entity}
                        id={id}
                        edit={false}
                        push={this.pushProduct}
                        data={null}
                        index={openedProductIndex}
                        key={'add'}
                    />
                </div>
            ) : (
                <CreateButton>
                    <Button onClick={this.goToCart} size={'large'} type={'danger'} shape={'round'} icon={'shopping-cart'}>Cart {cartItems > 0 && `(${cartItems})`}</Button>
                </CreateButton>
            )}
        </ProductsWrapper>
    }
}

export default withRouter(Products);