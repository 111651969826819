import React, {Component} from 'react';
import {
  Drawer, Divider, Avatar, Typography, Row, Col,
  Icon, Badge,
} from 'antd'
import { withRouter } from 'react-router-dom';
import Provider from '../utils/Provider';
import styled from 'styled-components';
import IAm from './IAm'
import ReportModal from '../modals/ReportModal'


const {Title} = Typography;
const Info = styled.p`
  font-size: 13px;
  color: #a3a3a3;
  margin: 4px 0;
  
  span {
    font-weight: bold;
    color: #000000;
  }
`;

const SettingsItems = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: 13px;
  
  li {
    margin: 10px 0;
    font-size: 14px;
  }
`;

class SidebarMenu extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      showReportModal: false
    }
  }

  logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    this.props.history.push(`/`);
  }

  goTo = (path) => {
    const {close} = this.props;
    this.props.history.push(path);
    close();
  }

  render(){
    const profile = new Provider('user').get('profile');
    const {info, close, visible} = this.props;
    const {showReportModal} = this.state;
    return (
      <div>
        <Drawer
          title="Your account"
          placement="left"
          closable={true}
          onClose={close}
          visible={visible}
        >
          <Avatar onClick={() => this.goTo(`/user/${profile.id}`)} size="large" src={profile.basics.photo}/>
          <Title style={{display: 'inline-block', margin: '0 10px', color: profile.basics.nameColor}} onClick={() => this.goTo(`/user/${profile.id}`)} level={4}>{profile.basics.name}</Title>
          <Row>
            <Col xs={12}>
              <Info onClick={() => this.goTo('followers')}>Followers: <span>{info.followers}</span></Info>
              <Info onClick={() => this.goTo('following')}>Following: <span>{info.following}</span></Info>
              <Info onClick={() => this.goTo('groups')}>Groups: <span>{info.groups}</span></Info>
            </Col>
            <Col xs={12}>
              {profile.iamStatus && <IAm style={{
                width: '70px',
                height: '55px'
              }}/>}
            </Col>
          </Row>
          <SettingsItems>
            {info.followRequests > 0 && <li onClick={() => this.goTo('/follow_requests')}><Icon type="user-add" /> Follow Requests <Badge count={info.followRequests}/></li>}
            <li onClick={() => this.goTo('/group/create')}><Icon type="usergroup-add" /> Create Group</li>
            <li onClick={() => this.goTo('/contacts')}><Icon type="phone" /> Contacts list</li>
          </SettingsItems>
          <Divider/>
          <SettingsItems>
            <li onClick={() => this.goTo('/settings/account')}><Icon type="setting" /> Account Settings</li>
            <li onClick={() => this.goTo('/settings/profile')}><Icon type="user"/> Profile Settings</li>
            <li onClick={() => this.goTo('/settings/notifications')}><Icon type="alert"/> Notifications Settings</li>
            <li onClick={() => {
              this.setState({
                showReportModal: true
              });
              close();
            }}><Icon type="question-circle"/> Support</li>
            <li onClick={() => this.goTo('/settings/kyc')}><Icon type="lock"/> KYC</li>
          </SettingsItems>
          <Divider/>
          <SettingsItems>
            <li onClick={this.logout}><Icon type="logout"/> Log out</li>
          </SettingsItems>
        </Drawer>
        <ReportModal
          visible={showReportModal}
          close={() => {
            this.setState({
              showReportModal: false
            })
          }}
          source="Support Request"
          text="Please, write your request"
        />
      </div>
    )
  }

}

export default withRouter(SidebarMenu);