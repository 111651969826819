import React, {Component} from 'react';
import { Button, Modal, Typography } from 'antd'
import RatingAvatar from '../ui/RatingAvatar';
import { NavLink } from 'react-router-dom';
import Constants from '../Constants';

const {Paragraph} = Typography;

const style = {
  margin: 0,
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 'bold'
};

class DealOfferModal extends Component {

  render(){
    const {user, trade, decline, accept, visible} = this.props;
    return (
      <Modal
        title={`Deal request from ${user.name}`}
        visible={visible}
        onOk={accept}
        onCancel={decline}
        okText="Accept"
        cancelText="Decline"
      >
          <RatingAvatar
            entity={user}
          />
        {trade && <Paragraph style={style}>interested in <span style={{color: Constants.secondaryColor}}>{trade.data.title}</span></Paragraph>}
        <div style={{width: '50%', margin: '10px auto'}}><NavLink to={`/post/deal/${trade.id}`}><Button type="primary" shape="round" block>Go to Deal</Button></NavLink></div>
      </Modal>
    )
  }

}

export default DealOfferModal;