import React, {Component} from 'react';
import { Drawer } from 'antd';
import CommentForm from '../forms/CommentForm'
import CommentsList from './CommentsList'


class CommentDrawer extends Component {

  render(){
    const {visible, close, comment, reply, loadMore, comments, like, setReply, cpp} = this.props;
    return (
      <Drawer
        placement="bottom"
        closable={false}
        visible={visible}
        zIndex={1010}
        height="70vh"
        onClose={close}
        className="comment-drawer"
      >
        <CommentForm send={comment} reply={reply}/>
        <CommentsList comments={comments} loadMore={loadMore} like={like} setReply={setReply} loadButton={comments.length >= cpp}/>
      </Drawer>
    )
  }

}

export default CommentDrawer;