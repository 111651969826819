import React, {Component} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Badge, Icon, List, message } from 'antd'
import Request from '../utils/Request'
import Spinner from './Spinner'


const ListItem = styled.div`
  position: relative;
  width: 100%;
  
  a {
    color: #000;
  }
  
  &:hover {
    cursor: pointer;
  }
`;


class GroupSettings extends Component {

  render(){
    const {id, data, info} = this.props;
    if (!data) return null;
    const links = [
      <ListItem key="general" onClick={() => this.props.history.push(`/group/${id}/settings/general`)}>General Settings</ListItem>,
      <ListItem key="followers" onClick={() => this.props.history.push(`/group/${id}/settings/followers`)}>Group Followers</ListItem>,
      <ListItem key="staff" onClick={() => this.props.history.push(`/group/${id}/settings/staff`)}>Staff Management</ListItem>,
      <ListItem key="blocked" onClick={() => this.props.history.push(`/group/${id}/settings/blocked`)}>Blocked Users</ListItem>,
    ];
    if (info.basics.chatAllowed) links.push(<ListItem key="messages" onClick={() => this.props.history.push(`/group/${id}/chat`)}>Messages {data.newMessages > 0 && <Badge count={data.newMessages}/>}</ListItem>)
    if (data.private) links.push(<ListItem key="requests" onClick={() => this.props.history.push(`/group/${id}/settings/requests`)}>Follow Requests {data.newRequests > 0 && <Badge count={data.newRequests}/>}</ListItem>);
    if (data.business) links.push(<ListItem key="wallet" onClick={() => this.props.history.push(`/group/${id}/wallet`)}>Wallet</ListItem>);
    return (
        <List
            className={'group-options'}
            bordered
            style={{
              borderRadius: 0,
              backgroundColor: '#fff'
            }}
            size="small"
            dataSource={links}
            renderItem={item => <List.Item>{item}<Icon style={{position: 'absolute', right: '15px'}} type="right"/></List.Item>}
        />
    )
  }

}

export default withRouter(GroupSettings);