import styled from 'styled-components';
import ServerHost from '../helpers/ServerHost'

const Logo = styled.div`
  width: ${props => props.width}%;
  margin: 0 auto;
  height: ${props => props.height}px;
  background-image: url("${props => ServerHost(`img/${props.path}.png`)}");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
`

export default Logo;