import React, {Component} from 'react';
import NotificationWrapper from "../../ui/notifications/NotificationWrapper";
import {scrolledToBottom} from "../../helpers/Funcs";


class Notifications extends Component {

    trackScrolling = async () => {
        const {htmlId} = this.props;
        const wrappedElement = document.getElementById(htmlId);
        const {onScrollToBottom, recordsLoading} = this.props;
        if (wrappedElement && scrolledToBottom(wrappedElement) && !recordsLoading){
            await onScrollToBottom();
        }
    };

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        this.props.onUnmount(window.scrollY);
        document.removeEventListener('scroll', this.trackScrolling);
    }

    render(){
        const {notifications, remove, htmlId} = this.props;
        return (
            <div id={htmlId}>
                {notifications.map((v, i) => {
                    return <NotificationWrapper index={i} remove={() => remove(i)} data={v} key={i}/>
                })}
            </div>
        )
    }

}

export default Notifications;