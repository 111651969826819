import React, {Component} from 'react';
import { Form, Icon, Input, Button} from 'antd';
import { Redirect } from 'react-router-dom';
import Request from '../utils/Request'


class RecoveryForm extends Component {

  constructor (props, context){
    super(props, context);
    this.state = {
      confirmDirty: false,
      token: props.token
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let res = await Request.create('post', 'user/recover', {
          password: values.password
        }, {
          Reset: this.state.token
        });
        if (res.status !== 200){
          this.props.notify('error', res.data.error);
        } else {
          this.props.saveData(res.data);
        }
      }
    });
  };

  render(){
    if (this.state.loggedIn) return <Redirect to="/feed"/>
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Please put your password!',
              }
            ],
          })(<Input.Password
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Password"
          />)}
        </Form.Item>
        <Form.Item>
          <Button
            loading={this.state.loading}
            shape="round"
            block
            type="primary"
            htmlType="submit"
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    )
  }

}

export default Form.create({ name: 'recovery' })(RecoveryForm);