import React, {Component} from 'react';
import styled from 'styled-components';
import {Button, Icon, Input, Typography} from "antd";
import Constants from "../../Constants";
import EntityCard from "../components/EntityCard";
import GoogleMap from "../../ui/GoogleMap";
import moment from "moment";
import OG from "../../ui/OG";


const {Search} = Input;
const {Title} = Typography;

const Wrap = styled.div`
    margin: 15px 30px;
`;

const PopularBox = styled.div`
    background-color: #fff;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #329ab7;
    h3 {
        color: ${Constants.primaryColor}
    }
`;

const Date = styled.div`
  color: ${Constants.primaryColor}
  font-size: 16px;
  font-weight: bold;
`;


class RightSide extends Component {

    search = (e) => {
        const {search} = this.props;
        search(e);
    };

    openLink = () => {
        const {link} = this.props;
        if (link) {
            let win = window.open(link, '_blank');
            win.focus();
        }
        return null;
    };

    render() {
        const {top, map, link, date, post} = this.props;
        let dateStr;
        if (date) {
            let utc = moment.utc(date);
            dateStr = (
                <Date>{moment(utc).local().format("dddd, MMMM Do YYYY, HH:mm")}</Date>
            );
        }
        return (
            <Wrap>
                <Search
                    placeholder="Search @ CrypTalks"
                    onSearch={value => this.search(value)}
                    style={{ width: '100%', marginBottom: 20 }}
                    dir={'auto'}
                />
                {!map && !link && !date && top && <div>
                    <PopularBox>
                        <h3>Interesting people</h3>
                        {top.users.map((v, i) => {
                            return <EntityCard data={v} key={i}/>
                        })}
                    </PopularBox>
                    <PopularBox>
                        <h3>Interesting groups</h3>
                        {top.groups.map((v, i) => {
                            return <EntityCard data={v} key={i}/>
                        })}
                    </PopularBox>
                </div>}
                {map && <div>
                    <Title level={4} style={{color: Constants.primaryColor}}>
                        <Icon type="environment" /> {map.address}
                    </Title>
                    <GoogleMap
                        lat={map.lat}
                        lng={map.lng}
                        width="100%"
                        height="250px"
                    />
                </div>}
                {date && dateStr}
                {link && <div style={{textAlign: 'center', margin: '10px 0'}}>
                    <Button onClick={this.openLink} type="default" icon="link" block shape={'round'}>Open</Button>
                </div>}
                {post.og && <OG key="og" data={post.og}/>}
            </Wrap>
        )
    }

}

export default RightSide;