import React, {Component} from 'react';
import { Form, message, Input, Modal } from 'antd';
import Paragraph from '../template/Paragraph';
import Request from '../utils/Request';
const { TextArea } = Input;


class ReportModal extends Component {

  constructor(props, context){
    super(props, context);
    this.state = {
      email: '',
      loading: false
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {form, source, close} = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let req = new Request('app/report', {
          source: source,
          text: values.text
        });
        let res = await req.post();
        if (res.status === 200){
          message.info(res.data.message);
          close();
          form.resetFields();
          this.setState({
            loading: false
          });
        }else{
          this.setState({
            loading: false
          });
          message.error(res.data.error);
        }
      }
    });
  };

  render(){
    const { getFieldDecorator } = this.props.form;
    let {title, visible, close, text} = this.props;
    if (!text) text = 'Please, write what in your opinion is wrong:';
    return (
      <Modal
        title={title}
        visible={visible}
        onOk={this.handleSubmit}
        confirmLoading={this.state.loading}
        onCancel={close}
        okText="Send"
      >
        <Paragraph>{text}</Paragraph>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('text', {
              rules: [
                { required: true, message: 'Please write something about report' }
              ],
            })(
              <TextArea
                placeholder="Write report"
                rows="4"
              />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'report' })(ReportModal);