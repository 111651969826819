import React, {Component} from 'react';
import { Form, Icon, Input, Button, Checkbox} from 'antd';
import Paragraph from '../template/Paragraph';
import Request from '../utils/Request';
import RecoveryModal from '../modals/RecoveryModal';
import GoogleButton from '../socials/GoogleButton';
import FacebookButton from '../socials/FacebookButton';


class LoginForm extends Component {

  constructor(props, context){
    super(props, context);
    this.state = {
      termsAccepted: true,
      loading: false,
      email: '',
      forgotPasswordModal: false,
    }
  }

  forgotPasswordModal = () => {
    this.setState({
      forgotPasswordModal: !this.state.forgotPasswordModal
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let res = await Request.authenticate(values.email, values.password);
        if (res.status !== 200){
          this.props.notify('error', res.data.error);
          this.setState({
            loading: false
          });
        } else {
          this.props.saveData(res.data);
        }
      }
    });
  };

  render(){
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Please put your e-mail!' },
                { type: 'email', message: 'E-mail is not valid!' },
              ],
            })(
              <Input
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="E-mail"
              />,
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please put your Password!' }],
            })(
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
              />,
            )}
          </Form.Item>
          <Form.Item>
            <Paragraph>
              <Button onClick={this.forgotPasswordModal} type="link">Forgot password</Button>
            </Paragraph>
            <Paragraph>
              <Button
                loading={this.state.loading}
                disabled={!this.state.termsAccepted}
                // shape="round"
                // block
                type="primary"
                htmlType="submit">
                Log in
              </Button>
            </Paragraph>
            <Button
              type="link"
              onClick={this.props.switch}
              block
            >
              Don't have an account? Sign up!
            </Button>
          </Form.Item>
          <Form.Item>
            <Paragraph>
              <FacebookButton
                disabled={!this.state.termsAccepted}
                callback={this.props.responseFacebook}
              />
            </Paragraph>
            <Paragraph>
              <GoogleButton
                disabled={!this.state.termsAccepted}
                callback={this.props.responseGoogle}
              />
            </Paragraph>
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('terms', {
              valuePropName: 'checked',
              initialValue: this.state.termsAccepted,
            })(<Checkbox onChange={async () => {
              this.setState({
                termsAccepted: !this.state.termsAccepted
              })
            }}>
              By signing in you agree to our <a href="https://cryptalks.app/terms-and-conditions">terms of use</a> and <a href="https://cryptalks.app/privacy-policy">privacy policy</a> and to get CrypTalk emails & updates
            </Checkbox>)}
          </Form.Item>
        </Form>
        <RecoveryModal visible={this.state.forgotPasswordModal} close={this.forgotPasswordModal} notify={this.props.notify}/>
      </div>
    )
  }
}

export default Form.create({ name: 'login' })(LoginForm);