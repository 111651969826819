import React, {Component} from 'react';
import styled from 'styled-components';
import { Button, Col, Icon, Row, Typography, message } from 'antd'
import Request from '../utils/Request'
import Constants from "../Constants";
import CheckMark from "./CheckMark";


const {Title, Paragraph, Text} = Typography;

const HeaderWrap = styled.div`
  width: 100%;
  height: 150px;
  position: relative;
`;

const Background = styled.div`
  width: 100%;
  height: 125px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  background-color: #e8e8e8;
`;

const Avatar = styled.div`
  width: 112px;
  height: 112px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 25px;
  border: 4px solid #fff;
`;

const BodyWrap = styled.div`
  padding: 0 25px;
`

const Wrap = styled.div`
  text-align: center;
  width: 100%;
  padding: 40px;
`



class ClosedEntity extends Component {

  follow = async () => {
    const {entityAction, data} = this.props;
    entityAction(data.entity, data.id, 'follow');
  };

  render(){
    const {message, data} = this.props;
    return (
     <div style={{backgroundColor: "#fff"}}>
       <HeaderWrap>
         <Background src={data.background}/>
         <Avatar src={data.photo}/>
       </HeaderWrap>
       <BodyWrap>
         <Row>
           <Col xs={12}><Title level={4} style={{color: data.hasOwnProperty('nameColor') ? data.nameColor :Constants.primaryColor}}>{data.name} {data.checkMark && <CheckMark/>}</Title></Col>
           <Col xs={12} style={{textAlign: 'right'}}>
             {!data.blocked && <Button disabled={data.followRequestSent} shape="round" type={data.followRequestSent ? "default" : "primary"} onClick={this.follow}>{data.followRequestSent ? "Request sent" : "Send request"}</Button>}
           </Col>
         </Row>
         <Paragraph><Text style={{whiteSpace: 'pre-wrap'}}>{data.entity === 'group' ? data.motto : data.about}</Text></Paragraph>
         <Paragraph style={{margin: data.entity === 'user' ? 0 : 'inherit'}}><Icon type="calendar"/> {data.entity === 'group' ? 'Created' : 'Registration'}: {data.registered}</Paragraph>
         {data.entity === 'user' && <Paragraph><Icon type="environment" /> {data.country}</Paragraph>}
         <Text><strong>{data.followers}</strong> {parseInt(data.followers) === 1 ? 'follower' : 'followers'}</Text>
       </BodyWrap>
       <Wrap>
         <Icon style={{fontSize: 72, color: '#e3e3e3'}} type="lock" />
         <Paragraph style={{color: '#e3e3e3', fontWeight: 'bold'}}>{message}</Paragraph>
       </Wrap>
     </div>
    )
  }

}

export default ClosedEntity;