import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Spinner from "../../ui/Spinner";
import {
    Button,
    Card,
    Empty,
    Typography,
    Table,
    Input,
    InputNumber,
    Radio,
    Avatar,
    Modal,
    Form,
    message,
    Row, Col
} from "antd";
import Request from '../../utils/Request';
import styled from "styled-components";


const {Title, Text} = Typography;
const Total = styled.div`
  text-align: right;
  border-top: 1px solid #e3e3e3;
  margin: 20px 0;
  padding: 20px 0;
`;
const {TextArea} = Input;


class Cart extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            empty: false,
            cart: [],
            methods: [],
            selectedMethod: 0,
            submitLoading: false,
            shipmentAddress: null,
            fullName: null,
            email: null,
            phone: null
        }
    }

    async componentDidMount() {
        const {entity, id} = this.props.match.params;
        const cart = localStorage.getItem(`${entity}${id}cart`);
        if (cart === null) this.setState({empty: true});
        const r = await new Request('product/cart', {
            cart: JSON.parse(cart)
        }).post();
        if (r.status === 200) {
            const methods = r.data[0].product.prices.map((v) => {
                return v.crypto
            });
            this.setState({
                cart: r.data,
                methods: methods
            });
        } else {
            this.setState({empty: true})
        }
        const {isWidget} = this.props;
        if (isWidget) {
            const r = await new Request('utils/country_code').get();
            if (r.status === 200) {
                this.setState({phone: r.data.value});
            }
        }
        this.setState({loading: false});
    }

    changeAmount = (v, i) => {
        // console.log(v, i)
        const {cart} = this.state;
        cart[i].amount = v;
        this.setState({cart: cart});
    }

    calculatePrice = (prices, index) => {
        const {selectedMethod, cart} = this.state;
        const price = ((cart[index].product.discount ? (
            (prices[selectedMethod].price - prices[selectedMethod].price / 100 * cart[index].product.discount)
        ) : (
            prices[selectedMethod].price
        )) * cart[index].amount).toFixed(5);
        return `${price} ${prices[selectedMethod].crypto.name}`;
    }

    calculateTotal = () => {
        const {cart, selectedMethod, methods} = this.state;
        let total = 0;
        cart.map((v) => {
            total += (v.product.discount ? (
                v.product.prices[selectedMethod].price - v.product.prices[selectedMethod].price / 100 * v.product.discount
            ) : (
                v.product.prices[selectedMethod].price
            )) * v.amount;
            return null;
        });
        return `${total.toFixed(5)} ${methods[selectedMethod].name}`
    }

    removeItem = index => {
        const {cart} = this.state;
        cart.splice(index, 1);
        this.setState({cart: cart});
        const {entity, id} = this.props.match.params;
        const key = `${entity}${id}cart`;
        const ls = JSON.parse(localStorage.getItem(key));
        ls.splice(index, 1);
        localStorage.setItem(key, JSON.stringify(ls));
    }

    submit = async () => {
        const {cart, methods, selectedMethod, shipmentAddress, fullName, email, phone} = this.state;
        const {isWidget} = this.props;
        if (!shipmentAddress) {
            message.error('Please specify shipment address!');
            return null;
        }
        if (shipmentAddress.length < 32) {
            message.error('Please specify more your shipment address!');
            return null;
        }
        this.setState({submitLoading: true});
        const products = [];
        cart.map((v) => {
            products.push({
                id: v.product.id,
                amount: v.amount
            })
            return null;
        })
        const data = {
            products: products,
            method: methods[selectedMethod].name,
            shipment_address: shipmentAddress
        }
        if (isWidget) {
            Object.assign(data, {
                name: fullName,
                email: email,
                phone: phone
            })
        }
        const r = await new Request('terminal/submit_payment', data).post();
        if (r.status === 200) {
            const {entity, id} = this.props.match.params;
            const key = `${entity}${id}cart`;
            localStorage.removeItem(key);
            if (!isWidget) {
                this.props.history.push('/payment/' + r.data.payment_id);
            } else {
                const {goToPayment} = this.props;
                goToPayment(r.data.payment_id)
            }
        } else {
            message.error(r.data.error);
            this.setState({submitLoading: false});
        }
    }

    render() {
        const {loading, empty, cart, selectedMethod, methods, submitLoading, shipmentAddress, fullName, email, phone} = this.state;
        const {entity, id} = this.props.match.params;
        const {isWidget} = this.props;
        const columns = [
            {
                title: 'Product',
                dataIndex: 'product',
                key: 'product',
                render: item => [item.name, item.discount ? ` (-${item.discount}%)` : '']
            },
            {
                title: 'Amount',
                key: 'amount',
                render: (data, obj, index) => {
                    return <InputNumber disabled={!data.product.fixed_amount} min={1} value={data.amount} onChange={v => this.changeAmount(v, index)}/>;
                }
            },
            {
                title: 'Price',
                dataIndex: 'product',
                key: 'price',
                render: (item, data, index) => this.calculatePrice(item.prices, index)
            },
            {
                key: 'remove',
                render: (item, data, index) => {
                    return <Button onClick={() => Modal.confirm({
                        title: 'Delete item',
                        content: 'Are you sure you want to delete item from cart?',
                        okText: 'Yes',
                        cancelText: 'No',
                        onOk: () => this.removeItem(index)
                    })} icon={'delete'} size={'small'} type={'link'}/>
                }
            }
        ];
        if (loading) return <Spinner/>;
        if (empty || cart.length === 0) return <Card style={{textAlign: 'center'}}>
            <Empty description={'Cart is empty'}/>
            <Button onClick={() => this.props.history.push(`/${entity}/${id}#products`)} type={'primary'} shape={'round'} icon={'arrow-left'}>Back to store</Button>
        </Card>
        return <Card>
            <Title level={4}>Checkout:</Title>
            <Table pagination={false} size={'small'} dataSource={cart} columns={columns} style={{marginBottom: 15}}/>
            <Form>
                <Form.Item label={'Select payment method'}>
                    <Radio.Group onChange={v => this.setState({selectedMethod: v.target.value})} value={selectedMethod}>
                        {methods.map((v, i) => {
                            return <Radio value={i} key={i}>
                                <Avatar size={'small'} src={v.icon}/> {v.name}
                            </Radio>
                        })}
                    </Radio.Group>
                </Form.Item>
            </Form>
            <Form>
                {isWidget && <Row gutter={16}>
                    <Col md={24}>
                        <Form.Item label={'Full name'}>
                            <Input value={fullName} onChange={e => this.setState({fullName: e.target.value})} placeholder={'Your full name'}/>
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Item label={'E-mail'}>
                            <Input value={email} onChange={e => this.setState({email: e.target.value})} placeholder={'Your email'}/>
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Item label={'Phone'}>
                            <Input value={phone} onChange={e => this.setState({phone: e.target.value})} placeholder={'Your phone number'}/>
                        </Form.Item>
                    </Col>
                </Row>}
                <Form.Item label={'Shipment address'}>
                    <TextArea value={shipmentAddress} onChange={e => this.setState({shipmentAddress: e.target.value})} rows={4} placeholder={'Country, city, ZIP-code, address etc.'}/>
                </Form.Item>
                <Total>
                    <Title level={3} style={{margin: 0}}>Total amount:</Title>
                    <Title level={2} style={{margin: 0}}>{this.calculateTotal()}</Title>
                    <Text type={'secondary'}>Total amount might slightly change after payment submission</Text>
                </Total>
                <div style={{textAlign: 'center'}}>
                    <Button htmlType={'submit'} onClick={this.submit} loading={submitLoading} type={'primary'} size={'large'} shape={'round'}>Submit payment</Button>
                </div>
            </Form>
        </Card>
    }

}

export default withRouter(Cart);