import React, {Component} from 'react';
import styled from 'styled-components';
import { Button, Typography } from 'antd'
import Constants from '../Constants'
import { NavLink } from 'react-router-dom'


const {Paragraph, Text} = Typography;

const Wrap = styled.div`
  min-height: 165px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  padding: 4px;
  text-align: center;
`;

const Pic = styled.div`
  background-image: url("${props => props.img}");
  background-size: cover;
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  background-repeat: no-repeat;
  background-position: center;
`;


class EntitySlide extends Component {

  render(){
    const {img, title, entity, id, following, followers} = this.props;
    return (
      <NavLink to={`${entity}/${id}`}>
        <Wrap>
          <Pic img={img}/>
          <Paragraph style={{
            textAlign: 'center',
            color: Constants.primaryColor,
            margin: 0,
            fontWeight: 'bold'
          }} ellipsis>{title}</Paragraph>
          <Text style={{fontSize: '12px'}}>{followers} {followers === 1 ? 'follower' : 'followers'}</Text>
          <Button type={following ? 'default' : 'primary'} shape="round">{following ? 'Following' : 'Follow'}</Button>
        </Wrap>
      </NavLink>
    )
  }

}

export default EntitySlide;