import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Request from '../utils/Request';
import Spinner from './Spinner';
import { Button, Col, Drawer, List, message, Row, Modal } from 'antd'
import styled from 'styled-components';
import Nothing from '../template/Nothing';
import {scrolledToBottom} from '../helpers/Funcs';


const { confirm} = Modal;

const Name = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: bold;
  vertical-align: top;
`;

const MemberWrap = styled.div`
  padding: 5px;
  border-top: 1px solid #e5e5e5;
  max-height: 45px;
  
  &:last-child {
    border-bottom: 1px solid #e5e5e5;    
  }
`;

const Avatar = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-size: 100%;
  background-image: url("${props => props.src}");
  border: 1px solid #e5e5e5;
  display: inline-block;
  margin-right: 10px;
`;


class GroupMemberList extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      records: [],
      loading: true,
      recordsLoading: false,
      fullLoad: false,
      page: 1,
      rpp: 15,
      drawer: false,
      drawerData: {}
    }
  }

  getRecords = async () => {
    const {id} = this.props.match.params;
    const {list, query} = this.props;
    const {page, rpp, records} = this.state;
    let path = `group/${list}?group_id=${id}&page=${page}&rpp=${rpp}`;
    if (query) path = path + `&query=${query}`
    const req = new Request(path);
    const res = await req.get();
    if (res.status === 200) {
      if (res.data.list.length > 0) {
        this.setState({
          records: [...records, ...res.data.list],
          loading: false,
          recordsLoading: false
        });
      } else {
        this.setState({
          loading: false,
          fullLoad: true,
          recordsLoading: false
        })
      }
    } else {
      message.error(res.data.error);
      this.setState({
        records: records,
        recordsLoading: false,
        loading: false
      });
    }
  };

  async componentDidMount(){
    await this.getRecords();
    document.addEventListener('scroll', this.trackScrolling);
  }

  async componentDidUpdate(prevProps){
    const {query} = this.props;
    if (query !== prevProps.query) {
      await this.setState({
        records: [],
        page: 1,
        loading: true
      });
      await this.getRecords();
    }
  }

  componentWillUnmount(){
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = async () => {
    const wrappedElement = document.getElementById('member-list');
    if (wrappedElement){
      const {fullLoad, page} = this.state;
      if (scrolledToBottom(wrappedElement) && !fullLoad) {
        await this.setState({
          page: page+1
        });
        await this.getRecords();
      }
    }
  };

  openDrawer = (data, key) => {
    this.setState({
      drawerData: {
        key: key,
        data: data
      },
      drawer: true
    })
  };

  closeDrawer = () => {
    this.setState({
      drawer: false,
      drawerData: {}
    });
  };

  showConfirm = (action, name) => {
    let action_name = action;
    if (action === 'unfollow') action_name = 'kick';
    const {id} = this.props.match.params;
    const {drawerData} = this.state;
    if (drawerData.data.isModerator && action === 'promote') action_name = 'demote';
    if (drawerData.data.groupBlocked && action === 'block') action_name = 'unblock';
    confirm({
      title: 'Confirm action',
      content: `Do you really want to ${action_name} ${name}?`,
      onOk: async () => {
        const req = new Request(`group/${action}`, {
          group_id: id,
          user_id: drawerData.data.id
        });
        const res = await req.post();
        if (res.status === 200) {
          message.info(res.data.message);
          if (res.data.update) this.update(res.data.data, drawerData.key);
          if (res.data.remove) this.removeFromList(drawerData.key);
          this.closeDrawer();
        } else {
          message.error(res.data.error);
        }
      }
    });
  };

  update = (data, key) => {
    const {records} = this.state;
    records[key] = data;
    this.setState({
      records: records
    });
  };

  removeFromList = (key) => {
    const {records} = this.state;
    records.splice(key, 1);
    this.setState({
      records: records
    });
  };

  render(){
    const {loading, records, recordsLoading, fullLoad, drawer, drawerData} = this.state;
    const {push} = this.props.history;
    if (loading) return <Spinner/>
    if (records.length === 0) return <Nothing>Nobody is here...</Nothing>
    const listData = [];
    if (drawerData.data) {
      listData.push(
        <Button onClick={() => this.showConfirm('promote', drawerData.data.basics.name)} block style={{textAlign: 'left'}} type="link" icon={drawerData.data.isModerator ? 'arrow-down' : 'to-top'} key="promote">{drawerData.data.isModerator ? 'Demote' : 'Promote'} {`${drawerData.data.basics.name}`} {drawerData.data.isModerator ? 'from' : 'to'} moderator</Button>,
        <Button onClick={() => this.showConfirm('unfollow', drawerData.data.basics.name)} block style={{textAlign: 'left'}} type="link" icon="user-delete" key="kick">Kick {`${drawerData.data.basics.name}`}</Button>,
        <Button onClick={() => this.showConfirm('block', drawerData.data.basics.name)} block style={{textAlign: 'left'}} type="link" icon={drawerData.data.groupBlocked ? 'check-circle' : 'close-circle'} key="block">{drawerData.data.groupBlocked ? 'Unblock' : 'Block'} {`${drawerData.data.basics.name}`}</Button>,
      )
    }
    return (
      <div id="member-list">
        {records.map((v, i) => {
          return (
            <MemberWrap key={i}>
              <Row>
                <Col xs={16} onClick={() => push(`/user/${v.basics.id}`)}>
                  <Avatar src={v.basics.photo}/>
                  <Name level={4}>{v.basics.name}</Name>
                </Col>
                <Col xs={8}>
                  {!v.isAdmin && <Button icon="more" type="link" style={{
                    float: 'right',
                    zIndex: 10
                  }} onClick={() => this.openDrawer(v, i)}/>}
                </Col>
              </Row>
            </MemberWrap>
          )
        })}
        {recordsLoading && !fullLoad && <Spinner/>}
        <Drawer
          placement="bottom"
          closable={false}
          onClose={this.closeDrawer}
          visible={drawer}
          height="auto"
        >
          <List
            dataSource={listData}
            size='small'
            renderItem={item => (
              <List.Item>
                {item}
              </List.Item>
            )}
          />
        </Drawer>
      </div>
    )
  }

}

export default withRouter(GroupMemberList);