import React, {Component} from 'react';
import styled from 'styled-components';
import { Input, Button, Row, Form, Col } from 'antd';
import Request from '../utils/Request';
import 'firebase/database';
import 'firebase/auth';


const Wrap = styled.div`
  padding: 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  
  @media screen and (min-width: 768px) {
    position: absolute;
    border: 1px solid #e3e3e3;
  }
  
  @media screen and (max-width: 767px) {
    bottom: 50px;
  }
`;

class MessageInput extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      text: '',
      roomId: props.roomId,
      buttonLoad: false,
      typing: false
    }
  }

  send = async (e) => {
    e.preventDefault();
    const {roomId} = this.state;
    const {form} = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          buttonLoad: true
        });
        const req = new Request('chat/send', {
          room_unique_id: roomId,
          text: values.text
        });
        const res = await req.post();
        if (res.status === 200) {
          await this.setState({
            buttonLoad: false
          });
          form.resetFields();
        }
      }
    });
  };

  handleChange = (e) => {
    this.setState({
      text: e.target.value
    })
  };

  typing = async () => {
    const {me, roomId} = this.props;
    const {typing} = this.state;
    if (!typing) {
      new Request('chat/typing', {
        room_id: roomId,
        entity: me.entity,
        id: me.id,
        typing: true
      }).post();
      this.setState({
        typing: true
      });
      setTimeout(() => {
        this.setState({
          typing: false
        });
        new Request('chat/typing', {
          room_id: roomId,
          entity: me.entity,
          id: me.id,
          typing: false
        }).post();
      }, 2000);
    }
  };

  render () {
    const {buttonLoad} = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <Wrap>
        <Form onSubmit={this.send} className="chat-input">
          <Row>
            <Col xs={20}>
              <Form.Item>
                {getFieldDecorator('text', {
                  rules: [
                    {
                      required: true,
                      message: ' '
                    }
                  ]
                })(<Input
                    dir={'auto'}
                  autoComplete="off"
                  placeholder="Write your message"
                  onKeyUp={this.typing}
                  onFocus={() => {
                    setTimeout(() => {
                      let objDiv = document.getElementsByClassName("rce-mlist")[0];
                      if (typeof (objDiv) !== 'undefined') objDiv.scrollTop = objDiv.scrollHeight;
                    }, 250)
                  }}
                />)}
              </Form.Item>
            </Col>
            <Col xs={4} style={{textAlign: 'right'}}>
              <Button loading={buttonLoad} htmlType="submit" type="primary" shape="round" icon="right"/>
            </Col>
          </Row>
        </Form>
      </Wrap>
    );
  }

}

export default Form.create({ name: 'message' })(MessageInput);