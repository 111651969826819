import React, {Component} from "react";
import {Button, Menu, Dropdown, Icon} from "antd";

class EntityListItemOptions extends Component {

    render() {
        const {data, action} = this.props;
        const menu = (
            <Menu>
                <Menu.Item key="0">
                    <Button type={'link'} size={'small'} onClick={() => action(data.entity, data.id, 'follow')}>
                        <Icon type={data.following ? data.entity === 'user' ? 'user-delete' : 'usergroup-delete' : data.entity === 'user' ? 'user-add' : 'usergroup-add'}/>
                        {data.following ? 'Stop follow' : 'Start follow'} {data.name}
                    </Button>
                </Menu.Item>
                {data.entity === 'user' && <Menu.Item key="1">
                    <Button type={'link'} size={'small'} onClick={() => action(data.entity, data.id, 'block')}>
                        <Icon type={data.blocked ? 'check-circle' : 'stop'}/>
                        {data.blocked ? 'Unblock' : 'Block'} {data.name}
                    </Button>
                </Menu.Item>}
                {data.entity === 'user' && data.followsMe && <Menu.Item key="3">
                    <Button type={'link'} size={'small'} onClick={() => action(data.entity, data.id, 'kick')}>
                        <Icon type={'close'} /> Remove {data.name} from followers
                    </Button>
                </Menu.Item>}
            </Menu>
        );
        return <Dropdown overlay={menu} trigger={['click']}>
            <Button icon={'more'} size={'small'} type={'link'}/>
        </Dropdown>
    }

}

export default EntityListItemOptions;