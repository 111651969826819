import React, {Component} from 'react';
import { Button, Modal, Typography } from 'antd'
import RatingAvatar from '../ui/RatingAvatar';
import { NavLink } from 'react-router-dom'

const {Paragraph} = Typography;

const style = {
  margin: 0,
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 'bold'
};

class TradeOfferModal extends Component {

  render(){
    const {user, trade, decline, accept, visible} = this.props;
    return (
      <Modal
        title={`Trade request from ${user.name}`}
        visible={visible}
        onOk={accept}
        onCancel={decline}
        okText="Accept"
        cancelText="Decline"
      >
          <RatingAvatar
            entity={user}
          />
        {trade && <Paragraph style={style}>offers to {trade.data.type === 'sell' ? 'buy' : 'sell'} {trade.data.amount} {trade.data.crypto}</Paragraph>}
        <div style={{width: '50%', margin: '10px auto'}}><NavLink to={`/post/trade/${trade.id}`}><Button type="primary" shape="round" block>Go to Trade</Button></NavLink></div>
      </Modal>
    )
  }

}

export default TradeOfferModal;