import React, {Component} from 'react';
import styled from 'styled-components';
import { Button, Icon, Typography } from 'antd'


const {Text, Paragraph} = Typography;
const Wrap = styled.div`
  width: 100%;
  text-align: center;
  padding: 25px;
  color: #e3e3e3;
`


class NoResults extends Component {
  render(){
    const {query, back} = this.props;
    return (
      <Wrap>
        <Paragraph><Icon type="frown" style={{fontSize: '72px', color: '#e3e3e3'}}/></Paragraph>
        <Paragraph><Text type="secondary">No results for "{query}"</Text></Paragraph>
        <Paragraph><Button type="primary" icon="arrow-left" onClick={back}>Back</Button></Paragraph>
      </Wrap>
    );
  }
}

export default NoResults;