import React, {Component} from 'react';
import {Modal, message, Button, Typography} from 'antd';
import Request from '../utils/Request'


class PaymentModal extends Component {

  render() {
    const {visible, close, link} = this.props;
    return (
      <Modal
        title="Submit a payment"
        visible={visible}
        footer={null}
        onCancel={close}
        style={{ top: 10 }}
      >
        <iframe src={link} width={'100%'} height={500} frameborder="0"></iframe>
      </Modal>
    )
  }

}

export default PaymentModal;