import axios from 'axios';
import React from 'react';
import { Redirect } from "react-router-dom";
import Provider from './Provider';
import Constants from '../Constants'

const HOST = Constants.host;

class Request {

  constructor (path, data = {}){
    const provider = new Provider('user');
    const tokens = provider.get('tokens');
    this.path = path;
    this.data = data;
    this.headers = {
      'Auth': tokens.auth,
      'Refresh': tokens.refresh,
    };
    this.method = 'get';
  }

  static create(method, path, data = {}, headers = {}){
    if (!headers){
      const provider = new Provider('tokens');
      headers = {
        'Auth': provider.get('auth'),
        'Refresh': provider.get('refresh'),
      }
    }
    return axios({
      method: method,
      url: HOST+path,
      data: data,
      headers: headers
    }).then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  }

  get(){
    const self = this;
    this.method = 'get';
    return axios({
      method: this.method,
      url: HOST+self.path,
      headers: self.headers
    }).then(function (response) {
      return response;
    })
    .catch(function (error) {
      if (error.response.status === 401){
        return self.refresh();
      }
      return error.response;
    });
  }

  post(){
    const self = this;
    this.method = 'post';
    return axios({
      method: this.method,
      url: HOST+self.path,
      data: JSON.stringify(self.data),
      headers: self.headers
    }).then(function (response) {
      return response;
    })
    .catch(function (error) {
      if (error.response.status === 401){
        return self.refresh();
      }
      return error.response;
    });
  }

  async refresh(){
    const self = this;
    let r = await axios({
      method: 'post',
      url: HOST + 'user/get_token',
      headers: self.headers
    });
    const provider = new Provider('user');
    if (r.status === 200) {
      provider.set(r.data);
      r = await axios({
        method: this.method,
        url: HOST+self.path,
        headers: {
          'Auth': r.data.tokens.auth
        }
      });
      return r;
    } else {
      Provider.clear();
      window.location.href = '/';
      return null;
    }
  }

  static authenticate(email, password){
    return axios({
      method: 'post',
      url: HOST+'user/login',
      data: JSON.stringify({
        email: email,
        password: password
      }),
    }).then(function (response) {
      const provider = new Provider('user');
      provider.set(response.data);
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  }

  static save_device_token(){
    const provider = new Provider('user');
    const token = provider.get('tokens').auth;
    return axios({
      method: 'post',
      headers: {
        'Auth': token
      },
      url: HOST+'user/save_fcm_token',
      data: JSON.stringify({
        token: localStorage.getItem('fcmToken'),
        platform: 'web'
      }),

    }).then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  }

}

export default Request;