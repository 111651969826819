import React, {Component} from 'react';
import Request from '../../utils/Request';
import {Badge, message, Tabs, Typography} from 'antd';
import Spinner from "../../ui/Spinner";
import Constants from "../../Constants";
import EntityCard from "./EntityCard";
import PostPreview from "./PostPreview";
import SessionData from "../../utils/SessionData";


const {TabPane} = Tabs;
const {Text} = Typography;


class SearchResults extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            results: false,
            loading: true,
            postsLoading: true,
            posts: [],
            activeTab: 'users'
        }
    }

    async componentDidMount() {

        const {query} = this.props;

        const session = new SessionData(`search:${query}`);
        let results = session.get();
        if (!results) {
            const req = new Request(`app/search?query=${query}`);
            const res = await req.get();
            if (res.status === 200) {
                this.setState({
                    results: res.data,
                });
                session.set(res.data);
                results = res.data;
            } else {
                message.error(res.data.error);
            }
        } else {
            this.setState({
                results: results,
            });
        }

        const obj = {
            users: results.users ? results.users.length : 0,
            groups: results.groups ? results.groups.length : 0,
            posts: results.postCount
        };

        const activeTab = Object.keys(obj).reduce((a, b) => obj[a] > obj[b] ? a : b);

        if (activeTab === 'posts') {
            await this.loadPosts();
        }

        this.setState({
            activeTab: activeTab,
            loading: false
        });
    }

    componentWillUnmount() {
        this.props.onUnmount(window.scrollY);
    }

    loadPosts = async () => {
        const {query} = this.props;

        const session = new SessionData(`search_posts:${query}`);
        const posts = session.get();

        if (!posts) {
            const req = new Request(`app/search_posts?query=${query}`);
            const res = await req.get();
            if (res.status === 200) {
                this.setState({
                    postsLoading: false,
                    posts: res.data
                });
                session.set(res.data);
            } else {
                message.error(res.data.error);
            }
        } else {
            this.setState({
                postsLoading: false,
                posts: posts
            });
        }

    };

    tabClick = async (key) => {
        if (key === 'posts') {
            await this.loadPosts();
        }
    };

    render() {
        const {loading, results, postsLoading, posts, activeTab} = this.state;
        if (loading) return <Spinner/>;

        return (
            <div style={{backgroundColor: '#fff', padding: '0 5px'}}>
                <Tabs defaultActiveKey={activeTab} size="small" tabPosition={'right'} onTabClick={this.tabClick}>
                    {<TabPane disabled={results.users.length === 0} tab={
                        <div>
                            <Text>People</Text>{' '}
                            <Badge count={results.users.length} style={{ backgroundColor: Constants.secondaryColor }} />
                        </div>
                    } key="users">
                        {results.users.map((v, i) => {
                            return <EntityCard fullWidth={true} data={v} key={i}/>
                        })}
                    </TabPane>}
                    {<TabPane disabled={results.groups.length === 0} tab={
                        <div>
                            <Text>Groups</Text>{' '}
                            <Badge count={results.groups.length} style={{ backgroundColor: Constants.secondaryColor }} />
                        </div>
                    } key="groups">
                        {results.groups.map((v, i) => {
                            return <EntityCard fullWidth={true} data={v} key={i}/>
                        })}
                    </TabPane>}
                    {<TabPane disabled={results.postCount === 0} tab={
                        <div>
                            <Text>Posts</Text>{' '}
                            <Badge count={posts.length > 0 ? posts.length : results.postCount} style={{ backgroundColor: Constants.secondaryColor }} />
                        </div>
                    } key="posts">
                        {postsLoading ? <Spinner/> : posts.map((v, i) => {
                            return <PostPreview data={v} key={i}/>
                        })}
                    </TabPane>}
                </Tabs>
            </div>
        )
    }

}

export default SearchResults;