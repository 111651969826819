import React , { Component} from 'react';
import { Modal } from 'antd';
import DealForm from '../forms/DealForm';


class DealModal extends Component {

  push = (val) => {
    this.props.close();
    this.props.push(val);
  }

  render (){
    const {visible, close, group} = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={close}
        title="Have a deal"
        footer={null}
        className="shout-modal"
        style={{ top: 0 }}
      >
        <DealForm group={group} push={this.push} edit={false}/>
      </Modal>
    )
  }

}

export default DealModal;