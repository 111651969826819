import React, {Component} from 'react';
import SessionData from '../utils/SessionData';
import Request from '../utils/Request';
import {message, Comment, Avatar, Button, Typography, Row, Col, message as notification} from 'antd'
import {scrolledToBottom} from '../helpers/Funcs';
import Spinner from './Spinner';
import StarRatings from 'react-star-ratings';
import { NavLink } from 'react-router-dom';
import CheckMark from './CheckMark';
import styled from 'styled-components';
import ServerHost from '../helpers/ServerHost';
import Constants from '../Constants'
import RateModal from '../modals/RateModal'
import Nothing from "../template/Nothing";
import {CopyToClipboard} from "react-copy-to-clipboard";


const {Title, Text} = Typography;


const Star = styled.div`
    width: 64px;
    height: 64px;
    background-image: url("${props => ServerHost('img/icons/star.png')}");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    outline: none;
    position: relative;
    margin: 5px auto 10px;
    &:after {
      content: '${props => props.rating}';
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 47%;
      font-size: 20px;
      font-weight: bold;
    }
`;


class Feedbacks extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      records: [],
      page: 1,
      rpp: 12,
      recordsLoading: false,
      loading: true,
      recordsLoadingStarted: false,
      fullLoad: false,
      id: props.id,
      entity: props.entity,
      showRateModal: false,
      me: props.me,
      rated: props.rated,
      rating: props.rating
    }
  }

  getFeedbacks = async () => {
    const {rpp, page, records, id, entity} = this.state;
    const route = `${entity}/feedbacks?rpp=${rpp}&page=${page}&${entity}_id=${id}`;
    const session = new SessionData(route);
    if (!session.get()) {
      let req = new Request(route);
      let res = await req.get();
      if (res.status === 200) {
        if (res.data.length > 0) {
          this.setState({
            records: [...records, ...res.data],
            recordsLoading: false,
            loading: false,
            recordsLoadingStarted: false,
          });
          session.set(res.data);
        } else {
          this.setState({
            recordsLoading: false,
            loading: false,
            page: page - 1
          })
        }
      } else {
        message.warning('Oups! Something wrong happen! Please try again later...');
        return null;
      }
    } else {
      let prevPosts = [];
      for (let i = 1; i < page + 1; i++) {
        let prevRoute = `${entity}/feedbacks?rpp=${rpp}&page=${i}&${entity}_id=${id}`;
        let prevRec = new SessionData(prevRoute);
        prevPosts = [...prevPosts, ...prevRec.get()];
      }
      await this.setState({
        records: prevPosts,
        recordsLoading: false,
        recordsLoadingStarted: false,
        loading: false
      });
    }
  }

  trackScrolling = async () => {
    const wrappedElement = document.getElementById('feedbacks-list');
    if (wrappedElement){
      const {fullLoad, recordsLoadingStarted} = this.state;
      if (scrolledToBottom(wrappedElement) && !fullLoad && !recordsLoadingStarted) {
        await this.setState({
          recordsLoading: true,
          recordsLoadingStarted: true,
          page: this.state.page+1
        });
        await this.getFeedbacks();
      }
    }
  };

  async componentDidMount(){
    document.addEventListener('scroll', this.trackScrolling);
    const page = new SessionData(`page:feedbacks`)
    await this.setState({
      page: page.get() ? page.get() : 1
    });
    await this.getFeedbacks();
    window.scrollTo(0, new SessionData(`scroll:feedbacks`).get());
  }

  componentWillUnmount() {
    const offset = window.pageYOffset;
    const session = new SessionData(`scroll:feedbacks`);
    session.set(offset);
    const page = new SessionData(`page:feedbacks`)
    page.set(this.state.page);
    document.removeEventListener('scroll', this.trackScrolling);
  }

  add = (item) => {
    const {records, rating} = this.state;
    records.unshift(item);
    let newRating = item.rating;
    if (rating > 0) {
      newRating = ((rating + item.rating) / 2).toFixed(1);
    }
    this.setState({
      records: records,
      rated: true,
      rating:newRating
    })
  };

  share = async () => {
    const {data} = this.props;
    let text;
    let title;
    const url = `${Constants.client}${data.entity}/${data.id}`;
    if (data.entity === 'user') {
      title = data.name;
      text = data.basics.about;
    } else {
      title = data.basics.name;
      text = data.about
    }
    const shareData = {
      title: title,
      text: text,
      url: url
    }
    await navigator.share(shareData);
    // console.log(shareData);
  };

  render() {
    const {records, loading, recordsLoading, fullLoad, showRateModal, me, rated, rating} = this.state;
    const {data, entity, setMeetAbout, entityAction} = this.props;
    const link = `${Constants.client}${data.entity}/${data.id}`;
    if (loading) return <Spinner/>;
    return (
      <div id="feedbacks-list">
        {entity === 'user' ? (
            <Row gutter={16}>
              <Col md={7} style={{textAlign: 'center'}}>
                <Title level={3} style={{color: Constants.primaryColor, borderBottom: '1px solid ' + Constants.primaryColor}}>Meet me</Title>
                <StarRatings
                    rating={data.basics.rating}
                    starRatedColor={Constants.orange}
                    numberOfStars={5}
                    name='rating'
                    starEmptyColor="#000"
                    starDimension="25px"
                    starSpacing="4px"
                />
                {!me && !rated && <Button onClick={() => {
                  this.setState({
                    showRateModal: true
                  })
                }} type="default" shape="round" block style={{marginBottom: 10}}>Rate</Button>}
                {!me && !data.chatRequestSent && !data.chatAllowed && <Button
                    onClick={() => entityAction(data.entity, data.id, 'send_request', 'chat')}
                    type={'primary'}
                    shape={'round'}
                    block>
                  Ask To Chat
                </Button>}
                {window.innerWidth > 1024 ? (
                    <CopyToClipboard
                        key="share"
                        onCopy={() => notification.info('Link copied to clipboard!')}
                        text={link}
                    >
                      <Button
                          block
                          type="link"
                          icon="share-alt">
                        Share profile
                      </Button>
                    </CopyToClipboard>
                ) : (
                    <Button
                        block
                        onClick={this.share}
                        type="link"
                        icon="share-alt">
                      Share profile
                    </Button>
                )}
              </Col>
              <Col md={17}>
                <Text editable={me ? {onChange: setMeetAbout} : false}>{data.meet_about}</Text>
              </Col>
            </Row>
        ) : <div style={{
          textAlign: 'center',
          width: '50%',
          margin: '10px auto'
        }}>
          <Title level={3} style={{color: Constants.primaryColor, margin: 0}}>Rating:</Title>
          <Star rating={rating}/>
          {!me && !rated && <Button onClick={() => {
            this.setState({
              showRateModal: true
            })
          }} type="primary" shape="round" block>Show Love</Button>}
        </div>}
        <div>
          {records.map((v, i) => {
            return  <Comment
              key={i}
              actions={[
                <StarRatings
                  rating={v.rating}
                  starRatedColor={Constants.orange}
                  numberOfStars={5}
                  name='rating'
                  starEmptyColor="#000"
                  starDimension="15px"
                  starSpacing="3px"
                />
              ]}
              author={<NavLink style={{color: v.from.nameColor}} to={`/user/${v.from.id}`}>
                {v.from.name}
                {v.from.checkMark && <CheckMark/>}
              </NavLink>}
              avatar={
                <NavLink style={{color: v.from.nameColor}} to={`/user/${v.from.id}`}>
                  <Avatar
                    src={v.from.photo}
                    alt={v.from.name}
                  />
                </NavLink>
              }
              content={<p>{v.comment}</p>}
              datetime={v.date}
            />
          })}
        </div>
        {recordsLoading && !fullLoad && <Spinner/>}
        <RateModal
          visible={showRateModal}
          entity={data}
          remove={() => {return null}}
          close={() => this.setState({
            showRateModal: false
          })}
          add={this.add}
        />
      </div>
    )
  }

}

export default Feedbacks;