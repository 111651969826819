import React, {Component} from 'react';
import {Button, Dropdown, Menu, message, Modal} from "antd";
import Request from "../../utils/Request";


const {confirm} = Modal;


class ChatOptions extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            data: props.data
        }
    }

    block = () => {
        const {data} = this.state;
        const {goBack, action} = this.props;
        confirm({
            title: 'Confirm action',
            content: `Do you really want to ${data.blocked ? 'unblock' : 'block'} ${data.name}? Your conversation will be deleted in this case.`,
            onOk: () => {
                action('user', data.id, 'block');
                goBack();
            }
        })
    };

    share = () => {
        const {data} = this.state;
        confirm({
            title: 'Confirm action',
            content: `Do you really want to share your personal information with ${data.name}? NOTE: ${data.name} will have to accept your share information request. Till that time, neither ${data.name} nor you won't be able to see personal contact information of each other.`,
            onOk: async () => {
                const req = new Request('user/send_request', {
                    type: 'info',
                    user_id: data.id
                });
                const res = await req.post();
                if (res.status === 200) {
                    message.info(res.data.message);
                } else {
                    message.error(res.data.error);
                }
            }
        })
    };

    render () {
        const {report} = this.props;
        const {data} = this.state;
        const menu = (
            <Menu>
                <Menu.Item key="1">
                    <Button type={'link'} onClick={report} icon={'warning'}>Report {data.name}</Button>
                </Menu.Item>
                {data.entity === 'user' &&  <Menu.Item key="2">
                    <Button type={'link'} onClick={this.block} icon={data.blocked ? "check-circle" : "close-circle"}>{data.blocked ? 'Unblock' : 'Block'} {data.name}</Button>
                </Menu.Item>}
                {!data.infoShared && data.entity === 'user' && <Menu.Item key="3">
                    <Button type={'link'} onClick={this.share} icon={'user-add'}>Add {data.name} to contacts</Button>
                </Menu.Item>}
            </Menu>
        );
        return (
            <div className={'chat-options'}>
                <Dropdown overlay={menu} trigger={['click']} className={'chat-options'} placement="bottomRight">
                    <Button type="link" icon="more" />
                </Dropdown>
            </div>
        );
    }

}

export default ChatOptions;