import styled from 'styled-components';
import Constants from '../Constants';


const BlueFrame = styled.div`
  border: 1px solid ${Constants.primaryColor};
  border-radius: 20px;
  width: 90%;
  height: 100%;
  margin: 15px auto;
  padding: 15px;
  background-color: #fff;
`

export default BlueFrame;