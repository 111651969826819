import React, {Component} from 'react';
import { Spin, Icon } from 'antd';
import styled from 'styled-components';


const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(255,255,255,.7);
  top: 0;
  left: 0;
  z-index: 99;
  & div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

class Spinner extends Component {

  render(){
    return <Wrapper><Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} /></Wrapper>;
  }

}

export default Spinner;

