import styled from 'styled-components';
import ServerHost from '../../helpers/ServerHost';


const WhiteBackground = styled.div`
    width: 100%;
    min-height: 100vh;
    background-image: url("${ServerHost('img/backgrounds/white.png')}");
    background-size: cover;
`

export default WhiteBackground;