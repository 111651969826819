import React, {Component} from 'react';
import Logo from './Logo';
import { NavLink, withRouter } from 'react-router-dom'
import styled from 'styled-components';
import {Badge, Icon, Button, message, Typography} from 'antd';
import ExtraTools from './ExtraTools';
import Constants from "../../Constants";
import TradeModal from "../../modals/TradeModal";
import DealModal from "../../modals/DealModal";
import ShoutModal from "../../modals/ShoutModal";
import Request from '../../utils/Request';


const Menu = styled.ul`
  padding: 10px;
  list-style-type: none;
  background-color: #fff;
  width: 95%;
  float: right;
  border-radius: 20px;
  border: 1px solid #329ab7;
`;

const ButtonsWrap = styled.div`
  padding: 15px;
  
  button {
    margin-bottom: 10px;
    border-color: ${Constants.primaryColor};
    color: ${Constants.primaryColor};
    font-weight: bold;
  }
`;

const Item = styled.li`
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
  
  a, button {
    color: #000;
    padding: 10px 15px;
    border-radius: 20px;
    background-color: transparent;
    border: none;
    font-weight: bold;
    
    &:focus {
      outline: none;
    }
    
    &:hover {
      background-color: rgba(48, 154, 183, .3);
      color: #309AB7;
      cursor: pointer;
    }
    
    @media screen and (max-width: 767px) {
      padding: 0 !important;
    }
    
    i {
      margin-right: 10px;
      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }
  }
  
  &:first-child {
    margin: 0 0 10px;
  }
  
  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;


const Avatar = styled.div`
  position: absolute;
  left: 33px;
  width: 28px;
  height: 28px;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  background-position: center, center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  @media screen and (max-width: 767px) {
    position: relative;
    left: 0;
  }
`;


const {Paragraph, Title} = Typography;


class Navigation extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      width: window.innerWidth,
      popover: false,
      tradeModal: false,
      shoutModal: false,
      dealModal: false,
      balance: null
    }
  }

  updateResize = () => {
    this.setState({
      width: window.innerWidth
    })
  };

  async componentDidMount() {
    window.addEventListener('resize', this.updateResize);
    const {url} = this.props.match;
    this.setState({
      active: url
    });
    const r = await new Request('user/balance').get();
    if (r.status === 200) {
      this.setState({
        balance: r.data.balance
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateResize);
  }

  componentDidUpdate(prevProps) {
    const {url} = this.props.match;
    const oldUrl = prevProps.match.url;
    if (oldUrl !== url) {
      this.setState({
        active: url
      })
    }
  }

  modal = (type) => {
    this.setState({
      [type]: !this.state[type],
    })
  };

  push = (data) => {
    const {push} = this.props;
    const elements = [
      `Your ${data.postType} sent successfully. `,
      <Button onClick={() => {
        this.props.history.push(`/post/${data.postType}/${data.id}`);
        message.destroy();
      }} type="primary" shape='round' style={{fontWeight: 'bold'}}>Check it out!</Button>
    ];
    message.success(elements);
    push(data);
  };

  render () {
    const {width, popover, tradeModal, shoutModal, dealModal, balance} = this.state;
    const {avatar, newNotifications, newMessages, provider, payment} = this.props;
    return (
      <div style={{position: 'fixed', top: 0, width: 'inherit', maxWidth: '250px'}}>
        <NavLink to="/feed" className={'desktop-logo'}>
          <Logo shrinked={width < 768}/>
          <Paragraph style={{
            textAlign: 'center',
            color: Constants.primaryColor,
            fontWeight: 'bold'
          }}>True. Crypto. Community.</Paragraph>
        </NavLink>
        <Menu className={'app-navigation'}>
          <Item><NavLink to="/feed"><Icon type="home"/> {width > 767 && 'Home'}</NavLink></Item>
          <Item><NavLink to="/popular"><Icon type="search"/> {width > 767 && 'Explore'}</NavLink></Item>
          <Item>
            <NavLink to="/notifications"><Icon type="bell"/> {width > 767 && 'Notifications'}</NavLink>
            <Badge
                count={newNotifications}
                style={{ backgroundColor: Constants.secondaryColor, color: '#fff'}}
            />
          </Item>
          <Item>
            <NavLink to="/chat"><Icon type="mail"/> {width > 767 && 'Messages'}</NavLink>
            <Badge
                count={newMessages}
                style={{ backgroundColor: Constants.secondaryColor, color: '#fff'}}
            />
          </Item>
          {/*<Item><NavLink to="/contacts"><Icon type="phone"/> {width > 767 && 'Contacts'}</NavLink></Item>*/}
          <Item><NavLink isActive={(match, location) => {return location.pathname === `/user/${provider.profile.id}/groups`}} to={`/user/${provider.profile.id}/groups`}><Icon type="team"/> {width > 767 && 'Groups'}</NavLink></Item>
          <Item><NavLink isActive={(match, location) => {return location.pathname === `/user/${provider.profile.id}`}} to={`/user/${provider.profile.id}`} style={{paddingLeft: 48}}><Avatar src={avatar}/> {width > 767 && 'Profile'}</NavLink></Item>
          <Item>
            <ExtraTools
              visibleChange={() => {this.setState({popover: !popover})}}
              visible={popover}
              width={width}
              provider={provider}
              payment={payment}
            />
          </Item>
        </Menu>
        <ButtonsWrap>
          {balance > 0 && <Button type={'primary'} style={{color: '#fff'}} size={'large'} shape={'round'} block>Balance: ${balance}</Button>}
          <Button type={'default'} size={'large'} shape={'round'} block onClick={() => this.modal('shoutModal')}>Tell the world</Button>
          {/*<Button type={'default'} size={'large'} shape={'round'} block onClick={() => this.modal('tradeModal')}>Trade Coins</Button>*/}
          {/*<Button type={'default'} size={'large'} shape={'round'} block onClick={() => this.modal('dealModal')}>Have a Deal</Button>*/}
        </ButtonsWrap>
        <TradeModal
            visible={tradeModal}
            close={() => this.modal('tradeModal')}
            push={this.push}
            group={null}
        />
        <ShoutModal
            visible={shoutModal}
            close={() => this.modal('shoutModal')}
            push={this.push}
            group={null}
            key={'navigation'}
        />
        <DealModal
            visible={dealModal}
            close={() => this.modal('dealModal')}
            push={this.push}
            group={null}
        />
      </div>
    )
  }

}

export default withRouter(Navigation);