import React, {Component} from 'react';
import { Row, Col, Avatar, Form, Button, Input, Icon } from 'antd'
import Provider from '../utils/Provider';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 15px 0 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    background-color: #fff;
    z-index: 1;
    @media screen and (max-width: 767px) {
      bottom: 40px;
    }
`


class CommentForm extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      loading: false
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {form, send} = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        send(values.text);
        form.resetFields();
      }
    });
  };

  render(){
    const profile = new Provider('user').get('profile').basics;
    const { getFieldDecorator } = this.props.form;
    const { reply } = this.props;
    return (
      <Wrapper>
        <Row>
          <Form onSubmit={this.handleSubmit}>
            <Col xs={4} style={{textAlign: 'right', padding: '7px'}}>
              <Avatar src={profile.photo} title={profile.name}/>
            </Col>
            <Col xs={16}>
              <Form.Item>
                {getFieldDecorator('text', {
                  rules: [
                    {
                      required: true,
                      message: 'Please write a comment',
                    }
                  ],
                })(<Input.TextArea
                  placeholder={reply ? `Reply to ${reply.name}...` : 'Post a comment...'}
                  autoSize={{minRows: 2, maxRows: 6}}
                  dir={'auto'}
                />)}
              </Form.Item>
            </Col>
            <Col xs={4} style={{textAlign: 'left', padding: '7px'}}>
              <Form.Item>
                <Button
                  loading={this.state.loading}
                  shape="round"
                  type="primary"
                  htmlType="submit"
                >
                  <Icon type="right"/>
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      </Wrapper>
    )
  }

}

export default Form.create({ name: 'comment' })(CommentForm);