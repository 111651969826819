import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Modal} from "antd";
import ProductForm from "../forms/ProductForm";


class ProductModal extends Component {

    async componentDidMount() {
        this.setState({
            loading: false,
            reset: () => {return null}
        });
    }

    setResetFields = async (func) => {
        await this.setState({
            reset: () => func()
        })
    }

    onClose = async () => {
        const {reset} = this.state;
        const {close} = this.props;
        await reset();
        await close();
    }

    render (){
        const {visible, entity, id, edit, push, data, update, index, close} = this.props;
        return (
            <Modal
                visible={visible}
                onCancel={close}
                title={edit ? 'Edit a product' : 'Post a product'}
                footer={null}
                className="shout-modal"
                style={{ top: 20 }}
            >
                <ProductForm
                    entity={entity}
                    id={id}
                    edit={edit}
                    push={push}
                    data={data}
                    update={update}
                    index={index}
                    setResetFields={this.setResetFields}
                    close={this.onClose}
                />
            </Modal>
        )
    }
}

export default withRouter(ProductModal);