import React, {Component} from 'react';
import {Form, Icon, Input, Button, Checkbox, message} from 'antd';
import Paragraph from '../template/Paragraph';
import Request from '../utils/Request';
import GoogleButton from '../socials/GoogleButton';
import FacebookButton from '../socials/FacebookButton';
import ReCAPTCHA from "react-google-recaptcha";


class RegistrationForm extends Component {

  constructor(props, context){
    super(props, context);
    this.state = {
      termsAccepted: true,
      loading: false,
      captcha: null
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {captcha} = this.state;
    if (!captcha) {
      message.error('Please, confirm you are not a robot!')
      return null;
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let req = new Request('user/register', {
          name: values.name,
          email: values.email,
          password: values.password,
          promo: sessionStorage.getItem('promo') ? sessionStorage.getItem('promo') : null,
          src: sessionStorage.getItem('affiliateSource') ? sessionStorage.getItem('affiliateSource') : '',
          captcha: captcha
        });
        let res = await req.post()
        if (res.status === 200){
          this.props.saveData(res.data);
        } else {
          this.props.notify('error', res.data.error);
          this.setState({
            loading: false
          });
        }
      }
    });
  };

  render(){
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('name', {
              rules: [
                { required: true, message: 'Please put your full name!' },
              ],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Full name"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Please put your e-mail!' },
                { type: 'email', message: 'E-mail is not valid!' },
              ],
            })(
              <Input
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="E-mail"
              />,
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please put your Password!' }],
            })(
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
              />,
            )}
          </Form.Item>
          <Form.Item>
            <ReCAPTCHA
                sitekey={'6LfQXW4dAAAAAKvr_0Ng1ey9HZywATZAUDGGUxmn'}
                onChange={v => this.setState({captcha: v})}
                size="normal"
                className="captcha"
                badge={'inline'}
                render="explicit"
            />
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('terms', {
              valuePropName: 'checked',
              initialValue: this.state.termsAccepted,
            })(<Checkbox onChange={async () => {
              this.setState({
                termsAccepted: !this.state.termsAccepted
              })
            }}>
              By signing up you agree to our <a target="_blank" rel="noopener noreferrer" href="https://cryptalks.app/terms-and-conditions">terms of use</a> and <a target="_blank" rel="noopener noreferrer" href="https://cryptalks.app/privacy-policy">privacy policy</a> and to get CrypTalk emails & updates
            </Checkbox>)}
          </Form.Item>
          <Form.Item>
            <Paragraph>
              <Button
                loading={this.state.loading}
                disabled={!this.state.termsAccepted}
                // shape="round"
                // block
                type="primary"
                htmlType="submit">
                Sign up
              </Button>
            </Paragraph>
            <Button
              type="link"
              onClick={this.props.switch}
              block
            >
              Already have an account? Log in!
            </Button>
          </Form.Item>
          <Form.Item>
            <Paragraph>
              <FacebookButton
                disabled={!this.state.termsAccepted}
                callback={this.props.responseFacebook}
              />
            </Paragraph>
            <Paragraph>
              <GoogleButton
                disabled={!this.state.termsAccepted}
                callback={this.props.responseGoogle}
              />
            </Paragraph>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default Form.create({ name: 'registration' })(RegistrationForm);