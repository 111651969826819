import React, {Component} from 'react';
import { Col, Form, Row, InputNumber, Typography, Button, Input, message } from 'antd';
import Constants from '../Constants';
import { CopyToClipboard } from 'react-copy-to-clipboard'


const {Title} = Typography;


class OrderForm extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      amount: 1,
      data: props.data,
    }
  }

  handleChange = async (e) => {
    await this.setState({
      amount: e
    });
  };

  render(){
    const {amount, data} = this.state;
    const cryptoPrice = (data.cryptoPrice * amount).toFixed(5);
    return <Form>
      <Form.Item label="Total products">
        <Row>
          <Col xs={18}>
            <Title style={{color: Constants.primaryColor}} level={4}>{data.title}</Title>
          </Col>
          <Col xs={6}>
            <InputNumber
              value={amount}
              disabled={data.fixed_amount}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label="Total price">
        <div style={{textAlign: 'center'}}>
          <Title style={{color: Constants.primaryColor}} level={4}>{data.price * amount} {data.currency}</Title>
        </div>
      </Form.Item>
      <Form.Item label="Price in crypto">
        <div style={{textAlign: 'center'}}>
          <Title style={{color: Constants.primaryColor}} level={4}>{cryptoPrice} {data.crypto}</Title>
          <CopyToClipboard
            onCopy={() => message.info('Crypto price was copied!')}
            text={cryptoPrice}
          >
            <Button size="small" type="danger" shape="round">Copy price</Button>
          </CopyToClipboard>
        </div>
      </Form.Item>
      <Form.Item label="Shop wallet">
        <div style={{textAlign: 'center'}}>
          <Input value={data.walletAddress} />
        </div>
      </Form.Item>
      <Form.Item>
        <div style={{textAlign: 'center'}}>
          <CopyToClipboard
            onCopy={() => message.info('Wallet address was copied!')}
            text={data.walletAddress}
          >
            <Button size="small" type="danger" shape="round">Copy wallet</Button>
          </CopyToClipboard>
        </div>
      </Form.Item>

    </Form>
  }

}

export default OrderForm;