import React, {Component} from 'react';
import { Modal } from 'antd';
import ShoutForm from '../forms/ShoutForm';


class ShoutModal extends Component {

  constructor(props, context){
    super(props, context);
    this.state = {
      form: 'shout'
    }
  }

  changeForm = (e) => {
    this.setState({
      form: e.target.value
    })
  };

  push = (val) => {
    this.props.close();
    this.props.push(val);
  };

  render (){
    const {visible, close, group} = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={close}
        title="Share with the world"
        footer={null}
        className="shout-modal"
        style={{ top: 20 }}
      >
        <ShoutForm close={close} group={group} edit={false} push={this.push}/>
      </Modal>
    )
  }

}

export default ShoutModal;