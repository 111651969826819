import React, {Component} from 'react';
import {Button, Dropdown, Icon, Menu, message} from "antd";
import {CopyToClipboard} from "react-copy-to-clipboard";
import ShoutForm from "../../forms/ShoutForm";
import EventForm from "../../forms/EventForm";
import TradeForm from "../../forms/TradeForm";
import SignalForm from "../../forms/SignalForm";
import DealForm from "../../forms/DealForm";
import Constants from "../../Constants";


class PostOptions extends Component {

    update = (index, value) => {
        const {update} = this.props;
        update(value.postType, value.id, value);
    };

    delete = () => {
        const {remove, data} = this.props;
        remove(data.postType, data.id);
    };

    share = async () => {
        const {data} = this.props;
        let text;
        let title;
        const url = `${Constants.client}post/${data.postType}/${data.id}`;
        switch (data.postType) {
            case 'shout':
                if (data.user){
                    title = data.user.name;
                } else {
                    title = data.group.name;
                }
                text = data.data.text;
                break;
            case 'deal':
                title = data.data.title;
                text = data.data.description + "\n" + `${data.data.price} ${data.data.currency}`;
                break;
            case 'trade':
                if (data.user){
                    title = data.user.name;
                } else {
                    title = data.group.name;
                }
                text = data.data.string;
                break;
        }

        const shareData = {
            title: title,
            text: text,
            url: url
        }
        await navigator.share(shareData);
    };

    render(){
        const {openUpdateModal, report, data, entityAction} = this.props;
        let menu;
        let form;
        switch (data.postType) {
            case 'shout':
                form = <ShoutForm edit={true} data={data} update={this.update} />;
                break;
            case 'event':
                form = <EventForm edit={true} data={data} update={this.update}/>;
                break;
            case 'trade':
                form = <TradeForm edit={true} data={data} update={this.update}/>;
                break;
            case 'signal':
                form = <SignalForm edit={true} data={data} update={this.update}/>;
                break;
            case 'deal':
                form = <DealForm edit={true} data={data} update={this.update}/>;
                break;
            default:
                return null;
        }
        const link = `${Constants.client}post/${data.postType}/${data.id}`;
        if (data.owner) {
            menu = (
                <Menu>
                    <Menu.Item key="1">
                        <Button onClick={() => openUpdateModal(data.postType, form)} block style={{textAlign: 'left'}} type="link" icon="edit" key="update">Edit</Button>
                    </Menu.Item>
                    <Menu.Item key="2">
                        {window.innerWidth > 1024 ? (
                            <CopyToClipboard
                                key="share"
                                onCopy={() => message.info('Link copied to clipboard!')}
                                text={link}
                            >
                                <Button
                                    block
                                    style={{textAlign: 'left'}}
                                    type="link"
                                    icon="share-alt">
                                    Share {data.postType}
                                </Button>
                            </CopyToClipboard>
                        ) : (
                            <Button
                                block
                                onClick={this.share}
                                style={{textAlign: 'left'}}
                                type="link"
                                icon="share-alt">
                                Share {data.postType}
                            </Button>
                        )}
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Button onClick={this.delete} block style={{textAlign: 'left'}} type="link" icon="delete" key="delete">Delete</Button>
                    </Menu.Item>
                </Menu>
            );
        } else {
            menu = (
                <Menu>
                    <Menu.Item key="1">
                        {window.innerWidth > 1024 ? (
                            <CopyToClipboard
                                key="share"
                                onCopy={() => message.info('Link copied to clipboard!')}
                                text={link}
                            >
                                <Button
                                    block
                                    style={{textAlign: 'left'}}
                                    type="link"
                                    icon="share-alt">
                                    Share {data.postType}
                                </Button>
                            </CopyToClipboard>
                        ) : (
                            <Button
                                block
                                onClick={this.share}
                                style={{textAlign: 'left'}}
                                type="link"
                                icon="share-alt">
                                Share {data.postType}
                            </Button>
                        )}

                    </Menu.Item>
                    <Menu.Item key="2">
                        <Button onClick={() => entityAction(data.entity, data[data.entity].id, 'follow')} block style={{textAlign: 'left'}} type="link" icon={
                            data.entity === 'user' ? data[data.entity].following ?  "user-delete" : 'user-add' : data[data.entity].following ? "usergroup-delete" : 'usergroup-add'
                        } key="follow">{data[data.entity].following ? 'Stop follow' : 'Start follow'} {`${data[data.entity].name}`}</Button>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Button onClick={() => entityAction(data.entity, data[data.entity].id, 'mute')} block style={{textAlign: 'left'}} type="link" icon="sound" key="mute">
                            {data[data.entity].muted ? 'Take off mute from' : 'Mute'} {`${data[data.entity].name}`}
                        </Button>
                    </Menu.Item>
                    <Menu.Item key="4">
                        <Button onClick={() => report(link)} block style={{textAlign: 'left'}} type="link" icon="warning" key="report">Report {`${data[data.entity].name}`}</Button>
                    </Menu.Item>
                    <Menu.Item key="5">
                        {
                            data.entity === 'user' && <Button onClick={() =>entityAction(data.entity, data[data.entity].id, 'block')} block style={{textAlign: 'left'}} type="link" icon={data.user.blocked ? "check-circle" : 'close-circle'} key="block">{data.user.blocked ? 'Unblock' : 'Block'} {`${data.user.name}`}</Button>
                        }
                    </Menu.Item>
                </Menu>
            )
        }
        return (
            <Dropdown overlay={menu} trigger={['click']} className={'post-options'} placement="bottomRight">
                <Button size="small" type="link"><Icon style={{width: 'auto'}} type="more"/></Button>
            </Dropdown>
        );
    }

}

export default PostOptions;