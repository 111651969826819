import React, {Component} from 'react';
import styled from 'styled-components';
import PopularItems from "../../ui/PopularItems";
import {Input, Typography} from "antd";
import PostPreview from "./PostPreview";

const {Title} = Typography;
const {Search} = Input;

const Wrap = styled.div`
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
  background-color: #fff;
`;

const PostsWrap = styled.div`
    padding: 0 15px;
`;

const MobileOptions = styled.div`
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    padding: 15px 15px 0;
  }
`;


class Explore extends Component {

    componentWillUnmount() {
        this.props.onUnmount(window.scrollY);
    }

    search = (e) => {
        const {search} = this.props;
        search(e);
    };

    render(){
        const {posts, entities} = this.props;
        return (
            <Wrap>
                <MobileOptions>
                    <Search
                        placeholder="Search @ CrypTalks"
                        onSearch={value => this.search(value)}
                        style={{ width: '100%', marginBottom: 20 }}
                        dir={'auto'}
                    />
                </MobileOptions>
                {entities && entities.users.length > 0 && <PopularItems items={entities.users} title="Interesting People"/>}
                {entities && entities.groups.length && <PopularItems items={entities.groups} title="Popular groups"/>}
                <Title style={{padding: '0 15px', margin: '10px 0'}} level={4}>Trending Talks</Title>
                {posts && <PostsWrap>
                    {posts.map((v, i) => {
                        return <PostPreview data={v} key={i}/>
                    })}
                </PostsWrap>}
            </Wrap>
        );
    }

}

export default Explore;