import React, {Component} from 'react';
import { Row, Col, Icon } from 'antd';
import Centerized from '../template/Centerized';
import BlueFrame from '../template/BlueFrame';
import Animation from '../template/Animation';
import Logo from '../template/Logo';
import LoginForm from '../forms/LoginForm'
import { Typography } from 'antd';
import Market from '../template/Market';
import styled from 'styled-components';
import Constants from '../Constants';
import RegistrationForm from '../forms/RegistrationForm';


const { Title } = Typography;


const MarketsWrap = styled.div`
  width: 50%;
  margin: 15px auto;
`;

const LinksWrap = styled.div`
  width: 55%;
  margin: 40px auto;
  text-align: center;
  & a {
    color: #A9A9A9 !important;
    display: inline-block;
    font-size: 12px;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  & .socials {
    margin: 20px 0;
    
    & a {
      font-size: 20px;
      color: #ffffff !important;
      background-color: #fff !important;
      border: 1px solid #555;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 0 5px;
      svg {
        color: #555;
      }
    }
  }
`;


class LoginScreen extends Component {

  constructor (props, context){
    super(props, context);
    this.state = {
      loginForm: true
    }
  }

  switchForm = () => {
    this.setState({
      loginForm: !this.state.loginForm
    });
  };

  componentDidMount() {
    const qs = window.location.search.replace('?','');
    const qs_params = qs.split('&');
    qs_params.map((v) => {
      const kv = v.split('=');
      sessionStorage.setItem(kv[0], kv[1]);
      return null;
    })
  }

  render(){
    return (
      <Row>
        <Col lg={10} sm={24}>
          <Centerized>
            <BlueFrame>
              <Logo path={'cryptalks'} width={75} height={225}/>
              <Title style={{color: Constants.primaryColor}}>Welcome {this.state.loginForm ? 'Back' : 'Home'}!</Title>
              <Title style={{color: Constants.primaryColor}} level={4}>True. Crypto. Community</Title>
              {this.state.loginForm ?
                <LoginForm
                  notify={this.props.notify}
                  saveData={this.props.saveData}
                  responseGoogle={this.props.responseGoogle}
                  responseFacebook={this.props.responseFacebook}
                  switch={this.switchForm}
                  params={this.props.params}
                /> :
                <RegistrationForm
                  notify={this.props.notify}
                  saveData={this.props.saveData}
                  responseGoogle={this.props.responseGoogle}
                  responseFacebook={this.props.responseFacebook}
                  switch={this.switchForm}
                  params={this.props.params}
                />}
            </BlueFrame>
          </Centerized>
        </Col>
        <Col className="mobile-hide" lg={12} sm={24}>
          <Animation/>
          <MarketsWrap>
            <Row>
              <Col lg={12}>
                <a href="https://itunes.apple.com/ua/app/cryptalk-app/id1403260955?l=ru&mt=8"><Market ios/></a>
              </Col>
              <Col lg={12}>
                <a href="https://play.google.com/store/apps/details?id=com.cryptalkgroup.cryptalk"><Market android/></a>
              </Col>
            </Row>
          </MarketsWrap>
          <LinksWrap>
            <a href="https://cryptalks.app/#about">About us</a>{' • '}
            <a href="https://cryptalks.app/privacy-policy">Privacy Policy</a>{' • '}
            <a href="https://cryptalks.app/faq">FAQ</a>{' • '}
            <a href="https://cryptalks.app/terms-and-conditions">Terms and Conditions</a>
            <div className="socials">
              <a title="YouTube" href="https://www.youtube.com/channel/UC_bVFaNVWlBL2_0i79VdHlw?view_as=subscriber"><Icon type="youtube"/></a>{' '}
              <a title="LinkedIn" href="https://www.linkedin.com/company/cryptalk-app/?viewAsMember=true"><Icon type="linkedin"/></a>{' '}
              <a title="Medium" href="https://medium.com/@cryptalk_app"><Icon type="medium"/></a>{' '}
              <a title="Facebook" href="https://www.facebook.com/cryptalkapp/?eid=ARCA4_IX4t0Y5immiNOod5aLakBncN2ta_h97DEK3rmmfdO7drHwI5tikCawGX0rAV8UzEps85ILPVVI"><Icon type="facebook"/></a>
            </div>
          </LinksWrap>
        </Col>
      </Row>
    )
  }

}

export default LoginScreen;
