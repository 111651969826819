import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Request from "../utils/Request";
import Spinner from "./Spinner";
import {
    Avatar,
    Button,
    Checkbox,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Table,
    Tabs,
    Tooltip,
    Typography
} from "antd";
import styled from "styled-components";


const {TabPane} = Tabs;
const TabsWrapper = styled.div`
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: left;
  }

  .ant-tabs-content {padding: 0}
`;
const Total = styled.div`
  padding: 15px 0;
  border-top: 1px solid #e3e3e3;
  text-align: right;
`
const {Paragraph, Text} = Typography


class Wallet extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            data: null,
            withdrawModal: false,
            withdrawCurrency: null,
            withdrawAmount: 0,
            withdrawLoading: false,
            activePayment: null,
            trackingNumberModal: false,
            trackingNumberLoading: false,
            buttonLoading: false
        }
    }

    async componentDidMount() {
        const {id} = this.props.match.params;
        if (typeof id === 'undefined') {
            const r = await new Request('user/wallet').get();
            if (r.status === 200) {
                this.setState({
                    data: r.data,
                    loading: false
                });
            }
        } else {
            const r = await new Request('group/wallet?id='+id).get();
            if (r.status === 200) {
                this.setState({
                    data: r.data,
                    loading: false
                });
            }
        }
    }

    withdraw = async e => {
        e.preventDefault();
        this.setState({withdrawLoading: true})
        const {form} = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const {withdrawCurrency, data} = this.state;
                values.crypto = data.balances[withdrawCurrency].crypto.name;
                const {id} = this.props.match.params;
                let r = null;
                if (typeof id === 'undefined') {
                    r = await new Request('user/withdraw', values).post();
                } else {
                    values.group = id;
                    r = await new Request('group/withdraw', values).post();
                }
                if (r.status === 200) {
                    message.success('Withdrawal request created successfully');
                    const {data} = this.state;
                    data.withdrawals.push(r.data);
                    this.setState({
                        data: data,
                        withdrawModal: false,
                        withdrawCurrency: null,
                        withdrawAmount: 0
                    })
                } else {
                    message.error(r.data.error);
                }
            }
            this.setState({
                withdrawLoading: false
            })
        });
    }

    calculateTotal = () => {
        const {withdrawCurrency, data, withdrawAmount} = this.state;
        const total = withdrawAmount + (withdrawAmount / 100 * data.balances[withdrawCurrency].crypto.service) + data.balances[withdrawCurrency].crypto.withdrawal_fee;
        try {
            return total.toFixed(6)
        } catch (TypeError) {
            return 'N/A';
        }
    }

    setTrackingNumber = async e => {
        e.preventDefault();
        this.setState({trackingNumberLoading: true});
        const {form} = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const {activePayment, data} = this.state;
                values.invoice_id = data.payments[activePayment].invoice_id;
                const r = await new Request('terminal/set_tracking_number', values).post();
                if (r.status === 200) {
                    message.success(r.data.message);
                    data.payments[activePayment] = r.data.data;
                    this.setState({
                        data: data,
                        trackingNumberModal: false,
                        activePayment: null,
                    })
                } else {
                    message.error(r.data.error);
                }
            }
            this.setState({
                trackingNumberLoading: false
            })
        });
    }

    openChat = async (index) => {
        const {data} = this.state;
        const {id} = this.props.match.params;
        let group = null;
        if (typeof (id !== 'undefined')) group = id;
        const r = await new Request('user/write', {
            user_id: data.payments[index].payer,
            text: 'Regarding Invoice #' + data.payments[index].invoice_id,
            group: group
        }).post();
        if (r.status === 200) {
            this.props.history.push('/chat/'+r.data.room)
        }
    }

    cancelWithdrawal = async (id, index) => {
        const r = await new Request('terminal/cancel_withdrawal', {
            id: id
        }).post();
        if (r.status === 200) {
            const {data} = this.state;
            data.withdrawals.splice(index, 1);
            this.setState({data: data});
            message.success(r.data.message);
        }
    }

    addCoin = e => {
        const {data} = this.state;
        if (!data.settings.acceptable_crypto.includes(e.target.value)) {
            data.settings.acceptable_crypto.push(e.target.value);
        } else {
            const index = data.settings.acceptable_crypto.indexOf(e.target.value);
            if (index !== -1) data.settings.acceptable_crypto.splice(index, 1);
        }
        this.setState({data: data});
    }

    selectAll = () => {
        const {data} = this.state;
        const allCoins = [];
        data.settings.available_crypto.map((v) => {
            allCoins.push(v.name);
            return null;
        });
        data.settings.acceptable_crypto = allCoins;
        this.setState({data: data});
    }

    save = async () => {
        this.setState({buttonLoading: true});
        const {id} = this.props.match.params;
        const {data} = this.state;
        let module = 'group';
        if (typeof id === 'undefined') module = 'user';
        const r = await new Request(module + '/set_acceptable_crypto', {
            crypto: data.settings.acceptable_crypto,
            group_id: id
        }).post();
        if (r.status === 200) {
            message.success(r.data.message);
        } else {
            message.error('Unexpected error');
        }
        this.setState({buttonLoading: false});
    }

    render() {
        const {
            loading,
            data,
            withdrawModal,
            withdrawCurrency,
            withdrawLoading,
            trackingNumberModal,
            trackingNumberLoading,
            buttonLoading
        } = this.state;
        if (loading) return <Spinner/>;
        const cryptoColumns = [
            {
                title: 'Crypto',
                dataIndex: 'crypto',
                key: 'crypto',
                render: item => <div>
                    <Avatar size={'small'} src={item.icon}/> {item.name}
                </div>
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount'
            },
            {
                title: 'Action',
                key: 'action',
                render: (item, data, index) => {
                    return <Tooltip title={'Withdraw'} placement={'top'}>
                        <Button onClick={() => this.setState({
                            withdrawModal: true,
                            withdrawCurrency: index,
                            withdrawAmount: item.crypto.min_withdraw
                        })} type={'default'} shape={'circle'} icon={'export'}/>
                    </Tooltip>
                }
            }
        ];
        const paymentsColumns = [
            {
                title: '#',
                dataIndex: 'invoice_id',
                key: 'invoice_id'
            },
            {
                title: 'Amount',
                render: item => `${item.amount} ${item.crypto}`,
                key: 'amount'
            },
            {
                title: 'Date',
                dataIndex: 'payment_date',
                key: 'date'
            },
            {
                title: 'Action',
                render: (item, data, index) => {
                    return [
                        item.status === 2 && !item.tracking_number && <Tooltip title={'Specify tracking #'} placement={'top'}>
                            <Button size={'small'} type={'primary'} icon={'number'} onClick={() => this.setState({
                                activePayment: index,
                                trackingNumberModal: true
                            })}/>
                        </Tooltip>,
                        ' ',
                        <Tooltip title={'Payment info'} placement={'top'}>
                            <Button size={'small'} type={'default'} icon={'info-circle'} onClick={() => {
                                const columns = [
                                    {
                                        title: 'Product',
                                        dataIndex: 'product',
                                        key: 'product',
                                        render: product => product.name
                                    },
                                    {
                                        title: 'Amount',
                                        dataIndex: 'amount',
                                        key: 'amount',
                                    }
                                ];
                                Modal.info({
                                    title: 'Order details',
                                    content: (
                                        <div>
                                            <Paragraph>Customer info:</Paragraph>
                                            <Paragraph style={{margin: 0}}>Invoice #: <Text strong>{data.invoice_id}</Text></Paragraph>
                                            <Paragraph style={{margin: 0}}>Name: <Text strong>{data.name}</Text></Paragraph>
                                            <Paragraph style={{margin: 0}}>Phone: <a href={`tel:${data.phone}`}>{data.phone}</a></Paragraph>
                                            <Paragraph style={{margin: 0}}>Shipment address: <Text strong>{data.shipment_address}</Text></Paragraph>
                                            <Table size={'small'} pagination={false} dataSource={data.products} columns={columns}/>
                                        </div>
                                    )
                                })
                            }}/>
                        </Tooltip>,
                        ' ',
                        item.payer && <Tooltip title={'Chat with customer'} placement={'top'}>
                            <Button size={'small'} type={'danger'} icon={'message'} onClick={() => this.openChat(index)}/>
                        </Tooltip>,
                    ]
                }
            }
        ];
        const withdrawalColumns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Amount',
                key: 'amount',
                render: item => item.amount + ' ' + item.crypto
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status'
            },
            {
                title: 'Action',
                key: 'action',
                render: (item, data, index) => {
                    return [
                        item.link && <Tooltip title={'Explore'} placement={'top'}>
                            <Button icon={'link'} size={'small'} type={'primary'} onClick={() => window.open(item.link, '_blank').focus()}/>
                        </Tooltip>,
                        ' ',
                        <Tooltip title={'Payment info'} placement={'top'}>
                            <Button size={'small'} type={'default'} icon={'info-circle'} onClick={() => {
                                Modal.info({
                                    title: 'Withdrawal details',
                                    content: (
                                        <div>
                                            <Paragraph style={{margin: 0}}>Withdrawal #: <Text strong>{data.id}</Text></Paragraph>
                                            <Paragraph style={{margin: 0}}>Amount: <Text strong>{data.amount} {data.crypto}</Text></Paragraph>
                                            <Paragraph style={{margin: 0}}>To address: <Text strong>{data.address}</Text></Paragraph>
                                            <Paragraph style={{margin: 0}}>Created: <Text strong>{data.created}</Text></Paragraph>
                                            <Paragraph style={{margin: 0}}>Status: <Text strong>{data.status}</Text></Paragraph>
                                        </div>
                                    )
                                })
                            }}/>
                        </Tooltip>,
                        ' ',
                        <Tooltip title={'Cancel withdrawal'} placement={'top'}>
                            <Button icon={'close'} size={'small'} type={'danger'} onClick={() => {
                            Modal.confirm({
                                title: 'Confirm action',
                                content: (
                                    <Paragraph>Are you sure you want to cancel Withdrawal request #{data.id}</Paragraph>
                                ),
                                okText: 'Yes',
                                cancelText: 'No',
                                onOk: () => this.cancelWithdrawal(data.id, index)
                            })}
                            }/>
                        </Tooltip>
                    ]
                }
            }
        ];
        const { getFieldDecorator } = this.props.form;
        return <div>
            <TabsWrapper>
                <Tabs defaultActiveKey="balance" tabPosition={'left'}>
                    <TabPane tab="Balance" key="balance">
                        <Table key={'balances'} size={'small'} dataSource={data.balances} columns={cryptoColumns}/>
                    </TabPane>
                    <TabPane tab="Payments" key="payments">
                        <Table key={'payments'} size={'small'} dataSource={data.payments} columns={paymentsColumns}/>
                    </TabPane>
                    <TabPane tab="Withdrawals" key="Withdrawals">
                        <Table key={'payments'} size={'small'} dataSource={data.withdrawals} columns={withdrawalColumns}/>
                    </TabPane>
                    <TabPane tab={'Settings'} key={'setting'}>
                        <div style={{padding: 10}}>
                            <Form.Item label={'Acceptable coins'} extra={[
                                'Coins you accept on selling your products.',
                                <Button type={'link'} onClick={this.selectAll}>Select all</Button>
                            ]}>
                                {data.settings.available_crypto.map((v, i) => {
                                    return <Checkbox onChange={this.addCoin} checked={data.settings.acceptable_crypto.includes(v.name)} key={i} value={v.name} style={{ lineHeight: '64px' }}>
                                        <Avatar size={'small'} src={v.icon}/> {v.name}
                                    </Checkbox>
                                })}
                                <div style={{textAlign: 'center'}}>
                                    <Button type={'primary'} shape={'round'} loading={buttonLoading} onClick={this.save}>Save</Button>
                                </div>
                            </Form.Item>
                        </div>
                    </TabPane>
                </Tabs>
            </TabsWrapper>
            <Modal
                title={'Withdraw'}
                visible={withdrawModal}
                onCancel={() => this.setState({withdrawModal: false})}
                footer={null}
                width={350}
            >
                {withdrawModal && <Form onSubmit={this.withdraw}>
                    <Form.Item label={'Address'}>
                        {getFieldDecorator('address', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please put wallet address',
                                }
                            ],
                        })(<Input
                            placeholder="Put wallet address"
                        />)}
                    </Form.Item>
                    <Form.Item label={'Amount'} extra={`Minimum withdrawal amount is ${data.balances[withdrawCurrency].crypto.min_withdraw} ${data.balances[withdrawCurrency].crypto.name}`}>
                        {getFieldDecorator('amount', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please specify amount',
                                }
                            ],
                            initialValue: data.balances[withdrawCurrency].crypto.min_withdraw
                        })(<InputNumber
                            min={data.balances[withdrawCurrency].crypto.min_withdraw}
                            placeholder={'Enter amount'}
                            style={{width: '100%'}}
                            onChange={e => this.setState({withdrawAmount: e})}
                            step={data.balances[withdrawCurrency].crypto.min_withdraw}
                        />)}
                    </Form.Item>
                    <Form.Item>
                        <Total>
                            <Paragraph>Withdrawal fee: <Text strong>{data.balances[withdrawCurrency].crypto.withdrawal_fee}</Text></Paragraph>
                            <Paragraph>Service: <Text strong>{data.balances[withdrawCurrency].crypto.service}%</Text></Paragraph>
                            <Paragraph>Total: <Text strong>{this.calculateTotal()} {data.balances[withdrawCurrency].crypto.name}</Text></Paragraph>
                        </Total>
                    </Form.Item>
                    <Form.Item>
                        <Button loading={withdrawLoading} htmlType={'submit'} type={'primary'} block shape={'round'}>Withdraw</Button>
                    </Form.Item>
                </Form>}
            </Modal>
            <Modal
                title={'Set tracking number'}
                visible={trackingNumberModal}
                onCancel={() => this.setState({trackingNumberModal: false, activePayment: null})}
                footer={null}
                width={350}
            >
                {trackingNumberModal && <Form onSubmit={this.setTrackingNumber}>
                    <Form.Item label={'Tracking number'}>
                        {getFieldDecorator('tracking_number', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please put tracking number for this order',
                                }
                            ],
                        })(<Input
                            placeholder="Put tracking number"
                        />)}
                    </Form.Item>
                    <Form.Item>
                        <Button loading={trackingNumberLoading} htmlType={'submit'} type={'primary'} block shape={'round'}>Confirm</Button>
                    </Form.Item>
                </Form>}
            </Modal>
        </div>
    }

}

export default Form.create({ name: 'withdraw' })(withRouter(Wallet));