import React, {Component} from 'react';
import Provider from "./utils/Provider";


class Session extends Component {

    componentDidMount() {
        const session = new Provider('user').get();
        // console.log(session);
        window.parent.postMessage(session, '*');
    }

    render(){
        return '';
    }

}

export default Session;