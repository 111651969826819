import React, {Component} from 'react';
import { Card, Icon, Avatar, Badge } from 'antd'
import { NavLink } from 'react-router-dom'
import CheckMark from './CheckMark'
import Constants from '../Constants'


const {Meta} = Card;


class FollowRequest extends Component {

  render(){
    const {data, accept, decline} = this.props;
    return (
      <Card
        style={{ width: '100%' }}
        actions={[
          <Icon onClick={accept} style={{color: Constants.green}} type="check-circle" key="accept" />,
          <Icon onClick={decline} style={{color: Constants.secondaryColor}} type="close-circle" key="decline" />
        ]}
      >
        <Meta
          avatar={<NavLink to={`/user/${data.profile.id}`}>
            <Badge
              status={data.profile.online ? 'success' : false}
              offset={[-5, 30]}
              style={{zIndex: 0}}
            >
              <Avatar src={data.profile.photo} />
            </Badge>
          </NavLink>}
          title={
            <NavLink style={{color: data.profile.nameColor}} to={`/user/${data.profile.id}`}>
              {data.profile.name}
              {data.profile.checkMark ? <CheckMark/> : ''}
            </NavLink>
          }
          description={data.profile.about}
        />
      </Card>
    )
  }

}

export default FollowRequest;