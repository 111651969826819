import React, {Component} from 'react';
import { Button, Drawer, List, message } from 'antd'
import Request from '../utils/Request'


class EntityListDrawer extends Component {

  constructor(props, context){
    super (props, context);
    this.state = {
      init: true
    }
  }

  action = async (action) => {
    const {index, remove, close, update, data} = this.props;
    let req = new Request(`${data.entity}/${action}`, {
      [`${data.entity}_id`]: data.id
    });
    let res = await req.post();
    if (res.status === 200){
      message.info(res.data.message);
      if (typeof (res.data.data !== 'undefined') && res.data.data) update(index, res.data.data);
      if (typeof (res.data.remove !== 'undefined') && res.data.remove) remove(index);
      close();
    } else {
      message.error(res.data.error);
    }
  }

  render () {
    const {visible, close, admin, data} = this.props;
    const regOpts = [
      <Button onClick={() => this.action('follow')} block style={{textAlign: 'left'}} type="link" icon={data.entity === 'user' ? data.following ?  "user-delete" : 'user-add' : data.following ? "usergroup-delete" : 'usergroup-add'} key="follow">{data.following ? 'Stop follow' : 'Start follow'} {`${data.name}`}</Button>,
    ];
    if (data.entity === 'user') {
      regOpts.push(
        <Button onClick={() => this.action('block')} block style={{textAlign: 'left'}} type="link" icon={data.blocked ? "check-circle" : 'stop'} key="block">{data.blocked ? 'Unblock' : 'Block'} {`${data.name}`}</Button>
      )
    }
    if (data.entity === 'user' && data.followsMe){
      regOpts.push(
        <Button onClick={() => this.action('kick')} block style={{textAlign: 'left'}} type="link" icon="close" key="remove">Remove {`${data.basics.name}`} from followers</Button>,
      )
    }
    return (
      <Drawer
        title={false}
        placement="bottom"
        closable={true}
        visible={visible}
        onClose={close}
        height="auto"
      >
        {admin ? (
          <p>admin</p>
        ) : (
          <List
            dataSource={regOpts}
            size='small'
            renderItem={item => (
              <List.Item>
                {item}
              </List.Item>
            )}
          />
        )}
      </Drawer>
    )
  }

}

export default EntityListDrawer;