import React, {Component} from 'react';
import styled from 'styled-components';
import Avatar from "../../ui/Avatar";
import {NavLink} from "react-router-dom";
import {Typography, Button} from "antd";
import CheckMark from "../../ui/CheckMark";
import Constants from "../../Constants";


const Wrap = styled.div`
    display: grid;
    grid-template-columns: ${props => props.fullWidth ? '15% 65% 20%' : '25% 40% 35%'};
    width: 100%;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: ${props => props.fullWidth ? '15% 45% 40%' : '25% 40% 35%'};
  }
    
    div.grid-row {
        padding: ${props => props.fullWidth ? '10px 0' : '5px 0'};
        margin-top: 5px;
        border-top: ${props => props.fullWidth ? 'none' : '1px solid #e3e3e3'};
        border-bottom: ${props => props.fullWidth ? '1px solid #e3e3e3' : 'none'};
    }
    
    button {
        margin: 10px 0;
    }
`;

const {Paragraph} = Typography;


class EntityCard extends Component {

    render() {
        const {data, fullWidth} = this.props;
        let name;
        name = <Paragraph ellipsis={true} style={{color: data.entity === 'user' ? data.basics.nameColor : Constants.primaryColor, fontWeight: 'bold', margin: 0}}>{data.basics.name} {data.basics.checkMark && <CheckMark/>}</Paragraph>;
        return (
            <NavLink to={`/${data.entity}/${data.id}`}>
                <Wrap fullWidth={fullWidth}>
                    <div className={'grid-row'}><Avatar src={data.basics.photo} width={50} height={50}/></div>
                    <div className={'grid-row'} dir={'auto'}>
                        {name}
                        <Paragraph dir={'auto'} ellipsis={true} style={{color: '#a9a9a9', fontSize: 12, margin: 0}}>{data.basics.about}</Paragraph>
                    </div>
                    <div className={'grid-row'}><Button disabled={data.following} shape={'round'} block type={data.following ? 'default' : 'primary'}>{data.following ? 'Following' : 'Follow'}</Button></div>
                </Wrap>
            </NavLink>
        )
    }

}

export default EntityCard;