import React, {Component} from 'react';
import {Card, Avatar, message, Modal, Typography, Table} from 'antd';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import TradeOfferModal from '../../modals/TradeOfferModal';
import Request from '../../utils/Request';
import ContactModal from '../../modals/ContactModal'
import ChatModal from '../../modals/ChatModal'
import RateModal from '../../modals/RateModal'
import DealOfferModal from '../../modals/DealOfferModal'
import ProductMessageModal from "../../modals/ProductMessageModal";
import ProductViewModal from "../../modals/ProductViewModal";
import PromoModal from "../../modals/PromoModal";
import received from '../../../assets/img/received.png';
import confirmed from '../../../assets/img/confirmed.png';


const {Meta} = Card;
const {Paragraph, Text} = Typography;
const Time = styled.span`
  color: #b3b3b3;
  font-size: 12px;
  margin: 0 5px;
  font-weight: 100;
  float: right;
`;

class NotificationWrapper extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      content: '',
      avatar: '',
      title: '',
      onClick: () => {return null;},
      showTradeModal: false,
      tradeData: false,
      userData: false,
      showContactModal: false,
      showChatModal: false,
      showRateModal: false,
      showDealModal: false,
      showProductModal: false,
      productData: null,
      productMessageModal: false,
      id: null,
      promoId: 0
    }
  }

  update = () => {
    const {data} = this.props;
    const cases = [
      data.shout, data.event, data.trade, data.signal, data.deal
    ];
    let occasion = false;
    let entity = {};
    switch (data.typeId) {
      case 'USER_FOLLOWING_USER':
        this.setState({
          avatar: data.user.photo,
          content: data.title,
          title: 'New follower!',
          onClick: () => {
            this.props.history.push(`/user/${data.user.id}`)
          }
        });
        break;
      case 'USER_FOLLOW_REQUEST':
        this.setState({
          avatar: data.user.photo,
          content: data.title,
          title: 'New follow request',
          onClick: () => {
            this.props.history.push('/follow_requests')
          }
        });
        break;
      case 'POST_COMMENT':
        cases.map((v) => {
          if (v !== false) {
            occasion = v;
          }
          return null;
        });
        this.setState({
          avatar: data.user.photo,
          content: data.text,
          title: 'New comment',
          onClick: () => {
            this.props.history.push(`/post/${occasion.postType}/${occasion.id}`);
          }
        });
        break;
      case 'COMMENT_LIKE':
        cases.map((v) => {
          if (v !== false) {
            occasion = v;
          }
          return null;
        });
        this.setState({
          avatar: data.user.photo,
          title: 'Your comment IS LIT!',
          content: data.title,
          onClick: () => {
            this.props.history.push(`/post/${occasion.postType}/${occasion.id}`);
          }
        });
        break;
      case 'GROUP_FOLLOW_REQUEST':
        this.setState({
          avatar: data.user.photo,
          title: 'New request',
          content: data.title,
          onClick: () => {
            this.props.history.push(`/group/${data.group.id}/settings/requests`);
          }
        });
        break;
      case 'GROUP_FOLLOW_ACCEPTED':
        this.setState({
          avatar: data.group.photo,
          title: 'Request accepted',
          content: data.title,
          onClick: () => {
            this.props.history.push(`/group/${data.group.id}`);
          }
        });
        break;
      case 'USER_FOLLOWING_GROUP':
        this.setState({
          avatar: data.user.photo,
          title: 'New follower in group',
          content: data.title,
          onClick: () => {
            this.props.history.push(`/user/${data.user.id}`);
          }
        });
        break;
      case 'GROUP_POST':
        cases.map((v) => {
          if (v !== false) {
            occasion = v;
          }
          return null;
        });
        this.setState({
          avatar: data.group.photo,
          title: 'New post in the group!',
          content: data.title,
          onClick: () => {
            this.props.history.push(`/post/${occasion.postType}/${occasion.id}`);
          }
        });
        break;
      case 'GROUP_PROMOTION':
        this.setState({
          avatar: data.group.photo,
          title: 'You are moderator!',
          content: data.title,
          onClick: () => {
            this.props.history.push(`/group/${data.group.id}`);
          }
        });
        break;
      case 'TRADE_OFFER':
        this.setState({
          avatar: data.user.photo,
          title: 'New trade request!',
          content: data.title,
          onClick: () => {
            this.setState({
              showTradeModal: true,
              tradeData: data.trade,
              userData: data.user
            })
          }
        });
        break;
      case 'TRADE_ACCEPTED':

        if (data.user) {
          entity = data.user;
        } else {
          entity = data.group
        }

        this.setState({
          avatar: entity.photo,
          title: data.text,
          content: data.title,
          onClick: () => {
            this.props.history.push(`/post/trade/${data.trade.id}`);
          }
        });
        break;
      case 'USER_INFO_REQUEST':
        this.setState({
          avatar: data.user.photo,
          title: 'New contact request!',
          content: data.title,
          onClick: () => {
            this.setState({
              showContactModal: true,
              userData: data.user
            })
          }
        });
        break;
      case 'USER_CHAT_REQUEST':
        this.setState({
          avatar: data.user.photo,
          title: 'New chat request!',
          content: data.title,
          onClick: () => {
            this.setState({
              showChatModal: true,
              userData: data.user
            })
          }
        });
        break;
      case 'USER_FOLLOW_ACCEPTED':
        this.setState({
          avatar: data.user.photo,
          title: 'Follow request accepted!',
          content: data.title,
          onClick: () => {
            this.props.history.push(`/user/${data.user.id}`);
          }
        });
        break;
      case 'USER_CHAT_ACCEPTED':
        this.setState({
          avatar: data.user.photo,
          title: 'Chat request accepted!',
          content: data.title,
          onClick: () => {
            this.props.history.push(`/chat/${data.chatroom.uniqueId}`);
          }
        });
        break;
      case 'USER_INFO_ACCEPTED':
        this.setState({
          avatar: data.user.photo,
          title: 'New contact info!',
          content: data.title,
          onClick: () => {
            this.props.history.push(`/contacts`);
          }
        });
        break;
      case 'EVENT_STARTING_TOMORROW':
        cases.map((v) => {
          if (v !== false) {
            occasion = v;
          }
          return null;
        });
        this.setState({
          avatar: occasion.image,
          title: data.title,
          content: data.text,
          onClick: () => {
            this.props.history.push(`/post/${occasion.postType}/${occasion.id}`);
          }
        });
        break;
      case 'EVENT_STARTED':
        cases.map((v) => {
          if (v !== false) {
            occasion = v;
          }
          return null;
        });
        this.setState({
          avatar: occasion.image,
          title: data.title,
          content: data.text,
          onClick: () => {
            this.props.history.push(`/post/${occasion.postType}/${occasion.id}`);
          }
        });
        break;
      case 'RATE_THE_TRADE':
        if (data.user) {
          entity = data.user;
        } else {
          entity = data.group;
        }

        this.setState({
          avatar: entity.photo,
          title: data.title,
          content: data.text,
          onClick: () => {
            this.setState({
              showRateModal: true,
              userData: entity
            })
          }
        });
        break;
      case 'DEAL_OFFER':
        this.setState({
          avatar: data.user.photo,
          title: 'New deal request!',
          content: data.title,
          onClick: () => {
            this.setState({
              showDealModal: true,
              tradeData: data.deal,
              userData: data.user
            })
          }
        });
        break;
      case 'DEAL_ACCEPTED':
        if (data.user) {
          entity = data.user;
        } else {
          entity = data.group
        }

        this.setState({
          avatar: entity.photo,
          title: data.text,
          content: data.title,
          onClick: () => {
            this.props.history.push(`/post/deal/${data.deal.id}`);
          }
        });
        break;
      case 'PRODUCT_PAID':
        this.setState({
          avatar: data.product.picsLinks[0],
          title: data.title,
          content: data.text,
          onClick: () => {
            if (data.chatroom_id) {
              this.props.history.push(`/chat/${data.chatroom_id}`);
            } else {
              this.setState({
                productMessageModal: true,
                id: data.id
              })
            }
          }
        });
        break;
      case 'PRODUCT_PAYMENT_RECEIVED':
        this.setState({
          avatar: data.product.picsLinks[0],
          title: data.title,
          content: data.text,
          onClick: () => {
            this.setState({
              showProductModal: true,
              productData: data.product
            })
          }
        });
        break;
      case 'PROMO_PRODUCT_OFFER':
        this.setState({
          avatar: data.user ? data.user.photo : data.group.photo,
          title: data.title,
          content: data.text,
          onClick: () => this.setState({
            promoModal: true
          })
        });
        break;
      case 'PROMO_PRODUCT_DISCOUNT':
        this.setState({
          avatar: data.promo.product.picsLinks[0],
          title: data.title,
          content: data.text,
          onClick: () => {
            this.setState({
              showProductModal: true,
              productData: data.promo.product,
              promoId: data.promo.id
            })
          }
        });
        break;
      case 'KYB_RECEIVED':
        this.setState({
          avatar: 'https://api.cryptalks.app/static/client/img/logos/logo144.png',
          title: data.title,
          content: data.text
        });
        break;
      case 'PRODUCT_INTEREST':
        this.setState({
          avatar: data.user.photo,
          title: data.title,
          content: data.text,
          onClick: () => {
            this.props.history.push(`/user/${data.user.id}`);
          }
        });
        break;
      case 'USER_VISITING_USER':
        this.setState({
          avatar: data.user.photo,
          title: data.title,
          content: data.text,
          onClick: () => {
            this.props.history.push(`/user/${data.user.id}`);
          }
        });
        break;
      case 'CRYPTO_PAYMENT_RECEIVED':
        this.setState({
          avatar: received,
          title: data.title,
          content: data.text,
          onClick: () => {
            const columns = [
                {
                  title: 'Product',
                  dataIndex: 'product',
                  key: 'product',
                  render: product => product.name
                },
                {
                  title: 'Amount',
                  dataIndex: 'amount',
                  key: 'amount',
                }
            ];
            Modal.info({
              title: 'Order details',
              content: (
                  <div>
                    <Paragraph>Customer info:</Paragraph>
                    <Paragraph style={{margin: 0}}>Invoice #: <Text strong>{data.payment.invoice_id}</Text></Paragraph>
                    <Paragraph style={{margin: 0}}>Name: <Text strong>{data.payment.name}</Text></Paragraph>
                    <Paragraph style={{margin: 0}}>Phone: <a href={`tel:${data.payment.phone}`}>{data.payment.phone}</a></Paragraph>
                    <Paragraph style={{margin: 0}}>Shipment address: <Text strong>{data.payment.shipment_address}</Text></Paragraph>
                    <Table size={'small'} pagination={false} dataSource={data.payment.products} columns={columns}/>
                  </div>
              )
            })
          }
        });
        break;
      case 'CRYPTO_PAYMENT_CONFIRMED':
        this.setState({
          avatar: confirmed,
          title: data.title,
          content: data.text,
          onClick: () => {
            const columns = [
              {
                title: 'Product',
                dataIndex: 'product',
                key: 'product',
                render: product => product.name
              },
              {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
              }
            ];
            Modal.info({
              title: 'Order details',
              content: (
                  <div>
                    <Paragraph>Customer info:</Paragraph>
                    <Paragraph style={{margin: 0}}>Invoice #: <Text strong>{data.payment.invoice_id}</Text></Paragraph>
                    <Paragraph style={{margin: 0}}>Name: <Text strong>{data.payment.name}</Text></Paragraph>
                    <Paragraph style={{margin: 0}}>Phone: <a href={`tel:${data.payment.phone}`}>{data.payment.phone}</a></Paragraph>
                    <Paragraph style={{margin: 0}}>Shipment address: <Text strong>{data.payment.shipment_address}</Text></Paragraph>
                    <Table size={'small'} pagination={false} dataSource={data.payment.products} columns={columns}/>
                  </div>
              )
            })
          }
        });
        break;
      case 'CRYPTO_PAYMENT_RECEIVED_PAYER':
        this.setState({
          avatar: received,
          title: data.title,
          content: data.text,
        });
        break;
      case 'CRYPTO_PAYMENT_CONFIRMED_PAYER':
        this.setState({
          avatar: confirmed,
          title: data.title,
          content: data.text,
        });
        break;
      default:
        return null;
    }
  };

  async componentDidMount() {
    await this.update();
  }

  async componentDidUpdate(prevProps){
    if (prevProps.data !== this.props.data) await this.update();
  }

  requestAction = async (type, action, id) => {
    const {remove} = this.props;
    const req = new Request(`user/${action}_request`, {
      type: type,
      request_id: id
    });
    const res = await req.post();
    if (res.status === 200) {
      await remove();
      message.info(res.data.message);
      this.setState({
        showTradeModal: false,
        showContactModal: false,
        showChatModal: false,
        showRateModal: false,
        showDealModal: false,
        tradeData: false,
        userData: false
      })
    } else {
      message.error(res.data.error);
    }
  };

  goToChatRoom = (room) => {
    this.props.history.push(`/chat/${room}`);
  }

  render(){
    const {data, remove, update, index} = this.props;
    const {
      avatar,
      content,
      title,
      onClick,
      showTradeModal,
      tradeData,
      userData,
      showContactModal,
      showChatModal,
      showRateModal,
      showDealModal,
      showProductModal,
      productData,
      productMessageModal,
      id,
      promoModal,
      promoId
    } = this.state;
    return (
        <div>
          <Card
              style={{
                width: '100%',
                backgroundColor: data.checked ? '#fff' : '#f3ffff'
              }}
              onClick={onClick}
          >
            <Meta
                avatar={<Avatar src={avatar}/>}
                title={[title, <Time key="time">{data.created}</Time>]}
                description={content}
            />
          </Card>
          <TradeOfferModal
              visible={showTradeModal}
              trade={tradeData}
              user={userData}
              accept={() => this.requestAction('trade', 'accept', data.tradeRequest)}
              decline={() => this.setState({
                showTradeModal: false,
                tradeData: false,
                userData: false
              })}
          />
          <ContactModal
              visible={showContactModal}
              user={userData}
              accept={() => this.requestAction('info', 'accept', data.shareInfoRequest)}
              decline={() => this.setState({
                showContactModal: false,
                userData: false
              })}
          />
          <ChatModal
              visible={showChatModal}
              user={userData}
              accept={() => this.requestAction('chat', 'accept', data.chatRequest)}
              decline={() => this.setState({
                showChatModal: false,
                userData: false
              })}
          />
          <RateModal
              visible={showRateModal}
              entity={userData}
              remove={remove}
              close={() => this.setState({
                showRateModal: false,
                userData: false
              })}
          />
          <DealOfferModal
              visible={showDealModal}
              trade={tradeData}
              user={userData}
              accept={() => this.requestAction('deal', 'accept', data.dealRequest)}
              decline={() => this.setState({
                showDealModal: false,
                tradeData: false,
                userData: false
              })}
          />
          {data.typeId === 'PRODUCT_PAID' && <ProductMessageModal
              visible={productMessageModal}
              close={() => {
                this.setState({
                  productMessageModal: false,
                })
              }}
              id={id}
              goToChatRoom={this.goToChatRoom}
              update={update}
              index={index}
          />}
          {productData && <ProductViewModal
              visible={showProductModal}
              close={() => this.setState({
                showProductModal: false
              })}
              data={productData}
              editing={false}
              edit={false}
              remove={null}
              promoId={promoId}
          />}
          {data.typeId === 'PROMO_PRODUCT_OFFER' && <PromoModal
              visible={promoModal}
              close={() => {
                this.setState({
                  promoModal: false,
                })
              }}
              data={data}
          />}
        </div>
    )
  }

}

export default withRouter(NotificationWrapper);