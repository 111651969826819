import React, {Component} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import CheckMark from './CheckMark';
import ServerHost from '../helpers/ServerHost';


const Star = styled.div`
    width: 64px;
    height: 64px;
    background-image: url("${props => ServerHost('img/icons/star.png')}");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    bottom: 0;
    right: 0;
    &:after {
      content: '${props => props.rating}';
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 47%;
      font-size: 20px;
      font-weight: bold;
    }
`;

const Wrapper = styled.div`
    height: auto;
    width: 100%;
    position: relative;
`;

const Photo = styled.div`
    width: 150px;
    height: 150px;
    background-size: 100%;
    border-radius: 50%;
    background-image: url("${props => props.photo}");
    position: relative;
    display: block;
    margin: 0 auto;
`;

const Flag = styled.div`
    width: 48px;
    height: 48px;
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    background-image: url("${props => props.flag}");
`

const Title = styled.h4`
  color: ${props => props.color};
  font-size: 18px;
  text-align: center;
  margin: 0;
`;

class RatingAvatar extends Component {

  render () {
    const {entity} = this.props;
    return (
      <Wrapper onClick={() => this.props.history.push(`/${entity.entity}/${entity.id}`)}>
        <Photo photo={entity.basics.photo}>
          {entity.hasOwnProperty('countryFlag') && <Flag flag={entity.countryFlag}/>}
          <Star rating={entity.basics.rating}/>
        </Photo>
        <Title color={entity.hasOwnProperty('nameColor') ? entity.nameColor : '#000'}>{entity.name} {entity.hasOwnProperty('checkMark') && entity.checkMark && <CheckMark/>}</Title>
      </Wrapper>
    )
  }

}

export default withRouter(RatingAvatar);