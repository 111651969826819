import React, {Component} from 'react';
import { Col, Icon, Row, Typography } from 'antd';
import Constants from '../../Constants';
import PostIcon from '../PostIcon';
import FollowButton from '../FollowButton'

const { Paragraph, Title, Text } = Typography;


class Shout extends Component {

  render(){
    const {data} = this.props.data;
    const {icon, short} = this.props;
    return (
      <Row>
        <Col xs={icon ? 16 : 24}>
          {short ? (
            <div style={{margin: '0 0  15px'}}>
              <Title level={4} style={{margin: 0}}>{`${data.base}/${data.quote}`}</Title>
              <Paragraph strong style={{margin: 0}}>
                <Icon type={data.call === 'long' ? 'rise' : 'fall'} style={{fontSize: '20px', color: data.call === 'long' ? Constants.green : Constants.secondaryColor}}/>
                {` EP: ${data.entryPoint}`}
              </Paragraph>
              {data.currentRate ? <Text type="secondary">Current value: {data.currentRate}</Text> : ''}
              {data.stoppedAt ? <Text type="secondary">Stopped @ {data.stoppedAt}</Text> : ''}
            </div>
          ) : (
            <Row style={{margin: '5px 0'}}>
              <Col xs={8}>
                <Paragraph>Pair: <span style={{fontWeight: 'bold'}}>{data.base}/{data.quote}</span></Paragraph>
                <Paragraph>Call: <span style={{
                  color: data.call === 'long' ? Constants.green : Constants.secondaryColor
                }}>{data.call}</span></Paragraph>
                {data.hasOwnProperty('currentRate') && <Paragraph style={{
                  color: "#e8e8e8",
                  fontSize: '12px'
                }}>Current price: {data.currentRate}</Paragraph>}
              </Col>
              <Col xs={8}>
                {data.stopped ? data.succeed ? <PostIcon post="signal" type="win"/> : <PostIcon post="signal" type="lose"/> : <PostIcon post="signal" type="wait"/>}
              </Col>
              <Col xs={8} style={{textAlign: 'right'}}>
                <Paragraph style={{margin: 0}}>EP: <span style={{color: Constants.primaryColor}}>{data.entryPoint}</span></Paragraph>
                <Paragraph style={{margin: 0}}>TP: <span style={{color: Constants.green}}>{data.takeProfit}</span></Paragraph>
                <Paragraph style={{margin: 0}}>SL: <span style={{color: Constants.secondaryColor}}>{data.stopLoss}</span></Paragraph>
              </Col>
            </Row>
          )}
        </Col>
        {icon && <Col xs={8}>
          <FollowButton disabled={this.props.data.owner} following={this.props.data.following}/>
        </Col>}
      </Row>
    )
  }

}

export default Shout;