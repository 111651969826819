import React, {Component} from 'react';
import { Icon, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

const {Text, Title, Paragraph} = Typography;
const Nothing = styled.div`
  padding: 40px;
  text-align: center;
  width: 100%;
  color: #a3a3a3;
  font-size: 16px;
`;
const Wrap = styled.div`
  padding: 15px;
  background-color: #fff;
`;


class GroupInfo extends Component {

  render(){
    const {data} = this.props;
    const socials = [
      data.socialNetworks.facebook,
      data.socialNetworks.linkedin,
      data.socialNetworks.telegram,
      data.socialNetworks.twitter,
      data.socialNetworks.instagram,
    ];

    const atLeastOne = () => {
      let one = false;
      socials.map((v) => {
        if (v.length > 0) one = true;
        return null;
      });
      return one;
    };

    return (
      <Wrap>
        {data.basics.about ? <Paragraph dir={'auto'}>{data.basics.about}</Paragraph> : <Nothing>We didn't manage to find at least something about this group :(</Nothing>}
        {atLeastOne() && (
          <div>
            <Title level={4}>Join Us:</Title>
            {data.socialNetworks.instagram && <Paragraph><a href={data.socialNetworks.instagram} target="_blank" rel="noopener noreferrer"><Icon type="instagram"/> Instagram</a></Paragraph>}
            {data.socialNetworks.facebook && <Paragraph><a href={data.socialNetworks.facebook} target="_blank" rel="noopener noreferrer"><Icon type="facebook"/> Facebook</a></Paragraph>}
            {data.socialNetworks.linkedin && <Paragraph><a href={data.socialNetworks.linkedin} target="_blank" rel="noopener noreferrer"><Icon type="linkedin"/> LinkedIn</a></Paragraph>}
            {data.socialNetworks.twitter && <Paragraph><a href={data.socialNetworks.twitter} target="_blank" rel="noopener noreferrer"><Icon type="twitter"/> Twitter</a></Paragraph>}
            {data.socialNetworks.telegram && <Paragraph><a href={data.socialNetworks.telegram} target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faTelegram}/> Telegram</a></Paragraph>}
          </div>
        )}
      </Wrap>
    )
  }

}

export default GroupInfo;