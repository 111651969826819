import React, {Component} from 'react';
import styled from 'styled-components';
import {Tag, Button, Form, Icon, Input, message, Modal, Upload} from 'antd'
import Provider from '../utils/Provider';
import Request from '../utils/Request'
import {uploadFilePath} from '../helpers/ServerHost';
import Spinner from '../ui/Spinner'
import IAm from "../ui/IAm";


const FormWrap = styled.div`
  padding: 15px;
  background-color: #fff;
`;

const IAmWrapper = styled.div`
  border-radius: 20px;
  padding: 15px;
  border: 1px solid #3ba3bd;
  margin-bottom: 15px;
  position: relative;
  
  p {
    position: absolute;
    top: -15px;
    left: 23px;
    padding: 5px;
    background-color: #fff;
    font-weight: bold;
    color: #f8ac4f;
  }
`;

class AccountForm extends Component {

  constructor(props, context) {
    super (props, context);
    const provider = new Provider('user');
    const tokens = provider.get('tokens');
    this.state = {
      profile: {},
      confs: {},
      socials: {},
      kyc: {},
      password: '',
      old_password: '',
      emailSent: false,
      smsSent: false,
      resendTimer: 0,
      token: tokens.auth,
      loading: true,
    }
  }

  async componentDidMount(){
    const req = new Request('user/me');
    const res = await req.get();
    if (res.status === 200){
      this.setState({
        profile: res.data.profile,
        confs: res.data.contactInfoConfirmations,
        socials: res.data.socialNetworks,
        kyc: res.data.kyc,
        loading: false
      });
      if (window.location.search.includes('iam_popup=1')) {
        Modal.info({
          title: 'I Am Who I Say I Am',
          content: 'In order to proceed, please submit all required information in "I Am Who I Say I Am" section',
          onOk() {},
        });
      }
      if (window.location.search.includes('iam_popup=2')) {
        Modal.info({
          title: 'I Am Who I Say I Am',
          content: 'Pending CrypTalks compliance team approval',
          onOk() {},
        });
      }
    } else {
      message.error(res.data.error);
    }
  }

  componentWillUnmount(){
    this.setState({
      profile: {},
      confs: {},
      socials: {},
      kyc: {},
      password: '',
      old_password: '',
      emailSent: false,
      smsSent: false,
      resendTimer: 0,
      loading: true
    })
  }

  update = (data) => {
    this.setState({
      profile: data.profile,
      confs: data.contactInfoConfirmations,
      socials: data.socialNetworks,
      kyc: data.kyc
    })
  }

  sendEmail = async () => {
    const req = new Request('user/send_confirmation', {
      email: true
    });
    const res = await req.post();
    const provider = new Provider('user');
    if (res.status === 200) {
      message.info(res.data.message);
      provider.set(res.data.data);
      this.update(res.data.data);
      await this.setState({
        emailSent: true,
        resendTimer: 30
      });
      this.timer = setInterval(() => {
        const {resendTimer} = this.state;
        this.setState({
          resendTimer: resendTimer - 1
        });
        if (resendTimer <= 1) {
          this.setState({
            emailSent: false,
            resendTimer: 0
          });
          clearInterval(this.timer);
        }
      }, 1000);
    }
  }

  sendSms = () => {
    localStorage.clear();
    window.location.href = '/';
  }

  defineEmailStatus = (data) => {
    const {emailSent, resendTimer} = this.state;
    const button = <Button key="button" type="link" size="small" onClick={this.sendEmail} style={{
      textTransform: 'lowercase',
      padding: 0,
      height: 20
    }}>Send email confirmation</Button>;
    if (data.status) return 'Your email verified';
    if (data.text === 'Pending') {
      if (!emailSent) {
        return [
          'Please, check your inbox (don\'t forget to check spam) in order to verify your email. If email didn\'t come you can ',
          button,
          ' one more time.'
        ];
      } else {
        return `Resend in ${resendTimer} seconds...`
      }
    }
    if (data.text === 'Not confirmed') return button;
  }

  definePhoneStatus = (data) => {
    const {smsSent, resendTimer} = this.state;
    const button = <Button key="button" type="link" size="small" onClick={this.sendSms} style={{
      textTransform: 'lowercase',
      padding: 0,
      height: 20
    }}>Send SMS verification</Button>;
    if (data.status) return 'Your phone verified';
    if (data.text === 'Pending') {
      if (!smsSent) {
        return [
          'Please, check your inbox in order to verify your phone. If SMS didn\'t come you can ',
          button,
          ' one more time.'
        ];
      } else {
        return `Resend in ${resendTimer} seconds...`
      }
    }
    if (data.text === 'Not confirmed') return button;
  }

  defineFacebookStatus = (data) => {
    if (data.text === 'Pending') return data.text+'...';
    else if (data.text === 'Not confirmed') return 'Put your Facebook or LinkedIn username or link'
    else return 'Your Facebook/LinkedIn verified';
  }

  defineLinkedInStatus = (data) => {
    if (data.text === 'Pending') return data.text+'...';
    else if (data.text === 'Not confirmed') return 'Put your LinkedIn username or link, e.g. "linkedin.com/in/williamhgates" or simply "williamhgates"'
    else return 'Your LinkedIn verified';
  }

  handleProfile = (e) => {
    const {profile} = this.state;
    profile[e.target.name] = e.target.value;
    this.setState({
      profile: profile
    });
    this.props.save({
      [e.target.name]: e.target.value
    })
  }

  handleSocials = async (e) => {
    const {socials} = this.state;
    socials[e.target.name] = e.target.value;
    this.setState({
      socials: socials
    });
    await this.props.save({
      [e.target.name]: e.target.value
    })
  }

  handleChange = (e) => {
    const {save} = this.props;
    this.setState({
      [e.target.name]: e.target.value
    });
    save({
      [e.target.name]: e.target.value
    });
  }

  render () {
    const {profile, confs, password, old_password, socials, kyc, token, loading} = this.state;
    const {save} = this.props;
    if (loading) return <Spinner/>
    const uploadProps = {
      name: 'file',
      action: uploadFilePath,
      onChange(info) {
        if (typeof (info.file.response) !== 'undefined'){
          save({
            selfie_id: info.file.response.url
          })
        }
      },
    };
    return (
      <Form layout="vertical">
        <FormWrap>
          <IAmWrapper>
            <p>I Am Who I Say I Am</p>
            <Form.Item label="Email" extra={this.defineEmailStatus(confs.email)}>
              <Input
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  name="email"
                  onChange={this.handleProfile}
                  placeholder="email"
                  value={profile.email}
                  disabled={confs.email.text !== 'Not confirmed'}
              />
            </Form.Item>
            <Form.Item label="Phone" extra={this.definePhoneStatus(confs.phone)}>
              <Input
                  prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  name="phone"
                  onChange={this.handleProfile}
                  placeholder="Phone"
                  value={profile.phone}
                  disabled={confs.phone.status}
              />
            </Form.Item>
            <Form.Item label="Selfie ID" extra={kyc.selfieId.label ? <Tag color="blue">{kyc.selfieId.text}</Tag>: kyc.selfieId.text}>
              <Upload
                  {...uploadProps}
                  disabled={kyc.selfieId.text !== 'Not confirmed'}
                  headers={{
                    Auth: token
                  }}
              >
                <Button disabled={kyc.selfieId.status}>
                  <Icon type="upload" /> Click to Upload
                </Button>
              </Upload>
            </Form.Item>

            {profile.iamStatus && <IAm style={{width: 100, height: 100}}/>}
          </IAmWrapper>

          <Form.Item label="Change password">
            <Input.Password
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              name="password"
              onChange={this.handleChange}
              placeholder="Password"
              value={password}
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item label="Current password">
            <Input.Password
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              name="old_password"
              onChange={this.handleChange}
              placeholder="Current password"
              value={old_password}
              autoComplete="new-password"
            />
          </Form.Item>
        </FormWrap>
      </Form>
    );
  }

}

export default AccountForm;