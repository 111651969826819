import React, {Component} from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import Constnatns from '../Constants';
import { Icon } from 'antd';

const MapWrapper = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  margin: 5px 0;
`;

const Marker = ({ text }) => <div>{text}</div>;

class GoogleMap extends Component {

  render(){
    const {lat, lng, width, height} = this.props;
    return (
      <MapWrapper width={width} height={height}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCqTyTncHqWrds9IIgMh8iAXjygQzAZxBY' }}
          defaultCenter={{lat: lat, lng: lng }}
          defaultZoom={16}
        >
          <Marker
            lat={lat}
            lng={lng}
            text={<Icon
              theme="twoTone"
              twoToneColor={Constnatns.secondaryColor}
              type="environment"
              style={{
                fontSize: '20px'
              }}
            />
            }
          />
        </GoogleMapReact>
      </MapWrapper>
    )
  }

}

export default GoogleMap;