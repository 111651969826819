import React, {Component} from 'react';
import Request from '../utils/Request';
import styled from 'styled-components';
import Spinner from '../ui/Spinner';
import { message } from 'antd'
import { ChatList } from 'react-chat-elements';
import Constants from '../Constants';
import { withRouter } from 'react-router-dom'
import Nothing from '../template/Nothing'
import CheckMark from "../ui/CheckMark";
import renderHTML from 'react-render-html';

const Label = styled.span`
  background-color: ${Constants.secondaryColor};
  padding: 2px 4px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
`;


class Rooms extends Component {

  constructor (props, context){
    super (props, context);
    // const id = new Provider('user').get('profile').id;
    this.state = {
      rooms: [],
      // user_id: id,
      group_id: props.groupId,
      loading: true
    }
  }

  async componentDidMount() {
    const {group} = this.props;
    let path = 'chat/rooms';
    if (group) path = `chat/rooms?group_id=${group}`
    const req = new Request(path);
    const res = await req.get();
    if (res.status === 200) {
      this.setState({
        rooms: res.data,
        loading: false
      });
    } else {
      message.error(res.data.error)
    }
  }

  render(){
    const {rooms, loading} = this.state;
    if (loading) return <Spinner/>;
    if (rooms.length === 0) return <Nothing>Nothing there yet :(</Nothing>;
    return (
      <ChatList
        className='chat-list'
        onClick={(item) => this.props.history.push(`/chat/${item.roomId}`)}
        dataSource={rooms.map((v) => {
          return {
            roomId: v.uniqueId,
            avatar: v.interloc.photo,
            alt: v.interloc.name,
            title: [<span style={{color: v.interloc.nameColor, fontWeight: 'bold'}}>{v.interloc.name}</span>, v.interloc.checkMark && <CheckMark/>],
            subtitle: v.lastMessage ? renderHTML(v.lastMessage.text) : <span style={{
              color: '#e3e3e3',
              fontStyle: 'italic',
              margin: 0
            }}>No messages</span>,
            date: v.lastMessage ? new Date(v.lastMessage.created) : <Label>New!</Label>,
            unread: v.newMessages,
            statusColor: v.interloc.online ? Constants.green : false,
            statusColorType: 'badge'
          }
        })} />
    )
  }

}

export default withRouter(Rooms);