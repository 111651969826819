import React, {Component} from 'react';
import { Button, Col, Row, Typography, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import Constants from '../../Constants'
import FollowButton from '../FollowButton'
import { hashtagfy } from '../../helpers/Funcs'
import OrderForm from '../../forms/OrderForm'

const { Paragraph, Title, Text } = Typography;

const {info} = Modal;

class Deal extends Component {

  constructor (props, context){
    super(props, context);
    this.state = {
      cut: props.cut,
      showModal: false
    }
  }

  render(){
    const {icon, short, data, follow} = this.props;
    const {cut} = this.state;
    let buttonStyle = {
      float: 'right'
    }
    if (!data.requestSent) {
      buttonStyle.backgroundColor = Constants.green;
      buttonStyle.borderColor = Constants.green;
    }
    return (

        <Row>
          <Col xs={icon ? 16 : 24}>
            {short ? <Title style={{color: Constants.secondaryColor}} level={4} onClick={() => this.props.history.push(`/post/${data.postType}/${data.id}`)}>
              {data.data.title}
            </Title> : (
              <div>
                <Title style={{color: Constants.secondaryColor, margin: 0}} level={4}>{data.data.title}</Title>
                <Text style={{color: '#a8a8a8'}}>{data.data.cryptoPrice} {data.data.crypto}</Text>
                {!data.owner && <Button onClick={() => info({
                  title: 'Order review',
                  content: <OrderForm data={data.data}/>,
                  okButtonProps: {
                    shape: 'round'
                  },
                  okText: 'I\'m Done, Thanks'
                })} type="danger" shape="round" style={buttonStyle}>Buy</Button>}
                <Paragraph ellipsis={cut} onClick={() => this.setState({
                  cut: !cut
                })}>
                  {hashtagfy(data.data.description)}
                </Paragraph>
              </div>
            )}
          </Col>
          {icon && <Col xs={8}>
            <FollowButton onClick={follow} disabled={data.followDisabled} following={data.following}/>
          </Col>}
        </Row>
    )
  }

}

export default withRouter(Deal);