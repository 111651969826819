class Provider {

  constructor(object){
    this.data = JSON.parse(localStorage.getItem('data'));
    this.object = object;
  }

  get = (key = false) => {
    return this.data && this.data[this.object] ? key ? this.data[this.object][key] : this.data[this.object] : false;
  };

  set = (value) => {
    let data = localStorage.getItem('data') ? JSON.parse(localStorage.getItem('data')) : {};
    data[this.object] = value;
    localStorage.setItem('data', JSON.stringify(data));
    return null;
  };

  static clear(){
    localStorage.removeItem('data');
    return null;
  }

}

export default Provider;