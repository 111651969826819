import React, {Component} from 'react';
import {Button, Form, Input, message, Modal} from "antd";
import Request from "../utils/Request";


const {TextArea} = Input;


class ProductMessageModal extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true
        }
    }

    async componentDidMount() {
        this.setState({
            loading: false
        });
    }

    send = async (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {form, id, goToChatRoom, update, index, close} = this.props;
        form.validateFields(async (err, values) => {
            if (!err){
                const r = await new Request('user/send_product_message', {
                    notification: id,
                    message: values.message
                }).post();
                if (r.status === 200) {
                    update(index, 'chatroom_id', r.data.room);
                    close();
                    goToChatRoom(r.data.room);
                } else {
                    message.error('Unexpected error.')
                }
            }

        });
    }

    render() {
        const {loading} = this.state;
        const {visible, close, form} = this.props;
        const { getFieldDecorator } = form;
        return <Modal
            title='Contact customer'
            visible={visible}
            footer={null}
            onCancel={close}
        >
            <Form onSubmit={this.send} className="post-form">
                <Form.Item hasFeedback>
                    {getFieldDecorator('message', {
                        initialValue: 'Hello! Everything is cool I\'ve received your payment, please send me back your personal information and shipping address in order to ship a product.'
                    })(<TextArea
                        rows={4}
                        placeholder="Message"
                    />)}
                </Form.Item>
                <Button htmlType={'submit'} loading={loading} shape="round" type="primary" block>Send</Button>
            </Form>
        </Modal>
    }
}

export default  Form.create({ name: 'productMessage' })(ProductMessageModal);