import React, {Component} from 'react';
import styled from 'styled-components';
import { message } from 'antd'
import {scrolledToBottom} from "../../helpers/Funcs";
import Spinner from "../../ui/Spinner";
import Request from "../../utils/Request";
import EntityListItem from "./EntityListItem";


const Nothing = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 16px;
  color: #e3e3e3;
`;


class EntityList extends Component {

  constructor(props, context){
    super (props, context);
    this.state = {
      scroll: 0
    }
  }

  async componentDidMount(){
    document.addEventListener('scroll', this.trackScrolling);
  }

  // async componentDidUpdate(prevProps){
  //   const {path} = this.props;
  //   if (path !== prevProps.path){
  //     await this.setState({
  //       records: [],
  //       path: path,
  //       page: 1,
  //       loading: true
  //     });
  //     await this.getList();
  //   }
  // }

  componentWillUnmount(){
    const {scroll} = this.state;
    document.removeEventListener('scroll', this.trackScrolling);
    this.props.onUnmount(scroll);
    this.props.reset();
  }


  trackScrolling = async () => {
    this.setState({scroll: window.scrollY});
    const {htmlId} = this.props;
    const wrappedElement = document.getElementById(htmlId);
    const {onScrollToBottom, recordsLoading} = this.props;
    if (wrappedElement && scrolledToBottom(wrappedElement) && !recordsLoading){
      await onScrollToBottom();
    }
  };

  // update = (index, value) => {
  //   const {records} = this.state;
  //   records[index] = value;
  //   this.setState({
  //     records: records
  //   });
  // };
  //
  // remove = (index) => {
  //   const {records} = this.state;
  //   records.splice(index, 1);
  //   this.setState({
  //     records: records
  //   });
  // };

  render(){
    const {action, records, htmlId} = this.props;
    return (
      <div id={htmlId}>
        {records && records.map((v, i) => {
          return <EntityListItem data={v} key={i} action={action}/>
        })}
      </div>
    )
  }

}

export default EntityList;