import styled from 'styled-components';


const PostImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  
  &:hover {
    cursor: pointer;
  }
  
`;

export default PostImage;