import React, {Component} from 'react';
import {Avatar, Badge, Button, Card, Col, Row} from "antd";
import {NavLink} from "react-router-dom";
import Constants from "../../Constants";
import CheckMark from "../../ui/CheckMark";
import EntityListItemOptions from "./EntityListItemOptions";


const { Meta } = Card;

class EntityListItem extends Component {

    action = (entity, id, type) => {
        const {action, data} = this.props;
        switch (type) {
            case 'follow':
                data.following = !data.following;
                break;
            case 'block':
                data.blocked = !data.blocked;
                if (data.blocked) {
                    data.following = false;
                }
                break;
            case 'kick':
                data.followsMe = false;
                break;
            default:
                return null;
        }
        this.setState({
            data: data
        });
        action(entity, id, type);
    };

    render () {
        const {data} = this.props;

        let params;
        if (data.hasOwnProperty('basics')) {
            params = data.basics;
        } else {
            params = data;
        }
        let selfCheck;
        if (data.entity === 'user' && data.following === 'self') selfCheck = true;
        else if (data.entity === 'group' && data.isAdmin) selfCheck = true;
        return (
            <Card
                style={{ width: '100%' }}
                dir={'auto'}
            >
                <Meta
                    avatar={<NavLink to={`/${params.entity}/${params.id}`}>
                        <Badge
                            status={typeof (params.online) !== 'undefined' && params.online ? 'success' : false}
                            offset={[-5, 30]}
                            style={{zIndex: 0}}
                        >
                            <Avatar src={params.photo} />
                        </Badge>
                    </NavLink>}
                    title={<div>
                        <NavLink style={{color: params.entity === 'user' ? params.nameColor : `${Constants.primaryColor}`}} to={`/${params.entity}/${params.id}`}>
                            {params.name}
                            {params.checkMark && <CheckMark/>}
                            {data.isAdmin === true && [' ', <Badge count={'A'}/>]}
                            {data.isModerator === true && [' ', <Badge count={'M'}/>]}
                        </NavLink>
                        <span style={{float: 'right'}}>{!selfCheck && <EntityListItemOptions data={data} action={this.action}/>}</span>
                    </div>}
                    description={<Row>
                        <Col md={18} dir={'auto'}>
                            {params.entity === 'group' ? params.motto : params.about}
                        </Col>
                        <Col md={6} style={{textAlign: 'right'}}>
                            {!selfCheck && <Button onClick={() => this.action(data.entity, data.id, 'follow')} type={data.following === true ? 'default' : 'primary'} shape={'round'} disabled={data.following === true}>
                                {data.following === true ? 'Following' : 'Follow'}
                            </Button>}
                            {data.entity === 'group' && data.isAdmin && <NavLink to={`/group/${data.id}/settings`}><Button type={'danger'} shape={'round'}>Settings</Button></NavLink>}
                        </Col>
                    </Row>}
                    extra={<Button icon={'ellipsis'} type={'link'} size={'small'}/>}
                />
            </Card>
        )
    }

}

export default EntityListItem;