import * as firebase from 'firebase/app';
import 'firebase/messaging';
import config from './firebase'
import Request from './components/utils/Request'

export const initializeFirebase = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
}

export const subscribe = () => {
  let messaging = firebase.messaging();
  messaging.requestPermission()
  .then(function () {
    // получаем ID устройства
    messaging.getToken()
    .then(function (currentToken) {
      if (currentToken) {
        localStorage.setItem('fcmToken', currentToken);
        new Request('user/save_fcm_token', {
          platform: 'web',
          token: currentToken
        }).post();
      }
    })
    .catch(function (err) {
      console.log('При получении токена произошла ошибка.', err);
      return false;
    });
  })
  .catch(function (err) {
    console.log('Не удалось получить разрешение на показ уведомлений.', err);
    return false;
  });
}