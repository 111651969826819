import React, {Component} from 'react';
import { Typography } from 'antd'
import ItemsCarousel from 'react-items-carousel';
import EntitySlide from './EntitySlide'
import CheckMark from "./CheckMark";

const {Title} = Typography;


class PopularItems extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      activeItemIndex: 0
    }
  }

  render(){
    const {items, title} = this.props;
    const {activeItemIndex} = this.state;
    return (
      <div style={{"padding":"5px 15px","maxWidth":800,"margin":"0 auto"}}>
        <Title level={4}>{title}</Title>
        <ItemsCarousel
          infiniteLoop={false}
          gutter={12}
          activePosition={'center'}
          chevronWidth={15}
          disableSwipe={false}
          alwaysShowChevrons={false}
          numberOfCards={window.innerWidth <= 767 ? 3 : 5}
          slidesToScroll={1}
          outsideChevron={true}
          showSlither={false}
          firstAndLastGutter={false}
          activeItemIndex={activeItemIndex}
          requestToChangeActive={value => this.setState({ activeItemIndex: value })}
          rightChevron={'>'}
          leftChevron={'<'}
        >
          {items.map((v, i) => {
              return (
                <EntitySlide
                  key={i}
                  img={v.hasOwnProperty('basics') ? v.basics.photo : v.photo}
                  title={v.hasOwnProperty('basics') ? <span style={{color: v.basics.nameColor}}>{v.basics.name} {v.basics.checkMark && <CheckMark/>}</span> : v.name}
                  followers={v.basics.followers}
                  entity={v.entity}
                  id={v.id}
                  following={v.following}
                />
              );
            }
          )}
        </ItemsCarousel>
      </div>
    )
  }

}

export default PopularItems;