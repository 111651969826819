import React, {Component} from 'react';
import ReportModal from '../modals/ReportModal'
import { Button, Drawer, List, message } from 'antd'
import Spinner from './Spinner'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Constants from '../Constants'
import Request from '../utils/Request'
import MessageModal from '../modals/MessageModal'


class EntityDrawer extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      reportWindow: false,
      reportSource: false,
      messageWindow: false,
      entity: '',
      id: '',
      data: {},
      loading: true
    }
  }

  componentDidMount(){
    const {data} = this.props;
    if (data.hasOwnProperty('profile')){
      this.setState({
        entity: 'user',
        id: data.profile.id,
        data: data.profile,
        loading: false
      });
    } else {
      this.setState({
        entity: 'group',
        id: data.id,
        data: data,
        loading: false
      });
    }
  }

  share = async () => {
    const {data, entity, id} = this.state;
    const link = `${Constants.client}${entity}/${id}`;
    const shareData = {
      title: data.basics.name,
      text: data.basics.about,
      url: link
    }
    await navigator.share(shareData);
    this.props.close();
  }

  action = async (action) => {
    const {data} = this.state;
    const {close} = this.props;
    let req = new Request(`${data.entity}/${action}`, {
      [`${data.entity}_id`]: data.id
    });
    let res = await req.post();
    if (res.status === 200){
      message.info(res.data.message);
      await this.setState({
        data: res.data.data
      });
      close();
    } else {
      message.error(res.data.error);
    }
  };

  sendChatRequest = async () => {
    const {data} = this.state;
    const {close} = this.props;
    let req = new Request('user/send_request', {
      type: 'chat',
      user_id: data.id
    });
    let res = await req.post();
    if (res.status === 200){
      message.info(res.data.message);
      await this.setState({
        data: res.data.data
      });
      close();
    } else {
      message.error(res.data.error);
    }
  };

  render() {
    const {reportWindow, reportSource, entity, id, data, loading, messageWindow} = this.state;
    if (loading) return <Spinner/>;
    const {visible, close} = this.props;
    const link = `${Constants.client}${entity}/${id}`;
    let listData = [
      <Button onClick={() => this.action('follow')} block style={{textAlign: 'left'}} type="link" icon={data.following ? 'user-delete' : 'user-add'} key="delete">{data.following ? 'Stop' : 'Start'} follow {`${data.basics.name}`}</Button>,
      <Button
        block
        onClick={this.share}
        style={{textAlign: 'left'}}
        type="link"
        icon="share-alt">
        Share link
      </Button>,
      <Button onClick={() => this.action('mute')} block style={{textAlign: 'left'}} type="link" icon="sound" key="mute">{data.muted ? 'Take off mute from' : 'Mute'} {`${data.basics.name}`}</Button>,
      <Button onClick={() => {this.setState({reportWindow: true, reportSource: link}); close();}} block style={{textAlign: 'left'}} type="link" icon="warning" key="report">Report {`${data.basics.name}`}</Button>
    ];

    if (entity === 'user') {
      listData.push(<Button onClick={() => this.action('block')} block style={{textAlign: 'left'}} type="link" icon={data.blocked ? "check-circle" : 'close-circle'} key="block">{data.blocked ? 'Unblock' : 'Block'} {`${data.basics.name}`}</Button>);
      if (!data.chatRequestSent) listData.push(<Button onClick={this.sendChatRequest} block style={{textAlign: 'left'}} type="link" icon="message" key="chat">Ask To Chat</Button>);
      if (data.chatAllowed)listData.push(<Button onClick={() => {
        this.setState({
          messageWindow: true
        });
        this.props.close();
      }} block style={{textAlign: 'left'}} type="link" icon="message" key="chat">Send message</Button>);
    } else {
      if (data.basics.chatAllowed) listData.push(<Button onClick={() => {
        this.setState({
          messageWindow: true
        });
        this.props.close();
      }} block style={{textAlign: 'left'}} type="link" icon="message" key="chat">Send message</Button>)
    }
    return (
      <div>
        <Drawer
          placement="bottom"
          closable={false}
          visible={visible}
          zIndex={1010}
          height="auto"
          onClose={close}
        >
          <List
            dataSource={listData}
            size='small'
            renderItem={item => (
              <List.Item>
                {item}
              </List.Item>
            )}
          />
          <Button
            shape="round"
            onClick={close}
            block
            type="primary">
            Close
          </Button>
        </Drawer>
        <ReportModal
          visible={reportWindow}
          close={() => this.setState({
            reportWindow: false,
            reportSource: ''
          })}
          source={reportSource}
        />
        <MessageModal
          visible={messageWindow}
          close={() => {
            this.setState({
              messageWindow: false
            })
          }}
          name={data.basics.name}
          id={data.basics.id}
          entity={data.entity}
        />
      </div>
    )
  }

}

export default EntityDrawer;