import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {Button, Col, message, Row, Modal, Dropdown, Menu, Badge} from 'antd'
import styled from 'styled-components';
import {scrolledToBottom} from "../../helpers/Funcs";
import Spinner from "../../ui/Spinner";
import Nothing from "../../template/Nothing";
import Request from "../../utils/Request";
import CheckMark from "../../ui/CheckMark";


const { confirm} = Modal;

const Name = styled.span`
  font-size: 14px;
  font-weight: bold;
  vertical-align: top;
  color: ${props => props.color}
`;

const MemberWrap = styled.div`
  padding: 5px;
  border-top: 1px solid #e5e5e5;
  max-height: 45px;
  background-color: #fff;
  
  &:last-child {
    border-bottom: 1px solid #e5e5e5;    
  }
  
  &:hover {
    cursor: pointer;
  }
`;

const Avatar = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-size: 100%;
  background-image: url("${props => props.src}");
  border: 1px solid #e5e5e5;
  display: inline-block;
  margin-right: 10px;
`;


class GroupMemberList extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      records: [],
      loading: true,
      recordsLoading: false,
      fullLoad: false,
      page: 1,
      rpp: 24,
    }
  }

  async componentDidMount(){
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount(){
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = async () => {
    const wrappedElement = document.getElementById('member-list');
    if (wrappedElement){
      const {onScrollToBottom, recordsLoading} = this.props;
      if (wrappedElement && scrolledToBottom(wrappedElement) && !recordsLoading){
        await onScrollToBottom();
      }
    }
  };

  showConfirm = (action, data, index) => {
    let action_name = action;
    if (action === 'unfollow') action_name = 'kick';
    const {id} = this.props.match.params;
    if (data.isModerator && action === 'promote') action_name = 'demote';
    if (data.groupBlocked && action === 'block') action_name = 'unblock';
    confirm({
      title: 'Confirm action',
      content: `Do you really want to ${action_name} ${data.basics.name}?`,
      onOk: async () => {
        const {adminAction} = this.props;
        adminAction(action, id, data, index);
      }
    });
  };

  update = (data, key) => {
    const {records} = this.state;
    records[key] = data;
    this.setState({
      records: records
    });
  };

  removeFromList = (key) => {
    const {records} = this.state;
    records.splice(key, 1);
    this.setState({
      records: records
    });
  };

  render(){
    const {push} = this.props.history;
    const {records} = this.props;
    if (typeof (records) === 'undefined') return null;
    if (records.length === 0) return <Nothing>Nobody is here...</Nothing>;
    return (
      <div id="member-list">
        {records.map((v, i) => {
          return (
            <MemberWrap key={i}>
              <Row>
                <Col md={16} onClick={() => push(`/user/${v.basics.id}`)}>
                  <Avatar src={v.basics.photo}/>
                  <Name level={4} color={v.basics.nameColor}>{v.basics.name} {v.basics.checkMark && <CheckMark/>} {v.isModerator && [' ', <Badge count={'M'}/>]}</Name>
                </Col>
                <Col md={8}>
                  {!v.isAdmin && <Dropdown placement="bottomRight" overlay={(<Menu>
                    <Menu.Item key="0">
                      <Button type={'link'} size={'small'} onClick={() => this.showConfirm('promote', v, i)} icon={v.isModerator ? 'arrow-down' : 'to-top'}>
                        {v.isModerator ? 'Demote' : 'Promote'} {`${v.basics.name}`} {v.isModerator ? 'from' : 'to'} moderator
                      </Button>
                    </Menu.Item>
                    <Menu.Item>
                      <Button onClick={() => this.showConfirm('unfollow', v, i)} block style={{textAlign: 'left'}} type="link" icon="user-delete" key="kick">Kick {`${v.basics.name}`}</Button>
                    </Menu.Item>
                    <Menu.Item>
                      <Button onClick={() => this.showConfirm('block', v, i)} block style={{textAlign: 'left'}} type="link" icon={v.groupBlocked ? 'check-circle' : 'close-circle'} key="block">{v.groupBlocked ? 'Unblock' : 'Block'} {`${v.basics.name}`}</Button>
                    </Menu.Item>
                  </Menu>)} trigger={['click']}>
                    <Button icon={'ellipsis'} size={'small'} type={'link'} style={{float: 'right'}}/>
                  </Dropdown>}
                </Col>
              </Row>
            </MemberWrap>
          )
        })}
      </div>
    )
  }

}

export default withRouter(GroupMemberList);