import styled from 'styled-components';
import ServerHost from '../helpers/ServerHost'

const Animtaion = styled.div`
    width: 50%;
    padding-top: 64%;
    background-image: url(${ServerHost('img/animation.gif')});
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 33px;
    margin: 0 auto;
`

export default Animtaion;