import 'firebase/database';

const config = {
  apiKey: "AIzaSyCmwWVrPw63pXsKrLs9rj5jUcxxm1gEf6I",
  authDomain: "cryptalk-v2.firebaseapp.com",
  databaseURL: "https://cryptalk-v2.firebaseio.com",
  projectId: "cryptalk-v2",
  storageBucket: "cryptalk-v2.appspot.com",
  messagingSenderId: "391150763502",
  appId: "1:391150763502:web:1ebe9b0907c83a25ae62ff"
};

export default config;
