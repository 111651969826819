import React, {Component} from 'react';
import { Modal } from 'antd'


class UpdateModal extends Component {

  render(){
    const {model, visible, close, form} = this.props;
    return (
      <Modal
        title={`Update ${model}`}
        visible={visible}
        onCancel={close}
        footer={null}
        className="shout-modal"
        style={{ top: 20 }}
      >
        {form}
      </Modal>
    );
  }

}

export default UpdateModal;