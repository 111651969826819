import React, {Component, cloneElement} from 'react';
import { PageHeader, Input, notification, Icon, message, Button, Typography } from 'antd'
import NavigationStack from './NavigationStack';
import Avatar from '../ui/Avatar';
import Container from '../template/Container';
import SidebarMenu from '../ui/SidebarMenu';
import Provider from '../utils/Provider';
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import OpenPostWrapper from '../ui/posts/OpenPostWrapper';
import { NavLink, withRouter } from 'react-router-dom'
import EntityFeed from '../ui/EntityFeed';
import Request from '../utils/Request';
import CheckMark from '../ui/CheckMark';
import ProfileForm from '../forms/ProfileForm';
import AccountForm from '../forms/AccountForm';
import EntityList from '../ui/EntityList';
import GroupForm from '../forms/GroupForm';
import GroupSettings from '../ui/GroupSettings';
import GroupMemberList from '../ui/GroupMemberList';
import ClosedEntity from '../ui/ClosedEntity';
import EntityRequests from '../ui/EntityRequests';
import Dialog from '../chat/Dialog';
import Spinner from '../ui/Spinner';
import Rooms from '../chat/Rooms';
import ChatDrawer from '../chat/ChatDrawer'
import TransSpinner from '../ui/TransSpinner'
import NotificationsForm from '../forms/NotificationsForm'
import SessionData from '../utils/SessionData'
import ContactsList from '../ui/ContactsList'
import KycForm from '../forms/KycForm'
import PaymentModal from "../modals/PaymentModal";
import LogoMobile from "../template/LogoMobile";



const { Search } = Input;
const {Paragraph} = Typography;

class MobileStack extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      stack: false,
      headerProps: {},
      sidebar: false,
      searchQuery: '',
      sidebarInfo: {
        followers: '',
        following: '',
        groups: ''
      },
      updateData: {},
      chatDrawer: false,
      chatDrawerData: {},
      loading: false,
      paymentModal: false,
      paymentLink: null,
      onPaymentModalClose: () => {return null}
    }
  }

  sidebar = async () => {
    const {sidebar} = this.state;
    const session = new SessionData('sidebarInfo');
    const info = session.get();
    if (!info) {
      let req = new Request('user/count');
      let res = await req.get();
      if (res.status === 200) {
        this.setState({
          sidebar: !sidebar,
          sidebarInfo: res.data
        });
        session.set(res.data);
      } else {
        message.error(res.data.error);
      }
    } else {
      this.setState({
        sidebar: !sidebar,
        sidebarInfo: info
      });
    }

  };

  handleSearch = async (val) => {
    await this.setState({
      searchQuery: val
    });
    await this.updateState();
  };

  handleUpdate = async (data) => {
    const {updateData} = this.state;
    let keys = Object.keys(data);
    keys.map((key) => {
      updateData[key] = data[key];
      return null;
    });
    await this.setState({
      updateData: updateData
    })
  };

  resetSearch = async () => {
    await this.setState({
      searchQuery: ''
    });
    await this.updateState();
  };

  save = async (action = 'update_profile') => {
    const {updateData} = this.state;
    const provider = new Provider('user');
    let req = new Request(`user/${action}`, updateData);
    let res = await req.post();
    if (res.status === 200){
      message.success(res.data.message);
      provider.set(res.data.data);
      await this.updateState();
    } else {
      message.error(res.data.error);
    }
  };

  saveGroup = async (type) => {
    const {updateData} = this.state;
    let req = new Request(`group/${type}`, updateData);
    let res = await req.post();
    if (res.status === 200){
      if (type === 'create') {
        if (!res.data.business) {
          message.success('Your group was successfully created!');
          this.props.history.push(`/group/${res.data.id}`);
        } else {
          this.setState({
            paymentModal: true,
            paymentLink: `https://api.cryptalks.app/processing/paypal?plan=P-8SY51871YT7382139L6X7LWY&description=Business%20Group&group=${res.data.id}`,
            onPaymentModalClose: () => {
              this.props.history.push(`/group/${res.data.id}`);
              this.setState({
                paymentModal: false
              })
            }
          })
        }
      } else {
        message.success(res.data.message)
      }
    } else if(res.status === 403) {
      window.location.href = res.data.redirect;
    } else {
      message.error(res.data.error);
    }
  };

  updateState = async () => {
    const {module} = this.props;
    const {searchQuery, visible} = this.state;

    const provider = new Provider('user');
    const profile = provider.get('profile');

    const navigatorModules = [
      'feed', 'popular', 'notifications', 'chat'
    ];
    const listModules = [
      'followers', 'following', 'groups'
    ];
    const groupModules = [
      'create_group', 'update_group'
    ];

    const groupMembersModules = [
      'group_moderators', 'group_followers', 'group_blocked'
    ];

    if (navigatorModules.includes(module)){
      await this.setState({
        stack: <NavigationStack
          searchQuery={searchQuery}
          resetSearch={this.resetSearch}
          fixed={visible}
          module={module}
        />,
        headerProps: {
          onBack: this.sidebar,
          backIcon: <Avatar/>,
          title: module
        }
      })
    }

    else if (listModules.includes(module)){
      let entity = typeof (this.props.match.params.entity) !== 'undefined' ? this.props.match.params.entity : 'user';
      let entId = typeof (this.props.match.params.id) !== 'undefined' ? this.props.match.params.id : profile.id;
      let path = `${entity}/${module}?${entity}_id=${entId}&rpp=12`;
      if (searchQuery) path = path + `&query=${searchQuery}`;
      await this.setState({
        headerProps: {
          title: '',
          subTitle:  <div>
            <Search
              placeholder={`Search ${module}`}
              onSearch={this.handleSearch}
              style={{ width: '100%' }}
            />
            {module === 'groups' && <Button onClick={() => this.props.history.push('/group/create')} size="small" icon="usergroup-add" type="primary" shape="round" style={{
              position: 'absolute',
              right: 10
            }}/>}
          </div>,
          onBack: () => this.props.history.goBack(),
          backIcon: <Icon style={{margin: '7px 0'}} type="arrow-left" />,
        },
        stack: <EntityList
          title={module}
          path={path}
          reset={this.resetSearch}
        />
      });
    }

    else if (groupModules.includes(module)){
      const title = module.split('_');
      await this.setState({
        headerProps: {
          title: `${title[0]} ${title[1]}`,
          className: 'button-header search-header',
          subTitle: <Button onClick={() => this.saveGroup(title[0])} size="small" type="primary" style={{float: 'right'}} shape="round">Save</Button>,
          onBack: () => this.props.history.goBack(),
          backIcon: <Icon type="arrow-left" />
        },
        stack: <GroupForm save={this.handleUpdate} edit={module === 'update_group'}/>
      })
    }

    else if (groupMembersModules.includes(module)){
      const list = module.replace('group_', '');
      await this.setState({
        headerProps: {
          title: '',
          subTitle: <Search
            placeholder={`Search group's ${list}`}
            onSearch={this.handleSearch}
            style={{ width: '100%' }}
          />,
          onBack: () => this.props.history.goBack(),
          backIcon: <Icon style={{margin: '7px 0'}} type="arrow-left" />
        },
        stack: <GroupMemberList list={list} id={this.props.match.params.id} query={searchQuery}/>
      })
    }

    const headerProps = this.state.headerProps;
    switch (module){
      case 'feed':
        headerProps['subTitle'] = '';
        this.setState({
          headerProps: headerProps
        });
        break;
      case 'popular':
        if (!searchQuery){
          headerProps['title'] = '';
          headerProps['subTitle'] = <Search
            placeholder="Search @ CrypTalks"
            onSearch={this.handleSearch}
            style={{ width: '100%' }}
          />;
        } else {
          headerProps['title'] = 'Search';
          headerProps['subTitle'] = searchQuery;
          headerProps['onBack'] = this.resetSearch;
          headerProps['backIcon'] = <Icon type="arrow-left" />;
          headerProps['className'] = 'search-header';
        }
        this.setState({
          headerProps: headerProps
        });
        break;
      case 'notifications':
        headerProps['subTitle'] = '';
        this.setState({
          headerProps: headerProps
        });
        break;

      case 'post':
        this.setState({
          stack: <OpenPostWrapper/>,
          headerProps: {
            title: module,
            onBack: () => this.props.history.goBack(),
            backIcon: <Icon type="arrow-left" />,
            className: 'search-header',
            subTitle: ''
          }
        });
        break;
      case 'entity':
        let entity = this.props.match.params.entity;
        let entId = this.props.match.params.id;
        if (entity && entId) {
          this.setState({
            loading: true
          });

          const entityPath = `${entity}_${entId}`;
          const savedData = new SessionData(entityPath);
          const savedStatus = new SessionData(`${entityPath}_status`);
          const savedMessage = new SessionData(`${entityPath}_message`);

          let entityData;
          let stack;
          let blocked = false;

          if (!savedData.get()) {
            let req = new Request(`${entity}/info?${entity}_id=${entId}`);
            let res = await req.get();
            savedStatus.set(res.status);
            if (res.status === 200) {
              entityData = res.data;
              stack = <EntityFeed key={`${entity}_${entId}`} data={entityData}/>
            }
            else if (res.status === 403) {
              blocked = true;
              entityData = res.data.data;
              stack = <ClosedEntity message={res.data.error} data={entityData}/>;
              savedMessage.set(res.data.error);
            } else {
              message.error(res.data.error);
            }
            savedData.set(entityData);
          } else {
            entityData = savedData.get();
            switch (savedStatus.get()) {
              case 200:
                stack = <EntityFeed key={`${entity}_${entId}`} data={entityData}/>;
                break;
              case 403:
                stack = <ClosedEntity message={savedMessage.get()} data={entityData}/>;
                break;
              default:
                return null;
            }
          }

          let name;
          let count;
          let verified;
          if (!blocked) {
            if (entityData.hasOwnProperty('basics')){
              name = entityData.basics.name;
              count = entityData.basics.posts;
              verified = entityData.verified;
            } else {
              name = entityData.profile.basics.name;
              count = entityData.profile.count.posts;
              verified = entityData.profile.basics.checkMark;
            }
          } else {
            name = entityData.name;
            verified = entityData.hasOwnProperty('verified') ? entityData.verified : false;
            count = entityData.posts;
          }

          await this.setState({
            stack: stack,
            headerProps: {
              title: [<div className={'page-header-mobile'} dir={"auto"}>{name}</div>, verified ? <CheckMark key="verified"/> : ''],
              onBack: () => this.props.history.goBack(),
              backIcon: <Icon type="arrow-left" />,
              className: 'search-header',
              subTitle: `${count} post${count > 1 ? 's' : ''}`
            }
          });
        }
        break;
      case 'settings':

        const stype = this.props.match.params.stype;
        let scomp;
        let onClick = () => this.save();
        switch (stype){
          case 'profile':
            scomp = <ProfileForm save={this.handleUpdate}/>;
            break;
          case 'account':
            scomp = <AccountForm save={this.handleUpdate}/>;
            break;
          case 'notifications':
            scomp = <NotificationsForm save={this.handleUpdate}/>;
            onClick = () => this.save('update_push_settings');
            break;
          case 'kyc':
            let kyc = {};
            const req = new Request('user/kyc_status');
            const res = await req.get();
            if (res.status === 200) {
              kyc = res.data;
            } else {
              message.error(res.data.error);
            }
            scomp = <KycForm kyc={kyc} save={this.handleUpdate}/>;
            onClick = () => this.save('update_kyc');
            break;
          default:
            return null;
        }

        this.setState({
          headerProps: {
            title: `${stype} ${module}`,
            className: 'button-header search-header',
            subTitle: <Button onClick={onClick} size="small" type="primary" style={{float: 'right'}} shape="round">Save</Button>,
            onBack: () => this.props.history.goBack(),
            backIcon: <Icon type="arrow-left" />,
          },
          stack: scomp
        });
        break;
      case 'group_settings':
        this.setState({
          headerProps: {
            title: 'Group Settings',
            className: 'button-header search-header',
            subTitle: '',
            onBack: () => this.props.history.goBack(),
            backIcon: <Icon type="arrow-left" />,
          },
          stack: <GroupSettings id={this.props.match.params.id}/>
        });
        break;
      case 'group_requests':
        this.setState({
          headerProps: {
            title: 'Group Follow Requests',
            className: 'button-header search-header',
            subTitle: '',
            onBack: () => this.props.history.goBack(),
            backIcon: <Icon type="arrow-left" />,
          },
          stack: <EntityRequests entity="group" id={this.props.match.params.id} path={`group/follow_requests?group_id=${this.props.match.params.id}`}/>
        });
        break;
      case 'user_requests':
        this.setState({
          headerProps: {
            title: 'Your Follow Requests',
            className: 'button-header search-header',
            subTitle: '',
            onBack: () => this.props.history.goBack(),
            backIcon: <Icon type="arrow-left" />,
          },
          stack: <EntityRequests entity="user" id={this.props.match.params.id} path={`user/follow_requests?user_id=${this.props.match.params.id}`}/>
        });
        break;
      case 'chat_dialog':
        this.setState({
          headerProps: {
            title: '',
            className: 'button-header search-header',
            subTitle: '',
          },
          stack: <Spinner/>
        });
        const roomId = this.props.match.params.room_id;
        const req = new Request(`chat/rooms?room_unique_id=${roomId}`);
        const res = await req.get();
        let verified = '';
        if (res.data.interloc.checkMark) verified = true;
        if (res.status === 200) {
          this.setState({
            headerProps: {
              title:
                <NavLink style={{color: '#000'}} to={`/${res.data.interloc.entity}/${res.data.interloc.id}`}>
                  {res.data.interloc.name} {verified && <CheckMark/>}
                  </NavLink>,
              className: 'button-header search-header',
              onBack: () => this.props.history.goBack(),
              backIcon: <Icon type="arrow-left" />,
            }
          });
          const room = firebase.database().ref('chat').child(roomId);
          const interlocutor = room.child('participants').child(`${res.data.interloc.entity}_${res.data.interloc.id}`);
          const messages = room.child('messages');

          const drawer = async (data) => {
            await this.setState({
              chatDrawer: true,
              chatDrawerData: data
            })
          };

          interlocutor.on("value", (snapshot) => {
            let val = snapshot.val();
            const {headerProps} = this.state;
            headerProps.subTitle = [
              <span key="status">{val.typing ? 'typing...' : val.online ? 'online' : res.data.interloc.hasOwnProperty('lastSeen') ? `last seen ${res.data.interloc.lastSeen.toLowerCase()}` : ''}</span>,
              <Button key="options" onClick={() => drawer(res.data.interloc)} size="small" icon="more" type="link" style={{
                position: 'absolute',
                right: 10
              }}/>];
            this.setState({
              headerProps: headerProps
            });
          });

          this.setState({
            stack: <Dialog interlocutor={interlocutor} roomId={roomId} me={res.data.me} messages={messages} />
          });
        }
        break;
      case 'group_chat':
        await this.setState({
          headerProps: {
            title: 'Messages',
            onBack: () => this.props.history.goBack(),
            backIcon: <Icon style={{margin: '7px 0'}} type="arrow-left" />,
            subTitle: ''
          },
          stack: <Rooms group={this.props.match.params.id} />
        });
        break;
      case 'contacts':
        this.setState({
          headerProps: {
            title: 'Contacts List',
            className: 'button-header search-header',
            subTitle: '',
            onBack: () => this.props.history.goBack(),
            backIcon: <Icon type="arrow-left" />,
          },
          stack: <ContactsList/>
        });
        break;
      default:
        return null;
    }
    this.setState({
      loading: false
    });
    return null;
  };

  async componentDidMount(){
    await this.updateState();
    const {module} = this.props;
    const provider = new Provider('user');
    const profile = provider.get('profile');
    const ntfctn = firebase.database().ref('global').child(profile.basics.id).child('notification');
    ntfctn.on("value", (snapshot) => {
      let val = snapshot.val();
      if (val) {
        try {
          return notification[val.type]({
            message: val.title,
            description: val.text,
            onClick: () => {
              this.props.history.push('/notifications');
              notification.destroy();
            }
          });
        } catch (e) {
          return null;
        }
      }
    });
    const message = firebase.database().ref('global').child(profile.basics.id).child('message');
    message.on("value", (snapshot) => {
      const val = snapshot.val();
      if (val && module !== 'chat_dialog') {
        try {
          return notification['info']({
            message: val.title,
            description: val.text,
            icon: <Avatar src={val.photo}/>,
            onClick: () => {
              this.props.history.push(`/chat/${val.room_id}`);
              notification.destroy();
            }
          });
        } catch (e) {
          return null;
        }
      }
    })
  }

  async componentDidUpdate(prevProps, prevState) {

    const prevUrl = prevProps.match.url;
    const newUrl = this.props.match.url;
    const module = this.props.module;

    if (module === 'entity') {

      if (prevUrl !== newUrl && newUrl.split('/').length === 3) {
        this.setState({
          loading: true
        });
        await this.updateState();
      }
    }

    if (prevProps.module !== this.props.module) {
      if (this.props.match.params.query) await this.setState({searchQuery: this.props.match.params.query});
      await this.updateState();
    }
  }

  render(){
    const style = {
      position: 'fixed',
      top: 0,
      zIndex: 2,
      maxWidth: 800,
      width: '100%',
      backgroundColor: '#ffffff'
    };
    const {
      sidebar,
      sidebarInfo,
      chatDrawerData,
      chatDrawer,
      loading,
      paymentLink,
      paymentModal,
      onPaymentModalClose
    } = this.state;
    return (
      <Container style={{paddingTop: 65, maxWidth: 800}}>
        {loading && <TransSpinner/>}
        <SidebarMenu visible={sidebar} info={sidebarInfo} close={this.sidebar}/>
        <LogoMobile/>
        {cloneElement(<PageHeader style={style}/>, this.state.headerProps)}
        {this.state.stack}
        <ChatDrawer
          visible={chatDrawer}
          data={chatDrawerData}
          close={() => this.setState({
            chatDrawer: false
          })}
        />
        <PaymentModal
            visible={paymentModal}
            close={onPaymentModalClose}
            link={paymentLink}
        />
      </Container>
    )
  }
}

export default withRouter(MobileStack);