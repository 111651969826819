import React, {Component} from 'react';
import { Form, message, Input, Modal } from 'antd';
import Request from '../utils/Request'
import { withRouter } from 'react-router-dom'

const { TextArea } = Input;


class MessageModal extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      buttonLoad: false,
    }
  }

  send = async (e) => {
    const {entity, id} = this.props;
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          buttonLoad: true
        });
        let req = new Request(`${entity}/write`, {
          text: values.text,
          [`${entity}_id`]: id
        });
        let res = await req.post();
        if (res.status === 200){
          this.setState({
            buttonLoad: false
          });
          this.props.close();
          this.props.form.resetFields();
          this.props.history.push(`/chat/${res.data.room}`);
        } else if (res.status === 403) {
          window.location.href = res.data.redirect;
        } else{
          this.setState({
            buttonLoad: false
          });
          message.error('error', res.data.error);
        }
      }
    });
  };

  render(){
    const { getFieldDecorator } = this.props.form;
    const {name, visible, close} = this.props;
    const { buttonLoad } = this.state;
    return (
      <Modal
        title={`Write to ${name}`}
        visible={visible}
        onOk={this.send}
        confirmLoading={buttonLoad}
        onCancel={close}
        okText="Send"
      >
        <Form onSubmit={this.send}>
          <Form.Item>
            {getFieldDecorator('text', {
              rules: [
                { required: true, message: 'Please write at least something!'}
              ]
            })(
              <TextArea
                placeholder="Write your message"
                rows={4}
              />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }

}

export default withRouter(Form.create({ name: 'recovery' })(MessageModal));