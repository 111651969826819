import React, {Component} from 'react';
import {
  Avatar, Badge, Button, Card, Col, Icon, Row, Tag,
  Typography,
} from 'antd'
import { NavLink } from 'react-router-dom';
import Constants from '../Constants';
import CheckMark from './CheckMark';


const {Paragraph, Text} = Typography;
const {Meta} = Card;


class SearchResults extends Component {

  render (){
    const {items} = this.props;
    return <div style={{marginTop: 15}}>
      {items.map((v, i) => {
          return (
            <Card style={{width: '100%'}} className="entity-list">
              <Row>
                <Col xs={16}>
                  <Meta
                    avatar={
                      <NavLink to={`/${v.entity}/${v.id}`}>
                        <Badge
                          status={typeof (v.online) !== 'undefined' && v.online ? 'success' : false}
                          offset={[-5, 30]}
                          style={{zIndex: 0}}
                        >
                          <Avatar src={v.basics.photo} />
                        </Badge>
                      </NavLink>}
                    title={<NavLink to={`/${v.entity}/${v.id}`} style={{
                      color: typeof (v.basics.nameColor) !== 'undefined' ? v.basics.nameColor : Constants.primaryColor
                    }}>{v.basics.name} {typeof (v.basics.checkMark) !== 'undefined' && v.basics.checkMark && <CheckMark/>} {v.blocked === true && <Icon type="stop" style={{
                      color: Constants.secondaryColor
                    }}/>}</NavLink>}
                    description={(
                      <div>
                        <Paragraph><Text type="secondary">{v.basics.about}</Text></Paragraph>
                        <Paragraph style={{margin: 0}}><Tag color="blue">{v.followers} {parseInt(v.followers) === 1 ? 'follower' : 'followers'}</Tag></Paragraph>
                      </div>
                    )}
                  />
                </Col>
                <Col xs={8} style={{textAlign: 'right', height: 78, position: 'relative'}}>
                  <NavLink to={`/${v.entity}/${v.id}`} style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}>
                    <Button type={v.following ? 'default' : 'primary'} shape="round">{v.following ? 'Following' : 'Follow'}</Button>
                  </NavLink>
                </Col>
              </Row>
            </Card>
          );
        }
      )}
    </div>
  }

}

export default SearchResults;