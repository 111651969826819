import React, {Component} from 'react';
import {Col, Icon, Progress, Row, Typography} from 'antd';
import Constants from '../Constants';
import styled from 'styled-components';
import IAm from './IAm'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";

const {Text, Paragraph, Title} = Typography;

const Wrap = styled.div`
  padding: 5px;
  
  .ant-typography {
    margin: 0;
  }
`;

class ProfileDetails extends Component {

  render(){
    const {data} = this.props;
    const socials = [
      data.socialNetworks.facebook,
      data.socialNetworks.linkedin,
      data.socialNetworks.telegram,
      data.socialNetworks.twitter,
      data.socialNetworks.instagram,
    ];

    const atLeastOne = () => {
      let one = false;
      socials.map((v) => {
        if (v && v.length > 0 && v.includes('https://')) one = true;
        return null;
      });
      return one;
    };
    return(
      <div className="profile">
        <Wrap>
          <Row>
            <Col xs={8}>
              <Text>Completed:</Text>
            </Col>
            <Col xs={16}>
              <Progress
                strokeColor={{
                  '0%': Constants.secondaryColor,
                  '100%': Constants.primaryColor,
                }}
                percent={data.profile.complete}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Paragraph>
                <Text>Email:</Text>
              </Paragraph>
              <Paragraph>
                <Text>Phone:</Text>
              </Paragraph>
              <Paragraph>
                <Text>Country:</Text>
              </Paragraph>
              <Paragraph>
                <Text>Selfie ID:</Text>
              </Paragraph>
            </Col>
            <Col xs={12} style={{textAlign: 'right'}}>
              <Paragraph>
                <Text style={{
                  color: data.contactInfoConfirmations.email.status ? Constants.primaryColor : Constants.secondaryColor
                }}>{data.profile.contactInfoShared ? <a href={`mailto:${data.profile.email}`}>{data.profile.email}</a> : data.contactInfoConfirmations.email.text}</Text>
              </Paragraph>
              <Paragraph>
                <Text style={{
                  color: data.contactInfoConfirmations.phone.status ? Constants.primaryColor : Constants.secondaryColor
                }}>{data.profile.contactInfoShared ? <a href={`tel:+${data.profile.phone}`}>{`+${data.profile.phone}`}</a> : data.contactInfoConfirmations.phone.text}</Text>
              </Paragraph>
              <Paragraph>
                <Text>{data.profile.basics.country}</Text>
              </Paragraph>
              <Paragraph>
                <Text style={{
                  color: data.kyc.selfieId.status ? Constants.primaryColor : Constants.secondaryColor
                }}>{data.kyc.selfieId.text}</Text>
              </Paragraph>
            </Col>
          </Row>
          {atLeastOne() && (
              <div>
                <Title level={4}>Follow {data.profile.name} at:</Title>
                {data.socialNetworks.instagram && <Paragraph><a href={data.socialNetworks.instagram} target="_blank" rel="noopener noreferrer"><Icon type="instagram"/> Instagram</a></Paragraph>}
                {data.socialNetworks.facebook && <Paragraph><a href={data.socialNetworks.facebook} target="_blank" rel="noopener noreferrer"><Icon type="facebook"/> Facebook</a></Paragraph>}
                {data.socialNetworks.linkedin && <Paragraph><a href={data.socialNetworks.linkedin} target="_blank" rel="noopener noreferrer"><Icon type="linkedin"/> LinkedIn</a></Paragraph>}
                {data.socialNetworks.twitter && <Paragraph><a href={data.socialNetworks.twitter} target="_blank" rel="noopener noreferrer"><Icon type="twitter"/> Twitter</a></Paragraph>}
                {data.socialNetworks.telegram && <Paragraph><a href={data.socialNetworks.telegram} target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faTelegram}/> Telegram</a></Paragraph>}
              </div>
          )}
          <div style={{textAlign: 'center'}}>
            {data.profile.iamStatus && <IAm/>}
            {data.profile.iamStatus && <Text>Approved by CrypTalks Team</Text>}
          </div>
        </Wrap>
      </div>
    )
  }

}

export default ProfileDetails;