import React, {Component} from 'react';
import Spinner from './Spinner';
import Request from '../utils/Request';
import {message} from 'antd';
import FollowRequest from './FollowRequest';
import {scrolledToBottom} from '../helpers/Funcs';
import Nothing from '../template/Nothing'


class EntityRequests extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      records: [],
      loading: true,
      rpp: 12,
      page: 1,
      recordsLoading: false,
      fullLoad: false
    }
  }

  getRecords = async () => {
    const {path} = this.props;
    const {records, rpp, page} = this.state;
    const req = new Request(`${path}&rpp=${rpp}&page=${page}`);
    const res = await req.get();
    if (res.status === 200) {
      if (res.data.length > 0) {
        this.setState({
          records: [...records, ...res.data],
          loading: false,
          recordsLoading: false
        });
      } else {
        this.setState({
          loading: false,
          fullLoad: true,
          recordsLoading: false
        })
      }
    } else {
      message.error(res.data.error);
      this.setState({
        records: records,
        recordsLoading: false,
        loading: false
      });
    }
  };

  async componentDidMount() {
    await this.getRecords();
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount(){
    document.removeEventListener('scroll', this.trackScrolling);
  }

  action = async (action, request_id, index) => {
    const {entity, id} = this.props;
    const {records} = this.state;
    const data = {
      [`${entity}_id`]: id,
      request_id: request_id
    };
    if (entity === 'user') data['type'] = 'follow';
    const req = new Request(`${entity}/${action}_request`, data);
    const res = await req.post();
    if (res.status === 200) {
      message.info(res.data.message);
      records.splice(index, 1);
      this.setState({
        records: records
      })
    } else {
      message.error(res.data.error);
    }

  };

  trackScrolling = async () => {
    const wrappedElement = document.getElementById('member-list');
    if (wrappedElement){
      const {fullLoad, page} = this.state;
      if (scrolledToBottom(wrappedElement) && !fullLoad) {
        await this.setState({
          page: page+1
        });
        await this.getRecords();
      }
    }
  };

  render() {
    const {loading, records, recordsLoading, fullLoad} = this.state;
    if (loading) return <Spinner/>;
    if (records.length === 0) return <Nothing>Nothing is here...</Nothing>;
    return (
      <div>
        {records.map((v, i) => {
          return <FollowRequest
            accept={() => this.action('accept', v.id, i)}
            decline={() => this.action('decline', v.id, i)}
            data={v}
            key={i}
          />
        })}
        {recordsLoading && !fullLoad && <Spinner/>}
      </div>
    )
  }

}

export default EntityRequests;