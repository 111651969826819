import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Card, Empty, message, Typography} from "antd";
import Spinner from "../../ui/Spinner";
import Request from '../../utils/Request';
import styled from "styled-components";
import * as firebase from "firebase";
import QRCode from "react-qr-code";
import Timer from "react-compound-timer";
import pending from '../../../assets/img/pending.png';
import expired from '../../../assets/img/expired.png';


const {Paragraph, Text, Title} = Typography;
const Header = styled.div`
  text-align: right;
  padding: 10px 0;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 20px;
`;


class Payment extends Component {

    constructor(props, context){
        super(props, context);
        this.state = {
            loading: true,
            data: null,
            paid: false,
            expired: false,
            message: null
        }
    }

    async componentDidMount() {
        const {isWidget} = this.props;
        let pid;
        if (isWidget) {
            pid = this.props.pid;
        } else {
            pid = this.props.match.params.pid;
        }
        const r = await new Request('terminal/payment_info?pid='+pid).get();
        if (r.status === 200) {
            if (r.data.message) {
                this.setState({
                    message: r.data.message,
                    loading: false
                })
            } else {
                this.setState({
                    data: r.data,
                    loading: false
                });
                const status = firebase.database().ref('payments').child(r.data.invoice_id).child('status');
                status.on("value", (snapshot) => {
                    if (snapshot.val() === true) {
                        this.setState({
                            message: {
                                text: 'Thank you! Your payment is pending',
                                icon: pending
                            }
                        })
                    }
                });
            }

        } else {
            message.error('Unexpected error');
        }
    }

    render() {
        const {loading, data, message} = this.state;
        if (loading) return <Spinner/>;
        if (message) return <Card>
            <div style={{textAlign: 'center'}}>
                <img src={message.icon} width={75}/>
                <Title style={{marginTop: 15}} level={4}>{message.text}</Title>
            </div>
        </Card>
        const address_link = `bitcoin:${data.wallet_address}?amount=${data.amount}`;
        return <Card>
            <Header>
                <Paragraph>Invoice <Text strong>#{data.invoice_id}</Text></Paragraph>
                <Paragraph>To: <Text strong>{data.name}</Text></Paragraph>
            </Header>
            <div style={{textAlign: 'center'}}>
                <Title level={4} style={{margin: 0}}>Due amount:</Title>
                <Title level={2} style={{margin: 0}}><Text copyable={{text: data.amount}}>{data.amount} {data.crypto}</Text></Title>
                <Paragraph style={{margin: 0}}>Wallet address:</Paragraph>
                <Title copyable={{text: data.wallet_address}} level={4} style={{margin: 0}}>
                    <a href={address_link}>{data.wallet_address}</a>
                </Title>
                <Paragraph>or scan QR code:</Paragraph>
                <a href={address_link}>
                    <QRCode value={data.wallet_address}/>
                </a>
                <Paragraph>
                    Expires in <Timer
                    initialTime={data.expires_in}
                    direction="backward"
                    formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                    checkpoints={[
                        {
                            time: 0,
                            callback: () => {
                                const message = {
                                    text: 'Payment is expired',
                                    icon: expired
                                }
                                this.setState({message: message})
                            },
                        },
                    ]}>
                    {() => (
                        <React.Fragment>
                            <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                        </React.Fragment>
                    )}
                </Timer>
                </Paragraph>
                <Paragraph>Please transfer <Text strong>{data.amount} {data.crypto}</Text> to <Text strong>{data.wallet_address}</Text> in order to complete a payment. <Text strong>Please note</Text> that amount of crypto must be not less than <Text strong>{data.amount} {data.crypto}</Text> in order your payment considered completed</Paragraph>
            </div>
        </Card>
    }

}

export default withRouter(Payment);