import React, {Component} from 'react';
import { Form, Icon, Input, Modal } from 'antd';
import Paragraph from '../template/Paragraph';
import Request from '../utils/Request'


class RecoveryModal extends Component {

  constructor(props, context){
    super(props, context);
    this.state = {
      email: '',
      loading: false
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let req = new Request('user/forgot', {
          email: values.email
        });
        let res = await req.post();
        if (res.status !== 200){
          this.setState({
            loading: false
          });
          this.props.notify('error', res.data.error);
        }else{
          this.setState({
            loading: false
          });
          this.props.notify('success', res.data.message);
          this.props.close();
          this.props.form.resetFields();
        }
      }
    });
  };

  render(){
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title="Password recovery"
        visible={this.props.visible}
        onOk={this.handleSubmit}
        confirmLoading={this.state.loading}
        onCancel={this.props.close}
        okText="Send"
      >
        <Paragraph>Please, enter your e-mail in order to recover your password</Paragraph>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Please put your e-mail!' },
                { type: 'email', message: 'E-mail is not valid!' },
              ],
            })(
              <Input
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="E-mail"
              />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create({ name: 'recovery' })(RecoveryModal);