import React, {Component} from 'react';
import { Form, Switch } from 'antd'
import styled from 'styled-components';
import Provider from '../utils/Provider';

const FormWrap = styled.div`
  padding: 15px;
`;


class NotificationsForm extends Component {

  constructor(props, context){
    super (props, context);
    const provider = new Provider('user');
    const settings = provider.get('pushSettings');
    const {trades_updates, events_updates, comments_updates, groups_updates, groups_requests, users_requests, chat_messages} = settings;
    this.state = {
      trades_updates: trades_updates,
      events_updates: events_updates,
      comments_updates: comments_updates,
      groups_updates: groups_updates,
      groups_requests: groups_requests,
      users_requests: users_requests,
      chat_messages: chat_messages
    }
  }

  render(){
    const {save} = this.props;
    const {trades_updates, events_updates, comments_updates, groups_updates, groups_requests, users_requests, chat_messages} = this.state;
    return (
      <div className="profile-form">
        <Form onSubmit={this.save} layout="vertical">
          <FormWrap>

            <Form.Item label="Events updates" extra="Notifications of following posts with specified date/time">
              <Switch checked={events_updates} onChange={async () => {
                await this.setState({
                  events_updates: !events_updates
                });
                save({events_updates: this.state.events_updates})
              }} />
            </Form.Item>
            <Form.Item label="Comments updates" extra="Notifications whenever your post was commented or liked">
              <Switch checked={comments_updates} onChange={async () => {
                await this.setState({
                  comments_updates: !comments_updates
                });
                save({comments_updates: this.state.comments_updates})
              }} />
            </Form.Item>
            <Form.Item label="Groups updates" extra="Notifications related to activities in groups">
              <Switch checked={groups_updates} onChange={async () => {
                await this.setState({
                  groups_updates: !groups_updates
                });
                save({groups_updates: this.state.groups_updates})
              }} />
            </Form.Item>
            <Form.Item label="Groups requests" extra="New followers, admission to the group, etc">
              <Switch checked={groups_requests} onChange={async () => {
                await this.setState({
                  groups_requests: !groups_requests
                });
                save({groups_requests: this.state.groups_requests})
              }} />
            </Form.Item>
            <Form.Item label="Users requests" extra="Chat/info/follow requests etc">
              <Switch checked={users_requests} onChange={async () => {
                await this.setState({
                  users_requests: !users_requests
                });
                save({users_requests: this.state.users_requests})
              }} />
            </Form.Item>
            <Form.Item label="Chat messages" extra="Notifications whenever somebody writes you">
              <Switch checked={chat_messages} onChange={async () => {
                await this.setState({
                  chat_messages: !chat_messages
                });
                save({chat_messages: this.state.chat_messages})
              }} />
            </Form.Item>
          </FormWrap>
        </Form>
      </div>
    )
  }

}

export default NotificationsForm;