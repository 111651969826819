import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import IAm from "../../ui/IAm";
import Spinner from "../../ui/Spinner";
import styled from "styled-components";
import Request from "../../utils/Request";
import {Badge, Button, Divider, Icon, message} from "antd";
import ReportModal from "../../modals/ReportModal";

const Info = styled.p`
  font-size: 13px;
  color: #a3a3a3;
  margin: 4px 0;
  
  &:hover {
    cursor: pointer;
  }
  
  span {
    font-weight: bold;
    color: #000000;
  }
`;

const SettingsItems = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: 13px;
  
  li {
    margin: 10px 0;
    font-size: 14px;
    
    &:hover {
      cursor: pointer;
    }
  }
`;

class MobileMenu extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            showReportModal: false
        }
    }

    goTo = (path) => {
        this.props.history.push(path);
    };

    logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        this.props.history.push(`/`);
    };

    render() {
        const {data, countInfo, payment} = this.props;
        const {showReportModal} = this.state;
        return <div>
            {data.iamStatus && <IAm style={{
                width: '100',
                height: '100'
            }} /> }
            {countInfo.accountType === 1 && <SettingsItems>
                <li onClick={() => this.goTo('/wallet')}><Icon type="wallet" /> My wallet</li>
                <Divider/>
            </SettingsItems>}
            <SettingsItems>
                {countInfo && countInfo.followRequests > 0 && <li onClick={() => this.goTo('/follow_requests')}><Icon type="user-add" /> Follow Requests <Badge count={countInfo.followRequests}/></li>}
                <li onClick={() => this.goTo('/group/create')}><Icon type="usergroup-add" /> Create Group</li>
                {countInfo.accountType === 0 && countInfo.iam_status && <Button onClick={() => {
                    payment();
                }} type={'primary'} icon={'arrow-up'} shape={'round'} block>Upgrade to PRO</Button>}
            </SettingsItems>
            <Divider/>
            <SettingsItems>
                <li onClick={() => this.goTo('/settings/account')}><Icon type="setting" /> Account Settings</li>
                <li onClick={() => this.goTo('/settings/profile')}><Icon type="user"/> Profile Settings</li>
                <li onClick={() => this.goTo('/settings/notifications')}><Icon type="alert"/> Notifications Settings</li>
                <li onClick={() => this.goTo('/settings/kyc')}><Icon type="dollar"/> Finance</li>
                <li onClick={() => {
                    this.setState({
                        showReportModal: true
                    });
                }}><Icon type="question-circle"/> Support</li>

            </SettingsItems>
            <Divider/>
            <SettingsItems>
                <li onClick={this.logout}><Icon type="logout"/> Log out</li>
            </SettingsItems>
            <ReportModal
                visible={showReportModal}
                close={() => {
                    this.setState({
                        showReportModal: false
                    })
                }}
                source="Support Request"
                text="Please, write your request"
            />
        </div>
    }
}

export default withRouter(MobileMenu);