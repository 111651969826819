import styled from 'styled-components';
import ServerHost from '../helpers/ServerHost';


const CheckMark = styled.span`
    width: 12px;
    height: 10px;
    background-image: url("${ServerHost('img/icons/check_mark.png')}");
    background-size: contain;
        display: inline-block;
    margin: 0 5px;
    background-repeat: no-repeat;
`

export default CheckMark;