import React, {Component} from 'react';
import SessionData from '../utils/SessionData';
import Request from '../utils/Request';
import {message, Comment, Icon, Avatar} from 'antd';
import {scrolledToBottom} from '../helpers/Funcs';
import Spinner from './Spinner'
import { NavLink } from 'react-router-dom'
import CheckMark from './CheckMark'
import Nothing from '../template/Nothing'


class ContactsList extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      loading: true,
      list: [],
      rpp: 12,
      page: 1,
      recordsLoading: false,
      recordsLoadingStarted: false
    }
  }

  getList = async () => {
    const {rpp, page, list} = this.state;
    const session = new SessionData(`contacts:page_${page}`);
    const info = session.get();
    if (!info) {
      const req = new Request(`user/contacts?rpp=${rpp}&page=${page}`);
      const res = await req.get();
      if (res.status === 200) {
        if (res.data.length > 0) {
          this.setState({
            list: [...list, ...res.data],
            recordsLoading: false,
            loading: false,
            recordsLoadingStarted: false
          });
          session.set(res.data);
        } else {
          this.setState({
            recordsLoading: false,
            loading: false,
            page: page - 1
          })
        }
      } else {
        message.error(res.data.error);
      }
    } else {
      let prevRecords = [];
      for (let i = 1; i < page + 1; i++) {
        let prevRoute = `contacts:page_${i}`;
        let prevRec = new SessionData(prevRoute);
        prevRecords = [...prevRecords, ...prevRec.get()];
      }
      await this.setState({
        list: prevRecords,
        recordsLoading: false,
        recordsLoadingStarted: false,
        loading: false
      });
    }
  }

  trackScrolling = async () => {
    const wrappedElement = document.getElementById('contacts-list');
    if (wrappedElement){
      const {recordsLoadingStarted, page} = this.state;
      if (scrolledToBottom(wrappedElement) && !recordsLoadingStarted) {
        await this.setState({
          recordsLoading: true,
          recordsLoadingStarted: true,
          page: page+1
        });
        await this.getList();
      }
    }
  };

  async componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
    const page = new SessionData(`page:contacts`);
    await this.setState({
      page: page.get() ? page.get() : 1
    });
    await this.getList();
    window.scrollTo(0, new SessionData(`scroll:contacts`).get());
  }

  componentWillUnmount() {
    const offset = window.pageYOffset;
    const session = new SessionData(`scroll:contacts`);
    session.set(offset);
    const page = new SessionData(`page:contacts`)
    page.set(this.state.page);
    document.removeEventListener('scroll', this.trackScrolling);
  }

  render () {
    const {loading, list, recordsLoading, recordsLoadingStarted} = this.state;
    if (loading) return <Spinner/>;
    if (list.length === 0) return <Nothing>No contacts found</Nothing>
    return (
      <div id="contacts-list">
        {list.map((v, i) => {
          const actions = [
            <span key="email">
              <a href={`mailto:${v.email}`}>
                <Icon type="mail" />
                <span style={{ paddingLeft: 8, cursor: 'auto' }}>{v.email}</span>
              </a>
            </span>,
            <span key="phone">
              <a href={`tel:${v.phone}`}>
                <Icon type="phone" />
                <span style={{ paddingLeft: 8, cursor: 'auto' }}>{v.phone}</span>
              </a>
            </span>
          ];
          return (
            <Comment
              key={i}
              actions={actions}
              author={<NavLink style={{color: v.basics.nameColor}} to={`/user/${v.basics.id}`}>
                {v.basics.name}
                {v.basics.checkMark && <CheckMark/>}
              </NavLink>}
              avatar={
                <NavLink style={{color: v.basics.nameColor}} to={`/user/${v.basics.id}`}>
                  <Avatar
                    src={v.basics.photo}
                    alt={v.basics.name}
                  />
                </NavLink>
              }
              content={
                <p>
                  {v.basics.about}
                </p>
              }
            />
          )
        })}
        {recordsLoading && <Spinner/>}
      </div>
    )
  }

}

export default ContactsList;