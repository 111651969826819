import React, {Component} from 'react';
import styled from 'styled-components';
import {Button, Form, Icon, Input, Switch, Upload, Modal} from 'antd'
import Request from '../utils/Request';
import {uploadPath} from '../helpers/ServerHost';
import {getBase64, beforeUpload, beforeDocumentUpload} from '../helpers/Funcs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { withRouter } from 'react-router-dom';
import Spinner from '../ui/Spinner';
import Constants from "../Constants";
import AmlModal from "../modals/AmlModal";


const telegramIcon = <FontAwesomeIcon icon={faTelegram} style={{ color: 'rgba(0,0,0,.25)' }} />;

const PicsUpload = styled.div`
  width: 100%;
  height: 150px;
  position: relative;
  
  
`;

const BackgroundImage = styled.div`
  width: 100%;
  height: 125px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
`;

const Avatar = styled.div`
  width: 104px;
  height: 104px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-repeat: no-repeat;
`;

const Layer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  position: relative;
  
  svg {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const FormWrap = styled.div`
  padding: 15px;
`;

const FileName = styled.div`
  color: #93928e;
  font-size: 12px;
`;


const {TextArea} = Input;

class GroupForm extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      avatar: '',
      avatarUploading: false,
      backgroundImage: '',
      backgroundUploading: false,
      name: '',
      about: '',
      website: '',
      facebook: '',
      linkedin: '',
      telegram: '',
      twitter: '',
      'private': false,
      showSocials: false,
      motto: '',
      chatAllowed: false,
      kyc: false,
      showBusiness: false,
      business: false,
      businessStatus: {
        aml: {},
        cbs: {},
        poa: {},
        aoi: {}
      },
      aoiFileName: null,
      poaFileName: null,
      cbsFileName: null,
      wallet_address: null,
      showAmlModal: false,
      aml: false
    }
  }

  setBackground = async (file) => {
    if(beforeUpload(file)){
      let base64 = await getBase64(file);
      await this.setState({
        backgroundImage: base64,
        backgroundUploading: true
      });
    }
  };

  setAvatar = async (file) => {
    if(beforeUpload(file)){
      let base64 = await getBase64(file);
      await this.setState({
        avatar: base64,
        avatarUploading: true
      });
    }
  };

  handleChange = (e) => {
    const {save} = this.props;
    this.setState({
      [e.target.name]: e.target.value
    });
    save({[e.target.name]: e.target.value})
  };

  async componentDidMount(){
    const {edit, save, data, id} = this.props;
    if (edit && data) {
      save({group_id: id});
      const socials = [
        data.socialNetworks.facebook,
        data.socialNetworks.linkedin,
        data.socialNetworks.telegram,
        data.socialNetworks.twitter,
        data.socialNetworks.website,
        data.socialNetworks.instagram
      ];

      const atLeastOne = () => {
        let one = false;
        socials.map((v) => {
          if (v.length > 0) one = true;
          return null;
        });
        return one;
      };

      this.setState({
        avatar: data.basics.photo,
        backgroundImage: data.backgroundImage,
        name: data.basics.name,
        about: data.basics.about,
        motto: data.basics.motto,
        'private': data.basics.private,
        showSocials: atLeastOne(),
        website: data.socialNetworks.website,
        facebook: data.socialNetworks.facebook,
        linkedin: data.socialNetworks.linkedin,
        twitter: data.socialNetworks.twitter,
        telegram: data.socialNetworks.telegram,
        instagram: data.socialNetworks.instagram,
        chatAllowed: data.basics.chatAllowed,
        business: data.business,
        showBusiness: data.business,
        businessStatus: data.businessStatus,
        wallet_address: data.walletAddress,
        aml: data.businessStatus.aml.status
      });
    }
    // const r = await new Request('user/kyc_status').get();
    this.setState({
      // kyc: r.data.status,
      kyc: true,
      loading: false
    })

    if (window.location.search.includes('verification_popup=1')) {
      Modal.info({
        title: 'Business verification',
        content: 'Please, submit required documents for your Business verification. We will notify you once your business is successfully verified. Status of verification you can find on this page.',
        onOk() {},
      });
    }
    if (window.location.search.includes('verification_popup=2')) {
      Modal.info({
        title: 'Business verification',
        content: ' Pending CrypTalks compliance team approval',
        onOk() {},
      });
    }
  }

  beforeUpload = async (file, name, fileName) => {
    let base64 = await getBase64(file);
    if (beforeDocumentUpload(file)) {
      this.setState({
        [name]: base64,
        [fileName]: [file.name, ' ', <Icon key="icon" type="check-circle" style={{color: Constants.green, fontSize: 18, margin: '10px 0'}}/>]
      })
    }
  };

  upload = async (file, b64, name) => {
    const {save} = this.props;
    const req = new Request('utils/upload', {
      file: this.state[b64]
    });
    let res = await req.post();
    if (res.status === 200) {
      save({[name]: res.data.url});
    }
  };

  render () {
    const {save, edit, data} = this.props;
    if (!data && edit) return null;
    const {
      avatar,
      backgroundImage,
      name,
      about,
      showSocials,
      website,
      twitter,
      linkedin,
      facebook,
      telegram,
      motto,
      instagram,
      kyc,
      showBusiness,
      businessStatus,
      aoiFileName,
      poaFileName,
      cbsFileName,
      wallet_address,
      showAmlModal,
      aml,
      backgroundUploading,
      avatarUploading
    } = this.state;
    return (
      <div className="profile-form">
        <Form onSubmit={this.save} layout="vertical">
          <PicsUpload>
            <Upload
              name="background"
              listType="picture-card"
              className="background-uploader"
              showUploadList={false}
              action={uploadPath}
              beforeUpload={this.setBackground}
              customRequest={async () => {
                let req = new Request('utils/upload', {
                  file: backgroundImage
                });
                let res = await req.post();
                save({background: res.data.url});
                this.setState({backgroundUploading: false})
              }}
            >
              {backgroundUploading ? (
                  <Spinner/>
              ) : backgroundImage ? (
                  <BackgroundImage src={backgroundImage}><Layer><Icon type={'picture'} /></Layer></BackgroundImage>
              ) : <Icon type={'picture'} />}
            </Upload>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={uploadPath}
              beforeUpload={this.setAvatar}
              customRequest={async () => {
                let req = new Request('utils/upload', {
                  file: avatar
                });
                let res = await req.post();
                save({photo: res.data.url});
                this.setState({avatarUploading: false})
              }}
            >
              {avatarUploading ? <Spinner/> : avatar ? <Avatar src={avatar}><Layer style={{
                borderRadius: '50%'
              }}><Icon type={'picture'} /></Layer></Avatar> : <Icon type={'picture'} />}
            </Upload>
          </PicsUpload>
          <FormWrap>
            <Form.Item label="Name of a group">
              <Input
                name="name"
                onChange={this.handleChange}
                placeholder="Name of a group"
                value={name}
              />
            </Form.Item>
            <Form.Item label="Group motto">
             <Input
               placeholder="Group motto"
               value={motto}
               name="motto"
               onChange={this.handleChange}
             />
            </Form.Item>
            <Form.Item label="About group">
             <TextArea
               placeholder="About group"
               value={about}
               name="about"
               onChange={this.handleChange}
             />
            </Form.Item>
            <Form.Item label="Business group" extra={kyc ?
                ['Start your business with CrypTalks ', <a href="https://i.cryptalks.app/groups" target={'_blank'}><Icon type={'question-circle'}/></a>] :
                ['You have to submit KYC to open a Business group: ', <Button onClick={() => this.props.history.push('/settings/kyc')} type={'link'} size={'small'}>Proceed</Button>]}>
              <Switch checked={this.state.business} onChange={async (e) => {
                await this.setState({
                  showBusiness: !showBusiness,
                  business: e
                });
                this.state.showBusiness && Modal.info({
                  title: 'Start your business with CrypTalks',
                  content: 'Sell products & receive Bitcoin to your wallet with your own Business group at CrypTalks. First month is free, starting from the 2nd month you will be charged 18$/month.',
                  onOk() {},
                });
                save({'business': this.state.business})
              }} disabled={!kyc}/>
            </Form.Item>
            {showBusiness && <div>
              <Form.Item label="Article of incorporation/Authorized dealer" extra={edit ? businessStatus.aoi.text : ''}>
                <Upload
                    name="aoi"
                    showUploadList={false}
                    action={uploadPath}
                    beforeUpload={(file) => this.beforeUpload(file, 'b64aoi', 'aoiFileName')}
                    customRequest={(file) => this.upload(file, 'b64aoi', 'article_of_incorporation')}
                >
                  <Button disabled={edit ? businessStatus.aoi.status : false}>
                    <Icon type="upload" /> Click to Upload
                  </Button>
                  <FileName>{aoiFileName}</FileName>
                </Upload>
              </Form.Item>
              <Form.Item label="Proof of address from your place of business" extra={edit ? businessStatus.poa.text : 'Utility bill or equivalent, no older than 3 calendar months'}>
                <Upload
                    name="poa"
                    showUploadList={false}
                    action={uploadPath}
                    beforeUpload={(file) => this.beforeUpload(file, 'b64poa', 'poaFileName')}
                    customRequest={(file) => this.upload(file, 'b64poa', 'proof_of_address')}
                >
                  <Button disabled={edit ? businessStatus.poa.status : false}>
                    <Icon type="upload" /> Click to Upload
                  </Button>
                  <FileName>{poaFileName}</FileName>
                </Upload>
              </Form.Item>
              <Form.Item label="Corporate bank statement" extra={edit ? businessStatus.cbs.text : 'No older than 3 calendar months'}>
                <Upload
                    name="cbs"
                    showUploadList={false}
                    action={uploadPath}
                    beforeUpload={(file) => this.beforeUpload(file, 'b64cbs', 'cbsFileName')}
                    customRequest={(file) => this.upload(file, 'b64cbs', 'bank_statement')}
                >
                  <Button disabled={edit ? businessStatus.cbs.status : false}>
                    <Icon type="upload" /> Click to Upload
                  </Button>
                  <FileName>{cbsFileName}</FileName>
                </Upload>
              </Form.Item>
              <Form.Item label="AML" extra={edit ? businessStatus.aml.text : aml ? 'Confirmed' : 'Not confirmed'}>
                <Button type="primary" shape={'round'} onClick={() => this.setState({
                  showAmlModal: true
                })} disabled={aml}>Sign an AML document </Button>
              </Form.Item>
              <Form.Item label="Business wallet address">
                <Input
                    placeholder="Bitcoin Wallet Address"
                    value={wallet_address}
                    name="wallet_address"
                    onChange={this.handleChange}
                />
              </Form.Item>
            </div>}
            <Form.Item label="Private group">
              <Switch checked={this.state.private} onChange={async () => {
                await this.setState({
                  'private': !this.state.private
                });
                save({'private': this.state.private})
              }} />
            </Form.Item>
            <Form.Item label="Chat">
              <Switch checked={this.state.chatAllowed} onChange={async () => {
                await this.setState({
                  chatAllowed: !this.state.chatAllowed
                });
                save({allow_chat: this.state.chatAllowed})
              }} />
            </Form.Item>
            <Form.Item label="Add social networks">
              <Switch checked={showSocials} onChange={async () => {
                await this.setState({
                  showSocials: !showSocials
                });
              }} />
            </Form.Item>
            {showSocials && (
              <div>
                <Form.Item label="Website">
                  <Input
                    prefix={<Icon type="chrome" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Website"
                    value={website}
                    name="website"
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item label="Instagram">
                  <Input
                      prefix={<Icon type="instagram" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Instagram"
                      value={instagram}
                      name="instagram"
                      onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item label="Facebook">
                  <Input
                    prefix={<Icon type="facebook" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Facebook"
                    value={facebook}
                    name="facebook"
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item label="LinkedIn">
                  <Input
                    prefix={<Icon type="linkedin" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="LinkedIn"
                    value={linkedin}
                    name="linkedin"
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item label="Twitter">
                  <Input
                    prefix={<Icon type="twitter" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Twitter"
                    value={twitter}
                    name="twitter"
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item label="Telegram">
                  <Input
                    prefix={telegramIcon}
                    placeholder="Telegram"
                    value={telegram}
                    name="telegram"
                    onChange={this.handleChange}
                  />
                </Form.Item>
              </div>
            )}
          </FormWrap>
        </Form>
        <AmlModal
            visible={showAmlModal}
            close={() => {
              this.setState({
                showAmlModal: false
              });
            }}
            accepted={edit ? businessStatus.aml.status : false}
            save={(aml) => {
              this.setState({
                aml: aml
              });
              save({aml: aml})
            }}
            path={false}
        />
      </div>
    )
  }



}

export default withRouter(GroupForm);
