import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginScreen from './components/screens/LoginScreen';
import 'react-notifications/lib/notifications.css';
import RecoveryScreen from './components/screens/RecoveryScreen';
import AuthStack from './components/stacks/AuthStack';
import ProtectedStack from './components/stacks/ProtectedStack';
import ConfirmationScreen from './components/screens/ConfirmationScreen';
import Session from "./components/Session";
import ProductsWidget from "./components/ui/ProductsWidget";


class App extends Component{

  render(){
    return (
      <Router basename="/">
        <Switch>
          <Route exact={true} path="/" render={() => <AuthStack screen={<LoginScreen/>}/>}/>
          <Route exact={true} path="/recover/:token" render={()=> <AuthStack screen={<RecoveryScreen/>}/>}/>+
          <Route exact={true} path="/confirm/:token" render={()=> <ConfirmationScreen/>}/>

          <Route exact={true} path="/feed" render={()=> <ProtectedStack module="feed" />}/>
          <Route exact={true} path="/popular" render={()=> <ProtectedStack module="popular"/>}/>
          <Route exact={true} path="/search/:query" render={()=> <ProtectedStack module="popular"/>}/>
          <Route exact={true} path="/notifications" render={()=> <ProtectedStack module="notifications"/>}/>
          <Route exact={true} path="/contacts" render={()=> <ProtectedStack module="contacts"/>}/>
          <Route exact={true} path="/me" render={()=> <ProtectedStack module="personal"/>}/>
          <Route exact={true} path="/wallet" render={()=> <ProtectedStack module="wallet"/>}/>

          <Route exact={true} path="/chat" render={()=> <ProtectedStack module="chat"/>}/>
          <Route exact={true} path="/chat/:room_id" render={()=> <ProtectedStack module="chat_dialog"/>}/>
          <Route exact={true} path="/payment/:pid" render={()=> <ProtectedStack module="payment"/>}/>

          <Route exact={true} path="/group/create" render={()=> <ProtectedStack module="create_group"/>}/>
          <Route exact={true} path="/group/:id/settings" render={()=> <ProtectedStack module="group_settings"/>}/>
          <Route exact={true} path="/group/:id/settings/general" render={()=> <ProtectedStack module="update_group"/>}/>
          <Route exact={true} path="/group/:id/settings/followers" render={()=> <ProtectedStack module="group_followers"/>}/>
          <Route exact={true} path="/group/:id/settings/staff" render={()=> <ProtectedStack module="group_moderators"/>}/>
          <Route exact={true} path="/group/:id/settings/blocked" render={()=> <ProtectedStack module="group_blocked"/>}/>
          <Route exact={true} path="/group/:id/settings/requests" render={()=> <ProtectedStack module="group_requests"/>}/>
          <Route exact={true} path="/group/:id/chat" render={()=> <ProtectedStack module="group_chat"/>}/>
          <Route exact={true} path="/group/:id/wallet" render={()=> <ProtectedStack module="wallet"/>}/>

          <Route exact={true} path="/followers" render={()=> <ProtectedStack module="followers"/>}/>
          <Route exact={true} path="/following" render={()=> <ProtectedStack module="following"/>}/>
          <Route exact={true} path="/groups" render={()=> <ProtectedStack module="groups"/>}/>

          <Route exact={true} path="/follow_requests" render={()=> <ProtectedStack module="user_requests"/>}/>
          <Route exact={true} path="/settings/:stype" render={()=> <ProtectedStack module="settings"/>}/>
          <Route exact={true} path="/post/:type/:id" render={()=> <ProtectedStack module="post"/>}/>
          <Route exact={true} path="/:entity/:id" render={()=> <ProtectedStack module="entity"/>}/>

          <Route exact={true} path="/:entity/:id/followers" render={()=> <ProtectedStack module="followers"/>}/>
          <Route exact={true} path="/:entity/:id/following" render={()=> <ProtectedStack module="following"/>}/>
          <Route exact={true} path="/:entity/:id/groups" render={()=> <ProtectedStack module="groups"/>}/>
          <Route exact={true} path="/:entity/:id/cart" render={()=> <ProtectedStack module="cart"/>}/>
          <Route exact={true} path="/session" render={()=> <Session/>}/>
          <Route exact={true} path="/products/:entity/:id" render={()=> <ProductsWidget/>}/>

        </Switch>
      </Router>
    );
  }
}

export default App;
