import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import Provider from '../utils/Provider';
import MobileStack from './MobileStack';
import DesktopStack from '../desktop/template/DesktopStack'
import {Modal, Typography} from "antd";

const {Paragraph} = Typography;

class ProtectedStack extends Component {

  constructor (props, context){
    super(props, context);
    const provider = new Provider('user');
    const tokens = provider.get('tokens');
    const confs = provider.get('contactInfoConfirmations');
    const params = {};
    this.state = {
      loggedIn: tokens && confs ? confs.phone.status : false,
      width: window.innerWidth,
      module: props.module
    };

    if (window.performance) {
      if (performance.navigation.type === 1) {
        sessionStorage.clear();
      }
    }
  }

  componentDidMount() {
    // const profile = new Provider('user').get('profile');
    // const self = this;
    // if (profile) {
    //
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.module !== this.props.module) {
      this.setState({
        module: this.props.module
      });
    }
  }

  render (){
    const {module, loggedIn} = this.state;
    const allowedModules = [
      'post',
      // 'payment'
    ];
    if (!loggedIn && !allowedModules.includes(module)) return <Redirect to="/"/>;
    if (module === 'post') {
      const user = new Provider('user').get();
      if (!user) {
        const {type, id} = this.props.match.params;
        window.location.href = 'https://api.cryptalks.app/prerender/post/' + type + '/' + id;
      }
    }
    return (
      <div>
        {/*{this.state.width < 768 ? <MobileStack module={module}/> : <DesktopStack module={module} />}*/}
        <DesktopStack module={module}/>
      </div>
    )
  }


}

export default withRouter(ProtectedStack);