import React, {Component, cloneElement} from 'react';
import Provider from '../utils/Provider'
import Request from '../utils/Request';
import { Redirect, withRouter } from 'react-router-dom'
import Blank from '../template/Blank';
import qs from 'qs';
import {  message } from 'antd';
import { subscribe } from '../../push-notification';
import ConfirmPhoneScreen from '../screens/ConfirmPhoneScreen'


class AuthStack extends Component {

  constructor(props, context){
    super(props, context);
    const provider = new Provider('user');
    const tokens = provider.get('tokens');
    const confs = provider.get('contactInfoConfirmations');
    const profile = provider.get('profile');
    const params = {};
    window.location.search.substr(1).split('&').map(function (v) {
      const kv = v.split('=');
      if (kv[0]) {
        Object.assign(params, {[kv[0]]: kv[1]});
      }
      return null;
    });
    this.state = {
      loggedIn: tokens && confs ? confs.phone.status : false,
      confirmPhone: confs ? !confs.phone.status : false,
      phone: profile ? profile.phone : '',
      params: params
    }
  }

  notify = (type, text) => {
    message[type](text);
  };

  saveData = async (data) => {
    const provider = new Provider('user');
    await provider.set(data);
    this.setState({
      loggedIn: data.contactInfoConfirmations.phone.status,
      confirmPhone: !data.contactInfoConfirmations.phone.status,
      phone: data.profile.phone
    });
    try {
      subscribe();
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  responseFacebook = async (response) => {
    if (response.accessToken){
      let req = new Request('socials/facebook', {
        token: response.accessToken,
        src: sessionStorage.getItem('affiliateSource') ? sessionStorage.getItem('affiliateSource') : '',
        promo: sessionStorage.getItem('promo') ? sessionStorage.getItem('promo') : null,
      });
      let res = await req.post();
      if (res.status !== 200){
        console.log(res);
        this.notify('error', res.data.error);
      }else{
        await this.saveData(res.data);
        subscribe();
      }
    }
  };

  responseGoogle = async (response) => {
    console.log(response);
    if (!response.error) {
      const { profileObj} = response;
      let req = new Request('socials/google', {
        fullName: profileObj.name,
        email: profileObj.email,
        id: profileObj.googleId,
        photo: profileObj.imageUrl,
        src: sessionStorage.getItem('affiliateSource') ? sessionStorage.getItem('affiliateSource') : '',
        promo: sessionStorage.getItem('promo') ? sessionStorage.getItem('promo') : null,
      });
      let res = await req.post();
      if (res.status !== 200){
        this.notify('error', res.data.error);
      }else{
        await this.saveData(res.data);
        subscribe();
      }
    }
  };

  componentDidMount(){
    if (!this.state.loggedIn){
      let query = qs.parse(this.props.location.search);
      if (query['?src']){
        sessionStorage.setItem('affiliateSource', query['?src'])
      }
    }
  }

  render(){
    if (this.state.loggedIn && this.state.params.postType && this.state.params.id) {
      const {postType, id} = this.state.params;
      return <Redirect to={'/post/' + postType + '/' + id}/>;
    }
    if (this.state.loggedIn) return <Redirect to="/feed"/>;
    return (
      <div>
        <Blank content={
          this.state.confirmPhone ? <ConfirmPhoneScreen params={this.state.params} phone={this.state.phone} key={'confirm'}/> : cloneElement(this.props.screen, {
            notify: this.notify,
            saveData: this.saveData,
            responseGoogle: this.responseGoogle,
            responseFacebook: this.responseFacebook,
            params: this.state.params
          })
        } className="vertical-centered"/>
      </div>
    )
  }

}

export default withRouter(AuthStack);

