import styled from 'styled-components';
import ServerHost from '../../helpers/ServerHost';

export default styled.div`
  width: ${props => props.shrinked ? '25px' : '200px'};
  height: ${props => props.shrinked ? '25px' : '60px'};;
  background-image: url("${props => props.shrinked ? ServerHost('img/logos/logo144.png') : ServerHost('img/logos/original.png')}");
  background-size: contain;
  margin: ${props => props.shrinked ? '10px auto' : '5px auto'};
  background-repeat: no-repeat;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 35px;
  }
`;