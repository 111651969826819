import React, {Component} from 'react';
import Closed from './Closed';
import {
  Input,
  Row,
  Col,
  Button,
  Tooltip,
  Upload,
  Form,
  Icon,
  DatePicker,
  message,
  Select,
  Empty,
  Modal,
  Typography
} from 'antd'
import Avatar from '../../ui/Avatar';
import styled from 'styled-components';
import {getBase64, beforeUpload, scrolledToBottom} from '../../helpers/Funcs';
import {uploadPath} from '../../helpers/ServerHost';
import Request from '../../utils/Request';
import LocationSearch from '../../ui/LocationSearch';
import { withRouter } from 'react-router-dom'
import Nothing from "../../template/Nothing";
import CreatePostStack from "../../stacks/CreatePostStack";
import Spinner from "../../ui/Spinner";
import Provider from "../../utils/Provider";


const FormWrap = styled.div`
  padding: 10px;
  width: inherit;
  border: 1px solid #f3f2ef;
  border-top: none;
  border-bottom: 10px solid #f3f2ef;
  background-color: #fff;
`;

const Preview = styled.div`
  width: 100%;
  border-radius: 20px;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  margin-top: 10px;
  position: relative;
`;


const initialState = {
  picturePreview: false,
  pictureLink: '',
  showLink: false,
  showLocation: false,
  showDate: false,
  text: '',
  link: '',
  address: '',
  coords: '',
  event_time: false,
  loading: false,
  showProduct: false,
  products: [],
  productsLoading: true,
  selectedProduct: 0,
  pictureLoading: false
};

const {Option} = Select;
const {Paragraph} = Typography;

class Posts extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = initialState;
  }

  manageField = (field) => {
    this.setState({
      [`show${field}`]: !this.state[`show${field}`]
    })
  };

  setPicture = async (file) => {
    if(beforeUpload(file)){
      let base64 = await getBase64(file);
      await this.setState({
        picturePreview: base64,
        pictureLoading: true
      });
    }
  };

  setLocation = (address, coords) => {
    this.setState({
      address: address,
      coords: coords
    })
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  handleDate = (e) => {
    this.setState({
      event_time: e.format()
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {form, add} = this.props;
    const {pictureLink, address, coords, selectedProduct} = this.state;
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        let postData = {
          text: values.text,
          link: values.link,
          image: pictureLink,
          address: address,
          coords: coords,
          event_time: values.date,
          product: selectedProduct
        };
        let req = new Request('post/shout/create', postData);
        let res = await req.post();
        if (res.status === 200){
          this.setState(initialState);
          add(res.data);
          form.resetFields();
          const elements = [
            `Your ${res.data.postType} sent successfully. `,
            <Button onClick={() => {
              this.props.history.push(`/post/${res.data.postType}/${res.data.id}`);
              message.destroy();
            }} type="primary" shape='round' style={{fontWeight: 'bold'}}>Check it out!</Button>
          ];
          message.success(elements)
        } else if (res.status === 403) {
          message.info(res.data.error);
          this.props.history.push('/settings/account');
        } else {
          message.error(res.data.error);
          this.setState({
            loading: false
          });
        }
      }
    });
  };

  getProducts = async () => {
    const r = await new Request(`product/my`).get();
    if (r.status === 200) {
      this.setState({
        products: r.data,
        productsLoading: false
      });
    } else {
      message.error('Something went wrong');
    }
  }

  loadProducts = async () => {
    const {showProduct, products} = this.state;
    if (showProduct){
      this.setState({
        showProduct: false,
        selectedProduct: 0
      })
    } else {
      this.setState({
        showProduct: true
      });
      if(products.length === 0) await this.getProducts();
    }
  }

  renderForm = () => {
    const {postForm} = this.props;
    const {
      picturePreview,
      showLink,
      showLocation,
      showDate,
      loading,
      showProduct,
      products,
      productsLoading,
      selectedProduct,
      pictureLoading
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const productsSelectProps = {
      onChange: (val) => this.setState({selectedProduct: val}),
      placeholder: "Select a product",
      style: {width: '100%'},
      loading: productsLoading
    }
    if (!productsLoading && selectedProduct) productsSelectProps.value = selectedProduct;
    if (postForm) {
      switch (postForm) {
        case 'solo':
          return (
            <FormWrap>
              <Row>
                <Col md={3} xs={3}>
                  <Avatar width={48} height={48}/>
                </Col>
                <Col md={21} xs={21}>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Item>
                      {getFieldDecorator('text', {
                        rules: [
                          {
                            required: true,
                            message: 'Please put some content',
                          }
                        ],
                      })(<Input.TextArea
                        autoSize={{
                          minRows: 2,
                          maxRows: 12
                        }}
                        placeholder="What's up?"
                        name="text"
                        dir={'auto'}
                      />)}
                    </Form.Item>
                    {showLink && <Form.Item hasFeedback>
                      {getFieldDecorator('link')(<Input
                        name="link"
                        placeholder="Share a link"
                        prefix={<Icon type="link" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      />)}
                    </Form.Item>}
                    {showLocation && <Form.Item>
                      <LocationSearch setLocation={this.setLocation}/>
                    </Form.Item>}
                    {showDate && <Form.Item hasFeedback>
                      {getFieldDecorator('date')(<DatePicker format="YYYY-MM-DD HH:mm" style={{width: '100%'}} showTime={{ format: 'HH:mm' }} placeholder="Select Time"/>)}
                    </Form.Item>}
                    {showProduct && <Form.Item>
                      <Select {...productsSelectProps}>
                        {products.length > 0 ? products.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        }) : null}
                      </Select>
                    </Form.Item>}
                    {pictureLoading && (
                        <div style={{
                          textAlign: 'center',
                          width: '100%'
                        }}>
                          <Spinner/>
                        </div>
                    )}
                    {picturePreview && !pictureLoading && (
                      <Form.Item>
                        <Preview src={picturePreview}>
                          <Button style={{
                            position: 'absolute',
                            right: '15px',
                            top: '15px'
                          }} type="default" icon="close" shape="circle" onClick={() => {
                            this.setState({
                              picturePreview: false,
                              pictureLink: ''
                            })
                          }}/>
                        </Preview>
                      </Form.Item>
                    )}

                    <Row style={{margin: '10px 0'}}>
                      <Col md={18}>
                        <Tooltip title="Picture" placement="bottom">
                          <Upload
                            name="picture"
                            showUploadList={false}
                            action={uploadPath}
                            beforeUpload={this.setPicture}
                            customRequest={async () => {
                              let req = new Request('utils/upload', {
                                file: picturePreview
                              });
                              let res = await req.post();
                              if (res.status === 200) {
                                this.setState({
                                  pictureLink: res.data.url,
                                  pictureLoading: false
                                });
                                message.success('Picture successfully uploaded');
                              } else {
                                this.setState({
                                  picturePreview: false,
                                  pictureLoading: false,
                                  pictureLink: ''
                                });
                                message.error('Image was not uploaded');
                              }
                            }}
                          >
                            <Button disabled={pictureLoading} title="Picture" size="small" type="link" icon="picture"/>
                          </Upload>
                        </Tooltip>
                        <Tooltip title="Link" placement="bottom"><Button title="Link" onClick={() => this.manageField('Link')} size="small" type="link" icon="link"/></Tooltip>
                        <Tooltip title="Location" placement="bottom"><Button onClick={() => this.manageField('Location')} size="small" type="link" icon="environment"/></Tooltip>
                        <Tooltip title="Date" placement="bottom"><Button onClick={() => this.manageField('Date')} size="small" type="link" icon="calendar"/></Tooltip>
                        <Tooltip title="Product"><Button title="Product" onClick={this.loadProducts} size="small" type="link" icon="dollar"/></Tooltip>
                      </Col>
                      <Col md={6}>
                        <Button
                          type="primary"
                          block
                          shape="round"
                          loading={loading}
                          htmlType="submit"
                          disabled={pictureLoading}
                        >Post</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </FormWrap>
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  update = async (index, data) => {
    const {update} = this.props;
    await update(index, data);
  };

  trackScrolling = async () => {
    const {htmlId} = this.props;
    const wrappedElement = document.getElementById(htmlId);
    const {onScrollToBottom, recordsLoading} = this.props;
    if (wrappedElement && scrolledToBottom(wrappedElement) && !recordsLoading){
      await onScrollToBottom(true);
    }
  };

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    if (typeof (this.props.onUnmount) !== 'undefined') this.props.onUnmount(window.scrollY);
    document.removeEventListener('scroll', this.trackScrolling);
  }

  addToCart = (product_id, quantity) => {
    const {entity, id} = this.props.match.params;
    const key = `${entity}${id}cart`;
    let cart = localStorage.getItem(key);
    if (cart === null) {
      cart = []
    } else {
      cart = JSON.parse(cart);
    }
    if (cart.length === 0) {
      cart.push({
        id: product_id,
        quantity: quantity
      });
      localStorage.setItem(key, JSON.stringify(cart));
      message.info('Product was added to the cart');
    } else {
      cart.map((v) => {
        if (v.id === product_id) {
          message.error('Product already in the cart!');
          return null;
        } else {
          cart.push({
            id: product_id,
            quantity: quantity
          });
          localStorage.setItem(key, JSON.stringify(cart));
          message.info('Product was added to the cart');
        }
        return null;
      });
    }
  }

  render() {
    const {posts, report, openUpdateModal, remove, update, htmlId, postAction, entityAction, loading, recordsLoading, create, push, groupId} = this.props;
    // if (posts.length === 0 && !recordsLoading) {
    //   return <Empty description={'No posts yet'}/>
    // }
    return (
      <div id={htmlId}>
        {this.renderForm()}
        {posts && posts.map((v, i) => {
          return <Closed
            openUpdateModal={openUpdateModal}
            update={update}
            index={i}
            key={`${v.entity}_${v.id}_${i}`}
            data={v}
            postAction={postAction}
            remove={remove}
            report={report}
            entityAction={entityAction}
            addToCart={this.addToCart}
          />
        })}
        {typeof (posts) !== 'undefined' && !loading && !recordsLoading && posts.length === 0 && ''}
        {create && (
            <CreatePostStack push={push} group={groupId}/>
        )}
      </div>
    )
  }

}

export default Form.create({ name: 'shout' })(withRouter(Posts));