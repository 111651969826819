import React, {Component} from 'react';
import { Form, Icon, Input, Switch, Upload, Select, message } from 'antd'
import {uploadPath} from '../helpers/ServerHost';
import {getBase64, beforeUpload} from '../helpers/Funcs';
import Request from '../utils/Request';
import styled from 'styled-components';
import Provider from '../utils/Provider';


const {Option} = Select;

const PicsUpload = styled.div`
  width: 100%;
  height: 150px;
  position: relative;
`;

const BackgroundImage = styled.div`
  width: 100%;
  height: 125px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
`;

const Avatar = styled.div`
  width: 104px;
  height: 104px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-repeat: no-repeat;
`;

const Layer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  position: relative;
  
  svg {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const FormWrap = styled.div`
  padding: 15px;
`;

const {TextArea} = Input;

class ProfileForm extends Component {

  constructor(props, context){
    super (props, context);
    const provider = new Provider('user');
    const profile = provider.get('profile');
    const socials = provider.get('socialNetworks');
    this.state = {
      backgroundImage: profile.backgroundImage,
      avatar: profile.basics.photo,
      name: profile.basics.name,
      about: profile.basics.about,
      website: socials.website,
      facebook: socials.facebook,
      linkedin: socials.linkedin,
      twitter: socials.twitter,
      instagram: socials.instagram,
      'private': profile.private,
      country: profile.basics.country,
      countries: []
    }
  }

  setBackground = async (file) => {
    if(beforeUpload(file)){
      let base64 = await getBase64(file);
      await this.setState({
        backgroundImage: base64,
      });
    }
  };

  setAvatar = async (file) => {
    if(beforeUpload(file)){
      let base64 = await getBase64(file);
      await this.setState({
        avatar: base64,
      });
    }
  };

  handleChange = (e) => {
    const {save} = this.props;
    this.setState({
      [e.target.name]: e.target.value
    });
    save({[e.target.name]: e.target.value})
  };

  handleCountryChange = (e) => {
    const {save} = this.props;
    this.setState({
      country: e
    });
    save({country: e})
  };

  async componentDidMount(){
    const req = new Request('utils/countries');
    const res = await req.get();
    if (res.status === 200) {
      this.setState({
        countries: res.data
      });
    } else {
      message.error('Oups! Some error happened, try again later!')
    }
  }

  render(){
    const {save} = this.props;
    const {backgroundImage, avatar, name, about, website, country, countries, facebook, linkedin, instagram, twitter} = this.state;
    const countryList = countries.map((v, i) => <Option value={v} key={i}>{v}</Option>);
    return (
      <div className="profile-form">
        <Form onSubmit={this.save} layout="vertical">
          <PicsUpload>
            <Upload
              name="background"
              listType="picture-card"
              className="background-uploader"
              showUploadList={false}
              action={uploadPath}
              beforeUpload={this.setBackground}
              customRequest={async () => {
                let req = new Request('utils/upload', {
                  file: backgroundImage
                });
                let res = await req.post();
                save({background: res.data.url});
              }}
            >
              {backgroundImage ? <BackgroundImage src={backgroundImage}><Layer><Icon type={'picture'} /></Layer></BackgroundImage> : <Icon type={'picture'} />}
            </Upload>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={uploadPath}
              beforeUpload={this.setAvatar}
              customRequest={async () => {
                let req = new Request('utils/upload', {
                  file: avatar
                });
                let res = await req.post();
                save({photo: res.data.url});
              }}
            >
              {avatar ? <Avatar src={avatar}><Layer style={{
                borderRadius: '50%'
              }}><Icon type={'picture'} /></Layer></Avatar> : <Icon type={'picture'} />}
            </Upload>
          </PicsUpload>
         <FormWrap>
           <Form.Item label="Full name">
             <Input
               name="name"
               onChange={this.handleChange}
               placeholder="Full name"
               value={name}
             />
           </Form.Item>
           <Form.Item label="About me">
             <TextArea
               placeholder="About me"
               value={about}
               name="about"
               onChange={this.handleChange}
             />
           </Form.Item>
           <Form.Item label="Location">
             <Select
               value={country}
               name="country"
               placeholder='Location'
               showArrow={true}
               onChange={this.handleCountryChange}
               notFoundContent={null}
               filterOption={false}
               style={{bottom: '1px'}}
             >
               {countryList}
             </Select>
           </Form.Item>
           <Form.Item label="Website">
             <Input
               placeholder="Website"
               value={website}
               name="website"
               onChange={this.handleChange}
             />
           </Form.Item>
           <Form.Item label="Instagram">
             <Input
                 prefix={<Icon type="instagram" style={{ color: 'rgba(0,0,0,.25)' }} />}
                 placeholder="Instagram"
                 value={instagram}
                 name="instagram"
                 onChange={this.handleChange}
             />
           </Form.Item>
           <Form.Item label="Facebook">
             <Input
                 prefix={<Icon type="facebook" style={{ color: 'rgba(0,0,0,.25)' }} />}
                 placeholder="Facebook"
                 value={facebook}
                 name="facebook"
                 onChange={this.handleChange}
             />
           </Form.Item>
           <Form.Item label="LinkedIn">
             <Input
                 prefix={<Icon type="linkedin" style={{ color: 'rgba(0,0,0,.25)' }} />}
                 placeholder="LinkedIn"
                 value={linkedin}
                 name="linkedin"
                 onChange={this.handleChange}
             />
           </Form.Item>
           <Form.Item label="Twitter">
             <Input
                 prefix={<Icon type="twitter" style={{ color: 'rgba(0,0,0,.25)' }} />}
                 placeholder="Twitter"
                 value={twitter}
                 name="twitter"
                 onChange={this.handleChange}
             />
           </Form.Item>
           <Form.Item label="Private profile">
             <Switch checked={this.state.private} onChange={async () => {
               await this.setState({
                 'private': !this.state.private
               });
               save({'private': this.state.private})
             }} />
           </Form.Item>
         </FormWrap>
        </Form>
      </div>
    )
  }

}

export default Form.create({ name: 'profile' })(ProfileForm);