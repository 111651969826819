import React, {Component} from 'react';
import { Radio } from 'antd';
import Provider from '../utils/Provider'
import Posts from './Posts';
import SessionData from '../utils/SessionData'
import Spinner from './Spinner'


class Feed extends Component {

  constructor (props, context){
    super(props, context);
    const profile = new Provider('user').get('profile')
    this.state = {
      profile: profile,
      active: false,
      loading: true
    }
  }

  handleModeChange = (e) => {
    const mode = e.target.value;
    const session = new SessionData('feed');
    session.set(mode);
    this.setState({
      active: mode
    });
  };

  async componentDidMount(){
    const session = new SessionData('feed');
    const active = session.get() ? session.get() : 'solo'
    await this.setState({
      active: active,
      loading: false
    })
  }

  render(){
    const {active, loading} = this.state;
    if (loading) return <Spinner/>;
    return (
      <div style={{paddingTop: 26}}>
        <div className="switch-buttons">
          <Radio.Group onChange={this.handleModeChange} value={active} buttonStyle="solid">
            <Radio.Button value="solo">Solo</Radio.Button>
            <Radio.Button value="groups">Groups</Radio.Button>
          </Radio.Group>
        </div>
        <Posts path={`app/${active}`} create={active === 'solo'} scroll={true} />
      </div>
    )
  }

}

export default Feed;