export default (path) => {
  return `https://api.cryptalks.app/static/client/${path}`;
}

export function uploadPath () {
  return 'https://api.cryptalks.app/utils/upload';
}

export function uploadFilePath () {
  return 'https://api.cryptalks.app/utils/upload_form_file';
}