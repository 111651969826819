import React, {Component} from 'react';
import { Modal, Typography, Input, Button, message } from 'antd';
import RatingAvatar from '../ui/RatingAvatar';
import StarRatings from 'react-star-ratings';
import Request from '../utils/Request';


const {Paragraph} = Typography;

const style = {
  margin: 0,
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
}

const { TextArea } = Input;

class RateModal extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      rating: 0,
      buttonLoad: false,
      comment: ''
    }
  }

  handleChange = (e) => {
    this.setState({
      comment: e.target.value
    })
  }

  rate = async () => {
    this.setState({
      buttonLoad: true
    })
    const {entity, close, remove, add} = this.props;
    const {rating, comment} = this.state;
    const req = new Request(`${entity.entity}/rate`, {
      [`${entity.entity}_id`]: entity.id,
      rating: rating,
      comment: comment
    });
    const res = await req.post();
    if (res.status === 200) {
      close();
      remove();
      message.info(res.data.message);
      if (typeof (add) !== 'undefined') {
        add(res.data.feedback);
      }
    } else {
      message.error(res.data.error);
      this.setState({
        buttonLoad: false
      })
    }
  }

  render(){
    const {entity, close, visible} = this.props;
    const {rating, buttonLoad, comment} = this.state;
    return (
      <Modal
        title={`Feedback for ${entity.name}`}
        visible={visible}
        footer={null}
        onCancel={close}
      >
          <RatingAvatar
            entity={entity}
          />
          <Paragraph style={style}>Give your feedback about your experience with {entity.name}!</Paragraph>
          <div style={{
            textAlign: 'center',
            maxWidth: '80%',
            margin: '0 auto'
          }}>
              <StarRatings
                rating={rating}
                starRatedColor="#fff222"
                numberOfStars={5}
                name='rating'
                starEmptyColor="#000"
                starDimension="25px"
                changeRating={(rating) => this.setState({
                  rating: rating
                })}
              />
              <TextArea onChange={this.handleChange} rows={3} value={comment} placeholder="Write a comment (optional)"/>
              <Button onClick={this.rate} loading={buttonLoad} shape="round" type="primary" block style={{
                margin: '15px 0'
              }}>Submit</Button>
          </div>
      </Modal>
    )
  }

}

export default RateModal;