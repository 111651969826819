import styled from 'styled-components';
import ServerHost from '../helpers/ServerHost'


const PostIcon = styled.div`
    width: ${props => props.type === 'wait' ? '30px' : '40px'};
    height: 50px;
    background-size: 100%;
    background-image: url("${props => ServerHost(`img/icons/${props.post}/${props.type}.png`)}");
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto 10px;
`;

export default PostIcon;