import React, {Component} from 'react';
import styled from 'styled-components';
import Avatar from "../../ui/Avatar";
import CheckMark from "../../ui/CheckMark";
import Constants from "../../Constants";
import {Icon, Typography} from 'antd';
import {NavLink} from "react-router-dom";


const {Paragraph} = Typography;

const Wrap = styled.div`
    border-bottom: 1px solid #e3e3e3;
    padding: 5px 0;
    margin: 5px 0;
    display: grid;
    grid-template-columns: 15% 85%;
`;

const Time = styled.span`
  color: #b3b3b3;
  font-size: 12px;
  margin: 0 5px;
  font-weight: 100;
`;


class PostPreview extends Component {

    render() {
        const {data} = this.props;
        let name;
        if (data.entity === 'user') {
            name = <Paragraph ellipsis={true} style={{color: data[data.entity].nameColor, fontWeight: 'bold', margin: 0}}>
                {data[data.entity].name} {data[data.entity].checkMark && <CheckMark/>} <Time>{data.createdAt}</Time>
            </Paragraph>
        } else {
            name = <Paragraph ellipsis={true} style={{color: Constants.primaryColor, fontWeight: 'bold', margin: 0}}>
                {data[data.entity].name}  <Time>{data.createdAt}</Time>
            </Paragraph>
        }

        let preview;
        switch (data.postType) {
            case 'shout':
                preview = data.data.text;
                break;
            case 'trade':
                preview = `Looks for ${data.data.type} ${data.data.amount} ${data.data.crypto} for ${data.data.price} ${data.data.currency}`;
                break;
            case 'deal':
                preview = `${data.data.title} (${data.data.cryptoPrice} ${data.data.crypto})`;
                break;
            default:
                return null;
        }

        return (<Wrap>
            <div>
                <NavLink to={`/${data.entity}/${data[data.entity].id}`}><Avatar width={48} height={48} src={data[data.entity].photo}/></NavLink>
            </div>
            <div>
                <NavLink to={`/${data.entity}/${data[data.entity].id}`}>{name}</NavLink>
                <NavLink to={`/post/${data.postType}/${data.id}`}><Paragraph ellipsis={true}>{preview}</Paragraph></NavLink>
                <span style={{marginRight: 10}}><Icon type={'message'}/> {data.comments}</span>
                <Icon type={'heart'} theme={'filled'} style={{color: Constants.secondaryColor}}/> {data.comments}
            </div>
        </Wrap>)
    }

}

export default PostPreview;