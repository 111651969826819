import React, {Component} from 'react';
import {scrolledToBottom} from "../../helpers/Funcs";
import {NavLink} from "react-router-dom";
import {Avatar, Badge, Button, Card, Col, Row} from "antd";
import CheckMark from "../../ui/CheckMark";
import Request from "../../utils/Request";


const {Meta} = Card;


class EntityRequests extends Component {

    trackScrolling = async () => {
        const {htmlId} = this.props;
        const wrappedElement = document.getElementById(htmlId);
        const {onScrollToBottom, recordsLoading} = this.props;
        if (wrappedElement && scrolledToBottom(wrappedElement) && !recordsLoading){
            await onScrollToBottom();
        }
    };

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    action = (request_action, request_id, index) => {
        const {entity, id, type, action} = this.props;
        action(type, entity, id, request_action, request_id, index);
    };

    render() {
        const {requests, htmlId} = this.props;
        return (
            <div id={htmlId}>
                {requests.map((v, i) => {
                    return (
                        <Card
                            style={{ width: '100%' }}
                            key={i}
                        >
                            <Meta
                                avatar={<NavLink to={`/user/${v.profile.id}`}>
                                    <Badge
                                        status={typeof (v.profile.online) !== 'undefined' && v.profile.online ? 'success' : false}
                                        offset={[-5, 30]}
                                        style={{zIndex: 0}}
                                    >
                                        <Avatar src={v.profile.photo} />
                                    </Badge>
                                </NavLink>}
                                title={<div>
                                    <NavLink style={{color: v.profile.nameColor}} to={`/user/${v.profile.id}`}>
                                        {v.profile.name}
                                        {v.profile.checkMark && <CheckMark/>}
                                    </NavLink>
                                </div>}
                                description={<Row>
                                    <Col md={18}>
                                        {v.profile.about}
                                    </Col>
                                    <Col md={6} style={{textAlign: 'right'}}>
                                        <Button type="danger" shape="circle" icon="close" style={{marginRight: 5}} onClick={() => this.action('decline', v.id, i)}/>
                                        <Button type="primary" shape="circle" icon="check" onClick={() => this.action('accept', v.id, i)}/>
                                    </Col>
                                </Row>}
                                extra={<Button icon={'ellipsis'} type={'link'} size={'small'}/>}
                            />
                        </Card>
                    )
                })}
            </div>
        )
    }

}

export default EntityRequests;