import React, {Component} from 'react';
import {Form, message, Input, Modal, Select} from 'antd';
import Paragraph from '../template/Paragraph';
import Request from '../utils/Request';
const {Option} = Select;

class PromoModal extends Component {

  constructor(props, context){
    super(props, context);
    this.state = {
      loading: false,
      products: []
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {form, close, data} = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        const params = Object.assign(values, {
          notification_id: data.id
        });
        const r = await new Request('product/send_promo', params).post();
        if (r.status === 200) {
          message.success(r.data.message);
          close();
        } else {
          message.error(r.data.error);
        }
        this.setState({
          loading: false
        });
      }
    });
  };

  async componentDidMount() {
    const {data} = this.props;
    const entity = data.user ? 'user' : 'group';
    const id = data.user ? data.user.id : data.group.id;
    const r = await new Request(`product/promo_list?id=${id}&entity=${entity}`).get();
    if (r.status === 200) {
      this.setState({products: r.data});
    }
  }

  render(){
    const { getFieldDecorator } = this.props.form;
    let {title, visible, close} = this.props;
    const {products} = this.state;
    return (
        <Modal
            title={title}
            visible={visible}
            onOk={this.handleSubmit}
            confirmLoading={this.state.loading}
            onCancel={close}
            okText="Send"
            width={400}
        >
          <Paragraph>Select one of suggested promo products and send it to two of your friends which are not using CrypTalks yet with 10% discount! Get 10% of the product price once your your friend will purchase the product:</Paragraph>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item>
              {getFieldDecorator('product', {
                rules: [
                  { required: true, message: 'Please select the product!' }
                ],
              })(
                  <Select
                      loading={products.length === 0}
                      placeholder="Select a product"
                  >
                    {products.map((v, i) => {
                      return <Option key={i} value={v.id}>{v.name}</Option>
                    })}
                  </Select>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('phone_1', {
                rules: [
                  { required: true, message: 'Please enter at least 1 friend\'s phone number!' }
                ],
              })(
                  <Input
                      placeholder="First friend's phone number"
                  />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('phone_2')(
                  <Input
                      placeholder="Second friend's phone number"
                  />
              )}
            </Form.Item>
          </Form>
        </Modal>
    )
  }
}

export default Form.create({ name: 'report' })(PromoModal);