import React, {Component, createRef} from 'react';
import { Button, Drawer, List, Modal, message } from 'antd'
import ShoutForm from '../forms/ShoutForm';
import UpdateModal from '../modals/UpdateModal';
import Request from '../utils/Request';
import EventForm from '../forms/EventForm';
import TradeForm from '../forms/TradeForm';
import SignalForm from '../forms/SignalForm'
import Constants from '../Constants'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Spinner from './Spinner'
import ReportModal from '../modals/ReportModal'
import DealForm from '../forms/DealForm'


const initialState = {
  showUpdateModal: false,
  form: false,
  updateModalTitle: '',
  loading: true,
  entityData: {},
  reportWindow: false
};

const { confirm } = Modal;


class PostDrawer extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      ...initialState,
    };
    this.input = createRef(null);
  }

  update = async (index, val) => {
    const {single} = this.props;
    this.props.update(index, val);
    if (single) {
      this.setState({
        showUpdateModal: false,
      });
    } else {
      this.setState(initialState);
    }
  };

  updateData = async () => {
    const {data} = this.props;
    if (Object.entries(data).length > 0 && data.constructor === Object){
      if (!data.owner){
        let req = new Request(`${data.entity}/info?${data.entity}_id=${data[data.entity].id}`);
        let res = await req.get();
        if (res.status === 200){
          this.setState({
            entityData: data.entity === 'user' ? res.data.profile : res.data,
            loading: false
          });
        } else {
          message.warning('Oups! Something wrong happen! Please try again later...');
          return null;
        }
      } else {
        this.setState({
          entityData: {},
          loading: false
        })
      }
    } else {
      this.setState({
        entityData: {},
        loading: false
      })
    }
  };

  async componentDidMount(){
    await this.updateData();
  }

  async componentDidUpdate(prevProps){
    const {data} = this.props;
    if (data !== prevProps.data){
      await this.updateData();
    }
  }

  updatePost = () => {
    const {data, close} = this.props;
    let {showUpdateModal, form} = this.state;
    switch (data.postType){
      case 'shout':
        showUpdateModal = true;
        form = <ShoutForm edit={true} data={data} update={this.update}/>;
        break;
      case 'event':
        showUpdateModal = true;
        form = <EventForm edit={true} data={data} update={this.update}/>;
        break;
      case 'trade':
        showUpdateModal = true;
        form = <TradeForm edit={true} data={data} update={this.update}/>;
        break;
      case 'signal':
        showUpdateModal = true;
        form = <SignalForm edit={true} data={data} update={this.update}/>;
        break;
      case 'deal':
        showUpdateModal = true;
        form = <DealForm edit={true} data={data} update={this.update}/>;
        break;
      default:
        return null;
    }
    this.setState({
      showUpdateModal,
      form,
      updateModalTitle: data.postType
    });
    close();
  };

  closeModal = () => {
    const {single} = this.props;
    if (single) {
      this.setState({
        showUpdateModal: false,
        reportWindow: false
      });
    } else {
      this.setState(initialState)
    }
  };

  deletePost = () => {
    const {close, data, del} = this.props;
    close();
    confirm({
      title: 'Do you want to delete this post?',
      onOk: async () => {
        let req = new Request(`post/${data.postType}/delete`, {
          post_id: data.id
        });
        let res = await req.post();
        if (res.status === 200) {
          del(data.index);
        } else {
          message.error(res.data.error);
        }
      }
    });
  };

  share = async () => {
    const {close, data} = this.props;
    console.log(data);
    let text;
    let title;
    const url = `${Constants.client}post/${data.postType}/${data.id}`;


    switch (data.postType) {
      case 'shout':
        if (data.user){
          title = data.user.name;
        } else {
          title = data.group.name;
        }
        text = data.data.text;
        break;
      case 'deal':
        title = data.data.title;
        text = data.data.description + "\n" + `${data.data.price} ${data.data.currency}`;
        break;
      case 'trade':
        if (data.user){
          title = data.user.name;
        } else {
          title = data.group.name;
        }
        text = data.data.string;
        break;
    }

    const shareData = {
      title: title,
      text: text,
      url: url
    }
    await navigator.share(shareData);
    close();
  };
  
  action = async (action) => {
    const {entityData} = this.state;
    const {close} = this.props;
    let req = new Request(`${entityData.entity}/${action}`, {
      [`${entityData.entity}_id`]: entityData.id
    });
    let res = await req.post();
    if (res.status === 200){
      message.info(res.data.message);
      this.setState({
        entityData: res.data.data
      });
      close();
    } else {
      message.error(res.data.error);
    }
  };

  render () {
    const {data, close} = this.props;
    const {showUpdateModal, form, updateModalTitle, loading, entityData, reportWindow, reportSource} = this.state;
    const link = `${Constants.client}post/${data.postType}/${data.id}`;
    let listData = [
      <Button
        onClick={this.share}
        block
        style={{textAlign: 'left'}}
        type="link"
        icon="share-alt">
        Share {data.postType}
      </Button>
    ];
    if (data.owner) {
      listData = [
        ...listData,
        <Button onClick={this.deletePost} block style={{textAlign: 'left'}} type="link" icon="delete" key="delete">Delete</Button>
      ];
      if (data.updateAllowed) listData.unshift(<Button onClick={this.updatePost} block style={{textAlign: 'left'}} type="link" icon="edit" key="update">Edit</Button>);
    } else {
      listData = [
        ...listData,
      ];
      if (Object.entries(entityData).length > 0 && entityData.constructor === Object) {
        listData = [
          ...listData,
          <Button onClick={() => this.action('follow')} block style={{textAlign: 'left'}} type="link" icon={entityData.entity === 'user' ? entityData.following ?  "user-delete" : 'user-add' : entityData.following ? "usergroup-delete" : 'usergroup-add'} key="follow">{entityData.following ? 'Stop follow' : 'Start follow'} {`${entityData.basics.name}`}</Button>,
          <Button onClick={() => this.action('mute')} block style={{textAlign: 'left'}} type="link" icon="sound" key="mute">{entityData.muted ? 'Take off mute from' : 'Mute'} {`${entityData.basics.name}`}</Button>,
          <Button onClick={() => {this.setState({reportWindow: true, reportSource: link}); close();}} block style={{textAlign: 'left'}} type="link" icon="warning" key="report">Report {`${entityData.basics.name}`}</Button>
        ];
        if (entityData.entity === 'user'){
          listData = [
            ...listData,
            <Button onClick={() => this.action('block')} block style={{textAlign: 'left'}} type="link" icon={entityData.blocked ? "check-circle" : 'close-circle'} key="block">{entityData.blocked ? 'Unblock' : 'Block'} {`${entityData.basics.name}`}</Button>
          ];
        }
      }
    }
    return (
     <div>
       <Drawer
         placement="bottom"
         closable={false}
         visible={this.props.visible}
         zIndex={1010}
         height="auto"
         onClose={this.props.close}
       >
         {loading ? <Spinner/> : listData.length > 0 && <List
           dataSource={listData}
           size='small'
           renderItem={item => (
             <List.Item>
               {item}
             </List.Item>
           )}
         />}
         <Button
           shape="round"
           onClick={close}
           block
           type="primary">
           Close
         </Button>
       </Drawer>
       <UpdateModal
         model={updateModalTitle}
         visible={showUpdateModal}
         form={form}
         close={this.closeModal}
       />
       <ReportModal
         visible={reportWindow}
         close={this.closeModal}
         source={reportSource}
       />
     </div>
    )
  }

}

export default PostDrawer;