import React, {Component} from 'react';
import Request from '../utils/Request';
import Spinner from './Spinner';
import {message} from 'antd';
import NotificationWrapper from './notifications/NotificationWrapper';
import {scrolledToBottom} from '../helpers/Funcs';
import SessionData from '../utils/SessionData';
import Nothing from '../template/Nothing'


class Notifications extends Component {

  constructor(props, context){
    super (props, context);
    this.state = {
      loading: true,
      notifications: [],
      limit: 12,
      page: 1,
      recordsLoading: false,
      recordsLoadingStarted: false,
      fullLoad: false
    }
  }

  getNotifications = async () => {
    const {limit, page, notifications} = this.state;
    const route = `/notifications?rpp=${limit}&page=${page}`;
    const session = new SessionData(route);
    if (!session.get()) {
      let req = new Request(`user/notifications?rpp=${limit}&page=${page}`);
      let res = await req.get();
      if (res.status === 200) {
        if (res.data.length > 0) {
          this.setState({
            notifications: [...notifications, ...res.data],
            recordsLoading: false,
            loading: false,
            recordsLoadingStarted: false
          });
          session.set(res.data);
        } else {
          this.setState({
            recordsLoading: false,
            loading: false,
            page: page - 1
          })
        }
      } else {
        message.warning('Oups! Something wrong happen! Please try again later...');
        return null;
      }
    } else {
      let prevPosts = [];
      for (let i = 1; i < page + 1; i++) {
        let prevRoute = `/notifications?rpp=${limit}&page=${i}`;
        let prevRec = new SessionData(prevRoute);
        prevPosts = [...prevPosts, ...prevRec.get()];
      }
      await this.setState({
        notifications: prevPosts,
        recordsLoading: false,
        recordsLoadingStarted: false,
        loading: false
      });
    }
  }

  trackScrolling = async () => {
    const wrappedElement = document.getElementById('notifications-list');
    if (wrappedElement){
      const {fullLoad, recordsLoadingStarted} = this.state;
      if (scrolledToBottom(wrappedElement) && !fullLoad && !recordsLoadingStarted) {
        await this.setState({
          recordsLoading: true,
          recordsLoadingStarted: true,
          page: this.state.page+1
        });
        await this.getNotifications();
      }
    }
  };

  async componentDidMount(){
    document.addEventListener('scroll', this.trackScrolling);
    const page = new SessionData(`page:notifications`)
    await this.setState({
      page: page.get() ? page.get() : 1
    });
    await this.getNotifications();
    window.scrollTo(0, new SessionData(`scroll:notifications`).get());
  }
  
  remove = async (index) => {
    const {notifications} = this.state;
    notifications.splice(index, 1);
    await this.setState({
      notifications: notifications
    });
  }

  componentWillUnmount() {
    const offset = window.pageYOffset;
    const session = new SessionData(`scroll:notifications`);
    session.set(offset);
    const page = new SessionData(`page:notifications`)
    page.set(this.state.page);
    document.removeEventListener('scroll', this.trackScrolling);
  }

  update = (index, key, value) => {
    const {notifications, limit, page} = this.state;
    notifications[index][key] = value;
    this.setState({notifications: notifications});
    const route = `/notifications?rpp=${limit}&page=${page}`;
    const session = new SessionData(route);
    session.set(notifications);
  }

  render (){
    const {loading, notifications, recordsLoading, fullLoad} = this.state;
    if (loading) return <Spinner/>
    if (notifications.length === 0) return <Nothing>Nothing there yet :(</Nothing>
    return (
      <div id="notifications-list">
        {notifications.map((v, i) => (<NotificationWrapper update={this.update} index={i} remove={() => this.remove(i)} data={v} key={i}/>))}
        {recordsLoading && !fullLoad && <Spinner/>}
      </div>
    )
  }

}

export default Notifications;