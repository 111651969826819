import styled from 'styled-components';
import ServerHost from '../helpers/ServerHost'


const IAm = styled.div`
    width: 100px;
    height: 100px;
    background-size: 100%;
    background-image: url("${ServerHost(`img/iam.png`)}");
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto 10px;
`;

export default IAm;