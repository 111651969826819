import React, {Component} from 'react';
import {Modal, message, Button, Typography} from 'antd';
import Request from '../utils/Request'

const {Paragraph, Title} = Typography;

class AmlModal extends Component {

  constructor (props, context) {
    super (props, context);
    this.state = {
      accepted: props.accepted,
      loading: false
    }
  }

  accept = async () => {
    this.setState({
      loading: true
    })
    const {close, save, path} = this.props;
    if (path) {
      const req = new Request(path, {
        aml: true
      });
      const res = await req.post();
      if (res.status === 200) {
        this.setState({
          accepted: res.data.data.kyc.aml.status,
          loading: false
        });
        save(res.data.data.kyc);
        message.info(res.data.message);
        close();
      }
    } else {
      save(true);
      message.info('You have accepted AML policy');
      close();
    }
  }

  render() {
    const {visible, close} = this.props;
    const {accepted, loading} = this.state;
    return (
      <Modal
        title="Anti Money Laundring Policy"
        visible={visible}
        footer={null}
        onCancel={close}
        style={{ top: 10 }}
      >
        <Title level={4}>ANTI-MONEY LAUNDERING (AML) AND COUNTER-TERRORIST FINANCING (CTF) POLICY</Title>
        <Paragraph>Money laundering is participation in, association in committing, attempts to commit, aiding, abetting, facilitating, counseling the commission and commission of:</Paragraph>

        <Paragraph>1. the conversion or transfer of property, knowing that such property is derived from criminal activity or an act of participation in such action, for concealing or disguising the illicit origin of the property or of assisting any person who is involved in the commission of such an activity to evade the legal consequences of that person's action;</Paragraph>

        <Paragraph>2. the concealment or disguise of the true nature, source, location, disposition, movement, rights with respect to, or ownership of, property, knowing that such property is derived from criminal activity or an act of participation in such an action; and</Paragraph>

        <Paragraph>3. the acquisition, possession or use of property, knowing, at the time of receipt, that such property was derived from criminal activity or an act of participation in such an action.</Paragraph>

        <Paragraph>Terrorist financing is provision or collection of funds, by any means, directly or indirectly, with the intention that they are used or in the knowledge that they are to be used, in full or in part, to damage a country or an international organization with the aim of:</Paragraph>

        <Paragraph>1. seriously intimidating a population, or</Paragraph>

        <Paragraph>2. unduly compelling a government or international organization to perform or abstain from performing any act, or</Paragraph>

        <Paragraph>3. seriously destabilizing or destroying the fundamental political, constitutional, economic or social structures of a country or an international organization.</Paragraph>

        <Paragraph>To address money-laundering and terrorist financing the European Union (EU) enacted various Directives. However, none of the Directives dealt with virtual currency until the EU adopted Directive (EU) 2018/843 (AMLD5). While Directive requires implementation by the Member States by January 2020 and cannot be applied without transposition into national law (except for cases that involve the vertical direct effect, indirect effect of the EU Directives), we consider that it is essential to join the EU in its attempt to combat illicit activities.</Paragraph>

        <Paragraph>It should be noted that CrypTalk decided to adopt internal systems and procedures that reflect high standards of the EU Directives.</Paragraph>

        <Paragraph>Our AML/CTF systems and procedures may include but not limited to:</Paragraph>

        <Paragraph>1. Risk assessment. It includes identification, where appropriate, sectors or areas of lower or higher risk of money laundering and terrorist financing; making relevant information available promptly to obliged entities; identification of possible risks related to different types of clients based on their geographical location, professional area, income, etc.</Paragraph>

        <Paragraph>2. Customer Due Diligence. It includes collecting customers` identity on the basis of documents, data or information obtained from the reliable source and provided by them; gathering information on beneficial owner inside and outside the EU; collecting data of the business relationships and their nature; ongoing monitoring of the business and client; identification of a beneficial ownership in case of business clients and check of the beneficial owner.</Paragraph>

        <Paragraph>3. Reporting. It includes informing relevant regulator in case of some suspicious activity or client; keeping records; notifications in case of serious breaches of applicable legislation.</Paragraph>

        <Paragraph>4. Dealing with Politically Exposed Persons (PEPs) and sanctioned countries and persons. It includes checking not only domestic PEPs (all inside the EU) but foreign PEPs (outside the EU) as well; as well as checking whether persons or the country they are coming from, are in the US, EU or UN sanction lists.</Paragraph>

        <Paragraph>To minimize risks and scalability of money-laundering and terrorist financing, we may impose the following verification requirements and limits:</Paragraph>

        <Paragraph>1) Before getting access to the Services in according to our Terms of Use, User is obliged to go through verification procedure to have his CrypTalk Account activated.</Paragraph>

        <Paragraph>2) To be verified User may be required to provide CrypTalk with personal information, a proof of identity (two sides if applicable and Selfie with an identity document); a proof of residence (bank account statement not older or utility bill on the name of the person issued no later than two months since the verification procedure started.</Paragraph>

        <Paragraph>3) For a corporate client may be required to provide information about the company (legal entity name, incorporation documents, registration number, contact information and identity records of beneficial owners who hold 10% or more of the company).</Paragraph>

        <Button onClick={this.accept} disabled={accepted} loading={loading} shape="round" type={accepted ? 'default' : 'primary'} block style={{
          margin: '15px 0'
        }}>{accepted ? 'Accepted' : 'Accept'}</Button>
      </Modal>
    )
  }

}

export default AmlModal;