import styled from 'styled-components';

export default styled.div`
  padding: 40px;
  text-align: center;
  width: 100%;
  color: #a3a3a3;
  font-size: 16px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #329ab7;;
`;