import React, {Component} from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import Container from '../template/Container';
import Spinner from '../ui/Spinner';
import {Typography} from 'antd';
import Request from '../utils/Request';
import Provider from '../utils/Provider'
import Blank from '../template/Blank'


const {Title} = Typography;


class ConfirmationScreen extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      token: props.match.params.token,
      loading: true,
      text: '',
      redirect: false
    }
  }

  async componentDidMount(){
    const {token} = this.state;
    let res = await Request.create('get', 'user/confirm', {}, {
      'Email': token
    });
    if (res.status === 200){
      this.setState({
        text: res.data.message + ' You will be redirected immediately.',
        loading: false
      });
      const provider = new Provider('user');
      provider.set(res.data.data);
      setTimeout(() => this.setState({
        redirect: true
      }), 3000);
    } else {
      this.setState({
        text: res.data.error,
        loading: false
      })
    }
  }

  render(){
    const {loading, text, redirect} = this.state;
    const containerStyle = {
      textAlign: 'center',
      padding: '20px'
    }
    if (loading) return <Container style={containerStyle}><Spinner/></Container>;
    if (redirect) return <Redirect to="/"/>
    return (
      <Blank content={<Container style={containerStyle}>
        <Title level={3}>{text}</Title>
      </Container>}/>
    )
  }

}

export default withRouter(ConfirmationScreen);