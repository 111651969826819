import React, {Component} from 'react';
import { Skeleton, Card} from 'antd';

class PostLoader extends Component {

  render(){
    return (
      <Card style={{ width: '100%'}}>
        <Skeleton loading={true} avatar active/>
      </Card>
    )
  }

}

export default PostLoader;