import React, {Component} from 'react';
import {Checkbox, Button, Col, Form, Input, InputNumber, message, Row, Select, Typography, Icon, Upload, Tooltip} from "antd";
import {getBase64, beforeUpload, getCurrentPrice} from '../helpers/Funcs';
import Request from "../utils/Request";
import {uploadPath} from "../helpers/ServerHost";
import PaymentModal from "../modals/PaymentModal";
import styled from "styled-components";
import Spinner from "../ui/Spinner";

const {Option} = Select;
const { Title } = Typography;

const PicsWrapper = styled.div`
    display: grid;
    width: calc(100% - 60px);
    grid-template-columns: repeat(4, 25%);
    grid-gap: 15px;
    margin-top: 15px;
`;

const Pic = styled.div`
  position: relative;
  padding-top: 100%;
  border: 1px solid #58a3cf;
  border-radius: 4px;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
`;

const PicLoader = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`

const initialState = {
    loading: false,
    currencyList: [
        {value: 'USD', text: 'USD'},
        {value: 'EUR', text: 'EUR'},
        {value: 'JPY', text: 'JPY'},
        {value: 'GBP', text: 'GBP'},
        {value: 'AUD', text: 'AUD'},
        {value: 'CAD', text: 'CAD'},
        {value: 'CHF', text: 'CHF'},
        {value: 'CNY', text: 'CNY'},
        {value: 'SEK', text: 'SEK'},
        {value: 'NZD', text: 'NZD'},
    ],
    title: null,
    description: null,
    discount: 0,
    currency: 'USD',
    price: 0,
    cryptoPrice: 0,
    attributesCount: 0,
    attrs: [],
    images: [],
    uploadProps: null,
    imagesList: [],
    posting: false,
    promo: false,
    paymentModal: false,
    paymentLink: null,
    coinify: false,
    fixed_amount: false,
    picLoading: false,
    in_stock: 0
}

class ProductForm extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = initialState;
    }

    setCurrentPrice = async () => {
        const {crypto, currency, price} = this.state;
        let cryptoPrice = await getCurrentPrice(crypto, currency);
        await this.setState({
            cryptoPrice: parseFloat(price) / cryptoPrice
        });
        return null;
    }

    handlePriceChange = async (val) => {
        await this.setState({
            price: val
        });
        await this.setCurrentPrice();
    }

    handleCryptoChange = async (val) => {
        await this.setState({
            crypto: val
        });
        await this.setCurrentPrice();
    }

    handleCurrencyChange = async (val) => {
        await this.setState({
            currency: val
        });
        await this.setCurrentPrice();
    }

    handleCryptoSearch = async (value) => {
        if (value) {
            let req = new Request(`utils/search_crypto?query=${value}`);
            let res = await req.get();
            if (res.status === 200){
                this.setState({ cryptoList: res.data });
            } else {
                message.error(res.data.error)
            }
        } else {
            this.setState({ cryptoList: [] });
        }
    };

    handleCurrencySearch = async (value) => {
        if (value) {
            let req = new Request(`utils/search_crypto?query=${value}`);
            let res = await req.get();
            if (res.status === 200){
                this.setState({ currencyList: res.data });
            } else {
                message.error(res.data.error)
            }
        } else {
            this.setState({ currencyList: [] });
        }
    };

    async componentDidMount() {
        const {edit, data} = this.props;
        if (edit) {
            this.setState({
                crypto: data.convert_to,
                attrs: data.attributes,
                images: data.picsLinks,
                title: data.name,
                ...data
            })
        }
        this.setState({
            loading: false
        });
    }

    addAttr = () => {
        const {attrs} = this.state;
        attrs.push({key: null, value: null});
        this.setState({attrs: attrs});
    }

    handleAttrChange = (e) => {
        const {target} = e;
        const name = target.name;
        const value = target.value;
        const index = target.dataset.index;
        const {attrs} = this.state;
        attrs[index][name] = value;
        this.setState({
            attrs: attrs
        });
    }

    removeAttr = (index) => {
        const {attrs} = this.state;
        attrs.splice(index, 1);
        this.setState({attrs: attrs});
    }

    bu = async (file) => {
        if(beforeUpload(file)){
            let base64 = await getBase64(file);
            this.setState({
                imageUrl: base64,
            })
        }
    }

    close = async () => {
        const {close} = this.props;
        this.setState(initialState);
        close();
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            posting: true
        });
        const {entity, id, push, data, edit, update, index} = this.props;
        const {
            title,
            description,
            discount,
            attrs,
            images,
            imagesList,
            price,
            currency,
            crypto,
            promo,
            coinify,
            fixed_amount,
            in_stock,
        } = this.state;
        const postData = {
            name: title,
            description: description,
            picsLinks: images,
            pics: imagesList,
            price: price,
            currency: currency,
            convert_to: crypto,
            attributes: attrs,
            [`${entity}_id`]: id,
            discount: discount,
            promo: promo,
            coinify: coinify,
            fixed_amount: fixed_amount,
            in_stock: in_stock
        }
        if (edit) postData.id = data.id;
        const r = await new Request(`product/${edit ? 'update' : 'create'}`, postData).post();
        if (r.status === 200){
            message.success(r.data.message);
            if (!edit) {
                push(r.data.data);
                initialState.images = [];
                this.setState(initialState);
            } else {
                update(index, r.data.data)
            }
            await this.close();
        } else if (r.status === 307) {
            this.setState({
                paymentModal: true,
                paymentLink: r.data.redirect
            })
        } else {
            message.error(r.data.error);
        }
        this.setState({
            posting: false
        });
    }

    setValues = e => {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        });
    }

    removePic = (i) => {
        const {images} = this.state;
        images.splice(i, 1);
        this.setState({images: images});
    }

    render(){
        const {
            currency,
            price,
            currencyList,
            attrs,
            imageUrl,
            images,
            posting,
            promo,
            paymentModal,
            paymentLink,
            title,
            discount,
            in_stock,
            description,
            picLoading,
            fixed_amount
        } = this.state;
        const currencyOptions = currencyList.map((v, i) => <Option value={v.value} key={i}>{v.text}</Option>);
        const {edit, data, entity} = this.props;
        const attributes = [];
        for (let i = 0; i < attrs.length; i++) {
            attributes.push(
                <div key={i} style={{margin: '10px 0'}}>
                    <Row gutter={16}>
                        <Col xs={8}>
                            <Input
                                placeholder="Key"
                                name={'key'}
                                data-index={i}
                                onChange={this.handleAttrChange}
                                value={attrs[i].key}
                            />
                        </Col>
                        <Col xs={16}>
                            <Input
                                placeholder="Value"
                                name={'value'}
                                data-index={i}
                                onChange={this.handleAttrChange}
                                value={attrs[i].value}
                                addonAfter={<Button onClick={() => this.removeAttr(i)} size={'small'} type={'link'}><Icon type="close" /></Button>}
                            />
                        </Col>
                    </Row>
                </div>
            )
        }

        const uploadProps = {
            showUploadList: false,
            action: uploadPath,
            beforeUpload: this.bu,
            multiple: true,
            customRequest: async () => {
                this.setState({picLoading: true})
                let req = new Request('utils/upload', {
                    file: imageUrl
                });
                let res = await req.post()
                images.push(res.data.url);
                this.setState({
                    images: images,
                    picLoading: false
                });
            },

        };
        return (
            <Form style={{marginTop: 15}} className="post-form">
                <Form.Item hasFeedback>
                    <Input
                        placeholder="Title"
                        name={'title'}
                        onChange={this.setValues}
                        value={title}
                    />
                </Form.Item>
                <Form.Item hasFeedback>
                    <Row>
                        <Col xs={12}>
                            <Title level={4}>Price</Title>
                        </Col>
                        <Col xs={12}>
                            <div className="currency-input">
                                <InputNumber
                                    value={price}
                                    type="number"
                                    placeholder="Price"
                                    name={'price'}
                                    onChange={v => this.setState({
                                        price: v
                                    })}
                                />
                                <Select
                                    showSearch
                                    value={currency}
                                    name="currency"
                                    placeholder='Currency'
                                    showArrow={true}
                                    onChange={v => this.setState({currency: v})}
                                    filterOption={false}
                                >
                                    {currencyOptions}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row>
                        <Col xs={12}>
                            <Title level={4}>Discount</Title>
                        </Col>
                        <Col xs={12}>
                            <InputNumber
                                style={{width: '100%', marginBottom: 10}}
                                placeholder="Discount"
                                name={'discount'}
                                onChange={v => this.setState({
                                    discount: v
                                })}
                                value={discount}
                                disabled={promo}
                                max={100}
                            />
                        {/*    <Checkbox checked={promo} onChange={(e) => {*/}
                        {/*        this.setState({*/}
                        {/*            promo: e.target.checked*/}
                        {/*        });*/}
                        {/*    }}>Promo</Checkbox> <Tooltip title={*/}
                        {/*    'Promote your store by adding promo products to it! Users, purchased products from your shop will have an ability to spread your promo products all over the world so you will get bigger profits from your business!'*/}
                        {/*}><Icon type={'question-circle'} /></Tooltip>*/}
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row>
                        <Col xs={24}>
                            <Checkbox checked={fixed_amount} onChange={(e) => {
                                this.setState({
                                    fixed_amount: e.target.checked,
                                    in_stock: 0
                                });
                            }}>Fixed amount</Checkbox>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row>
                        <Col xs={12}>
                            <Title level={4}>In stock</Title>
                        </Col>
                        <Col xs={12}>
                            <InputNumber
                                style={{width: '100%', marginBottom: 10}}
                                placeholder="In stock"
                                name={'in_stock'}
                                onChange={v => this.setState({
                                    in_stock: v
                                })}
                                value={in_stock}
                                disabled={!fixed_amount}
                            />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Input.TextArea
                        placeholder="Description (optional)"
                        rows={2}
                        name={'description'}
                        value={description}
                        onChange={this.setValues}
                    />
                </Form.Item>
                <Form.Item>
                    <Row>
                        <Col xs={24} style={{margin: '10px 0'}}>
                            <Title level={4}>Attributes <Button onClick={this.addAttr} type="primary" shape="circle" size={'small'}>+</Button></Title>
                        </Col>
                    </Row>
                    {attributes}
                </Form.Item>
                <Form.Item>
                    <Upload {...uploadProps}>
                        <Button><Icon type={'upload'}/> Upload Pics</Button>
                    </Upload>
                    {picLoading && <PicLoader>
                        <Spinner />
                    </PicLoader>}
                    <PicsWrapper>
                        {images.map((v, i) => {
                            return <Pic key={i} src={v}>
                                <Button onClick={() => this.removePic(i)} style={{
                                    position: 'absolute',
                                    top: 5,
                                    right: 5
                                }} size={'small'} shape={'circle'} icon={'close'}/>
                            </Pic>
                        })}
                    </PicsWrapper>
                </Form.Item>
                <Form.Item>
                    <Button
                        loading={posting}
                        shape="round"
                        block
                        type="primary"
                        htmlType="submit"
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                </Form.Item>
                <PaymentModal
                    visible={paymentModal}
                    link={paymentLink}
                    close={() => this.setState({
                        paymentModal: false
                    })}
                />
            </Form>
        )
    }
}

export default ProductForm;