import styled from 'styled-components';


const MethodIcon = styled.span`
    width: 15px;
    height: 15px;
    background-image: url("${props => props.icon}");
    background-size: contain;
        display: inline-block;
    margin: 0 5px;
    background-repeat: no-repeat;
`;

export default MethodIcon;